import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Location} from 'src/app/models/location';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(private httpclient: HttpClient ,  private router: Router) {
  }
  // https://uclean.in/api/v1/services?franchise=UCLEAN&lat=28.4917218&lng=77.2886424

  servicesUrl = environment.baseUrl + 'webusers/services?franchise=' + environment.franchise; //DONE
  slotsUrl = environment.baseUrl + 'stores/getSlots?franchise=' + environment.franchise;  //Done
  buildOrderUrl = environment.baseUrl + 'webusers/getAllBookings?franchise=' + environment.franchise;
  addressesUrl = environment.baseUrl + 'webusers/getUserAddresses?franchise=' + environment.franchise;  //Done
  createOrderUrl = environment.baseUrl + 'webusers/bookings';
  applyCouponUrl = environment.baseUrl + 'coupons'   //NOT WORKING

  services(location: Location) {
    let url = this.makeUrls(this.servicesUrl, location, undefined, undefined,
      undefined, undefined);
    return this.httpclient.get(url);
  }

  slots(location: Location, store_id: any, selectedServiceIds: any) {
    let url = this.makeUrls(this.slotsUrl, location, store_id, selectedServiceIds, undefined, undefined);
    
    return this.httpclient.get(url).pipe(
      catchError((error) => {
        // Programmatically navigate to login prompt
        this.router.navigate(['login-prompt'], {
          // Optional: pass error details if needed
          queryParams: {
            errorMessage: 'API call failed',
            errorStatus: error.status
          }
        });

        // Log the error for debugging
        console.error('API Call Failed', {
          url: url,
          errorStatus: error.status,
          errorMessage: error.message
        });

        // Rethrow the error
        return throwError(() => error);
      })
    );
  }

  buildOrder(location: Location, store_id: any, selectedServiceIds: any, slot_id: any, address_id: any) {
    let url = this.makeUrls(this.buildOrderUrl, location, store_id, selectedServiceIds, slot_id, address_id);
    return this.httpclient.get(url);
  }

  createOrder(store_id: any, service_ids: any, slot_id: any, address_id: any, suggestion: any, coupon_code: any, coupon_id: any) { 
    console.log('Service method - Address ID received:', address_id);
    
    let url = this.createOrderUrl; 
    let body = { 
        "franchise": environment.franchise, 
        "store_id": store_id, 
        "slot_id": slot_id, 
        "address_id": address_id, 
        "services": service_ids, 
        'suggestion': suggestion, 
        "coupon_code": undefined, 
        "coupon_id": undefined, 
        "orderthrough_id": 5
    }; 

    console.log('Request body before coupon check:', body);
    
    if(coupon_id != undefined){ 
        body.coupon_id = coupon_id; 
        body["coupon_code"] = coupon_code; 
    } 

    console.log('Final request body:', body);
    return this.httpclient.post(url, body); 
} 


  applyCoupon(store_id: any, selectedServiceIds: any, coupon_code: any){
    let url = this.applyCouponUrl;
    let body = { "franchise": environment.franchise, "store_id": store_id, "coupon_code": coupon_code,
      "services": selectedServiceIds};
    return this.httpclient.post(url, body);
  }

  private makeUrls(url: string, location: Location, store_id: any, selectedServiceIds: any, slot_id: any, address_id: any) {
    if (location != undefined) {
      url = url + '&lat=' + location.lat + '&lng=' + location.lng;
    }

    if (store_id != undefined) {
      url = url + '&store_id=' + store_id;
    }

    if (selectedServiceIds != undefined && selectedServiceIds.length > 0) {
      //send services as separate fields
      for (let serviceId of selectedServiceIds) {
        url = url + '&services[]=' + serviceId;
      }
    }

    if (slot_id != undefined) {
      url = url + '&slot_id=' + slot_id;
    }

    if (address_id != undefined) {
      url = url + '&address_id=' + address_id;
    }
    return url;
  }

}
