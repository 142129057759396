import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bag-cleaning',
  templateUrl: './bag-cleaning.component.html',
  styleUrls: ['./bag-cleaning.component.scss']
})
export class BagCleaningComponent implements OnInit {
  bannerImageUrl = `${environment.imageBaseUrl}Banner/BagCleaning.jpg`;
  bagCleaningImage: string = `${environment.imageBaseUrl}cta/BagCleaning.jpg`;
  processSteps = [
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step6.svg`,
      title: 'Inspection',
      description: 'Inspect bags for overall condition, material type, and level of dirt and wear. Remove any accessories for a separate cleaning.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step5.svg`,
      title: 'Stain Treatment',
      description: 'Dry brushing to gently remove visible dirt and debris. Pre-wash treatment for all visible stains with specialized stain removers. Focus on tough spots for thorough cleaning.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step4.svg`,
      title: 'Surface Cleaning',
      description: 'Cleaning of outer surface of the bag using material-specific foam or cream cleanser.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step3.svg`,
      title: 'Inner Cleaning',
      description: 'Comprehensive cleaning of insides of the bag for a fresh, odor-free, and spotless finish.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step2.svg`,
      title: 'Drying',
      description: 'Air-dry bags in a well-ventilated area, avoiding direct sunlight to prevent warping and odors.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step1.svg`,
      title: 'Conditioning',
      description: 'Bag’s surface is gently buffed with cream conditioner to restore suppleness and shine.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step3.svg`,
      title: 'Quality Check',
      description: 'Inspect bags to ensure they are clean and in excellent condition. Accessories are re-attached.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step2.svg`,
      title: 'Packaging',
      description: 'Bags are packaged in transparent packaging with humidity catchers.'
    }
  ];
  

  constructor() { }

  ngOnInit(): void {
  }

}
