<div class="delete-account-container">
    <div class="delete-header">
        <img src="//assets.ucleanlaundry.com/assets/images/delete.svg" style="margin-bottom: 20px !important;">
      <h1>DELETE ACCOUNT</h1>
    </div>
      <div class="description">
        <p>This is Delete Account page for deleting your user account on UClean.</p><p>This will permanently delete your account and you will loose access to all data and services effective immediately.</p>
        
        <p>We value your feedback and would appreciate it if you could let us know why you are deleting your account. Please choose one of the options below:</p>
      </div>
      
    
      <form [formGroup]="deleteForm" (ngSubmit)="onSubmit()">
        <div class="reason-section">
          <h2>Reason for deleting your account</h2>
          <p class="subtitle">If you need to delete an account and you're prompted to provide a reason:</p>
        <div >
          <label class="custom-checkbox" >
            <input type="radio" formControlName="reason" value="No longer using the service/platform">
            <span class="checkbox-mark"></span>
            <span class="checkbox-label">No longer using the service/platform</span>
          </label>
  
          <label class="custom-checkbox">
            <input type="radio" formControlName="reason" value="Found a better alternative">
            <span class="checkbox-mark"></span>
            <span class="checkbox-label">Found a better alternative</span>
          </label>
  
          <label class="custom-checkbox">
            <input type="radio" formControlName="reason" value="Privacy concerns">
            <span class="checkbox-mark"></span>
            <span class="checkbox-label">Privacy concerns</span>
          </label>
  
          <label class="custom-checkbox">
            <input type="radio" formControlName="reason" value="Too many emails/notifications">
            <span class="checkbox-mark"></span>
            <span class="checkbox-label">Too many emails/notifications</span>
          </label>
  
          <label class="custom-checkbox">
            <input type="radio" formControlName="reason" value="Difficulty navigating the platform">
            <span class="checkbox-mark"></span>
            <span class="checkbox-label">Difficulty navigating the platform</span>
          </label>
  
          <label class="custom-checkbox">
            <input type="radio" formControlName="reason" value="Account security concerns">
            <span class="checkbox-mark"></span>
            <span class="checkbox-label">Account security concerns</span>
          </label>
  
          <label class="custom-checkbox">
            <input type="radio" formControlName="reason" value="Personal reasons">
            <span class="checkbox-mark"></span>
            <span class="checkbox-label">Personal reasons</span>
          </label>
  
          <label class="custom-checkbox">
            <input type="radio" formControlName="reason" value="Others">
            <span class="checkbox-mark"></span>
            <span class="checkbox-label">Others</span>
          </label>
        </div>
      </div>
  
      <div class="message-section" *ngIf="showMessageBox">
        <h3>Write A Message</h3>
        <textarea 
          formControlName="message" 
          placeholder="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed"
        ></textarea>
      </div>
  
      <div class="agreement">
        <label class="custom-checkbox agreement-checkbox">
          <input type="checkbox" formControlName="agreement">
          <span class="checkbox-mark"></span>
          <span class="checkbox-label">I understand that deleting my account is permanent, and the data cannot be restored.</span>
        </label>
      </div>
      <button 
    
      type="submit"
      [disabled]="!agreementValue"
      class="delete-btn"
      [ngClass]="{'disabled': !agreementValue}"
    >
      Delete Account
    </button>
    </form>
  </div>
  
  <div *ngIf="showLoginModal" class="modal-overlay">
   
    <div class="modal-content">
      <h2>WARNING !</h2>
      <p>Are you sure you want to delete your account? This action cannot be undone.</p>
      
      <div class="modal-actions">
        <button type="button" (click)="closeLoginModal()">Cancel</button>
        <button type="button" (click)="redirectToLogin()">Proceed to Login</button>
      </div>
    </div>
  </div>