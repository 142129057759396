<section class="hp_banner_section" [style.background-image]="'url(' + bannerImageUrl + ')'">
    <div class="container">
      <div class="row">
        <div class="hp_banner_block">
          <div class="hp_banner_tilte">
            <h3 class="baner-top">Carpet Cleaning</h3>
            <h3 class="banner-botomm">Eco-Friendly - Expert Stain Removal</h3>
          </div>
  
        </div>
      </div>
    </div>
  </section>
 
  <div class="service-body">
    <h2 class="process-title" style="margin-bottom: 30px;">
      A Glance at Our Carpet Cleaning Service 
    </h2>
    <div class="body body-text">
      Is your carpet quietly crying out for help? As the unsung hero of your home, it bears the brunt of daily life, collecting memories and mishaps alike. At UClean, we understand that a clean carpet is the heartbeat of a fresh home. Our expert carpet cleaning service breathes new life into your floors, lifting stains, banishing odors, and restoring vibrant colors. Our expert cleaning doesn't just lift stains and revive colors; it wages war on hidden germs and allergens. Transform your living space from the ground up! Let UClean's magic touch revitalize your carpets, leaving your home feeling renewed, inviting, and OH-SO-COZY. Ready to fall in love with your floors again?
      From wool and silk to polyester and nylon, at UClean, we specialize in providing professional and efficient cleaning services for all types of carpets and rugs. Our experienced and certified technicians have years of experience in the carpet and rug cleaning industry, and are trained to provide the best possible results. We use only the highest quality cleaning products and techniques to ensure that your carpets and rugs are cleaned effectively and safely.      
    </div>
    </div>
            <div class="process">
               
                    <h2 class="process-title">OUR CARPET CLEANING PROCESS</h2>
                    <p class="process-description">Every cloth goes through a comprehensive 6 stage process enabling us to deliver on the promise of sparkling & fresh clothes, every time.</p>
                    
                    <div class="process-timeline">
                      <div class="process-step" *ngFor="let step of processSteps; let last = last">

                        <div class="step-icon">
                            <img [src]="step.iconPath" [alt]="step.title" class="custom-icon">
                          </div>
                    
                        <div class="step-content">
                          <h3 class="step-title">{{ step.title }}</h3>
                          <p class="step-description">{{ step.description }}</p>
                        </div>
                        <div class="step-connector" *ngIf="!last"></div>
                      </div>
                    </div>
                 
            </div>
            <div class="book-now">
                <h1>Trust us for keeping your clothes ‘AS GOOD AS NEW’!</h1>
                <button class="btn primary">Book Now</button>
            </div>
           
            <div class="dry-cleaning-container">
                <div class="image-container">
                  <img [src]="carpetCleaningImage" alt="Carpet Cleaning Service">
                </div>
                <div class="content-container">
                  <h2 class="title">OUR CARPET CLEANING PRICES</h2>
                  <p class="description body-text">
                    At UClean, we believe in delivering high quality cleaning services at very affordable prices. With stores across multiple Tier 1, Tier 2 and Tier 3 cities, Quality, Speed and Economical Prices have been our biggest value drivers.
                  </p>
                  <a href='laundry-drycleaning-price-list' class="view-pricing-btn">View Pricing</a>
                </div>
              </div>

            <div class="promise-container">
                <h2 class="promise-title">OUR PROMISE TO YOU</h2>
                <app-our-promise></app-our-promise>
              </div>