<div class="promise-grid">
    <div class="promise-item">
      <div class="icon-container">
        <i class="icon" [ngStyle]="{'background-image': 'url(' + affordableRatesIconUrl + ')'}"></i>
      </div>
      <p class="body-text">Affordable Rates</p>
    </div>
    <div class="promise-item">
      <div class="icon-container">
        <i class="icon" [ngStyle]="{'background-image': 'url(' + skinFriendlyIconUrl + ')'}"></i>
      </div>
      <p class="body-text">Skin friendly</p>
    </div>
    <div class="promise-item">
      <div class="icon-container">
        <i class="icon" [ngStyle]="{'background-image': 'url(' + uvSafeIconUrl + ')'}"></i>
      </div>
      <p class="body-text">UV Safe Air Drying</p>
    </div>
    <div class="promise-item">
      <div class="icon-container">
        <i class="icon" [ngStyle]="{'background-image': 'url(' + freshWaterIconUrl + ')'}"></i>
      </div>
      <p class="body-text">Less & Fresh Water</p>
    </div>
  </div>
  