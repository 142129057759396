import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { PixelService } from 'ngx-pixel';
import { metaTags } from 'src/assets/meta-contant';
import { PagesService } from '../pages.service';

interface PriceData {
  cloths: { [key: string]: any[] };
  services: { [key: string]: any[] };
  addons: { [key: string]: any[] };
}

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  citiesList: any;
  city: number = 0;
  store: any;
  store_id: number = 0;
  services: any;
  cityId: number = 0;
  serviceID: number = 0;
  storePrices: any = [];
  itemPrices: any;
  addOnstorePrices: any;
  cityServices = true;
  subCategoryFilter = false;
  filterP: any;
  activeButton: number = 0;
  activeButtonCat: number = 0;
  showLoader = false;
  priceTable = false;
  pricePerKg = false;
  pricePerPc = true;
  tempHideServices = true;
  serviceHideText = false;
  pickUpDeliverPrice: any;
  storesList: any = [];
  catID: number = 0;
  
  priceData: PriceData = {
    cloths: {},
    services: {},
    addons: {}
  };
  
  selectedServiceId: string = '';
  showServices: boolean = true;
  showAddons: boolean = true;

  categories = [
    { id: 1, placeholder: "men", name: "Mens", img: "//assets.ucleanlaundry.com/assets/images/man_icon.png" },
    { id: 2, placeholder: "women", name: "Womens", img: "//assets.ucleanlaundry.com/assets/images/woman_icon.png" },
    { id: 3, placeholder: "household", name: "Household", img: "//assets.ucleanlaundry.com/assets/images/household_icon.png" },
    { id: 4, placeholder: "kids", name: "Kids", img: "//assets.ucleanlaundry.com/assets/images/kids_icon.png" },
    { id: 5, placeholder: "Luxury", name: "Luxury", img: "//assets.ucleanlaundry.com/assets/images/luxury_icon.png" }
  ];

  constructor(
    private pixel: PixelService,
    private metaTagService: Meta,
    private pagesService: PagesService
  ) { }

  ngOnInit(): void {
    console.log('Initializing PricingComponent...');
    this.pagesService.cityList().subscribe((data: any) => {
      console.log('Fetched citiesList:', data);
      this.citiesList = data.cities;
    });
    this.pixel.trackCustom('PricingPage');
    this.metaTagService.updateTag({ property: 'title', content: metaTags?.['laundry-drycleaning-price-list']?.title });
    this.metaTagService.updateTag({ property: 'description', content: metaTags?.['laundry-drycleaning-price-list']?.description });
    console.log('Meta tags updated:', metaTags?.['laundry-drycleaning-price-list']);
  }

  showPhase(event: number) {
    console.log('showPhase triggered with event:', event);
    this.activeButton = event;
  }

  showPhaseCat(event: number) {
    console.log('showPhaseCat triggered with event:', event);
    this.activeButtonCat = event;
  }

  getCityStores() {
    console.log('getCityStores triggered with city:', this.city);
    this.showLoader = true;
    this.cityServices = true;
    this.pagesService.selCityID = this.city;

    this.pagesService.getStores().subscribe((data: any) => {
      console.log('Fetched stores data:', data);
      this.storesList = data.stores.filter((p: any) => p.city_id == this.city);
      this.getSerivceIdDefault();
      console.log('Filtered storesList:', this.storesList);
    });

    this.pagesService.cityServices().subscribe((data: any) => {
      console.log('Fetched city services:', data);
      this.services = data.services;
      this.activeButton = 0;
      this.activeButtonCat = 0;
      this.showLoader = false;
    }, (err: any) => {
      console.error('Error fetching city services:', err);
      this.showLoader = false;
    });
  }

  getstoreDtl() {
    console.log('getstoreDtl triggered with city:', this.city, 'and store_id:', this.store_id);
    this.showLoader = true;
    this.cityServices = false;
    this.pagesService.selStoreCityId = this.city;
    this.pagesService.selstoreID = this.store_id;

    this.pagesService.storeServices().subscribe((data: any) => {
      console.log('Fetched store services:', data);
      this.services = data.services;
      this.getSerivceIdDefault();
      this.activeButton = 0;
      this.activeButtonCat = 0;
      this.showLoader = false;
    }, (err: any) => {
      console.error('Error fetching store services:', err);
      this.showLoader = false;
    });
  }

  getSerivceId(id: any, index: any) {
    console.log('getSerivceId triggered with id:', id, 'and index:', index);
    this.showLoader = true;
    this.pagesService.selServiceID = this.services?.[index]?.id;
    this.selectedServiceId = this.services?.[index]?.id?.toString();

    if (this.cityServices) {
      console.log('Fetching city prices...');
      this.pagesService.cityPrice().subscribe((data: any) => {
        this.updatePrices(data, index);
      }, (err: any) => {
        console.error('Error fetching city prices:', err);
        this.showLoader = false;
      });
    } else {
      console.log('Fetching store prices...');
      this.pagesService.storePrice().subscribe((data: any) => {
        this.updatePrices(data, index);
      }, (err: any) => {
        console.error('Error fetching store prices:', err);
        this.showLoader = false;
      });
    }

    this.togglePricingView(index);
  }
  
  togglePricingView(index: any) {
    console.log('togglePricingView triggered for index:', index);
    this.pricePerKg = [4, 5, 9].includes(this.services?.[index]?.id);
    this.pricePerPc = !this.pricePerKg;
    this.activeButtonCat = 0;
  }

  updatePrices(data: any, index: any) {
    console.log('updatePrices triggered with data:', data);
    this.priceData = data;
    this.selectedServiceId = this.services?.[index]?.id?.toString();

    // Reset visibility flags
    this.tempHideServices = true;
    this.serviceHideText = false;
    this.priceTable = true;

    if (this.services?.[index]?.id == 2 || this.services?.[index]?.id == 10) {
      // Special handling for Premium Laundry (id: 2) and other special services
      const hasAddonsData = data.addons && data.addons[this.selectedServiceId]?.length > 0;
      
      if (hasAddonsData) {
        this.tempHideServices = true;
        this.serviceHideText = false;
        this.priceTable = true;
        // Clear cloths data since it's empty for premium laundry
        this.itemPrices = [];
        this.storePrices = [];
      } else {
        this.tempHideServices = false;
        this.serviceHideText = true;
        this.priceTable = false;
      }
    } else {
      const hasClothsData = data.cloths && data.cloths[this.selectedServiceId]?.length > 0;
      const hasServicesData = data.services && data.services[this.selectedServiceId]?.length > 0;
      const hasAddonsData = data.addons && data.addons[this.selectedServiceId]?.length > 0;

      if (hasClothsData || hasServicesData || hasAddonsData) {
        this.itemPrices = data.cloths[this.selectedServiceId] || [];
        this.storePrices = this.itemPrices;

        if ([1, 3].includes(this.services?.[index]?.id)) {
          this.subCategoryFilter = true;
          this.filterPrices('men', 0);
        } else {
          this.subCategoryFilter = false;
        }
      } else {
        this.tempHideServices = false;
        this.serviceHideText = true;
        this.priceTable = false;
      }
    }

    this.showLoader = false;
  }

  toggleDataSection(section: 'services' | 'addons') {
    if (section === 'services') {
      this.showServices = !this.showServices;
    } else {
      this.showAddons = !this.showAddons;
    }
  
    console.log('Filtered itemPrices:', JSON.stringify(this.itemPrices, null, 2));
  }

  filterPrices(placeholder: string, index: any) {
    console.log('filterPrices triggered with placeholder:', placeholder, 'and index:', index);
    if (!this.storePrices || this.storePrices.length === 0) return;

    this.itemPrices = this.storePrices.filter((p: any) =>
      p.category?.toLowerCase() === placeholder?.toLowerCase()
    );

    if (this.categories[index]?.id == 5) {
      this.luxuryFilter();
    }

    console.log('Filtered itemPrices:', this.itemPrices);
  }

  luxuryFilter() {
    console.log('luxuryFilter triggered');
    const placeholder = "Luxury";
    this.itemPrices = this.storePrices.filter((p: any) => p.category?.substr(-6) === placeholder);
    console.log('Luxury Filtered itemPrices:', this.itemPrices);
  }

  filterPrices1() {
    console.log('filterPrices1 triggered for default Men category');
    const placeholder = "men";
    this.itemPrices = this.storePrices.filter((p: any) => p.category?.toLowerCase() === placeholder?.toLowerCase());
    console.log('Default Men Category itemPrices:', this.itemPrices);
  }

  getSerivceIdDefault() {
    console.log('getSerivceIdDefault triggered');
    this.getSerivceId(0, 0);
  }

  trackByIdentity = (index: number, item: any) => {
    console.log('trackByIdentity triggered for index:', index, 'and item:', item);
    return item;
  }
  hasServices(): boolean {
    return !!this.priceData?.services?.[this.selectedServiceId]?.length;
  }

  hasAddons(): boolean {
    return !!this.priceData?.addons?.[this.selectedServiceId]?.length;
  }
  hasItems(): boolean {
    return !!this.priceData?.cloths?.[this.selectedServiceId]?.length;
  }

  getServices(): any[] {
    return this.priceData?.services?.[this.selectedServiceId] || [];
  }

  getAddons(): any[] {
    return this.priceData?.addons?.[this.selectedServiceId] || [];
  }

  hasServicePrice(): boolean {
    return this.getServices().some(service => 
      service?.price !== undefined && service?.price !== null
    );
  }

  hasServiceWeight(): boolean {
    return this.getServices().some(service => 
      service?.weight !== undefined && service?.weight !== null
    );
  }

  hasAddonPrice(): boolean {
    return this.getAddons().some(addon => 
      addon?.price !== undefined && addon?.price !== null
    );
  }

  hasAddonType(): boolean {
    return this.getAddons().some(addon => 
      addon?.type !== undefined && addon?.type !== null
    );
  }
}