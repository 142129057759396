<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="chip-wrapper">
                <div class="day-chip-container">
                    <div class="day-chip" [ngClass]="{'active': selectedDate === item?.formatDate}"
                        *ngFor="let item of weekDaysList; let i = index;" (click)="selectedDate = item?.formatDate;onWeekDateSelect(item)">
                        <span>{{ item?.dd}} </span>
                        <span>{{item?.displayDay}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>