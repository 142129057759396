<div class="container">
    <div class="row">
        <div class="col-md-6">
            <div class="quote-wrapper">
                <div class="quote-content-box">
                    <img class="quote-img" src="//assets.ucleanlaundry.com/assets/images/login/quote.svg" alt="quote-img">
                    <p [innerHTML]="mainBannerText"></p>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="white-box-wrapper">
                    <div class="loader" id='loading_wrap' *ngIf="isLoading"></div>
                    <div class="white-box" [ngClass]="{'loading': isLoading}">
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    </div>
</div>