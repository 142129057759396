<!-- inner_banner_section start-->

<section class="inner_banner_section locate_banner">
  <div class="container">
    <div class="row">
      <div class="inner_banner_block">
        <!-- <div class="inner_banner_tilte">
          <h1>Locate us</h1>
        </div> -->
      </div>
    </div>
  </div>
</section>

<!-- inner_banner_section end-->


<!-- locate_main_section start-->

<section class="locate_page_section">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="locate_form">
          <div class="form_group col-lg-7 col-md-6 col-sm-5 col-xs-12">
            <div class="city_search">
              <!-- <input type="text" class="form-control" id="searchRegion" [(ngModel)]="searchStoreAdd"
                     placeholder="Enter city/area/pin Code"> -->
                     <input type="text" class="form-control"  [(ngModel)]="searchStoreAdd"
                     placeholder="Enter city/area/pin Code">
              <span><i class="fa fa-map-marker" aria-hidden="true"></i></span>
            </div>
          </div>
          <div class="form_group col-lg-2 col-md-2 col-sm-3 col-xs-12">
            <button type="submit" class="btn btn-default">Search</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- locate_main_section end-->

<section class="locate_map_section">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="map_block">
          <div class="full" >
          </div>
          <!-- <div class="full" id="map">
          </div> -->
          <div class="locate_search">
            <div class="locate_list">
              <li class="col-12"
                  *ngFor="let store of storesList | filter:searchStoreAdd; let i = index"
                  [ngClass]="(store.storecode == selectedStore.storecode) ? 'active' : ''"
                  (click)="chooseSelectedStore(store.storecode)">
                <h4>{{store.name}}</h4>
                <h5>{{store.owner_name}}</h5>
                <p>{{store.address}}</p>
                <div class="dd_tm">
                  <ul>
                    <li>
                      <p><i class="fa fa-calendar" aria-hidden="true"></i>Mon to Sat</p>
                    </li>
                    <li>
                      <p><i class="fa fa-clock-o" aria-hidden="true"></i>9 : am - 11 : pm</p>
                    </li>
                  </ul>
                </div>
                <a (click)="getStoresDtl(store)" class="detail_btn"><i class="fa fa-share" aria-hidden="true"></i>View
                  Detail</a>
                  <a [href]="goToDirection(store.id,i)" target="_blank" class="direct_btn">
                    <i class="fa fa-location-arrow" aria-hidden="true"></i>Directions
                  </a>
                <!-- <div class="locate_time_sec">
                  <span class="km">4.5 Km</span>
                </div> -->
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
