import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PixelService } from 'ngx-pixel';

@Component({
  selector: 'app-franchise-applied-success',
  templateUrl: './franchise-applied-success.component.html',
  styleUrls: ['./franchise-applied-success.component.scss']
})
export class FranchiseAppliedSuccessComponent implements OnInit {

  public mainBannerText = 'Sit back and relax. Let us take care of your laundry';
  public descriptionTex1 = '<p>Thank you for choosing <b class="dark-bold">UClean</b> as your Laundry Partner. Our team will contact you shortly! <br>Thank You</p>';
  
  constructor(
    private router: Router,
    private pixel: PixelService,
    private route: ActivatedRoute,
    private metaTagService: Meta) { }

  ngOnInit(): void {
    // this.pixel.trackCustom('Purchased Booked', {});
    this.route.queryParams.subscribe(params => {
      this.pixel.trackCustom('SubmissionSuccessful', {
        'First name': params['name'],
        'Phone Number': params['phone'],
        'Email address': params['email']
      });
    });
  }
  
  goBackToFranchiseScreen() {
    this.router.navigate(['apply-for-franchise'], {
      replaceUrl: true
    });
  }

  continueOrdering() {
    this.router.navigate([''], {
      replaceUrl: true
    });
  }
}
