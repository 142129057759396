
    <div class="profile_right_block">
        <div class="order_history_block">
            <div class="profile_title">
                <h2>Order History</h2>
            </div>
            <div *ngFor="let history of userOrdHistory">
                <div class="lc_sec" *ngIf="history.virtual_store == 0">
                    <h4><img src="//assets.ucleanlaundry.com/assets/images/shop_icon.png">
                        {{history.storecode}}</h4>
                    <ul class="gst_code">
                        <li><b>GSTIN</b></li>
                        <li><b>:</b></li>
                        <li>{{history.store_gst}}</li>
                    </ul>

                    <p>{{history.store_address}}</p>
                </div>

                <div class="order_delivered_block">
                    <div class="order_delivered_list">
                        <h4><img src="//assets.ucleanlaundry.com/assets/images/delivered_icon.png">
                            {{history.status}}</h4>
                        <ul class="order_id">
                            <li><b>Order #</b></li>
                            <li><b>:</b></li>
                            <li>{{history.booking_code}}</li>
                        </ul>
                        <ul class="order_id">
                            <li><b>Challan #</b></li>
                            <li><b>:</b></li>
                            <li>{{history.challan_number}}</li>
                        </ul>
                    </div>
                    <div class="order_item_sec">
                        <h4> Services</h4>
                        <ul class="order_item_id">
                            <li><b>Service type</b></li>
                            <li><b>:</b></li>
                            <li>{{history.booking_type}}</li>
                        </ul>
                    </div>
                    <div class="order_item_sec">
                        <h4>Order details</h4>
                        <ul class="order_item_id">
                            <li><b>Total items</b></li>
                            <li><b>:</b></li>
                            <li>{{history.total_cloth}}</li>
                        </ul>

                        <ul class="order_item_id">
                            <li><b>Order Date/Time</b></li>
                            <li><b>:</b></li>
                            <li>{{history.created_at}}</li>
                        </ul>
                        <ul class="order_item_id">
                            <li><b>Pickup Date/Time</b></li>
                            <li><b>:</b></li>
                            <li>{{history.pickup_date}}/{{history.pickup_time}}</li>
                        </ul>
                        <!-- <ul class="order_item_id">
                            <li><b>Pickup address</b></li>
                            <li><b>:</b></li>
                            <li>need help</li>
                        </ul> -->
                    </div>

                    <div class="order_item_sec">
                        <ul class="order_item_id invoice_item_sec">
                            <li><b>Invoice </b></li>
                            <li><b>:</b></li>
                            <li *ngIf="downloadInvoice"><i class="fa fa-inr" aria-hidden="true"></i>{{history.final_amount}}
                                <a href="/o/{{history.invoice_id}}">Download</a>
                            </li>
                            <li *ngIf="invoiceNotGenerate">
                                <a>Invoice not generated</a>
                            </li>
                        </ul>
                        <!-- <ul class="order_item_id">
                        <li><b>Payment Mode</b></li>
                        <li><b>:</b></li>
                        <li>PayTm / Creadit Card /Debit card</li>
                    </ul> -->

                    </div>

                </div>
            </div>


            <!-- <div class="order_delivered_block">
                <div class="order_delivered_list">
                    <h4><img src="//assets.ucleanlaundry.com/assets/images/inprocess_icon.png"> In
                        Processed</h4>
                    <ul class="order_id">
                        <li><b>Order #</b></li>
                        <li><b>:</b></li>
                        <li>UC133-1659</li>
                    </ul>
                    <ul class="order_id">
                        <li><b>Challan #</b></li>
                        <li><b>:</b></li>
                        <li>627</li>
                    </ul>
                </div>
                <div class="order_item_sec">
                    <h4> items</h4>
                    <ul class="order_item_id">
                        <li><b>DC</b></li>
                        <li><b>:</b></li>
                        <li>04 PCS</li>
                    </ul>
                    <ul class="order_item_id">
                        <li><b>WI</b></li>
                        <li><b>:</b></li>
                        <li>20 PCS</li>
                    </ul>
                    <ul class="order_item_id">
                        <li><b>Order Date/Time</b></li>
                        <li><b>:</b></li>
                        <li>16/06/2020 <span>at</span> 11:30 PM</li>
                    </ul>
                </div>

                <div class="order_item_sec">
                    <ul class="order_item_id invoice_item_sec">
                        <li><b>Invoice </b></li>
                        <li><b>:</b></li>
                        <li><i class="fa fa-inr" aria-hidden="true"></i>200 <a href="">Download</a>
                        </li>
                    </ul>
                    <ul class="order_item_id">
                        <li><b>Payment</b></li>
                        <li><b>:</b></li>
                        <li><a href="" class="paynow_btn">Pay Now</a></li>
                    </ul>

                </div>

            </div> -->


            <!-- <div class="order_delivered_block">
                <div class="order_delivered_list">
                    <h4><img src="//assets.ucleanlaundry.com/assets/images/inprocess_icon.png"> In
                        Processed</h4>
                    <ul class="order_id">
                        <li><b>Order #</b></li>
                        <li><b>:</b></li>
                        <li>UC133-1659</li>
                    </ul>
                    <ul class="order_id">
                        <li><b>Challan #</b></li>
                        <li><b>:</b></li>
                        <li>627</li>
                    </ul>
                </div>
                <div class="order_item_sec">
                    <h4> items</h4>
                    <ul class="order_item_id">
                        <li><b>DC</b></li>
                        <li><b>:</b></li>
                        <li>04 PCS</li>
                    </ul>
                    <ul class="order_item_id">
                        <li><b>WI</b></li>
                        <li><b>:</b></li>
                        <li>20 PCS</li>
                    </ul>
                    <ul class="order_item_id">
                        <li><b>Order Date/Time</b></li>
                        <li><b>:</b></li>
                        <li>16/06/2020 <span>at</span> 11:30 PM</li>
                    </ul>
                </div>

                <div class="order_item_sec">
                    <ul class="order_item_id invoice_item_sec">
                        <li><b>Invoice </b></li>
                        <li><b>:</b></li>
                        <li class="in_p">Invoice pending</li>
                    </ul>


                </div>

            </div> -->

        </div>

    </div>
