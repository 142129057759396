import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { PixelService } from 'ngx-pixel';
import { metaTags } from 'src/assets/meta-contant';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(
    private metaTagService: Meta,
    private pixel: PixelService) { }

  ngOnInit(): void {
    this.pixel.trackCustom('AboutusPage');
    this.metaTagService.updateTag({ name: 'title', content: metaTags?.['about-us']?.title });
    this.metaTagService.updateTag({ name: 'description', content: metaTags?.['about-us']?.description });
  }

}
