<!-- hp_cust_section start-->

<section class="hp_cust_section">
    <div class="container">
       <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
             <div class="main_title_bar">
                <h2>Customer Speak</h2>
                <p class="high-rated">UClean is the highest rated and most searched Laundry and Dry-Cleaning brand in India! <br> Still not sure? Here is a glimpse of our raving reviews!</p>
             </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="tab_bar customer-tabbar">
               <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
            
                  <div class="tab-pane active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
            
                     <div id="owl-demo" class="owl-carousel owl-theme">
                        <div class="item">
                           <div class="cust_block">
                              <div class="cust_img">
                                 <img src="//assets.ucleanlaundry.com/assets/images/cust_img_1_11zon.webp"  alt=" best laundry nearme">
                              </div>
                              <div class="cust_text">
                                 <p>Gave up on my 15 year old “dry cleaners “ for U Clean. Excellent service , Courteous staff, Super sonic delivery. Feel pampered. And yes, not to forget the competitive rates. A definite recommend. Get your clothes U Cleaned. I’m loving it.</p>
                                 <ul>
                                    <li><i class="fa fa-star" aria-hidden="true"></i>
                                    <li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i>
                                    <li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i>
                                    <li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i>
                                    <li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i>
                                    <li>
                                 </ul>
                                 <h5>ARVINDER LAMBA</h5>
                                 <span>
                                    <p>(Entrepreneur, Delhi)</p>
                                 </span>
                              </div>
                           </div>
                        </div>
                        <div class="item">
                           <div class="cust_block cust_block2">
                              <div class="cust_img">
                                 <img src="//assets.ucleanlaundry.com/assets/images/cust_img_2_11zon.webp" alt=" best dry cleaning near me">
                              </div>
                              <div class="cust_text">
                                 <p>The best laundry service I ever got! The manager, Yash, was very good at communication and they delivered the clothes within a day! Also got some discount on the bulk order. Thanks to the team. Cheers! Keep up the good work</p>
                                 <ul>
                                    <li><i class="fa fa-star" aria-hidden="true"></i>
                                    <li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i>
                                    <li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i>
                                    <li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i>
                                    <li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i>
                                    <li>
                                 </ul>
                                 <h5>NIKHIL KATARIYA</h5>
                                 <span>
                                    <p>(Student, Pune)</p>
                                 </span>
                              </div>
                           </div>
                        </div>
                        <div class="item">
                           <div class="cust_block">
                              <div class="cust_img">
                                 <img src="//assets.ucleanlaundry.com/assets/images/cust_img_3_11zon.webp"  alt=" affordable dry cleaning">
                              </div>
                              <div class="cust_text">
                                 <p>For someone who has a young daughter, a clean home is an absolute necessity for me. I started with UClean as my laundry and dry-cleaning service provided. But they wooed me with their excellent service, and now they are my one-stop shop for cleaning requirements. I am a customer for life.</p>
                                 <ul>
                                    <li><i class="fa fa-star" aria-hidden="true"></i>
                                    <li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i>
                                    <li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i>
                                    <li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i>
                                    <li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i>
                                    <li>
                                 </ul>
                                 <h5>CHITRA JOHRI</h5>
                                 <span>
                                    <p>(Vice President, Bradford License India)</p>
                                 </span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            </div>
       </div>
    </div>
 </section>

 <!-- hp_cust_section end-->

 <!-- hp_faq_section start-->

 <!-- <section class="hp_faq_section">
    <div class="container">
       <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
             <div class="main_title_bar">
                <h2>Have more questions?</h2>
                <h6>Find the answers in our FAQ section</h6>
                <a href="">View All </a>
             </div>
          </div>
       </div>
    </div>
 </section> -->

 <!-- hp_faq_section end-->
