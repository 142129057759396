<section class="franchise_form_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="main_title_bar">
                    <h2>Submit Application for UCLEAN FRANCHISE</h2>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="franchise_form">
                    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" [hidden]="hideForm" id="contact-form"
                        method="post" action="#contact" role="form" novalidate="novalidate">
                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <div class="form-group">
                                    <input formControlName="name" [(ngModel)]="name" id="form_name" type="text"
                                        name="name" value="" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                                        placeholder="Name*">
                                </div>
                                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                    <div *ngIf="f.name.errors.required">Please enter name</div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <div class="form-group">
                                    <input formControlName="mobile"
                                        [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" [(ngModel)]="phone"
                                        id="form_phone" type="tel" name="phone" value="" class="form-control"
                                        placeholder="Phone Number*">
                                </div>
                                <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                    <div *ngIf="f.mobile.errors.required">Please enter mobile</div>
                                    <div *ngIf="f.mobile.errors.minlength">Mobile must be at least 10 numbers</div>
                                    <div *ngIf="f.mobile.errors.maxlength">Mobile not more then be 10 numbers</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <div class="form-group">
                                    <input formControlName="email"
                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" [(ngModel)]="email"
                                        id="form_email" type="email" name="email" value="" class="form-control"
                                        placeholder="Email Id*">
                                </div>
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">Please enter email</div>
                                    <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <div class="form-group">
                                    <input formControlName="city"
                                        [ngClass]="{ 'is-invalid': submitted && f.city.errors }" [(ngModel)]="city"
                                        (keyup)="addSearches()" type="text" name="City" value=""
                                        class="form-control" placeholder="City*"
                                        style="height: 51px; margin-bottom: 3%;">
                                    <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                        <div *ngIf="f.city.errors.required">Please select your city</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="privacyCheck" formControlName="privacyPolicy"
                                        [ngClass]="{ 'is-invalid': submitted && f.privacyPolicy.errors }">
                                    <label class="form-check-label" for="privacyCheck">
                                        I agree to the Privacy Policy
                                    </label>
                                    <div *ngIf="submitted && f.privacyPolicy.errors" class="invalid-feedback">
                                        <div *ngIf="f.privacyPolicy.errors.required">You must agree to the privacy policy</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <input (click)="applyFranchise();" type="submit" class="btn btn-success btn-send"
                                    value="Submit Application" [disabled]="!registerForm.get('privacyPolicy').value">
                            </div>
                        </div>
                    </form>
                    <div [hidden]="showSuccesMsg" id="successMsg">
                        <h2>{{franchiseData.status}}</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>