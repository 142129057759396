import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { enterAnimation } from 'src/app/animations/enter-leave.animation';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-signup-page',
  templateUrl: './signup-page.component.html',
  styleUrls: ['./signup-page.component.scss'],
  animations: [ enterAnimation ]
})
export class SignupPageComponent implements OnInit {

  public isLoading = false;
  public signupForm!: FormGroup;
  public errorMsg: any;
  public showError = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private metaTagService: Meta
   ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.formValueChangeSubscription();
  }

  initializeForm() {
    this.signupForm = this.fb.group({
      name: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    })
  }

  formValueChangeSubscription() {
    this.signupForm.valueChanges.subscribe(() => {
      this.showError = false;
    })
  }

  goToLoginPage() {
    this.router.navigate(['login-page'], {
      replaceUrl: true
    })
  }

  goToOtpVerification() {
    this.router.navigate(['otp-verification'], {
      queryParams: {
        fromUrl: 'signup-page',
        enc: btoa(JSON.stringify({...this.signupForm.getRawValue(), apiCallingTime: new Date().getTime()}))
      }
    })
  }

  sendOtpForVerification() {
    this.isLoading = true;
    const {name, phone, email} = this.signupForm.getRawValue();
    const requestBody = {
      mobile: phone,
      name,
      email
    }
    this.authService.sendOtpRegister(requestBody).subscribe((res: any) => {
      this.isLoading = false;
      if(res?.status === 'SUCCESS') {
        this.goToOtpVerification();
      } else if(res?.status === 'ERROR') {
        this.errorMsg = res?.msg;
        this.showError = true;
      }
      console.log(res);
    }, (err) => {
      this.isLoading = false;
    });
  }
}
