<section class="screen otp-verification">
    <app-auth-wrapper [isLoading]="isLoading">
        <div class="form-wrapper">
            <div class="backdrop">
                <img src="//assets.ucleanlaundry.com/assets/images/login/back-arrow.svg" (click)="goToPreviousScreen()"> Back to previous screen
            </div>
            <div class="form-header-wrapper font-700">
                <p>OTP Verification</p>
                <span class="line"></span>
            </div>
            <div class="form-sub-header">
                <span>OTP sent to entered mobile number</span><a href="javascript:void(0)">&nbsp;<b class="black-b">{{ formValue?.phone }}</b></a>
            </div>
            <div class="input-form">
                <ng-otp-input [config]="{length: 6}" (onInputChange)="onOtpChange($event)"></ng-otp-input>
            </div>
            <div class="form-sub-header otp-resend-container center-text">
                <span>Didn’t receive the OTP?</span><a href="javascript:void(0)">&nbsp;<b *ngIf="!timerRunning" (click)="checkRemainingTimeAndPlayCounter()">Resend OTP</b><b *ngIf="timerRunning">Resend OTP in 00:{{counter | number: '2.'}} Seconds</b></a>
            </div>
            <div class="input-form">
                <button class="primary-btn" matRipple [ngClass]="{'blur': otpControl.value.length !== 6 }" [disabled]="otpControl.value.length !== 6" (click)="verifyOtp()" >Continue</button>
            </div>
            <div class="form-sub-header">
                <span class="api-error" [@enterAnimation] *ngIf="showError">{{ errorMsg }}</span>
            </div>
        </div>
    </app-auth-wrapper>
</section>