<!-- login popup model -->

<div *ngIf="loginModalshow" class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="loginModalLabel"
	aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form_title_bar">
					<img src="//assets.ucleanlaundry.com/assets/images/login_icon.png">
					<h1>Login</h1>
				</div>

				<div class="login_form_info">
					<div class="form-group">
						<input maxlength="10" type="text" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" [(ngModel)]="phone" class="form-control" id="inputphonenumber"
							placeholder="Enter Your Phone Number">
					</div>
					<div class="form-group" [hidden]="hiddenOtpBox">
						<input type="text" maxlength="6" (keypress)="utililtyService.numberOnly($event)" [(ngModel)]="otp" class="form-control" id="inputOtp" placeholder="Enter Your OTP">

					</div>
					<p style="color: red; float: left;">{{loginError.msg}}</p>

					<div class="form-group" [hidden]="hiddenReOtpButton">
						<button [disabled]="displayTimer" (click)="sendOTP()" style="float: right; color: #75b945; margin-bottom: 10px; cursor: pointer; background: transparent; border: none;">Resend
							OTP<span *ngIf="displayTimer"> in {{transform(counter)}}</span></button>
					</div>

					<div class="form-group" *ngIf="logInErrorMassege">
							<button style="float: left; color: #75b945; margin-bottom: 10px; cursor: auto; background: transparent; border: none;">Seems like we are facing some technical issue. Request you to please reach out to our customer care team at <a href="tel:+91 9999759911" style="color: #75b945;"> 9999759911</a></button>
					</div>

					<div class="form-group" [hidden]="hiddenOtpButton" (click)="enableOtpBox()">
						<input type="button" name="login" class="login loginmodal-submit" value="Send OTP"
							(click)="sendOTP()">
					</div>
					<div class="form-group" (click)="login()" [hidden]="hiddenLoginButton">
						<input type="button" name="login" class="login loginmodal-submit" value="Login"
							>
					</div>
<!--					<div>-->
<!--						<p>OR</p>-->
<!--					</div>-->
				</div>

<!--				<div class="social_buttons">-->
<!--					<a href="#" class="btn-fb"><img-->
<!--							src="//assets.ucleanlaundry.com/assets/images/fb-icon.png">Facebook</a>-->
<!--					<a href="#" class="btn-tw" style="margin-left: 10%;"><img-->
<!--							src="//assets.ucleanlaundry.com/assets/images/gg-icon.png">Google</a>-->
<!--				</div>-->
				<div class="form_bottom">
					<p>by logging in you agree to the terms & conditions</p>
					<a routerLink="terms-and-conditions">Terms of Service</a> & <a routerLink="privacy-policy">Privacy
						Policy</a>
				</div>


			</div>
		</div>
	</div>
</div>
