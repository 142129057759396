<!-- inner_banner_section start-->

<section class="inner_banner_section">
    <div class="container">
        <div class="row">
            <div class="inner_banner_block">
                <div class="inner_banner_tilte">
                    <h1>About Us</h1>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- inner_banner_section end-->

<!-- ab_main_section start-->
<section class="ab_main_section ab_pad">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ab-wht">
                <div class="main_title_bar">
                    <h2 class="ll">Laundry Belongs in a Laundromat</h2>
                    <p>An average human spends between 12000 hours of their adult life in cleaning and managing their
                        clothes. We have all grown up <br> on a heavy dose of detergent commercials propagating the idea
                        of ‘whiter the better’. </p>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top ab-wht">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 ab-wht">
                    <div class="about_img_sec">
                        <img src="//assets.ucleanlaundry.com/assets/images/about_img_11zon.webp">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 ab-wht">
                    <div class="about_text_sec">
                        <p>But none of these commercials talked about the hours of hard work required to get
                            that blinding whiteness. Do the words ‘enzyme soak’, hot water cleaning’, ‘bluing’ ring a
                            bell? How we wish maintaining ourwhite clothes was easy as watching those commercials. With
                            UClean, <b>it is Easier!</b></p>
                        <p>At UClean, we bring the best-in-class Laundry, Dry Cleaning and Home Cleaning Services at
                            your doorstep!</p>
                        <p>UClean operates a network of over 500+ mini laundromats across 150+ cities, making <b> it the
                                Largest Laundry & Dry-Cleaning Chain in India. </b> Your nearest UClean store offerslive
                            laundry, steam ironing and dry-cleaning services, with freehome pick & drop, and a promise
                            to deliver your clothes in less than 24 hours*. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- ab_main_section end-->

<!-- ab_promise_section start-->

<section class="ab_promise_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ab-wht">
                <div class="main_title_bar">
                    <h2 class="promise">Our Promise</h2>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top ab-wht">
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 ab-wht">
                    <div class="promise_block">
                        <div class="promise_icon">
                            <img src="//assets.ucleanlaundry.com/assets/images/promise_icon_1_11zon.webp" alt="Laundry ">
                        </div>
                        <div class="promise_text">
                            <h4>Quality</h4>
                            <p>Expert professionals handling <br> your clothes with utmost care.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 ab-wht">
                    <div class="promise_block">
                        <div class="promise_icon colr_2">
                            <img src="//assets.ucleanlaundry.com/assets/images/promise_icon_2_11zon.webp">
                        </div>
                        <div class="promise_text">
                            <h4>Transparency</h4>
                            <p>Neighbourhood live laundry stores <br> offering service as you please.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 ab-wht">
                    <div class="promise_block">
                        <div class="promise_icon colr_3">
                            <img src="//assets.ucleanlaundry.com/assets/images/promise_icon_3_11zon.webp">
                        </div>
                        <div class="promise_text">
                            <h4>Speed</h4>
                            <p>Shorter turnaround times, delivery <br> within24 hours of pick up.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- ab_promise_section end-->

<section class="hp_main_section ab_mid_sectopn ab_mid_pad">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ab_promise ab-wht">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 ab-wht">
                    <div class="promise_bottom">
                        <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12 ab-wht">
                            <div class="promise_img">
                                <img src="//assets.ucleanlaundry.com/assets/images/promise_icon_4_11zon.webp">
                            </div>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-8 col-xs-12 ab-wht">
                            <div class="promise_text">
                                <h4>We Aim</h4>
                                <p>To provide all cleaning solutions on a click</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 ab-wht">
                    <div class="promise_bottom">
                        <div class="col-lg-3  col-md-3 col-sm-4 col-xs-12 ab-wht">
                            <div class="promise_img">
                                <img src="//assets.ucleanlaundry.com/assets/images/promise_icon_5_11zon.webp">
                            </div>
                        </div>
                        <div class="col-lg-9  col-md-9 col-sm-8	col-xs-12 ab-wht">
                            <div class="promise_text">
                                <h4>We Aspire</h4>
                                <p>To become India’s largest and most preferred brand of cleaning services </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ul>
    <li></li>
    <li><img src="//assets.ucleanlaundry.com/assets/images/icon_2.png"></li>
    <li><img src="//assets.ucleanlaundry.com/assets/images/icon_3.png"></li>
    <li></li>
    </ul>
</section>

<!-- ab_story_section start-->

<!-- <section class="ab_story_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="main_title_bar">
                    <h2>Our Story</h2>
                    <p>UClean was founded by IIT Bombay alumnus Arunabh Sinha in October 2016, with the first ever store
                        launch in January 2017 <br> in Vasant Kunj Delhi. In its 5-year long journey, UClean has won
                        many awards and accolades, including the Oscars of the <br> Franchising Industry “International
                        Franchise Awards” for being the “Best Emerging Brand”. Not just that, UClean is also <br> the
                        only Indian brand to have ever won it. Here is a sneak peak into our journey so far!</p>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top">
                <div class="ab_story_block">
                    <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                        <div class="tab-pane active" id="nav-2016" role="tabpanel" aria-labelledby="nav-2016-tab">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding_sec">
                                <div id="owl-demo" class="owl-carouse2 owl-theme">
                                    <div class="item">
                                        <div class="story_block">
                                            <div class="story_img">
                                                <img src="//assets.ucleanlaundry.com/assets/images/story_img.jpg">
                                            </div>
                                            <div class="story_text">
                                                <span>October - 2016 </span>
                                                <p>The Idea was born</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="story_block">
                                            <div class="story_img">
                                                <img src="//assets.ucleanlaundry.com/assets/images/story_img_2.jpg">
                                            </div>
                                            <div class="story_text">
                                                <span>November - 2016 </span>
                                                <p>UClean gets registered and launched. Hurray!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="story_block">
                                            <div class="story_img">
                                                <img src="//assets.ucleanlaundry.com/assets/images/story_img_3.jpg">
                                            </div>
                                            <div class="story_text">
                                                <span>December - 2016 </span>
                                                <p>UClean partnered Franchise India to Establish UClean as the Largest
                                                    Franchise Laundry Chain Globally</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="story_block">
                                            <div class="story_img">
                                                <img src="//assets.ucleanlaundry.com/assets/images/story_img.jpg">
                                            </div>
                                            <div class="story_text">
                                                <span>October - 2016 </span>
                                                <p>The Idea was born</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-2017" role="tabpanel" aria-labelledby="nav-2017-tab">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding_sec">
                                <div id="owl-demo" class="owl-carouse2 owl-theme">
                                    <div class="item">
                                        <div class="story_block">
                                            <div class="story_img">
                                                <img src="//assets.ucleanlaundry.com/assets/images/story_img.jpg">
                                            </div>
                                            <div class="story_text">
                                                <span>January - 2017 </span>
                                                <p>Entered into a Partnership with Alliance Laundry Systems, USA &
                                                    Diverseyas an exclusive Equipment and Clean-Tech Partner</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="story_block">
                                            <div class="story_img">
                                                <img src="//assets.ucleanlaundry.com/assets/images/story_img_2.jpg">
                                            </div>
                                            <div class="story_text">
                                                <span>January - 2017 </span>
                                                <p>UClean launches first two stores in Delhi and Gurgaon</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="story_block">
                                            <div class="story_img">
                                                <img src="//assets.ucleanlaundry.com/assets/images/story_img_3.jpg">
                                            </div>
                                            <div class="story_text">
                                                <span>January - 2017 </span>
                                                <p>Recognised celebrity couple Soha Ali & Kunal Kemmu came on board as
                                                    our strategic partners</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="story_block">
                                            <div class="story_img">
                                                <img src="//assets.ucleanlaundry.com/assets/images/story_img.jpg">
                                            </div>
                                            <div class="story_text">
                                                <span>May - 2017 </span>
                                                <p>Expansion beyond Delhi NCR begins. UClean goes live in Pune,
                                                    Bangalore and Hyderabad</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="item">
                                        <div class="story_block">
                                            <div class="story_img">
                                                <img src="//assets.ucleanlaundry.com/assets/images/story_img.jpg">
                                            </div>
                                            <div class="story_text">
                                                <span>November - 2017 </span>
                                                <p>UClean launches its sub-brands UClean Select and Mint Clean</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="item">
                                        <div class="story_block">
                                            <div class="story_img">
                                                <img src="//assets.ucleanlaundry.com/assets/images/story_img.jpg">
                                            </div>
                                            <div class="story_text">
                                                <span>November - 2017 </span>
                                                <p>UClean wins Franchisor of the Year- Consumer Services at the
                                                    Franchise Awards’17</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-2018" role="tabpanel" aria-labelledby="nav-2018-tab">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding_sec">
                                <div id="owl-demo" class="owl-carouse2 owl-theme">
                                    <div class="item">
                                        <div class="story_block">
                                            <div class="story_img">
                                                <img src="//assets.ucleanlaundry.com/assets/images/story_img.jpg">
                                            </div>
                                            <div class="story_text">
                                                <span>February - 2018 </span>
                                                <p>UClean wins Small Business of the Year at the Small Business Awards
                                                    2018</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="story_block">
                                            <div class="story_img">
                                                <img src="//assets.ucleanlaundry.com/assets/images/story_img_2.jpg">
                                            </div>
                                            <div class="story_text">
                                                <span>February - 2018 </span>
                                                <p>Our Founder- Arunabh Sinha, gets featured in the Entrepreneur India’s
                                                    2018 class of “35 under 35”</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="story_block">
                                            <div class="story_img">
                                                <img src="//assets.ucleanlaundry.com/assets/images/story_img_3.jpg">
                                            </div>
                                            <div class="story_text">
                                                <span>August - 2018 </span>
                                                <p>UClean rapidly grows and Arunabh Sinha wins another coveted title-
                                                    Young Entrepreneur of the Year at the Start Up Awards 2018 </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="story_block">
                                            <div class="story_img">
                                                <img src="//assets.ucleanlaundry.com/assets/images/story_img.jpg">
                                            </div>
                                            <div class="story_text">
                                                <span>September - 2018 </span>
                                                <p>UClean becomes the winner of India’s first ever Business Reality
                                                    Show- The Franchise India Show aired on Time Now</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="story_block">
                                            <div class="story_img">
                                                <img src="//assets.ucleanlaundry.com/assets/images/story_img.jpg">
                                            </div>
                                            <div class="story_text">
                                                <span>October - 2018 </span>
                                                <p>UClean claims the title of India’s Largest Laundry Chain, with 60+
                                                    stores across 22 cities. UClean also brings World’s #1 Home Cleaning
                                                    Brand Chem-Dry to India</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav>
                        <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                            <a class="nav-item nav-link selected" id="nav-2016-tab" data-toggle="tab" href="#nav-2016"
                                role="tab" aria-controls="nav-2016" aria-selected="true">2016</a>
                            <a class="nav-item nav-link " id="nav-2017-tab" data-toggle="tab" href="#nav-2017"
                                role="tab" aria-controls="nav-2017" aria-selected="false">2017</a>
                            <a class="nav-item nav-link" id="nav-2018-tab" data-toggle="tab" href="#nav-2018" role="tab"
                                aria-controls="nav-2018" aria-selected="false">2018</a>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- ab_story_section end-->

<!-- ab_about_section start-->

<section class="ab_about_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ab-wht">
                <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12 ab-wht">
                    <div class="ab_about_img">
                        <img src="//assets.ucleanlaundry.com/assets/images/man_img_11zon.webp">
                        <div class="man team_text">
                            <h4>Arunabh Sinha</h4>
                            <p>Founder at UClean </p>

                        </div>
                    </div>
                </div>
                <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12 ab-wht">
                    <div class="ab_about_title">
                        <h2>Meet the Laundry Man of India!</h2>
                        <span></span>
                        <p> An IIT Bombay graduate, Arunabh founded UClean in October 2016 with a single-minded vision
                            to introduce the concept of laundromats in India and to organize the huge but highly
                            unorganized, laundry segment in the country. Arunabh has been conferred with the prestigious
                            ”Entrepreneur 35 Under 35” award by the Entrepreneur Magazine, for his bold move of ushering
                            in the laundry revolution in India. Having built India’s largest laundry chain, Arunabh is
                            highly regarded as a growth specialist and an expert on Franchising. A self-confessed
                            workaholic, he is often found doting over his daughter Saanvi in his free time. </p>
                        <a class="linkdin-btn" href="https://www.linkedin.com/in/arunabhsinha/" target="_blank"><i
                                class="fa fa-linkedin" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="ab_about_section" style="background-color:#f4fcee; padding-top: 5%;"> -->
<section class="ab_about_section ab_la_pad" >
    <div class="container gun-back">
        <div class="row">
            <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ab-wht">
                <div class="main_title_bar">
                    <h2>WOMAN BEHIND THE LAUNDRY MAN</h2>
                </div>
            </div> -->
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ab-wht">
                <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12 ab-wht Gunjan" style="text-align: center;">
                    <div class="ab_about_img">
                        <img src="//assets.ucleanlaundry.com/assets/images/Gunjan_11zon.webp">
                        <div class="team_text">
                            <h4>Gunjan Taneja</h4>
                            <p>Director & Member of Board at UClean </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12 ab-wht">
                    <div class="ab_about_title">

                        
                            <h2>WOMAN BEHIND THE LAUNDRY MAN</h2>
                        
                        <p> After completing her B.A. (h) Economics from Ramjas College, Delhi University, Gunjan (or GT
                            for her colleagues) started her professional journey at a young age of 20. Curiosity is her
                            second nature, which led her to explore many professional roles before finally finding her
                            calling at HCL Technologies. Her career pivoted from being a Market Entry & Expansion
                            Strategy Specialist to that of a Marketer. She has also done her Digital Marketing
                            specialisation from MICA. At UClean, she wears many hats but she mostly dedicates herself to
                            Branding, Marketing and Product Development at UClean. Personally, her learning mode is
                            always on; she is an awesome baker and loves reading. </p>
                        <a class="linkdin-btn" href="https://www.linkedin.com/in/gunjantaneja11" target="_blank"><i
                                class="fa fa-linkedin" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- ab_about_section end-->

<!-- ab_team_section start-->

<!-- <section class="ab_team_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="main_title_bar">
                    <h2>Team Members</h2>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col_lg_12" style="margin: 0px 0px 0px -9px;">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <div class="team_block">
                            <div class="team_img">
                                <img  src="//assets.ucleanlaundry.com/assets/images/team_img_2.jpg">
                            </div>
                            <div class="team_text">
                                <h4>Gunjan Taneja</h4>
                                <p>Director & Member of Board at UClean </p>
                                <a href="https://www.linkedin.com/in/gunjantaneja11" target="_blank"><i
                                        class="fa fa-linkedin" aria-hidden="true"></i></a>
                            </div>
                            <div class="div_overlay">
                                <img src="//assets.ucleanlaundry.com/assets/images/team_img_02.jpg">
                                <div class="text">
                                    <h4>Gunjan Taneja</h4>
                                    <h6>Director & Member of Board at UClean</h6>
                                    <p><i class="fa fa-quote-left" aria-hidden="true"></i>With an Eco (h) from D.U and
                                        Digital Marketing specialisation from MICA, Gunjan wears many hats. She is
                                        passionate about everything marketing and design. She is an awesome baker and an
                                        avid reader.</p>
                                    <a href="https://www.linkedin.com/in/gunjantaneja11" target="_blank"><i
                                            class="fa fa-linkedin" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <div class="team_block">
                            <div class="team_img">
                                <img src="//assets.ucleanlaundry.com/assets/images/team_img_1.jpg">
                            </div>
                            <div class="team_text">
                                <h4>Dinesh kumar</h4>
                                <p>Chief Project officer</p>
                                <a href="https://www.linkedin.com/in/dinesh-kumar1108/" target="_blank"><i
                                        class="fa fa-linkedin" aria-hidden="true"></i></a>
                            </div>
                            <div class="div_overlay">
                                <img src="//assets.ucleanlaundry.com/assets/images/team_img_01.jpg">
                                <div class="text">
                                    <h4>Dinesh kumar</h4>
                                    <h6>Chief Project officer</h6>
                                    <p><i class="fa fa-quote-left" aria-hidden="true"></i>Its not an exaggeration to say
                                        that laundry runs in the blood for Dinesh. He is a third generation Launderer &
                                        Dry Cleaner. However, he found his love for building projects at UClean and is
                                        the muscle behind those beautiful green & white stores.</p>
                                    <a href="https://www.linkedin.com/in/dinesh-kumar1108/" target="_blank"><i
                                            class="fa fa-linkedin" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </div>
        </div>
    </div>
</section> -->