import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dry-cleaning',
  templateUrl: './dry-cleaning.component.html',
  styleUrls: ['./dry-cleaning.component.scss']
})
export class DryCleaningComponent implements OnInit {
  bannerImageUrl = `${environment.imageBaseUrl}Banner/DryCleaning.jpg`;
  dryCleaningImage: string = `${environment.imageBaseUrl}images/Dry Cleaning2.png`;


  processSteps = [
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step6.svg`,
      title: 'Tagging  & Sorting',
      description: 'Clothes are tagged to avoid potential mix-ups.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step4.svg`,
      title: 'Garment Inspection',
      description: 'Garment is inspected for stains, potential color bleeds, snags, and tears. Assess fiber type for cleaning needs and ensure pockets are empty.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step3.svg`,
      title: 'Stain Removal',
      description: 'Pre-wash treatment for all visible stains with specialized stain removers. Focus on tough spots for thorough cleaning.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step2.svg`,
      title: 'Customized Clean',
      description: 'Garment-specific solvent-based cleaning tailored to fabric type for gentle and thorough care.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step1.svg`,
      title: 'Finishing',
      description: 'Finishing treatments like starch, zari polish, fabric softener, stain repellant, etc., applied as per requirement.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step3.svg`,
      title: 'Steam Press',
      description: 'A high-pressure vacuum steam press is done for that crisp and perfect finishing.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step2.svg`,
      title: 'Quality Check',
      description: 'Multiple checks to ensure exceptional quality and satisfaction.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step1.svg`,
      title: 'Packaging',
      description: 'Flexible packaging options to suit your preference. Choose whether you like your clothes folded or hanged.'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
