export const LOW_INVESTMENT_LIST = [
    {
        headerText: 'Typical Store area',
        description: '250-300 sq. ft.',
        imagePath: '//assets.ucleanlaundry.com/assets/images/franchise/low-investment/1.svg'
    },
    {
        headerText: 'Set-up cost for the outlet',
        subHeaderText: '(Including franchise fee)',
        description: '≈ 20 Lacs',
        imagePath: '//assets.ucleanlaundry.com/assets/images/franchise/low-investment/2.svg'
    },
    {
        headerText: 'Franchise Fee',
        description: '6 Lacs (+Taxes)',
        imagePath: '//assets.ucleanlaundry.com/assets/images/franchise/low-investment/3.svg'
    },
    {
        headerText: 'Royalty',
        description: '7% of gross sales',
        imagePath: '//assets.ucleanlaundry.com/assets/images/franchise/low-investment/4.svg'
    },
    {
        headerText: 'Average revenue per month',
        description: '3.5 to 4 lacs',
        imagePath: '//assets.ucleanlaundry.com/assets/images/franchise/low-investment/5.svg'
    },
    {
        headerText: 'Average profitability per month',
        description: '1.5 to 2 lacs',
        imagePath: '//assets.ucleanlaundry.com/assets/images/franchise/low-investment/6.svg'
    },
    {
        headerText: 'Gross Profit(%)',
        description: '70 %',
        imagePath: '//assets.ucleanlaundry.com/assets/images/franchise/low-investment/7.svg'
    }
]

export const SUPPORT_LIST = [
    {
        headerText: 'Project Support',
        description: "Right location at the right price is essential for <b>success</b> of any retail business. Our project and marketing team will help you identify locations in <b>high performing</b> micro markets, <b>within your budget</b>."
    },
    {
        headerText: 'Operations Support',
        description: "UClean stores enjoy <b>highest rating of 4.4/5 on Google</b> for our quality. Our operations training ensures that franchises become experts at <b>high-quality cleaning techniques</b>."
    },
    {
        headerText: 'Hiring & Training Support',
        description: "We understand how important a good team is for running a successful business. <b>We’ll help you hire & train</b> the right team with all the required operational skills"
    },
    {
        headerText: 'Supply Chain Management',
        description: "We have established well-oiled supply chain machinery to ensure that you don t have to run pillar to post to buy operational items."
    },
    {
        headerText: 'Tech & IT Support',
        description: "<b>Data confidentiality</b> is at the heart of our business. Unlike, any other Laundry brand, UClean has built <b>in-house invoicing</b> and <b>lead generation software</b> to provide you with data security and <b>data confidentiality</b>"
    },
    {
        headerText: 'Marketing Support',
        description: "Our Branding and Marketing support is unparalleled in the industry. <b>UClean's digital presence</b> is indomitable"
    }
]
