<!-- inner_banner_section start-->
<section class="inner_banner_section order_summary_page_banner">
  <div class="container">
    <div class="row">
      <!-- <div class="inner_banner_block">
        <div class="inner_banner_tilte">
          <h1>Select Your Pick up Date & time</h1>
        </div>
      </div> -->
    </div>
  </div>
</section>

<section class="page_progress_section">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px; margin-top:15px">
        <div class="prog_step">
          <ul>
            <li class="active"><a (click)="takeToBuildOrder()"><i class="fa fa-circle"
                                                                  aria-hidden="true"></i>
              <p> Build your <br> Order</p>
            </a></li>
            <li class="active"><a (click)="takeToSchedulePickup()"><i class="fa fa-circle"
                                                                      aria-hidden="true"></i>
              <p>Schedule <br> Pick up</p>
            </a></li>
            <li class="active"><a (click)="takeToChooseAddress()"><i class="fa fa-circle"
                                                                     aria-hidden="true"></i>
              <p>Choose <br> Address</p>
            </a></li>
            <li class="active"><i class="fa fa-circle" aria-hidden="true"></i>
              <p>Confirm </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- page_progress_section end-->

<!-- address_pg_section start-->

<section class="order_summary_pg_section">
  <div class="container">
    <div class="row" >
      <div class="col-xs-12">
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top">
      <div class="col-lg-7 col-md-8 col-sm-12 col-xs-12" style="padding:0px">
        <div class="order_summary_pg" >
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 order">
            <div class="sec_title_bar">
              <h2>Order Summary</h2>
            </div>
          </div>
          <div class="order_info">
            <ul class="orr">
              <li><b class="start">Store Address</b></li>
              <li class="mid-sign">&nbsp;&nbsp;&nbsp;&nbsp;<b>:</b></li>
              <li class="end" style="color: #65BA0D; width:46%">
                <b>{{store.address || 'Checking'}}</b>
              </li>
            </ul>
          </div>
          
          <div class="order_info">
            <ul class="orr">
              <li><b>Pick up Date</b></li>
              <li class="mid-sign">&nbsp;&nbsp;&nbsp;&nbsp;<b>:</b></li>
              <li class="end" style="color: #65BA0D;">
                <b>{{pickup_date || 'Checking'}}</b>
              </li>
            </ul>
          </div>
          
          <div class="order_info">
            <ul class="orr">
              <li><b>Pick up Time</b></li>
              <li class="mid-sign">&nbsp;&nbsp;&nbsp;&nbsp;<b>:</b></li>
              <li class="end" style="color: #65BA0D;">
                <b>{{pickup_time || 'Checking'}}</b>
              </li>
            </ul>
          </div>
          
          <div class="order_info">
            <ul class="orr">
              <li><b>Pick up Address</b></li>
              <li class="mid-sign">&nbsp;&nbsp;&nbsp;&nbsp;<b>:</b></li>
              <li class="end" style="width:50%; color: #65BA0D;">
                <p>
                  <b>
                   {{address}}
                  </b>
                </p>
              </li>
            </ul>
          </div>
          
          <div class="order_sel_list">
            <ul class="order_sel" *ngFor="let service of orderSummary.services">
              <li>{{service.name}}</li>
              <li><span><i class="fa fa-check" aria-hidden="true"></i></span></li>
            </ul>
          </div>

        </div>
      </div> 
      <div class="col-lg-5 col-md-4 col-sm-12 col-xs-12" style="padding:0px">
        <div class="order_summary_pg">
          <div class="order_summary_detail">
            <h4>order details</h4>
          </div>

          <div class="order_sel_list">
            <ul class="order_sel" *ngFor="let serviceName of name">
              <li>{{serviceName}}</li>
              <li><span><i class="fa fa-check" aria-hidden="true"></i></span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
      <div class="page_bn_btn">
        <a class="page_back_btn" (click)="takeToChooseAddress()">Back</a>
        <a class="page_next_btn" (click)="createOrder()">Confirm Order <img
          src="//assets.ucleanlaundry.com/assets/images/right_icon.png"></a>
        </div>
      </div>
    </div>
</section>
