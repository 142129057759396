import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-laundry',
  templateUrl: './laundry.component.html',
  styleUrls: ['./laundry.component.scss']
})
export class LaundryComponent implements OnInit {
  bannerImageUrl = `${environment.imageBaseUrl}Banner/Laundry.jpg`;
  laundryImage: string = `${environment.imageBaseUrl}cta/Laundry.jpg`;

  processSteps = [
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step6.svg`,
      title: 'Tagging & Sorting',
      description: 'Clothes are tagged & carefully sorted to avoid potential mix-ups.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step4.svg`,
      title: 'Garment Inspection',
      description: 'Garment is inspected for stains, potential color bleeds, snags and tears. Assess fiber type for cleaning needs and ensure pockets are empty.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step3.svg`,
      title: 'Cuff & Collar Treatment',
      description: 'Cuffs and collars are treated using special detergents.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step2.svg`,
      title: 'Pre Wash Enzyme Soak',
      description: 'Clothes with extra soiling and oil stains are treated to a special enzyme soak before washing.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step1.svg`,
      title: 'Washing',
      description: 'Clothes are washed as per the load type.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step3.svg`,
      title: 'Softener / Antiseptic',
      description: 'Softener or Antiseptic is added as per the customer requirement.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step2.svg`,
      title: 'Pre Wash Enzyme Soak',
      description: 'Clothes are air-dried in antibacterial stainless-steel dryers.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step1.svg`,
      title: 'Washing',
      description: 'High-pressure vacuum steam iron is done for that perfect finishing.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step1.svg`,
      title: 'Packaging',
      description: 'Clean washed and ironed clothes are neatly packed into stacks in transparent packaging for a seamless delivery.'
    }
  ];
  
  constructor() { }

  ngOnInit(): void {
  }

}
