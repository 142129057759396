import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CalenderService {

  weekDay = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  constructor() { }

  getWeekDaysList(customDate?: any, format?: any) {
    let ngDate = customDate ? new Date(customDate) : new Date();
    if (customDate) {
      if (format == 'mm/dd/yyyy') {
        ngDate = new Date(customDate)
      } else if (format == 'dd/mm/yyyy') {
        const arr = customDate.split('/');
        ngDate = new Date(`${arr[1]}/${arr[0]}/${arr[2]}`)
      }
    } else {
      ngDate = new Date();
    }
    ngDate.setDate(ngDate.getDate() + 7);
    return Array(7).fill(1).map((elm, i) => {
      ngDate.setDate(ngDate.getDate() - 1);
      const [dd, mm, yyyy] = [new Date(ngDate).getDate(), new Date(ngDate).getMonth() + 1, new Date(ngDate).getFullYear()]
      return {
        dd: `${ dd < 10 ? '0' + dd : dd }`,
        mm,
        yyyy,
        formatDate: `${dd < 10 ? '0' + dd : dd}/${mm < 10 ? '0' + mm : mm}/${yyyy}`,
        weekDay: this.weekDay[ngDate.getDay()],
        displayDay: this.weekDay[ngDate.getDay()].slice(0,3)
      }
    }).reverse();
  }
}
