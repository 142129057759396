<section class="inner_banner_section pricing_page_banner">
    <div class="container">
        <div class="row">
            <div class="inner_banner_block">
                <div class="inner_banner_tilte">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pricing_pg_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="pricing_title_bar">
                    <p>We make Laundry affordable by charging you per kilo and not per piece. Our monthly package
                        pricing keeps your budget in check <br> and saves you more money than doing it at home. Along
                        with saving your money, <span>we save your time!</span></p>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="search_section">
                    <div class="select_city">
                        <select id="selectbox1" name="city" [(ngModel)]="city" (change)="getCityStores()">
                            <option value="0">Select city</option>
                            <option *ngFor="let city of citiesList" value="{{city.id}}">{{city.name}}</option>
                        </select>
                    </div>
                    <div class="select_store">
                        <select id="selectbox2" name="store_id" [(ngModel)]="store_id" (change)="getstoreDtl()">
                            <option value="0">Select Store</option>
                            <option *ngFor="let stores of storesList" value="{{stores.id}}">{{stores.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pricing_main_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="pr_text">
                    <p>Free Pick up & Drop on orders above <span>Rs. 480 </span></p>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top" *ngIf="city || store_id">
                <div class="row">
                    <!-- Left Sidebar with Services -->
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="left_tab_bar" id="sidebar-wrapper">
                            <nav id="spy">
                                <h4>Select Services</h4>
                                <ul class="sidebar-nav nav nav-tabs" (click)="showPhase(i)"
                                    *ngFor="let service of services; index as i"
                                    [class.phase-active]="activeButton === i">
                                    <li class="sidebar-brand active" (click)="getSerivceId(service.id,i);">
                                        <img src="{{service.full_url}}" alt="{{service.name}}">{{service.name}}
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <!-- Right Content Area -->
                    <div class="col-lg-8 col-md-8 col-sm-8 tab-content" id="myTabContent">
                        <!-- Loader -->
                        <div class="row" *ngIf="showLoader">
                            <div class="col-xs-12">
                                <div id='loading_wrap'></div>
                            </div>
                        </div>

                        <!-- Service Unavailable Message -->
                        <h2 *ngIf="serviceHideText" class="service-unavailable">
                            Please contact the store or call us at 9999759911 for latest Dry Cleaning, Premium Laundry
                            and Steam Press price list.
                            <br><br><br>
                            Thank You!
                        </h2>

                        <!-- Price Content -->
                        <div *ngIf="priceTable" class="tab-pane pricing_right_side fade in active">
                            <div *ngIf="tempHideServices">
                                <!-- Category Filter Tabs -->
                                <nav *ngIf="subCategoryFilter" class="category-nav">
                                    <div class="nav nav-tabs nav-fill top_tab_bar" id="nav-tab"
                                        *ngFor="let category of categories; index as i"
                                        [class.phase-active-cat]="activeButtonCat === i"
                                        (click)="showPhaseCat(i)">
                                        <a class="nav-item nav-link" (click)="filterPrices(category.placeholder, i)"
                                            data-toggle="tab" href="#laundry_mens" role="tab"
                                            aria-controls="laundry_mens" aria-selected="true">
                                            <img src="{{category.img}}" alt="{{category.name}}">
                                            <span>{{category.name}}</span>
                                        </a>
                                    </div>
                                </nav>

                                <!-- Cloths Section -->
                                <div class="pricing-section"  *ngIf="hasItems()">
                                    <h3>Items & Prices</h3>
                                    <table class="price-table">
                                        <thead>
                                          <tr>
                                            <th>Item list</th>
                                            <th *ngIf="pricePerPc">Price Per Pc</th>
                                            <th *ngIf="pricePerKg">Price Per kg</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of itemPrices">
                                                <td>{{data.name}}</td>
                                                <td class="prices">₹{{data.price}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                               <!-- Services Section -->
<!-- Services Section -->
<div class="pricing-section">
    <div class="section-header" (click)="toggleDataSection('services')" 
         *ngIf="hasServices()">
         <h3>Services & Prices</h3>
        <span class="toggle-icon">{{ showServices ? '' : '' }}</span>
    </div>
    <table *ngIf="showServices && hasServices()" class="price-table">
        <thead>
            <tr>
                <th>Service Name</th>
                <th *ngIf="hasServicePrice()">Price</th>
               
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let service of getServices()">
                <td>{{service.name}}</td>
                <td *ngIf="hasServicePrice()">₹{{service.price}}</td>
              
            </tr>
        </tbody>
    </table>
</div>

<!-- Add-ons Section -->
<div class="pricing-section">
    <h3>Addons & Prices</h3>
    <div class="section-header" (click)="toggleDataSection('addons')"
         *ngIf="hasAddons()">
        <span class="toggle-icon">{{ showAddons ? '' : '' }}</span>
    </div>
    <table *ngIf="showAddons && hasAddons()" class="price-table">
        <thead>
            <tr>
                <th>Add-on Name</th>
                <th *ngIf="hasAddonPrice()">Price</th>
              
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let addon of getAddons()">
                <td>{{addon.name}}</td>
                <td *ngIf="hasAddonPrice()">₹{{addon.price}}</td>
                
            </tr>
        </tbody>
    </table>
</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>