import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-steam-ironing',
  templateUrl: './steam-ironing.component.html',
  styleUrls: ['./steam-ironing.component.scss']
})
export class SteamIroningComponent implements OnInit {
  bannerImageUrl = `${environment.imageBaseUrl}Banner/SteamPress.jpg`;
  steamPressImage: string = `${environment.imageBaseUrl}cta/SteamPress.jpg`;
  processSteps = [
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step6.svg`,
      title: 'Garment Inspection',
      description: 'Inspect fabric, stains, and colors. Assess fiber type for cleaning needs. Check for potential color bleeding.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step5.svg`,
      title: 'Steam Press',
      description: 'High-pressure steam ironing is done for a flawless and crisp finish.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step2.svg`,
      title: 'Quality Check',
      description: 'Multiple quality checks to ensure top quality and customer satisfaction.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step1.svg`,
      title: 'Packaging',
      description: 'Packaging options as per your needs. Combined, Individual or Hanger Packaging.'
    }
  ];
  

  constructor() { }

  ngOnInit(): void {
  }

}
