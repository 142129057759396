import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-curtain-cleaning',
  templateUrl: './curtain-cleaning.component.html',
  styleUrls: ['./curtain-cleaning.component.scss']
})
export class CurtainCleaningComponent implements OnInit {
  bannerImageUrl = `${environment.imageBaseUrl}Banner/CurtainCleaning.jpg`;
  curtainCleaningImage: string = `${environment.imageBaseUrl}cta/CurtainCleaning1.jpg`;
  processSteps = [
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step6.svg`,
      title: 'Tagging & Sorting',
      description: 'Curtains are tagged to avoid potential mix-ups.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step4.svg`,
      title: 'Garment Inspection',
      description: 'Curtains are inspected for stains, potential color bleeds, snags and tears. Fiber type is assessed to identify appropriate cleaning process.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step3.svg`,
      title: 'Stain Removal',
      description: 'Pre-wash treatment for all visible stains with specialized stain removers. Focus on tough spots for thorough cleaning.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step2.svg`,
      title: 'Customized Clean',
      description: 'Fabric-specific solvent-based cleaning tailored to fabric type for gentle and thorough care.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step1.svg`,
      title: 'Finishing',
      description: 'Finishing treatments like starch, fabric softener, stain repellant, etc., applied as per requirement.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step3.svg`,
      title: 'Steam Press',
      description: 'A high-pressure vacuum steam press is done for that crisp and perfect finishing.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step2.svg`,
      title: 'Quality Check',
      description: 'Multiple checks to ensure exceptional quality and satisfaction.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step1.svg`,
      title: 'Packaging',
      description: 'Flexible packaging options to suit your preference. Choose how you like them folded.'
    }
  ];
  
  

  constructor() { }

  ngOnInit(): void {
  }

}
