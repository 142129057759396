<section class="hp_banner_section" [style.background-image]="'url(' + bannerImageUrl + ')'">
  <div class="container">
    <div class="row">
      <div class="hp_banner_block">
        <div class="hp_banner_tilte">
          <h3 class="baner-top">Steam Ironing</h3>
          <h3 class="banner-botomm">Eco-Friendly - Expert Stain Removal</h3>
        </div>
  
      </div>
    </div>
  </div>
</section>
 
  <div class="service-body">
    <h2 class="process-title" style="margin-bottom: 30px;">
      INTRODUCTION

    </h2>
    <div class="body body-text">
     
      Picture your favorite garments—a tailored suit, a delicate blouse, or a beloved dress. They hang in your wardrobe, waiting for the perfect occasion. Now imagine subjecting them to the harsh, unyielding heat of a coal iron, its heavy weight pressing down without mercy. The fabric endures intense, uncontrolled heat, leaving behind shine marks on your trousers and potentially ruining the smooth finish of polyester.
      Coal irons have been a staple for years, utilizing hot coals and a heavy iron press to smooth out wrinkles. However, this method comes with significant drawbacks. Coal iron comes with limited temperature control. This lack of temperature modulation often leads to shine marks on delicate fabrics like polyester, which can be difficult to remove. Further, the ash and soot from coal irons can settle on your clean garments, exposing them to potential allergens and compromising their cleanliness.
      
  
    </div>
    <h2 class="process-title" style="margin-bottom: 30px; margin-top: 50px;">
      Teflon-Coated Safety for Delicate Fabrics
    </h2>
    <div class="body-lower body-text">
      Our Professional Steam Press, on the other hand, utilizes the power of high-pressure steam to deliver a flawless finish while caring for your garments in a way that traditional methods cannot match:
      Crisp Finish & 100% Sanitation: The steam penetrates deep into the fabric fibers, ensuring a sharp, wrinkle-free look with the added benefit of complete sanitation.
      Teflon-Coated Safety: The press is equipped with Teflon plates, which protect your garments from any fiber damage, preserving their integrity and extending their lifespan.
      At UClean, our team of skilled professionals employs top-of-the-line steam ironing equipment and advanced techniques to smooth out wrinkles and creases with precision. Our experts are adept at handling a diverse range of fabrics and styles, including delicate materials like silk and wool. Beyond the flawless ironing, UClean is renowned for its impeccable packaging, ensuring that your garments retain their shape and deliver that crisp, freshly ironed feel every time you unpackage them.
      
    </div>
</div>

            <div class="process">
               
                    <h2 class="process-title">OUR STEAM IRONING PROCESS</h2>
                    <p class="process-description">Every cloth goes through a comprehensive 6 stage process enabling us to deliver on the promise of sparkling & fresh clothes, every time.</p>
                    
                    <div class="process-timeline">
                      <div class="process-step" *ngFor="let step of processSteps; let last = last">

                        <div class="step-icon">
                            <img [src]="step.iconPath" [alt]="step.title" class="custom-icon">
                          </div>
                    
                        <div class="step-content">
                          <h3 class="step-title">{{ step.title }}</h3>
                          <p class="step-description">{{ step.description }}</p>
                        </div>
                        <div class="step-connector" *ngIf="!last"></div>
                      </div>
                    </div>
                 
            </div>
            <div class="book-now">
                <h1>Trust us for keeping your clothes ‘AS GOOD AS NEW’!</h1>
                <button class="btn primary">Book Now</button>
            </div>
           
            <div class="dry-cleaning-container">
                <div class="image-container">
                  <<img [src]="steamPressImage" alt="Steam Press Service">
                </div>
                <div class="content-container">
                  <h2 class="title">OUR STEAM IRONING PRICES</h2>
                  <p class="description body-text">
                    At UClean, we believe in delivering high quality cleaning services at very affordable prices. With stores across multiple Tier 1, Tier 2 and Tier 3 cities, Quality, Speed and Economical Prices have been our biggest value drivers.
                  </p>
                  <a href='laundry-drycleaning-price-list' class="view-pricing-btn">View Pricing</a>
                </div>
              </div>

            <div class="promise-container">
                <h2 class="promise-title">OUR PROMISE TO YOU</h2>
                <app-our-promise></app-our-promise>
              </div>