import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RouteStateService {
  private isFromDeleteAccount = false;

  setFromDeleteAccount(value: boolean) {
    this.isFromDeleteAccount = value;
  }

  isNavigatingFromDeleteAccount(): boolean {
    return this.isFromDeleteAccount;
  }

  resetState() {
    this.isFromDeleteAccount = false;
  }
}