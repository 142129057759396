import { Component, OnInit } from '@angular/core';
import { OrderService } from '../order.service';
import { Location } from '../../../models/location';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-order-confrimed',
  templateUrl: './order-confrimed.component.html',
  styleUrls: ['./order-confrimed.component.scss']
})
export class OrderConfrimedComponent implements OnInit {


  // Query params
  location: any;
  selectedStoreId: any;
  selectedServiceIds: any;
  selectedSlotId: any;
  selectedAddressId: any;
  selectedCouponCode: any;
  selectedCouponId: any;

  // view states
  showLoader = true;
  couponErrorMessage: any;
  couponSuccessMessage: any;
  //
  orderSummary: any = {};
  address: any = {};
  slot: any = {};
  store: any = {};
  pickup_date: any;
  suggestion: any;
  orderId: any;

  constructor(private route: ActivatedRoute, private datepipe: DatePipe, private router: Router, private orderService: OrderService) {
  }



  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.location = {} as Location;
      this.location.lat = params['lat'];
      this.location.lng = params['lng'];
      this.location.name = params['locationName'];
      this.location.pincode = params['pincode'];
      this.location.city = params['city'];
      this.selectedServiceIds = params['service_ids'];
      this.selectedStoreId = params['store_id'];
      this.selectedSlotId = params['slot_id'];
      this.selectedAddressId = params['address_id'];
      this.store.name = params['store_name'];
      this.orderId = params['booking_id'];

      // convert to array if single service selected
      if (!Array.isArray(this.selectedServiceIds)) {
        let service = this.selectedServiceIds;
        this.selectedServiceIds = [];
        this.selectedServiceIds.push(service);
      }
      this.orderService.buildOrder(this.location, this.selectedStoreId, this.selectedServiceIds,
        this.selectedSlotId, this.selectedAddressId).subscribe((data: any) => {
          this.orderSummary = data;
          this.store = data.store;
          this.address = data.address[0];
          this.slot = data.slot;
          this.pickup_date = this.datepipe.transform(data.slot.date, 'dd/MM/yyyy');
          this.showLoader = false;
        });
    });
  }
  takeToBuildOrder() {
    this.router.navigate(['build-your-order'], {
      queryParams: {
        lat: this.location.lat,
        lng: this.location.lng,
        locationName: this.location.name,
        pincode: this.location.pincode,
        city: this.location.city,
        service_ids: this.selectedServiceIds,
        slot_id: this.selectedSlotId,
        address_id: this.selectedAddressId,
      }
    });
  }

  takeToSchedulePickup() {
    this.router.navigate(['/schedule-pick-up'],
      {
        queryParams: {
          lat: this.location.lat,
          lng: this.location.lng,
          locationName: this.location.name,
          pincode: this.location.pincode,
          city: this.location.city,
          store_id: this.selectedStoreId,
          service_ids: this.selectedServiceIds,
          slot_id: this.selectedSlotId,
          address_id: this.selectedAddressId
        }
      });
  }

  takeToChooseAddress() {
    this.router.navigate(['/choose-address'], {
      queryParams: {
        lat: this.location.lat,
        lng: this.location.lng,
        locationName: this.location.name,
        pincode: this.location.pincode,
        city: this.location.city,
        store_id: this.selectedStoreId,
        service_ids: this.selectedServiceIds,
        slot_id: this.selectedSlotId,
        address_id: this.selectedAddressId,
      }
    });

  }
}