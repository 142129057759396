import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { PagesService } from '../pages.service';
import { AuthService } from '../../../services/auth.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  model: NgbDateStruct | any;
  editProfile = true;
  userData: any = {};

  constructor(
    private pagesService: PagesService, 
    public authService: AuthService, 
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    // Load saved gender from localStorage if it exists
    const savedGender = localStorage.getItem('userGender');
    if (savedGender) {
      this.authService.user.sex = savedGender;
    }
  }

  editProfileEnable() {
    this.editProfile = false;
  }

  editProfileDisable() {
    this.editProfile = true;
  }

  updateProfile() {
    const body = {
      name: this.authService.user.name,
      dob: `${this.authService.user.dob.year}-${this.authService.user.dob.month}-${this.authService.user.dob.day}`,
      sex: this.authService.user.sex,
    };
    localStorage.setItem('userGender', this.authService.user.sex);

    this.pagesService.updateUserProfile(body).subscribe((data: any) => {
      this.editProfile = true;
      this.userData = data;
    });
  }

  onGenderChange(event: any) {
    this.authService.user.sex = event.target.value;
    localStorage.setItem('userGender', event.target.value);
  }
}