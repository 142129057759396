import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PixelService } from 'ngx-pixel';
import { AuthService } from 'src/app/services/auth.service';
import { OrderService } from '../../build-order/order.service';

@Component({
  selector: 'app-order-confirmed-success',
  templateUrl: './order-confirmed-success.component.html',
  styleUrls: ['./order-confirmed-success.component.scss']
})
export class OrderConfirmedSuccessComponent implements OnInit {

  // Query params
  location: any;
  selectedStoreId: any;
  selectedServiceIds: any;
  selectedSlotId: any;
  selectedAddressId: any;
  selectedCouponCode: any;
  selectedCouponId: any;

   // view states
   showLoader = true;
   couponErrorMessage: any;
   couponSuccessMessage: any;

  orderSummary: any = {};
  address: any = {};
  slot: any = {};
  store: any = {};
  pickup_date: any;
  suggestion: any;
  orderId: any;

  public mainBannerText = 'Sit back and relax. Let us take care of your laundry';
  public descriptionText = '';

  constructor(
    private pixel: PixelService,
    private authService: AuthService,
    private router: Router, private route: ActivatedRoute, private orderService: OrderService, private datepipe: DatePipe, private metaTagService: Meta) { }

  ngOnInit(): void {
    this.showLoader = true;
    this.route.queryParams.subscribe(params => {
      this.location = {} as Location;
      this.location.lat = params['lat'];
      this.location.lng = params['lng'];
      this.location.name = params['locationName'];
      this.location.pincode = params['pincode'];
      this.location.city = params['city'];
      this.selectedServiceIds = params['service_ids'];
      this.selectedStoreId = params['store_id'];
      this.selectedSlotId = params['slot_id'];
      this.selectedAddressId = params['address_id'];
      this.store.name = params['store_name'];
      this.orderId = params['booking_id'];
      const userDetails = this.authService.userDetails;
      this.pixel.trackCustom('Booked', {
        'First name': userDetails?.name,
        'Phone Number': userDetails?.mobile
      });

      // convert to array if single service selected
      if (!Array.isArray(this.selectedServiceIds)) {
        let service = this.selectedServiceIds;
        this.selectedServiceIds = [];
        this.selectedServiceIds.push(service);
      }
      this.orderService.buildOrder(this.location, this.selectedStoreId, this.selectedServiceIds,
        this.selectedSlotId, this.selectedAddressId).subscribe((data: any) => {
          this.orderSummary = data;
          this.store = data.store;
          this.address = data.address[0];
          this.slot = data.slot;
          this.pickup_date = this.datepipe.transform(data.slot.date, 'dd/MM/yyyy');
          this.showLoader = false;
          this.descriptionText = '<p>Your order is placed with <b class="dark-bold">'+this.orderSummary?.store?.name+'</b> The store team will contact you shortly!<br>Thank You</p>';
        });
    });
  }

  goToHome() {
    this.router.navigate(['']);
  }

  goBackToPrevScreen() {
    this.takeToBuildOrder();
  }

  takeToBuildOrder() {
    this.router.navigate(['build-your-order'], {
      queryParams: {
        lat: this.location.lat,
        lng: this.location.lng,
        locationName: this.location.name,
        pincode: this.location.pincode,
        city: this.location.city,
        service_ids: this.selectedServiceIds,
        slot_id: this.selectedSlotId,
        address_id: this.selectedAddressId,
      }
    });
  }

}
