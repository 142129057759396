import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from '../blog.service';


@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss']
})
export class BlogDetailComponent implements OnInit {

  share : string = "";

  sharePostUrl ="https://www.facebook.com/sharer/sharer.php?u="+window.location.href;

  constructor(private blogService:BlogService,private route: ActivatedRoute, private metaTagService: Meta) {}

  blogDetail:any = {};
  recentBlogs: any;
  ngOnInit(): void {
    this.blogService.detailBlogs(this.route.snapshot.paramMap.get('slug')).subscribe((data:any)=>{
      this.blogDetail = data;
    });

    this.blogService.latestBlogs().subscribe((data:any)=>{
      this.recentBlogs = data.blogs;
    });
    this.share =  this.sharePostUrl
  };

  recBlogs(){
    this.blogService.detailBlogs(this.route.snapshot.paramMap.get('slug')).subscribe((data:any)=>{
      this.blogDetail = data;
    });
  }

}
