import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { BlogService } from '../blog.service';

@Component({
  selector: 'app-latest-blogs',
  templateUrl: './latest-blogs.component.html',
  styleUrls: ['./latest-blogs.component.scss']
})
export class LatestBlogsComponent implements OnInit {
  constructor(private blogService:BlogService, private metaTagService: Meta) {}

  latestBlog:any;

  ngOnInit(): void {
    this.blogService.latestBlogs().subscribe((data:any)=>{
      this.latestBlog = data.blogs;
    });
  };
}

