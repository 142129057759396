import {Component, OnInit} from '@angular/core';
import {NgbCalendar, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {PagesService} from '../../pages/pages.service';
import {OrderService} from '../order.service';
import {Location} from '../../../models/location';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-schedule-pickup',
  templateUrl: './schedule-pickup.component.html',
  styleUrls: ['./schedule-pickup.component.scss']
})

export class SchedulePickupComponent implements OnInit {
  // Default data for calendar
  from_date = {year: 2022, month: 4, day: 11};
  to_date = {year: 2022, month: 4, day: 20};
  selected_date: any;

  // Query params
  location: any;
  selectedStoreId: any;
  selectedServiceIds: any;
  selectedSlotId: any;

  selectedAddressId: any;

  // view states
  showLoader = true;
  showNoSlotsFound = false;
  noSlotSelectedError = false;
  selectedIndex=-1;

  // Data received from server
  slots: any;
  timingSlots: any;

  

  constructor(
    private metaTagService: Meta,
    private orderService: OrderService, private route: ActivatedRoute,
              public router: Router, public authService: AuthService) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.location = {} as Location;
      this.location.lat = params['lat'];
      this.location.lng = params['lng'];
      this.location.name = params['locationName'];
      this.location.pincode = params['pincode'];
      this.location.city = params['city'];
      this.selectedServiceIds = params['service_ids'];
      this.selectedStoreId = params['store_id'];
      this.selectedSlotId = params['slot_id'];
      this.selectedAddressId = params['address_id'];
      this.selectedIndex=params['selected_index'];
      // convert to array if single service selected
      if (!Array.isArray(this.selectedServiceIds)) {
        let service = this.selectedServiceIds;
        this.selectedServiceIds = [];
        this.selectedServiceIds.push(service);
        
      }

      this.orderService.slots(this.location, this.selectedStoreId, this.selectedServiceIds).subscribe((data: any) => {
        if (data['Status'] == 'Fail') {
          this.showNoSlotsFound = true;
        } else {
          this.slots = {};
          // group slots based on the date
          for (let slot of data.slots) {
            if (this.slots[slot.date] == undefined) {
              this.slots[slot.date] = [];
            }
            this.slots[slot.date].push(slot);
            if (this.selectedSlotId != undefined && this.selectedSlotId == slot.id) {
             this.selected_date = this.convertToDate(slot.date);
             this.onDateSelect(this.selected_date);
            } 
          }
          // set the min & max dates
          this.setMinMaxDates();

          // if date is not selected then select the min date.
          if (this.selected_date == undefined) {
          //  this.selected_date = this.from_date;
          }//

          // set the timing slots
        //  this.onDateSelect(this.selected_date);
        }

        this.showLoader = false;
      });
    });
  }

  onDateSelect(selectedDate: any): void {
    this.selected_date = selectedDate;
    let selectedDateStr = this.convertToStringDate(selectedDate)
    let slots: any[] = this.slots[selectedDateStr];
    let currentDateStr =  new Date().toISOString().split('T')[0];
    let time = new Date();
    let finalslots: any[] = [];
    
    for (let slot of slots) {
      // console.log(slot.date, currentDateStr, time);
      // console.log(slot.start_time, time.getHours())
      if(slot.date == currentDateStr){
        if(slot.start_time > time.getHours()) {
          finalslots.push(slot);
        }
      } else{
        finalslots.push(slot);
      }

    }
    this.timingSlots = finalslots;
    //TODO remove slot if current date and time is less than current time.
    //TODO if no slots then render empty page
  }

  convertToStringDate(selectedDate: any): any {
    let month = selectedDate.month;
    if (month < 10) {
      month = '0' + month;
    }
    let day = selectedDate.day;
    if (day < 10) {
      day = '0' + day;
    }
    let str = selectedDate.year + '-' + month + '-' + day;
    return str;
  }

  convertToDate(date: any): any {
    let year = date.split('-')[0];
    let month = date.split('-')[1];
    let day = date.split('-')[2];
    return {year: parseInt(year), month: parseInt(month), day: parseInt(day)};
  }

  convertToDateFromStr(date: any): any {
    let day = date.split('/')[0];
    let month = date.split('/')[1];
    let year = date.split('/')[2];
    return {year: parseInt(year), month: parseInt(month), day: parseInt(day)};
  }

  setMinMaxDates(): void {
    let startDate = Object.keys(this.slots)[0];
    let endDate = Object.keys(this.slots)[Object.keys(this.slots).length - 1];
    this.from_date = this.convertToDate(startDate);
    this.to_date = this.convertToDate(endDate);
  }

  selectSlot(slot_id: any): void {
    this.selectedSlotId = slot_id;
  }

  takeToBuildOrder() {
    this.router.navigate(['build-your-order'], {
      queryParams: {
        lat: this.location.lat,
        lng: this.location.lng,
        locationName: this.location.name,
        pincode: this.location.pincode,
        city: this.location.city,
        service_ids: this.selectedServiceIds,
        slot_id: this.selectedSlotId,
        address_id: this.selectedAddressId,
        
      }
      
    });
  }

  takeToChooseAddress() {
    if (this.selectedSlotId != undefined) {
      if (this.authService.loggedIn) {
        this.noSlotSelectedError = false;
        this.router.navigate(['/choose-address'], {
          queryParams: {
            lat: this.location.lat,
            lng: this.location.lng,
            locationName: this.location.name,
            pincode: this.location.pincode,
            city: this.location.city,
            store_id: this.selectedStoreId,
            service_ids: this.selectedServiceIds,
            slot_id: this.selectedSlotId,
            address_id: this.selectedAddressId,
            selected_index:this.selectedIndex
          }
        });
      } else {

      }
    } else {
      this.noSlotSelectedError = true;
    }
  }
}
