<section class="hp_banner_section" [style.background-image]="'url(' + bannerImageUrl + ')'">
    <div class="container">
      <div class="row">
        <div class="hp_banner_block">
          <div class="hp_banner_tilte">
            <h3 class="baner-top">Customer Cleaning</h3>
            <h3 class="banner-botomm">Eco-Friendly - Expert Stain Removal</h3>
          </div>
  
        </div>
      </div>
    </div>
  </section>
 
  <div class="service-body">
    <h2 class="process-title" style="margin-bottom: 30px;">
      INTRODUCTION
    </h2>
    <div class="body body-text">
      Ever gazed at your curtains and wondered how many stories they've silently witnessed? These elegant guardians of your privacy do more than just frame your windows. They add a touch of color, texture, and style to any room, transforming it with their soft, elegant presence. Whether they're concealing unsightly elements or enhancing your space's ambiance, curtains are the perfect finishing touch.
However, despite their aesthetic appeal, curtains can be notoriously difficult to clean. From shrinkage to fading, maintaining their beauty requires careful handling, especially since they're often made from a blend of different fabrics. Choosing the right cleaning method can be challenging, but with UClean, you don’t have to worry. From lightweight sheers to heavy velvets. Our experienced professionals are equipped with the knowledge and proper cleaning agents to ensure your curtains are cared for with the utmost precision, preserving their elegance and extending their lifespan.
    </div>
    </div>
            <div class="process">
               
                    <h2 class="process-title">OUR CURTAIN CLEANING PROCESS</h2>
                    <p class="process-description">Every cloth goes through a comprehensive 6 stage process enabling us to deliver on the promise of sparkling & fresh clothes, every time.</p>
                    
                    <div class="process-timeline">
                      <div class="process-step" *ngFor="let step of processSteps; let last = last">

                        <div class="step-icon">
                            <img [src]="step.iconPath" [alt]="step.title" class="custom-icon">
                          </div>
                    
                        <div class="step-content">
                          <h3 class="step-title">{{ step.title }}</h3>
                          <p class="step-description">{{ step.description }}</p>
                        </div>
                        <div class="step-connector" *ngIf="!last"></div>
                      </div>
                    </div>
                 
            </div>
            <div class="book-now">
                <h1>Trust us for keeping your clothes ‘AS GOOD AS NEW’!</h1>
                <button class="btn primary">Book Now</button>
            </div>
           
            <div class="dry-cleaning-container">
                <div class="image-container">
                  <img [src]="curtainCleaningImage" alt="Curtain Cleaning Service">
                </div>
                <div class="content-container">
                  <h2 class="title">OUR CARPET CLEANING PRICES</h2>
                  <p class="description body-text">
                    At UClean, we believe in delivering high quality cleaning services at very affordable prices. With stores across multiple Tier 1, Tier 2 and Tier 3 cities, Quality, Speed and Economical Prices have been our biggest value drivers.
                  </p>
                  <a href='laundry-drycleaning-price-list' class="view-pricing-btn">View Pricing</a>
                </div>
              </div>

            <div class="promise-container">
                <h2 class="promise-title">OUR PROMISE TO YOU</h2>
                <app-our-promise></app-our-promise>
              </div>