import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { OrderService } from './order.service';
import { tap, catchError } from 'rxjs/operators';

// Define an interface for more type safety
export interface ScheduleData {
  location: any;
  selectedStoreId: any;
  selectedServiceIds: any[];
  selectedSlotId: any;
  selectedAddressId: any;
  selectedDate: any;
  timingSlots: any[];
  combine_time: string | undefined;
}

@Injectable({
  providedIn: 'root'
})
export class OrderStateService {
  // BehaviorSubject to store the entire schedule information
  private scheduleSubject = new BehaviorSubject<ScheduleData | null>(null);
  schedule$ = this.scheduleSubject.asObservable();

  // BehaviorSubject to store the selected address
  private selectedAddressSubject = new BehaviorSubject<any>(null);
  selectedAddress$ = this.selectedAddressSubject.asObservable();

  // New BehaviorSubject to store selected services
  private selectedServicesSubject = new BehaviorSubject<string[]>([]);
  selectedServices$ = this.selectedServicesSubject.asObservable();

  constructor(
    private orderService: OrderService
  ) {}

  private currentSchedule: ScheduleData | null = null;

  // Updated method to update the schedule with combine_time
  updateSchedule(scheduleData: ScheduleData) {
    // Update the schedule subject with the full schedule data
    this.scheduleSubject.next(scheduleData);
    console.log('Updated schedule data:', scheduleData);
  }

  // Method specifically for updating address
  updateSelectedPickupAddress(address: any) {
    console.log('Full address object received:', address);
    console.log('Address properties:', Object.keys(address));
    
    this.selectedAddressSubject.next(address);
  }
  // Updated method to get services and store details
// Method to update selected services
updateSelectedServices(serviceNames: string[]) {
  console.log('Updating selected services:', serviceNames);
  this.selectedServicesSubject.next(serviceNames);
}

  // Method to get the current schedule
  getCurrentSchedule(): ScheduleData | null {
    const schedule = this.scheduleSubject.getValue();
    console.log('Current schedule:', schedule);
    return schedule;
  }

  // Method to get the current selected address
  getSelectedPickupAddress() {
    const address = this.selectedAddressSubject.getValue();
    console.log('Selected pickup address:', address);
    return address;
  }

  // Method to get the current selected services
  getSelectedServices() {
    const services = this.selectedServicesSubject.getValue();
    console.log('Selected services:', services);
    return services;
  }
}