<div class="profile_right_block">
    <div class="profile_setting_block">
        <div class="profile_title">
            <h2>Notification Settings</h2>
        </div>

        <div class="setting_sec">
            <li>
                <h4>Promos and offers</h4>
                <p>Receive coupons, promotions and money-saving offers</p>
                <h5><i class="fa fa-bell-o" aria-hidden="true"></i>Push</h5>
                <label class="switch_btn">
                    <input type="checkbox" checked>
                    <span data-toggle="active" class="slider_check round"></span>
                </label>
            </li>
            <li>
                <h4>Orders and purchases</h4>
                <p>Receive updates related to your order status, <br> memberships table bookings
                    and more </p>
                <h5><i class="fa fa-bell-o" aria-hidden="true"></i>Push</h5>
                <label class="switch_btn">
                    <input type="checkbox" checked>
                    <span class="slider_check round"></span>
                </label>
            </li>
        </div>


    </div>
</div>