<section class="screen otp-success">
    <app-auth-wrapper>
        <div class="form-wrapper">
            <div class="backdrop">
                <img src="//assets.ucleanlaundry.com/assets/images/login/back-arrow.svg"> Back to previous screen
            </div>
            <div class="form-header-wrapper font-700">
                <p>OTP Verification</p>
                <span class="line"></span>
            </div>
            <div class="success-msg-container">
                <img src="//assets.ucleanlaundry.com/assets/images/login/success.svg" alt="success">
                <p><b>OTP successfully Verified</b></p>
            </div>
            <div class="next-action-container">
                <p>Redirecting you to the Dashboard</p>
                <div class="loader-wrapepr">
                    <app-dot-loader></app-dot-loader>
                </div>
            </div>
        </div>
    </app-auth-wrapper>
</section>