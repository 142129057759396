<div class="profile_right_block">
  <div class="profile_form">
    <div class="profile_title">
      <h2>My Profile</h2>
    </div>

    <div class="edit_btn">
      <button name="button" (click)="editProfileEnable()" type="button">Edit</button>
    </div>
    <form method="POST" action="#" role="form">
      <div class="form-group">
        <input [(ngModel)]="authService.user.name" [disabled]="editProfile" name="name"
               value="{{authService.user.name}}" id="Name" type="text" class="form-control"
               placeholder="Name">
      </div>
      <div class="form-group">
        <input value="{{authService.user.mobile}}" [disabled]="editProfile" name="mobile"
               id="Phone" type="Phone" class="form-control" placeholder="Phone">
      </div>
      <div class="form-group">
        <input name="email" value="{{authService.user.email}}" [disabled]="editProfile"
               id="Email" type="Email" class="form-control" placeholder="Email">
      </div>
      <div class="form-group" style="margin-bottom: 0;">
        <div class="row">
          <div class="col-md-6 col-sm-12" style="margin-bottom: 25px;">
            <div class="select_pro_sec gander_select">
              <select name="sex" [(ngModel)]="authService.user.sex" [disabled]="editProfile"
                      id="selectbox1">
                <option value="M">Male</option>
                <option value="F">Female</option>
                <option value="i'd rather not say">i'd rather not say</option>
              </select>
            </div>
          </div>

          <div class="col-md-6 col-sm-12" style="margin-bottom: 25px;">
            <div class="select_pro_sec day_select">
              <form style="margin-top: 0px;">
                <div class="input-group" style="display: block">
                  <input [(ngModel)]="authService.user.dob" [disabled]="editProfile"
                         [minDate]="{year: 1970, month:1, day: 1}"
                         class="form-control"
                         placeholder="DOB"
                         name="dob" ngbDatepicker #d="ngbDatepicker" style="width: 80%;">
                  <button class="btn btn-outline-secondary calendar"
                          (click)="d.toggle()" type="button" style="height: 48px;"><i class="fa fa-calendar"
                                                                                      aria-hidden="true"></i></button>
                </div>

              </form>
            </div>
          </div>
        </div>


      </div>
      <div class="form-group">
        <button (click)="updateProfile()" id="signupSubmit" type="submit" [disabled]="editProfile"
                class="btn btn-info btn-block">Save
          Details
        </button>
      </div>
    </form>
  </div>
</div>
