// confirm-order.component.ts
import { Component, OnInit } from '@angular/core';
import { OrderService } from '../order.service';
import { Location } from '../../../models/location';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { OrderStateService } from '../order-state.service';

@Component({
  selector: 'app-confirm-order',
  templateUrl: './confirm-order.component.html',
  styleUrls: ['./confirm-order.component.scss']
})
export class ConfirmOrderComponent implements OnInit {
  // Query params
  location: any;
  selectedStoreId: any;
  selectedServiceIds: any;
  selectedSlotId: any;
  selectedAddressId: any;
  AddressId: any;
  selectedCouponCode: any;
  selectedCouponId: any;
  noAddressSelectedError = false;

  // view states
  showLoader = true;
  couponErrorMessage: any;
  couponSuccessMessage: any;
  
  orderSummary: any = {};
  address: any = {};
  slot: any;
  store: any = {};
  pickup_date: any;
  pickup_time: any;
  suggestion: any;
  orderId: any;
  name: any;
  selectedIndex: number = -1;

  private readonly STORAGE_KEY = 'orderSummaryData';

  constructor(
    private metaTagService: Meta,
    private route: ActivatedRoute,
    private datepipe: DatePipe,
    private router: Router,
    private orderService: OrderService,
    private orderStateService: OrderStateService
  ) {}

  ngOnInit(): void {
    // Subscribe to address updates with ID handling
    this.orderStateService.selectedAddress$.subscribe(addressData => {
      if (addressData) {
        console.log('Address received in ConfirmOrderComponent:', addressData);
        
        // Set the selectedAddressId from the address id
        if (addressData.id) {
          this.selectedAddressId = addressData.id;
          console.log('Selected Address ID set to:', this.selectedAddressId);
         
        }
        
        // Format the full address from the address object
        const addressParts = [];
        if (addressData.flat_info) addressParts.push(addressData.flat_info);
        if (addressData.street_info) addressParts.push(addressData.street_info);
        if (addressData.landmark_info) addressParts.push(addressData.landmark_info);
        if (addressData.city) addressParts.push(addressData.city);
        if (addressData.pincode) addressParts.push(addressData.pincode);
        if (addressData.id) addressParts.push(addressData.id);
        
        this.address = addressParts.join(', ');
        this.saveToStorage();
        console.log('Selected Address here iss set to:', this.selectedAddressId);
        this.AddressId = this.selectedAddressId;
      }
    });

    // Subscribe to services with proper array handling
    this.orderStateService.selectedServices$.subscribe(serviceNames => {
      if (serviceNames && serviceNames.length > 0) {
        console.log('Service Names received:', serviceNames);
        this.name = serviceNames;
        this.saveToStorage();
      }
    });

    // Subscribe to schedule with proper data mapping
    this.orderStateService.schedule$.subscribe(scheduleData => {
      if (scheduleData) {
        console.log('Schedule data received:', scheduleData);
        
        // Update store information
        if (scheduleData.location) {
          this.store.address = scheduleData.location.name || '';
        }
        
        // Update pickup date
        if (scheduleData.selectedDate) {
          const { day, month, year } = scheduleData.selectedDate;
          this.pickup_date = `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year}`;
        }
        
        // Update pickup time
        if (scheduleData.combine_time) {
          this.pickup_time = scheduleData.combine_time;
        }

        // Update other necessary fields
        this.selectedStoreId = scheduleData.selectedStoreId;
        this.selectedServiceIds = scheduleData.selectedServiceIds;
        this.selectedSlotId = scheduleData.selectedSlotId;
        this.selectedAddressId = scheduleData.selectedAddressId;
        
        this.saveToStorage();
      }
    });

    // Load stored data on initialization
    this.loadFromStorage();
    // Handle route params
    this.route.queryParams.subscribe(params => {
      this.location = {
        lat: params['lat'],
        lng: params['lng'],
        name: params['locationName'],
        pincode: params['pincode'],
        city: params['city'],
      } as Location;
  
      this.store.address = this.location.name;
  
      // Handle pickup_date logic
      if (params['selected_date']) {
        this.pickup_date = this.formatDate(params['selected_date']);
      } else if (params['date']) {
        this.pickup_date = this.formatDate(params['date']);
      }

      this.selectedServiceIds = params['service_ids'];
      this.selectedStoreId = params['store_id'];
      this.selectedSlotId = params['slot_id'];
      this.selectedAddressId = params['address_id'];
      this.selectedIndex = params['selectedIndex'];
  
      // Ensure selectedServiceIds is an array
      if (!Array.isArray(this.selectedServiceIds)) {
        this.selectedServiceIds = [this.selectedServiceIds];
      }
    });

    console.log("here is the address id ",this.AddressId);
  }

  private hasStoredData(): boolean {
    return !!localStorage.getItem(this.STORAGE_KEY);
  }
  private saveToStorage() {
    const dataToStore = {
      location: this.location,
      store: this.store,
      address: this.address,
      selectedAddressId: this.AddressId, // Make sure to include the ID
      pickup_date: this.pickup_date,
      pickup_time: this.pickup_time,
      selectedServiceIds: this.selectedServiceIds,
      selectedStoreId: this.selectedStoreId,
      selectedSlotId: this.selectedSlotId,
      name: this.name,
      selectedIndex: this.selectedIndex
    };

    localStorage.setItem('orderSummaryData', JSON.stringify(dataToStore));
    console.log('Saved data with address ID:', dataToStore);
  }

  private loadFromStorage() {
    const storedData = localStorage.getItem('orderSummaryData');
    if (storedData) {
      const data = JSON.parse(storedData);
      this.location = data.location;
      this.store = data.store || {};
      this.address = data.address;
      this.selectedAddressId = data.selectedAddressId; // Load the ID from storage
      this.pickup_date = data.pickup_date;
      this.pickup_time = data.pickup_time;
      this.selectedServiceIds = data.selectedServiceIds;
      this.selectedStoreId = data.selectedStoreId;
      this.selectedSlotId = data.selectedSlotId;
      this.name = data.name;
      this.selectedIndex = data.selectedIndex;
    }
  }


  private formatDate(date: string): string {
    const parsedDate = new Date(date);
    if (!isNaN(parsedDate.getTime())) {
      const day = parsedDate.getDate().toString().padStart(2, '0');
      const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
      const year = parsedDate.getFullYear();
      return `${day}-${month}-${year}`;
    }
    return date;
  }

// Component method
createOrder(): void {
  this.orderService.createOrder(this.selectedStoreId, this.selectedServiceIds,
    this.selectedSlotId, this.selectedAddressId, this.suggestion, this.selectedCouponCode, this.selectedCouponId).subscribe((data: any) => {
      if (data["Status"] == "Success") {
        this.orderId = data['booking_id'];
        if (this.selectedAddressId != undefined) {
          this.noAddressSelectedError = false;
          this.router.navigate(['/order-booked'],
            {
              queryParams: {
                lat: this.location.lat,
                lng: this.location.lng,
                locationName: this.location.name,
                pincode: this.location.pincode,
                city: this.location.city,
                store_id: this.selectedStoreId,
                service_ids: this.selectedServiceIds,
                slot_id: this.selectedSlotId,
                address_id: this.selectedAddressId,
                store_name: this.store.name,
                booking_id: this.orderId,
              }
            });
        } else {
          this.noAddressSelectedError = true;
        }
      }
      console.log(data);
    });
}


  takeToBuildOrder() {
    this.router.navigate(['build-your-order'], {
      queryParams: {
        lat: this.location.lat,
        lng: this.location.lng,
        locationName: this.location.name,
        pincode: this.location.pincode,
        city: this.location.city,
        service_ids: this.selectedServiceIds,
        slot_id: this.selectedSlotId,
        address_id: this.selectedAddressId,
      }
    });
  }

  takeToSchedulePickup() {
    this.router.navigate(['/schedule-pick-up'],
      {
        queryParams: {
          lat: this.location.lat,
          lng: this.location.lng,
          locationName: this.location.name,
          pincode: this.location.pincode,
          city: this.location.city,
          store_id: this.selectedStoreId,
          service_ids: this.selectedServiceIds,
          slot_id: this.selectedSlotId,
          address_id: this.selectedAddressId
        }
      });
  }

  takeToChooseAddress() {
    this.router.navigate(['/choose-address'], {
      queryParams: {
        lat: this.location.lat,
        lng: this.location.lng,
        locationName: this.location.name,
        pincode: this.location.pincode,
        city: this.location.city,
        store_id: this.selectedStoreId,
        service_ids: this.selectedServiceIds,
        slot_id: this.selectedSlotId,
        address_id: this.selectedAddressId,
        selected_index:this.selectedIndex
      }
    });

  }
}