<section class="hp_services_section">
   <div class="container">
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 tb" style="height: 20px;">
            <div class="sec_title_bar">
               <h2 class="tb">Our Services</h2>
            </div>
         </div>
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="tab_bar">
               <nav>
                  <!-- <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                      <a class="nav-item nav-link selected" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Fabric Care</a>
                      <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Home Care</a>
                   </div> -->
               </nav>
            </div>
            <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">

               <div class="tab-pane active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                  <div id="owl-demo" class="owl-carousel owl-theme">
                     <div class="item">
                        <div class="serv_block">
                           <div class="img_sec">
                              <img src="//assets.ucleanlaundry.com/assets/images/serv_img_1.jpg" alt=" Drycleaners near me">
                           </div>
                           <div class="text_sec">
                              <span><img src="//assets.ucleanlaundry.com/assets/images/serv_icon_1.png" alt=" Laundry Services near me"></span>
                              <h4>Dry Cleaning</h4>
                              <p>Eco Friendly I Expert Stain Removal</p>
                              <a href="javascript(0);" [routerLink]="['/services/DryCleaning']">Learn More</a>
                           </div>
                           <div class="div_overlay">
                              <img src="//assets.ucleanlaundry.com/assets/images/serv_img_01.jpg">
                              <div class="text">
                                 <span><img src="//assets.ucleanlaundry.com/assets/images/serv_icon_1.png" alt=" Laundry Services near me"></span>
                                 <h4>Dry Cleaning</h4>
                                 <p>Eco Friendly I Expert Stain Removal</p>
                                 <a href="">Learn More</a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="item">
                        <div class="serv_block">
                           <div class="img_sec">
                              <img src="//assets.ucleanlaundry.com/assets/images/services2_.webp" alt=" a girl with laundry basket">
                           </div>
                           <div class="text_sec">
                              <span><img src="//assets.ucleanlaundry.com/assets/images/serv_icon_2.png"  alt=" laundry washing machine "></span>
                              <h4>Laundry by Kg</h4>
                              <p>Wash & Iron I Wash & Fold</p>
                              <a href="javascript(0)" [routerLink]="['/services/LaundrybyKg']">Learn More</a>
                           </div>
                           <div class="div_overlay">
                              <img src="//assets.ucleanlaundry.com/assets/images/serv_img_02_.webp">
                              <div class="text">
                                 <span><img src="//assets.ucleanlaundry.com/assets/images/serv_icon_2.png"  alt=" laundry washing machine "></span>
                                 <h4>Laundry by Kg</h4>
                                 <p>Wash & Iron I Wash & Fold</p>
                                 <a href="">Learn More</a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="item">
                        <div class="serv_block">
                           <div class="img_sec">
                              <img src="//assets.ucleanlaundry.com/assets/images/services3.webp" alt=" laundry done shoes and clothes ">
                           </div>
                           <div class="text_sec">
                              <span><img src="//assets.ucleanlaundry.com/assets/images/serv_icon_3.png" alt="Shoes Cleaning"></span>
                              <h4>Premium Laundry</h4>
                              <p>Economical I Personalized Care</p>
                              <a href="javascript(0)" [routerLink]="['/services/PremiumLaundry']">Learn More</a>
                           </div>
                           <div class="div_overlay">
                              <img src="//assets.ucleanlaundry.com/assets/images/serv_img_03.jpg">
                              <div class="text">
                                 <span><img src="//assets.ucleanlaundry.com/assets/images/serv_icon_3.png" alt="Shoes Cleaning"></span>
                                 <h4>Premium Laundry</h4>
                                 <p>Economical I Personalized Care</p>
                                 <a href="">Learn More</a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="item">
                        <div class="serv_block">
                           <div class="img_sec">
                              <img src="//assets.ucleanlaundry.com/assets/images/serv_img_4.jpg" alt="clothes ironed ">
                           </div>
                           <div class="text_sec">
                              <span><img src="//assets.ucleanlaundry.com/assets/images/serv_icon_4.png" alt=" steam iron "></span>
                              <h4>Steam Ironing</h4>
                              <p>Sanitized & Smooth</p>
                              <a href="javascript(0)" [routerLink]="['/services/SteamIroning']">Learn More</a>
                           </div>
                           <div class="div_overlay">
                              <img src="//assets.ucleanlaundry.com/assets/images/serv_img_04.jpg">
                              <div class="text">
                                 <span><img src="//assets.ucleanlaundry.com/assets/images/serv_icon_4.png" alt=" steam iron "></span>
                                 <h4>Steam Ironing</h4>
                                 <p>Sanitized & Smooth</p>
                                 <a href="">Learn More</a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="item">
                        <div class="serv_block">
                           <div class="img_sec">
                              <img src="//assets.ucleanlaundry.com/assets/images/serv_img_1.jpg" alt=" Sofa Cleaning">
                           </div>
                           <div class="text_sec">
                              <span><img src="//assets.ucleanlaundry.com/assets/images/serv_icon_1.png" alt=" Laundry Services near me"></span>
                              <h4>Dry Cleaning</h4>
                              <p>Eco Friendly I Expert Stain Removal</p>
                              <a href="javascript(0);" [routerLink]="['/services/DryCleaning']">Learn More</a>
                           </div>
                           <div class="div_overlay">
                              <img src="//assets.ucleanlaundry.com/assets/images/serv_img_01.jpg">
                              <div class="text">
                                 <span><img src="//assets.ucleanlaundry.com/assets/images/serv_icon_1.png" alt=" Laundry Services near me"></span>
                                 <h4>Dry Cleaning</h4>
                                 <p>Eco Friendly I Expert Stain Removal</p>
                                 <a href="">Learn More</a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                  <div id="owl-demo" class="owl-carousel owl-theme">
                     <div class="item">
                        <div class="serv_block">
                           <div class="img_sec">
                              <img src="//assets.ucleanlaundry.com/assets/images/serv_img_1.jpg" alt=" Sofa Cleaning">
                           </div>
                           <div class="text_sec">
                              <span><img src="//assets.ucleanlaundry.com/assets/images/serv_icon_1.png" alt=" Laundry Services near me"></span>
                              <h4>Dry Cleaning</h4>
                              <p>Eco Friendly I Expert Stain Removal</p>
                              <a href="">Learn More</a>
                           </div>
                           <div class="div_overlay">
                              <img src="//assets.ucleanlaundry.com/assets/images/serv_img_01.jpg">
                              <div class="text">
                                 <span><img src="//assets.ucleanlaundry.com/assets/images/serv_icon_1.png" alt=" Laundry Services near me"></span>
                                 <h4>Dry Cleaning</h4>
                                 <p>Eco Friendly I Expert Stain Removal</p>
                                 <a href="">Learn More</a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="item">
                        <div class="serv_block">
                           <div class="img_sec">
                              <img src="//assets.ucleanlaundry.com/assets/images/serv_img_2.jpg">
                           </div>
                           <div class="text_sec">
                              <span><img src="//assets.ucleanlaundry.com/assets/images/serv_icon_2.png"  alt=" laundry washing machine "></span>
                              <h4>Laundry by Kg</h4>
                              <p>Wash & Iron I Wash & Fold</p>
                              <a href="">Learn More</a>
                           </div>
                           <div class="div_overlay">
                              <img src="//assets.ucleanlaundry.com/assets/images/serv_img_02.jpg">
                              <div class="text">
                                 <span><img src="//assets.ucleanlaundry.com/assets/images/serv_icon_2.png"  alt=" laundry washing machine "></span>
                                 <h4>Laundry by Kg</h4>
                                 <p>Wash & Iron I Wash & Fold</p>
                                 <a href="">Learn More</a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="item">
                        <div class="serv_block">
                           <div class="img_sec">
                              <img src="//assets.ucleanlaundry.com/assets/images/serv_img_3.jpg">
                           </div>
                           <div class="text_sec">
                              <span><img src="//assets.ucleanlaundry.com/assets/images/serv_icon_3.png" alt="Shoes Cleaning"></span>
                              <h4>Premium Laundry</h4>
                              <p>Economical I Personalized Care</p>
                              <a href="">Learn More</a>
                           </div>
                           <div class="div_overlay">
                              <img src="//assets.ucleanlaundry.com/assets/images/serv_img_03.jpg">
                              <div class="text">
                                 <span><img src="//assets.ucleanlaundry.c/om/assets/images/serv_icon_3.png" alt="Shoes Cleaning"></span>
                                 <h4>Premium Laundry</h4>
                                 <p>Economical I Personalized Care</p>
                                 <a href="">Learn More</a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="item">
                        <div class="serv_block">
                           <div class="img_sec">
                              <img src="//assets.ucleanlaundry.com/assets/images/serv_img_4.jpg" alt="clothes ironed ">
                           </div>
                           <div class="text_sec">
                              <span><img src="//assets.ucleanlaundry.com/assets/images/serv_icon_4.png" alt="steam iron"></span>
                              <h4>Steam Ironing</h4>
                              <p>Sanitized & Smooth</p>
                           </div>
                           <div class="div_overlay">
                              <img src="//assets.ucleanlaundry.com/assets/images/serv_img_04.jpg">
                              <div class="text">
                                 <span><img src="//assets.ucleanlaundry.com/assets/images/serv_icon_4.png" alt="steam iron"></span>
                                 <h4>Steam Ironing</h4>
                                 <p>Sanitized & Smooth</p>
                                 <a href="">Learn More</a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="item">
                        <div class="serv_block">
                           <div class="img_sec">
                              <img src="//assets.ucleanlaundry.com/assets/images/serv_img_1.jpg" alt=" Sofa Cleaning">
                           </div>
                           <div class="text_sec">
                              <span><img src="//assets.ucleanlaundry.com/assets/images/serv_icon_1.png" alt=" Laundry Services near me"></span>
                              <h4>Dry Cleaning</h4>
                              <p>Eco Friendly I Expert Stain Removal</p>
                           </div>
                           <div class="div_overlay">
                              <img src="//assets.ucleanlaundry.com/assets/images/serv_img_01.jpg">
                              <div class="text">
                                 <span><img src="//assets.ucleanlaundry.com/assets/images/serv_icon_1.png" alt=" Laundry Services near me"></span>
                                 <h4>Dry Cleaning</h4>
                                 <p>Eco Friendly I Expert Stain Removal</p>
                                 <a href="">Learn More</a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>

<!-- hp_main_section start-->

<section class="hp_main_section">
   <div class="container">
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="main_title_bar">
               <h2>India’s No. 1 Laundry & Dry-Cleaning <br> Brand from an IITian</h2>
            </div>
         </div>
      </div>
   </div>
   <ul>
      <li><img src="//assets.ucleanlaundry.com/assets/images/icon_1.png" alt="Laundry "></li>
      <li><img src="//assets.ucleanlaundry.com/assets/images/icon_2.png"></li>
      <li><img src="//assets.ucleanlaundry.com/assets/images/icon_3.png"alt=" laundry wash and fold"></li>
      <li><img src="//assets.ucleanlaundry.com/assets/images/icon_4.png" alt=" Laundry wash and iron"></li>
    </ul>
</section>

<!-- hp_main_section end-->