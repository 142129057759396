<!-- inner_banner_section start-->
<section class="inner_banner_section address_page_banner">
  <div class="container">
    <div class="row">
    </div>
  </div>
</section>
<!-- inner_banner_section end-->
<!-- page_progress_section start-->
<section class="page_progress_section">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px;">
        <div class="prog_step">
          <ul>
            <li class="active"><a (click)="takeToBuildOrder()"><i class="fa fa-circle" aria-hidden="true"></i>
                <p>Build your <br> Order</p>
              </a></li>
            <li class="active"><a (click)="takeToSchedulePickup()"><i class="fa fa-circle" aria-hidden="true"></i>
                <p>Schedule <br> Pick up</p>
              </a></li>
            <li class="active"><i class="fa fa-circle" aria-hidden="true"></i>
              <p>Choose <br> Address</p>
            </li>
            <li><i class="fa fa-circle" aria-hidden="true"></i>
              <p>Confirm </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- page_progress_section end-->
<!-- address_pg_section start-->
<section class="address_pg_section">
  <div class="container">
    <div class="row" *ngIf="showLoader">
      <div class="col-xs-12">
        <div id='loading_wrap'></div>
      </div>
    </div>
    <div class="row" *ngIf="!showLoader && showAddressList">
      <div class="col-xs-12 choose" style="padding:10px">
        <div class="address_pg_block">
          <div class="profile_title">
            <h2>CHOOSE ADDRESS</h2>
          </div>
          <div class="addd">
            <button (click)="addNewAddress()" id="add_btn_form" name="button" type="button" >Add New Address</button>
          </div> <br>
          <div class="from">
            <h2>Pick-up from:</h2>
          </div>
          <div class="address_list_sec">
            <ul>
              <li class="adddd"  (click)="selectAddress(address.id)" *ngFor="let address of addresses;let i=index"
                [ngClass]="getClass(address.id)" >
                <span>
                  <img src="//assets.ucleanlaundry.com/assets/images/hotal_icon.png" *ngIf="address.type == 'Hotel'">
                  <img src="//assets.ucleanlaundry.com/assets/images/home_icon.png" *ngIf="address.type == 'Home'">
                  <img src="//assets.ucleanlaundry.com/assets/images/work_icon.png" *ngIf="address.type == 'Office'">
                </span>
                <p class="p">{{address.flat_info}}, {{address.street_info}}</p>
                <p>{{address.landmark}}</p>
                <p>{{address.city_name}} - {{address.pincode}}</p>
                
                <!-- <input type="radio" name="xyz" class="form-check-input checkk" [checked]="checks" > -->
                <input type="radio" (change)="addressChange($event,i)"  name="xyz" class="form-check-input checkk" [checked]="address.selected" >
                <div class="editt">
                <button name="button" class="remove" (click)="removeAdd(address)" type="button"> Remove</button>
              </div>
              </li>
            </ul>
          </div>
          <!-- <div class="new_address_btn" *ngIf="!showAddressForm">
            <button (click)="addNewAddress()" id="add_btn_form" name="button" type="button"><i class="fa fa-plus-circle"
                aria-hidden="true"></i>Add New Address
            </button>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row" id="addressForm" *ngIf="!showLoader && showAddressForm">
      <div class="col-xs-12" style="padding:0px">
        <!-- <div class="address-form-wrapper"> -->
          <div class="add_new_form">
            <h4>Add New Address </h4>
            <div class="address-form-wrapper">
              <form (ngSubmit)="saveAdd();">
                <div class="form-group">
                  <!-- <input type="text" name="location" class="form-control" placeholder="Select Location"
                    [(ngModel)]="address.location" disabled> -->
                    <div class="input-with-prefix">
                      <input class="google-place-input" id="location-input" type="text" name="location" class="form-control" placeholder="Select Location"
                      [(ngModel)]="address.location" GooglePlace (setAddress)="onAddressSelected($event)" (searchState)="onLocationSearchStateChange($event)">
                      <i class="fa fa-pencil-square-o" aria-hidden="true" (click)="enableLocationEditing()"></i>
                    </div>
                    <div class="commmon-error" [ngClass]="{'visible': isServiceProviderErrorModal && !isApiCalling}">
                      <p>Location you have entered is not into our serviceable area, please try with some other location.</p>
                    </div>
                  </div>
                
                
                <div class="form-group" [ngClass]="validation.type">
                  <label>
                    <input type="radio" name="address_type" value="Home" [(ngModel)]="address.type">
                    Home
                  </label> &nbsp;&nbsp;
                  <label>
                    <input type="radio" name="address_type" value="Office" [(ngModel)]="address.type">
                    Office
                  </label>&nbsp;&nbsp;
                  <label>
                    <input type="radio" name="address_type" value="Hotel" [(ngModel)]="address.type">
                    Hotel
                  </label>&nbsp;&nbsp;
                  <label>
                    <input type="radio" name="address_type" value="Other" [(ngModel)]="address.type">
                    Other
                  </label>&nbsp;&nbsp;
                  <input [hidden]="disableInputType" type="text" [(ngModel)]="address.type" name="type"
                    placeholder="Address Type">
                    <div class="error" [ngClass]="validation.type">
                      <p>*Please select an address type</p>
                    </div>
                </div>
    
                <div class="form-group" [ngClass]="validation.flat_info">
                  <input type="text" name="flat_info" class="form-control" placeholder="House-No. / Flat-No."
                    [(ngModel)]="address.flat_info">
                    <div class="error" [ngClass]="validation.flat_info">
                      <p>*Please add House-No. / Flat-No.</p>
                    </div>
                </div>
                <div class="form-group" [ngClass]="validation.street_info">
                  <input type="text" name="street_info" class="form-control" placeholder="Street / Road"
                    [(ngModel)]="address.street_info">
                    <div class="error" [ngClass]="validation.flat_info">
                      <p>*Please add Street / Road</p>
                    </div>
                </div>
                <div class="form-group" [ngClass]="validation.landmark">
                  <input type="text" name="landmark" class="form-control" placeholder="Landmark(Optional)"
                    [(ngModel)]="address.landmark">
                </div>
                <div class="form-group" [ngClass]="validation.city_id">
                  <!-- <select [disabled]="!(address.pincode && !address.city_id) && !mannualCity" name="city_id" class="form-control" [(ngModel)]="address.city_id" (change)="onMannualCityChange()">  
                    <option>Select City</option>
                    <option *ngFor="let city of citiesList" [value]="city.id">
                      {{city.name}}</option>
                  </select>
                  <div class="error" [ngClass]="validation.city_id">
                    <p>*Please select the city as the location which you are trying to select in not in our serviceable area, kindly change the location.</p>
                  </div> -->
                  <div id='loading_wrap' *ngIf="searchingLocation || isApiCalling || !isMapInitialised"></div>
                  <mat-form-field class="width city-select" [style.width.%]="100" floatLabel="never">
                    <input
                      matInput
                      placeholder="City"
                      aria-label="City"
                      [matAutocomplete]="auto"
                      [formControl]="cityCtrl"
                      autocomplete="off"
                    />
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option
                        *ngFor="let city of filteredCity | async"
                        [value]="city?.name">
                        <span>{{ city?.name }}</span>
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <div class="error mat-custom-error" [ngClass]="validation.city_id">
                    <p>*Please select the city</p>
                  </div>
                </div>
    
                <div class="form-group" [ngClass]="validation.pincode">
                  <div id='loading_wrap' *ngIf="searchingLocation"></div>
                  <!-- m  -->
                  <input name="pincode" [disabled]="true" type="text"
                    class="form-control" placeholder="Post Code" [(ngModel)]="address.pincode">
                    <div class="error" [ngClass]="validation.pincode">
                      <p>*Please enter Post code</p>
                    </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <input type="submit" [ngClass]="{'disabled': isServiceProviderErrorModal || !cityCtrl.value}" name="button" class="btn btn-success" [value]="isApiCalling ? 'Wait..': 'Save'">
                    </div>
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-md-12">
                    
                  </div>
                </div> -->
              </form>
              <div class="map-section">
                <app-leaflet-map #mapCompRef (mapInit)="onMapInit($event)" (mapLocationDragged)="onLocationConfirm($event)" (mapCurrentLocationRequest)="onLocationConfirm($event)"></app-leaflet-map>
              </div>
            </div>
            <div class="close_form" (click)="hideAddressForm()"><i class="fa fa-times" aria-hidden="true"></i></div>
          </div>
          <!-- <div class="map-section">
            <app-leaflet-map></app-leaflet-map>
          </div> -->
        <!-- </div> -->
      </div>
    </div>

    <div class="row" *ngIf="!showLoader && showAddressList">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px">
        <br />
        <div class="alert alert-danger text-center" role="alert" [hidden]=!noAddressSelectedError>
          <span class="error-block">Please select the address</span>
        </div>

        <div class="page_bn_btn">
          <a class="page_back_btn" (click)="takeToSchedulePickup()" style="margin:0px; margin-right:10px">Back</a>
          <a class="page_next_btn" (click)="takeToConfirmOrder()" style="margin:0px">Next</a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- address_pg_section end-->

<!-- current location popup -->
