import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { PixelService } from 'ngx-pixel';
import { metaTags } from 'src/assets/meta-contant';
import { PagesService } from '../pages.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  constructor(
    private pixel: PixelService,
    private metaTagService: Meta,
    private pagesService: PagesService) { }

  citiesList: any;
  city: number = 0;
  store: any;
  store_id: number = 0;
  services: any;
  cityId: number = 0;
  serviceID: number = 0;
  storePrices: any = [];
  itemPrices: any;
  addOnstorePrices: any;
  cityServices = true;
  subCategoryFilter = false;
  filterP: any;
  activeButton: number = 0;
  activeButtonCat: number = 0;
  showLoader = false;
  priceTable = false;
  pricePerKg = false;
  pricePerPc = true;
  tempHideServices = true;
  serviceHideText = false;
  pickUpDeliverPrice:any;



  storesList: any = [];
  catID: number = 0;



  ngOnInit(): void {
    this.pagesService.cityList().subscribe((data: any) => {
      this.citiesList = data.cities;
    });
    this.pixel.trackCustom('PricingPage');
    this.metaTagService.updateTag({ property: 'title', content: metaTags?.['laundry-drycleaning-price-list']?.title });
    this.metaTagService.updateTag({ property: 'description', content: metaTags?.['laundry-drycleaning-price-list']?.description });
  }

  showPhase(event: number) {
    this.activeButton = event;
  }

  showPhaseCat(event: number) {
    this.activeButtonCat = event;
  }


  getCityStores() {
    this.showLoader = true;
    this.cityServices = true;
    console.log(this.city)
    this.pagesService.selCityID = this.city;
    this.pagesService.getStores().subscribe((data: any) => {
      this.storesList = data.stores.filter((p: any) => p.city_id == this.city);
      this.getSerivceIdDefault();
      console.log("Store Array", this.storesList);
    });
    this.pagesService.cityServices().subscribe((data: any) => {
      this.services = data.services;
      this.activeButton = 0;
      this.activeButtonCat = 0;
      this.showLoader = false;
    }, (_err: any) => {
      this.showLoader = false;
    })
  }

  getstoreDtl() {
    this.showLoader = true;
    this.cityServices = false;
    console.log("storeDtl => ", this.store_id)
    this.pagesService.selStoreCityId = this.city;
    this.pagesService.selstoreID = this.store_id;
    if (this.cityServices == false) {
      this.pagesService.storeServices().subscribe((data: any) => {
        this.services = data.services;
        this.getSerivceIdDefault()
        this.activeButton = 0;
        this.activeButtonCat = 0;
        this.showLoader = false;
      }, (_err: any) => {
        this.showLoader = false;
      })
    }
  }

  getSerivceId(id: any, index: any) {
    this.showLoader = true;
    if (this.cityServices == true) {
      this.pagesService.selServiceID = this.services?.[index]?.id;
      this.pagesService.cityPrice().subscribe((data: any) => {
        if (this.services?.[index]?.id == 2 || this.services?.[index]?.id == 10) {
          this.tempHideServices = false;
          this.serviceHideText = true;
        }
        else {
          this.serviceHideText = (!(data.prices || []).length && this.store_id == 1) ? true : false;
          this.tempHideServices = true;
          this.storePrices = data.prices || [];
          this.itemPrices = data.prices;
          this.addOnstorePrices = data.addon_prices;
          this.priceTable = true;
          this.subCategoryFilter = (this.services?.[index]?.id == 1 || this.services?.[index]?.id == 3) ? true : false;
          if (this.services?.[index]?.id == 1 || this.services?.[index]?.id == 2 || this.services?.[index]?.id == 3) {
            this.filterPrices1();
          }
        }
        this.showLoader = false;
      }, (_err: any) => {
        this.showLoader = false;
      })
    }

    else {
      this.showLoader = true;
      console.log("serviceID =>", this.services?.[index]?.id)
      this.pagesService.selServiceID = this.services?.[index]?.id;
      this.pagesService.storePrice().subscribe((data: any) => {
        if (this.services?.[index]?.id == 2 || this.services?.[index]?.id == 10) {
          this.tempHideServices = false;
          this.serviceHideText = true;
          this.showLoader = false;
        }
        else {
          this.serviceHideText = (!(data.prices || []).length && this.store_id == 1) ? true : false;
          this.tempHideServices = true;
          this.storePrices = data.prices || [];
          this.itemPrices = data.prices;
          this.addOnstorePrices = data.addon_prices;
          this.priceTable = true;
          this.subCategoryFilter = (this.services?.[index]?.id == 1 || this.services?.[index]?.id == 3) ? true : false;
          if (this.services?.[index]?.id == 1 || this.services?.[index]?.id == 2 || this.services?.[index]?.id == 3) {
            this.filterPrices1();
          }
        }
        this.showLoader = false;
      })
    }
    if (this.services?.[index]?.id == 4 || this.services?.[index]?.id == 5 || this.services?.[index]?.id == 9) {
      this.pricePerKg = true;
      this.pricePerPc = false;
    }
    else {
      this.pricePerKg = false;
      this.pricePerPc = true;
    }
    this.activeButtonCat = 0;
  }

  categories = [
    {id:1, placeholder: "men", name: "Mens", img: "//assets.ucleanlaundry.com/assets/images/man_icon.png" },
    {id:2, placeholder: "women", name: "Womens", img: "//assets.ucleanlaundry.com/assets/images/woman_icon.png" },
    {id:3, placeholder: "household", name: "Household", img: "//assets.ucleanlaundry.com/assets/images/household_icon.png" },
    {id:4, placeholder: "kids", name: "Kids", img: "//assets.ucleanlaundry.com/assets/images/kids_icon.png" },
    {id:5, placeholder: "Luxury", name: "Luxury", img: "//assets.ucleanlaundry.com/assets/images/luxury_icon.png" }
  ];

  filterPrices(placeholder: string , index:any) {
    this.itemPrices = this.storePrices.filter((p: any) => p.category?.toLowerCase() == placeholder?.toLowerCase()).filter((elm: any) => elm?.name);
    console.log(JSON.stringify(this.itemPrices));
    if(this.categories[index]?.id == 5) {
      this.luxuryFilter();
      console.log("inid",this.categories[index]?.id)
    }
  }

  luxuryFilter(){
    let placeholder1 = "Luxury";
    this.itemPrices = this.storePrices.filter((p: any) => p.category.substr(p.category.length - 6) == placeholder1);
    console.log(JSON.stringify(this.itemPrices));
  }

  filterPrices1() {
    let placeholder = "men";
    this.itemPrices = this.storePrices.filter((p: any) => p.category?.toLowerCase() == placeholder?.toLowerCase()).filter((elm: any) => elm?.name);
    console.log(this.itemPrices);
  }

  getSerivceIdDefault() {
    this.showLoader = true;
    let index = 0;
    if (this.cityServices == true) {
      this.pagesService.selServiceID = this.services?.[index]?.id;
      this.pagesService.cityPrice().subscribe((data: any) => {
        if (this.services?.[index]?.id == 2 || this.services?.[index]?.id == 10) {
          this.tempHideServices = false;
          this.serviceHideText = true;
          this.showLoader = false;
        }
        else {
          this.serviceHideText = (!(data.prices || []).length && this.store_id == 1) ? true : false;
          this.tempHideServices = true;
          this.storePrices = data.prices || [];
          this.itemPrices = data.prices;
          this.addOnstorePrices = data.addon_prices;
          this.priceTable = true;
          this.subCategoryFilter = (this.services?.[index]?.id == 1 || this.services?.[index]?.id == 3) ? true : false;
          this.filterPrices1();
          this.showLoader = false;
        }
      })
    }

    else {
      console.log("serviceID =>", this.services?.[index]?.id)
      this.pagesService.selServiceID = this.services?.[index]?.id;
      this.pagesService.storePrice().subscribe((data: any) => {
        if (this.services?.[index]?.id == 2 || this.services?.[index]?.id == 10) {
          this.tempHideServices = false;
          this.serviceHideText = true;
          this.showLoader = false;
        }
        else {
          this.serviceHideText = (!(data.prices || []).length && this.store_id == 1) ? true : false;
          this.tempHideServices = true;
          this.storePrices = data.prices || [];
          this.itemPrices = data.prices;
          this.addOnstorePrices = data.addon_prices;
          this.priceTable = true;
          this.subCategoryFilter = (this.services?.[index]?.id == 1 || this.services?.[index]?.id == 3) ? true : false;
          this.filterPrices1();
          this.showLoader = false;
        }
      })
    }
  }

  trackByIdentity = (index: number, item: any) => item;
}
