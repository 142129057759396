<section class="screen otp-success">
    <app-auth-wrapper [mainBannerText]="mainBannerText">
        <div class="form-wrapper">
            <div class="backdrop">
                <img src="//assets.ucleanlaundry.com/assets/images/login/back-arrow.svg" (click)="goBackToFranchiseScreen()"> Back to previous screen
            </div>
            <div class="form-header-wrapper font-700">
                <p>Franchise applied</p>
                <span class="line"></span>
            </div>
            <div class="success-msg-container">
                <img src="//assets.ucleanlaundry.com/assets/images/login/success.svg" alt="success">
                <p><b>Your form has been submitted</b></p>
            </div>
            <div class="msg-description">
                <div [innerHTML]="descriptionTex1"></div>
            </div>
            <div class="modal-footer-button" matRipple (click)="continueOrdering()">
                <p>Continue ordering</p>
            </div>
        </div>
    </app-auth-wrapper>
</section>