import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-carpet-cleaning',
  templateUrl: './carpet-cleaning.component.html',
  styleUrls: ['./carpet-cleaning.component.scss']
})
export class CarpetCleaningComponent implements OnInit {
  bannerImageUrl = `${environment.imageBaseUrl}Banner/CarpetCleaning.jpg`;
  carpetCleaningImage: string = `${environment.imageBaseUrl}cta/Carpet Cleaning 2.jpg`;
  processSteps = [
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step6.svg`,
      title: 'Inspection',
      description: 'Carefully examine the carpet for damage, stains, or tears and identify the optimal cleaning method.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step5.svg`,
      title: 'Dry Vacuuming',
      description: 'Use a combination of steam cleaning and dry vacuuming techniques to remove dirt and debris from the fabric.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step4.svg`,
      title: 'Stain Treatment',
      description: 'Speciality upholstery cleaning stain removal treatments done to address hard stains.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step3.svg`,
      title: 'Surface Cleaning',
      description: 'Thorough deep cleaning of carpets tailored to the specific fiber blend, achieving a revitalized, stain-free, and refreshed appearance.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step2.svg`,
      title: 'Air Drying',
      description: 'Vacuum, pre-treat stains, clean with a solution, vacuum again, and use fans for faster drying.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step1.svg`,
      title: 'Fabric Protection',
      description: 'After cleaning, fabric protector is applied to repel stains and spills, enhancing durability and ease of future cleanings.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step3.svg`,
      title: 'Quality Check',
      description: 'Inspect the carpet to ensure it is fully clean and in excellent condition.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step2.svg`,
      title: 'Packaging',
      description: 'Clean carpets are neatly packaged in transparent packaging with humidity catchers.'
    }
  ];
  
  

  constructor() { }

  ngOnInit(): void {
  }

}
