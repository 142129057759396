import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-customer-section',
  templateUrl: './customer-section.component.html',
  styleUrls: ['./customer-section.component.scss']
})
export class CustomerSectionComponent implements OnInit {

  constructor(private metaTagService: Meta) { }

  ngOnInit(): void {
    // this.loadJsFile("//assets.ucleanlaundry.com/assets/js/slick.js");

    // $(document).ready(function () {
    //   (<any>$('.owl-carousel')).owlCarousel({
    //     autoPlay: 3000,
    //     //loop: true,
    //     margin: 10,
    //     nav: true,
    //     navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
    //     responsive: {
    //       0: {
    //         items: 1
    //       },
    //       600: {
    //         items: 2
    //       },
    //       1000: {
    //         items: 3
    //       }
    //     }
    //   });
    // });
  }
  public loadJsFile(url: string) {
    // let node = document.createElement('script');
    // node.src = url;
    // node.type = 'text/javascript';
    //document.getElementsByTagName('head')[0].appendChild(node);
  }


  cusSection = [
    {
      image:"//assets.ucleanlaundry.com/assets/images/cust_img_1.jpg" ,
      description:"The best laundry service I ever got! The manager, Yash, was very good at communication and they delivered the clothes within a day! Also got some discount on the bulk order. Thanks to the team. Cheers! Keep up the good work",
      name:"ARVINDER LAMBA",
      address:"(Entrepreneur, Delhi)"
    },
    {
      image:"//assets.ucleanlaundry.com/assets/images/cust_img_2.jpg",
      description:"Gave up on my 15 year old “dry cleaners “ for U Clean. Excellent service , Courteous staff, Super sonic delivery. Feel pampered. And yes, not to forget the competitive rates. A definite recommend. Get your clothes U Cleaned. I’m loving it.",
      name:"NIKHIL KATARIYA",
      address:"(Student, Pune)"
    },
    {
      image:"//assets.ucleanlaundry.com/assets/images/cust_img_3.jpg",
      description:"Gave up on my 15 year old “dry cleaners “ for U Clean. Excellent service , Courteous staff, Super sonic delivery. Feel pampered. And yes, not to forget the competitive rates. A definite recommend. Get your clothes U Cleaned. I’m loving it.",
      name:"CHITRA JOHRI",
      address:"(Vice President, Bradford License India)"
    },
    {
      image:"//assets.ucleanlaundry.com/assets/images/cust_img_1.jpg",
      description:"The best laundry service I ever got! The manager, Yash, was very good at communication and they delivered the clothes within a day! Also got some discount on the bulk order. Thanks to the team. Cheers! Keep up the good work",
      name:"ARVINDER LAMBA",
      address:"(Entrepreneur, Delhi)"
    },
  ]

}
