<div class="blog-card">
    <div class="blog-header-image">
        <img [routerLink]="learnMoreLink" [src]="blogImageUrl"/>
    </div>
    <div class="blog-card-body">
        <div class="blog-header">
            <p>{{ blogHeaderText }}</p>
        </div>
        <div class="blog-content">
            <p>{{ blogDescriptionText }}</p>
        </div>
        <div class="blog-subheader">
            <p> {{ blogSubHeaderText }}</p>
        </div>
        <div class="blog-footer">
            <a [routerLink]="learnMoreLink">Learn More</a>
        </div>
    </div>
</div>