<section class="hp_banner_section" [style.background-image]="'url(' + bannerImageUrl + ')'">
    <div class="container">
      <div class="row">
        <div class="hp_banner_block">
          <div class="hp_banner_tilte">
            <h3 class="baner-top">Bag Cleaning</h3>
            <h3 class="banner-botomm">Eco-Friendly - Expert Stain Removal</h3>
          </div>
  
        </div>
      </div>
    </div>
  </section>
 
          <div class="service-body">
            <h2 class="process-title" style="margin-bottom: 30px;">
              A Glance at Our Bag Cleaning Service 
            </h2>
            <div class="body body-text">
              Ever noticed how your favorite bag tells the tale of your adventures? But who's taking care of your trustworthy companion? At UClean, we believe your bags deserve some Tender Love and Care too! From bustling briefcases to world-trotting backpacks, we'll restore their glory. Our expert cleaning revives fabrics, banishes stains, and refreshes hardware. Ready to fall in love with your bags all over again? Let UClean work its magic and give your beloved bags the pampering they deserve. Experience the difference of professional bag cleaning and keep your accessories looking as fresh as your style.
At UClean, we understand the importance of maintaining the appearance and longevity of your favourite bags. Whether it's a designer handbag, a backpack for school or work, or a luggage set for travel, we have the expertise and equipment to give your bag a thorough and careful cleaning.  No matter the condition of your bag, we are confident that we can bring it back to its best possible appearance.

            </div>
           
            </div>
            <div class="process">
               
                    <h2 class="process-title">OUR BAG CLEANING PROCESS</h2>
                    <p class="process-description">Every cloth goes through a comprehensive 6 stage process enabling us to deliver on the promise of sparkling & fresh clothes, every time.</p>
                    
                    <div class="process-timeline">
                      <div class="process-step" *ngFor="let step of processSteps; let last = last">

                        <div class="step-icon">
                            <img [src]="step.iconPath" [alt]="step.title" class="custom-icon">
                          </div>
                    
                        <div class="step-content">
                          <h3 class="step-title">{{ step.title }}</h3>
                          <p class="step-description">{{ step.description }}</p>
                        </div>
                        <div class="step-connector" *ngIf="!last"></div>
                      </div>
                    </div>
                 
            </div>
            <div class="book-now">
                <h1>Trust us for keeping your clothes ‘AS GOOD AS NEW’!</h1>
                <button class="btn primary">Book Now</button>
            </div>
           
            <div class="dry-cleaning-container">
                <div class="image-container">
                  <img [src]="bagCleaningImage" alt="Bag Cleaning Service">
                </div>
                <div class="content-container">
                  <h2 class="title">OUR BAG CLEANING PRICES</h2>
                  <p class="description body-text">
                    At UClean, we believe in delivering high quality cleaning services at very affordable prices. With stores across multiple Tier 1, Tier 2 and Tier 3 cities, Quality, Speed and Economical Prices have been our biggest value drivers.
                  </p>
                  <a href='laundry-drycleaning-price-list' class="view-pricing-btn">View Pricing</a>
                </div>
              </div>

            <div class="promise-container">
                <h2 class="promise-title">OUR PROMISE TO YOU</h2>
                <app-our-promise></app-our-promise>
              </div>