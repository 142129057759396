import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { PixelService } from 'ngx-pixel';
import { metaTags } from 'src/assets/meta-contant';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit {

  constructor(
    private pixel: PixelService,
    private metaTagService: Meta) { }

  ngOnInit(): void {
    this.pixel.trackCustom('CareerPage');
    this.metaTagService.updateTag({ property: 'title', content: metaTags?.careers?.title });
    this.metaTagService.updateTag({ property: 'description', content: metaTags?.careers?.description });
  }

  imageObject = [{
    video: '//assets.ucleanlaundry.com/assets/images/uclean_event_1.jpg'
  },{
    video: '//assets.ucleanlaundry.com/assets/images/uclean_event_2.jpg'
  },{
    video: '//assets.ucleanlaundry.com/assets/images/uclean_event_3.jpg'
  },{
    video: '//assets.ucleanlaundry.com/assets/images/uclean_event_4.jpg'
  }]

}
