import { Component, OnInit } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loginprompt',
  templateUrl: './loginprompt.component.html',
  styleUrls: ['./loginprompt.component.scss']
})
export class LoginpromptComponent implements OnInit {
  showMessageBox = true;
  showLoginModal = true;


  constructor(
    private fb: FormBuilder,
    private router: Router
  ) {
    // Initialize form in constructor
  
  }

  ngOnInit(): void {
  }

  closeLoginModal(event?: Event): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    } // Close the modal first
    this.router.navigate([''], {
      replaceUrl: true
    });
  }

  redirectToLogin(event?: Event): void {
    if (event) {
      event.preventDefault();
    }
    this.showLoginModal = false; // Close the modal first
    this.router.navigate(['login-page'], {
      replaceUrl: true
    });
  }


}
