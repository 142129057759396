import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { OrderService } from '../order.service';
import { OrderStateService, ScheduleData } from '../order-state.service';
import { Location } from '../../../models/location';

@Component({
  selector: 'app-schedule-pickup',
  templateUrl: './schedule-pickup.component.html',
  styleUrls: ['./schedule-pickup.component.scss']
})
export class SchedulePickupComponent implements OnInit {
  from_date = { year: 2022, month: 4, day: 11 };
  to_date = { year: 2022, month: 4, day: 20 };
  selected_date: any;

  // Query params
  location: any;
  selectedStoreId: any;
  selectedServiceIds: any;
  selectedSlotId: any;
  selectedAddressId: any;
  selectedIndex = -1;

  showLoader = true;
  showNoSlotsFound = false;
  noSlotSelectedError = false;

  slots: any;
  timingSlots: any;

  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    public router: Router,
    public authService: AuthService,
    private orderStateService: OrderStateService
  ) {}

  ngOnInit(): void {
    // First try to restore state from OrderStateService
    const savedSchedule = this.orderStateService.getCurrentSchedule();
    
    this.route.queryParams.subscribe(params => {
      this.initializeFromParams(params);
      
      // If we have saved state, restore it
      if (savedSchedule) {
        this.restoreSavedState(savedSchedule);
      }
      
      this.loadSlots();
    });
  }

  private initializeFromParams(params: any) {
    this.location = {
      lat: params['lat'],
      lng: params['lng'],
      name: params['locationName'],
      pincode: params['pincode'],
      city: params['city']
    } as Location;
    
    this.selectedServiceIds = params['service_ids'];
    this.selectedStoreId = params['store_id'];
    this.selectedSlotId = params['slot_id'];
    this.selectedAddressId = params['address_id'];
    this.selectedIndex = params['selected_index'];

    if (!Array.isArray(this.selectedServiceIds)) {
      this.selectedServiceIds = [this.selectedServiceIds];
    }
  }

  private restoreSavedState(savedSchedule: any) {
    this.selected_date = savedSchedule.selectedDate;
    this.selectedSlotId = savedSchedule.selectedSlotId;
    this.timingSlots = savedSchedule.timingSlots;
  }

  private loadSlots() {
    this.orderService.slots(this.location, this.selectedStoreId, this.selectedServiceIds)
      .subscribe((data: any) => {
        if (data['Status'] === 'Fail') {
          this.showNoSlotsFound = true;
        } else {
          this.processSlots(data.slots);
        }
        this.showLoader = false;
      });
  }

  private processSlots(slots: any[]) {
    this.slots = {};
    for (let slot of slots) {
      if (!this.slots[slot.date]) {
        this.slots[slot.date] = [];
      }
      this.slots[slot.date].push(slot);
      
      if (this.selectedSlotId === slot.id) {
        this.selected_date = this.convertToDate(slot.date);
        this.onDateSelect(this.selected_date);
      }
    }
    
    this.setMinMaxDates();
    
    if (!this.selected_date && Object.keys(this.slots).length > 0) {
      const firstDate = this.convertToDate(Object.keys(this.slots)[0]);
      this.onDateSelect(firstDate);
    }
  }


  private filterValidSlots(slots: any[]): any[] {
    const currentDate = new Date().toISOString().split('T')[0];
    const currentHour = new Date().getHours();
    
    return slots.filter(slot => {
      if (slot.date === currentDate) {
        return slot.start_time > currentHour;
      }
      return true;
    });
  }

  
  selectSlot(slot_id: any): void {
    this.selectedSlotId = slot_id;
    const currentSchedule = this.orderStateService.getCurrentSchedule();
    
    if (currentSchedule) {
      // Create a complete ScheduleData object
      const updatedSchedule: ScheduleData = {
        location: this.location,
        selectedStoreId: this.selectedStoreId,
        selectedServiceIds: this.selectedServiceIds,
        selectedSlotId: slot_id,
        selectedAddressId: this.selectedAddressId,
        selectedDate: this.selected_date,
        timingSlots: this.timingSlots || [],
        combine_time: this.getSelectedSlotTime()
      };
      
      this.orderStateService.updateSchedule(updatedSchedule);
    }
  }

  onDateSelect(selectedDate: any): void {
    this.selected_date = selectedDate;
    const selectedDateStr = this.convertToStringDate(selectedDate);
    const slots: any[] = this.slots[selectedDateStr] || [];
    
    this.timingSlots = this.filterValidSlots(slots);
    
    // Create a complete ScheduleData object
    const updatedSchedule: ScheduleData = {
      location: this.location,
      selectedStoreId: this.selectedStoreId,
      selectedServiceIds: this.selectedServiceIds,
      selectedSlotId: this.selectedSlotId,
      selectedAddressId: this.selectedAddressId,
      selectedDate: this.selected_date,
      timingSlots: this.timingSlots,
      combine_time: this.getSelectedSlotTime()
    };
    
    this.orderStateService.updateSchedule(updatedSchedule);
  }

  private getSelectedSlotTime(): string | undefined {
    return this.timingSlots?.find((slot: { id: any; }) => 
      slot.id === this.selectedSlotId
    )?.combine_time;
  }

  // ... Your existing helper methods (convertToStringDate, convertToDate, setMinMaxDates) ...

  takeToBuildOrder() {
    this.router.navigate(['build-your-order'], {
      queryParams: {
        lat: this.location.lat,
        lng: this.location.lng,
        locationName: this.location.name,
        pincode: this.location.pincode,
        city: this.location.city,
        service_ids: this.selectedServiceIds,
        slot_id: this.selectedSlotId,
        address_id: this.selectedAddressId
      }
    });
  }

  takeToChooseAddress() {
    if (!this.selectedSlotId) {
      this.noSlotSelectedError = true;
      return;
    }

    if (!this.authService.loggedIn) {
      return;
    }

    this.noSlotSelectedError = false;
    this.router.navigate(['/choose-address'], {
      queryParams: {
        lat: this.location.lat,
        lng: this.location.lng,
        locationName: this.location.name,
        pincode: this.location.pincode,
        city: this.location.city,
        store_id: this.selectedStoreId,
        service_ids: this.selectedServiceIds,
        slot_id: this.selectedSlotId,
        address_id: this.selectedAddressId,
        selected_index: this.selectedIndex
      }
    });
  }

  convertToStringDate(selectedDate: any): any {
    let month = selectedDate.month;
    if (month < 10) {
      month = '0' + month;
    }
    let day = selectedDate.day;
    if (day < 10) {
      day = '0' + day;
    }
    let str = selectedDate.year + '-' + month + '-' + day;
    return str;
  }

  convertToDate(date: any): any {
    let year = date.split('-')[0];
    let month = date.split('-')[1];
    let day = date.split('-')[2];
    return {year: parseInt(year), month: parseInt(month), day: parseInt(day)};
  }

  convertToDateFromStr(date: any): any {
    let day = date.split('/')[0];
    let month = date.split('/')[1];
    let year = date.split('/')[2];
    return {year: parseInt(year), month: parseInt(month), day: parseInt(day)};
  }

  setMinMaxDates(): void {
    let startDate = Object.keys(this.slots)[0];
    let endDate = Object.keys(this.slots)[Object.keys(this.slots).length - 1];
    this.from_date = this.convertToDate(startDate);
    this.to_date = this.convertToDate(endDate);
  }

}