export const metaTags = {
    'home': {
        title: `UCLEAN: India's #1 Laundry & Dry Cleaning Service Provides`,
        description: `UClean provides the best  Laundry & Dry Cleaning service. We have over 300+ stores across 85+ cities. With franchise opportunities to run successful business`
    },
    'about-us': {
        title: `About Us - Asia's Largest Laundry & Dry Cleaning Chain - UClean`,
        description: `UClean is Asia's largest laundry and dry cleaning chain. With the best services,  free home pick & drop, and a promise to deliver clothes in less than 24 hours.`
    },
    'services': {
        title: `India's Best Laundry & Dry Cleaning Service Provider - UClean`,
        description: `UClean provides the best dry cleaning, laundry, stream ironing, shoe cleaning, bag cleaning, home cleaning, sofa cleaning, carpet cleaning, car cleaning.`
    },
    'laundry-drycleaning-price-list': {
        title: `Laundry By Killo | Dry Cleaning Service at Best Prices - UClean`,
        description: `The best-in class laundry, dry cleaning service has become affordable with UClean. Our budget friendly package saves your money and time.`
    },
    'apply-for-franchise': {
        title: `Most Profitable Laundry Business: Apply For Franchise - UClean`,
        description: `UClean provides easy franchise business opportunity with low investment, high ROI, less risk and zero hassles. We provide 24*7 support system.`
    },
    'services/DryCleaning': {
        title: `Book Dry Cleaning | Free Instant Pickup & Drop Service - UClean`,
        description: `UClean provides various types of laundry and dry cleaning services at affordable price. With additional benefits such as free home pick and drop. `
    },
    'services/LaundrybyKg': {
        title: `Book Laundry By Kg: Wash & Fold or Ironing Service - UClean`,
        description: `UClean provides Laundry by kg/kilo : Wash & Fold or Ironing Services. It has become easy with UClean to pay less and more time.`
    },
    'services/PremiumLaundry': {
        title: `Book Premium Laundry Service Online at Best Price - UClean`,
        description: `UClean provides the best Premium laundry service at affordable price with quality service. With benefits such as quick service and doorstep pickup and drop.`
    },
    'services/SteamIroning': {
        title: `Book Steam Ironing Service Online at Best Price in India - UClean`,
        description: `Book now for the best stream ironing service at UClean. Ironing clothes from professionals gives your cloth freshness and brightens up.`
    },
    'services/ShoeCleaning': {
        title: `Book Shoe Cleaning or Laundry at Best Price in India - UClean`,
        description: `Get your shoes clean at affordable price with UClean. Shoe cleaning gives newness to the shoes which saves your money and space.`
    },
    'services/BagCleaning': {
        title: `Book Bag Cleaning or Laundry Service for All Sizes - UClean`,
        description: `All size bag Cleaning is easy with UClean. We provide you best cleaning service to keep you upto date and help to save your money. With doorstep pick and drop.`
    },
    'services/CurtainCleaning': {
        title: `Book Curtain Cleaning Service in India at Best Price - UClean`,
        description: `Clean Curtain means clean house. UClean provides free doorstep service to keep your house clean. Book a slot now to make your house trendy.`
    },
    'services/SofaCleaning': {
        title: `Book Sofa Cleaning Service in India at Best Price - UClean`,
        description: `Get your sofa clean from trustable place, UClean is here to give your sofa newness. The best-in class service is now available at an affordable price.`
    },
    'services/CarpetCleaning': {
        title: `Book Carpet Cleaning Service in India at Best Price - UClean`,
        description: `UClean is here to provide you the best cleaning service. Carpet cleaning can be tuff at home, to solve this issue book a slot now.`
    },
    'services/HomeDeepCleaning': {
        title: `Book Deep House Cleaning Service in India at Best Price - UClean`,
        description: `Any type of home cleaning is possible with UClean at an affordable price. We are here to provide the best-in class service at a single click.`
    },
    'services/CarCleaning': {
        title: `Book Car Cleaning Service at Best Price in India - UClean`,
        description: `Car cleaning by the professionals can make you look smarter. Book a slot now and get your car clean at affordable price.`
    },
    'uclean-store-near-you': {
        title: `Find UClean Store Near Me | Laundry & Dry Cleaning Services`,
        description: `For laundry and dry cleaning service check your nearby UClean stores.  We have over 300+ stores spread over 85+ cities.`
    },
    'terms-and-conditions': {
        title: `Terms & Conditions - UClean`,
        description: `UClean provides the best  Laundry & Dry Cleaning service. We have over 300+ stores across 85+ cities. Please check our terms and conditions.`
    },
    'privacy-policy': {
        title: `Privacy Policy - UClean`,
        description: `Do read our privacy policy before doubting. UClean values it's precious customer and promises to keep your personal details and personal information safe with us.`
    },
    'careers': {
        title: `Jobs at UClean`,
        description: `UClean provides the most friendly work environment and several other benefits. Do check the reasons for joining us.`
    },
    'media': {
        title: `Latest Media Updates - UClean`,
        description: `UClean provides the best  Laundry & Dry Cleaning service. We have over 300+ stores across 85+ cities. Do check our media page and keep yourself upto date.`
    },
    'covid-19': {
        title: `COVID Guidelines - UClean`,
        description: `UClean provides the best cleaning service while following COVID rules to keep our valuable customer safe. Also check the ways to clean and disinfect your home.`
    }

}

// metaTags