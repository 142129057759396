<!-- hp_letest_blog_section start-->
<!-- 
<section class="hp_blog_section">
   <div class="container">
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="main_title_bar">
               <h2>Latest Blogs</h2>
               <p>Stay updated with the best of garment and home cleaning</p>
            </div>
         </div>
      </div>
   </div>
   <div class="container">
      <div class="row blog-card-list">
         <div class="col-md-4 col-sm-12 col-xs-12" *ngFor="let blog of latestBlog;">
            <app-blog-card [blogImageUrl]="'//assets.ucleanlaundry.com/assets/images/services1.jpg'"
               [blogHeaderText]="blog?.title" [blogSubHeaderText]="blog?.created_at"
               [blogDescriptionText]="blog?.description" [learnMoreLink]="'/blog/' + blog.slug"></app-blog-card>
         </div>
      </div>
   </div>
   <div class="container">
      <div class="row">
         <div class="tab_bar customer-tabbar">
            <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
               <div class="tab-pane active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  <div id="owl-demo" class="owl-carousel owl-theme">
                     <div class="item">
                        HI
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div> 
</section> -->

<!-- hp_letest_blog_section start-->

<section class="hp_blog_section">
   <div class="container">
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="main_title_bar">
               <h2>Latest Blogs</h2>
               <p>Stay updated with the best of garment and home cleaning</p>
            </div>
         </div>
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top">
            <div class="blog_block large">
               <div *ngFor="let blog of latestBlog; let i = index" class="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                  [ngClass]="{'right_side_blog' : i == 1, 'right_side_blog2': i == 2}">
                  <div class="blog_box" [ngClass]="{'first-box': i === 0}">
                     <div class="blog_img" [ngClass]="{'first-img': i === 0}">
                        <img [routerLink]="['/blog', blog.slug]" src="{{blog.full_url}}">
                     </div>
                     <div class="blog_text">
                        <span>{{blog.created_at}}</span>
                        <h4>{{blog.title}}</h4>
                        <p>{{blog.description}}</p>
                        <a [routerLink]="['/blog', blog.slug]">Learn More</a>
                     </div>
                  </div>
               </div>
            </div>
            <div class="blog_block mob">
               <div class="slider2">
                  <div *ngFor="let blog of latestBlog; let i = index" class="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                  [ngClass]="{'right_side_blog1' : i == 0,'right_side_blog' : i == 1, 'right_side_blog2': i == 2}">
                     <div class="blog_box">
                        <div class="blog_img">
                           <img [routerLink]="['/blog', blog.slug]" src="{{blog.full_url}}">
                        </div>
                        <div class="blog_text">
                           <span>{{blog.created_at}}</span>
                           <h4>{{blog.title}}</h4>
                           <p>{{blog.description}}</p>
                           <a [routerLink]="['/blog', blog.slug]">Learn More</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>

<!-- hp_latest_blog_section end-->

