<section class="hp_banner_section" [style.background-image]="'url(' + bannerImageUrl + ')'">
    <div class="container">
      <div class="row">
        <div class="hp_banner_block">
          <div class="hp_banner_tilte">
            <h3 class="baner-top">Laundry</h3>
            <h3 class="banner-botomm">Eco-Friendly - Expert Stain Removal</h3>
          </div>
  
        </div>
      </div>
    </div>
  </section>
 
  <div class="service-body">
    <h2 class="process-title" style="margin-bottom: 30px;">
      Laundry by Kilo: Wash and Iron, Wash and Fold
    </h2>
    <div class="body body-text">
       Laundry is one of the basic human needs, yet it overwhelms an average person buried under the pile of dirty clothes. 
      We literally take the load off you and return you time. However, that’s not it! Getting your laundry done by a professional has many other benefits.
    </div>
  
    <h2 class="process-title" style="margin-bottom: 30px; margin-top: 50px;">
      Wash & Fold vs Wash & Iron
    </h2>
    <div class="body-lower body-text">
      With our Wash & Fold service, every garment undergoes a meticulous 4-stage process: washing, drying, folding, and neatly stacking for packaging. 
      Our Wash & Iron service follows the same thorough process, but with an added step of professional ironing, ensuring your clothes are crisp, wrinkle-free, and perfectly presented before final packing.
      
      <br><br><strong>But why outsource laundry & not just wash at home?</strong>
      <br>
      <br>
      <br><strong>Cheaper Laundry by Kilo</strong> – Outsourcing your laundry can save you money. A family of four spends around ₹5,000 monthly on washing clothes at home. 
      With our laundry subscription packages, you can save up to 20% on your monthly expenses.
      <br>
      <br>
      <br><strong>Save Time, Space & Water</strong> – Laundry takes 40-50 hours a month for a family of four. 
      Plus, line drying requires space, which is often scarce in big cities. Dryers are costly and consume a lot of water—an average 6kg load uses 80-100 liters. 
      Our advanced machines use up to 40% less water, making outsourcing better for you and the environment.
  <br>
  <br>
      <br><strong>High-Pressure Steam Ironing (Wash & Iron Service)</strong> – Home irons and coal irons can cause shine marks and damage fabrics. 
      We use high-pressure steam with a Teflon-coated iron to avoid any damage. Further, steam sterilization, recommended by the CDC and WHO, is the safest way to sanitize clothes.
    </div>
  </div>
  
            <div class="process">
               
                    <h2 class="process-title">OUR LAUNDRY PROCESS</h2>
                    <p class="process-description">Every cloth goes through a comprehensive 6 stage process enabling us to deliver on the promise of sparkling & fresh clothes, every time.</p>
                    
                    <div class="process-timeline">
                      <div class="process-step" *ngFor="let step of processSteps; let last = last">

                        <div class="step-icon">
                            <img [src]="step.iconPath" [alt]="step.title" class="custom-icon">
                          </div>
                    
                        <div class="step-content">
                          <h3 class="step-title">{{ step.title }}</h3>
                          <p class="step-description">{{ step.description }}</p>
                        </div>
                        <div class="step-connector" *ngIf="!last"></div>
                      </div>
                    </div>
                 
            </div>
            <div class="book-now">
                <h1>Trust us for keeping your clothes ‘AS GOOD AS NEW’!</h1>
                <button class="btn primary">Book Now</button>
            </div>
           
            <div class="dry-cleaning-container">
                <div class="image-container">
                  <img [src]="laundryImage" alt="Laundry Service">
                </div>
                <div class="content-container">
                  <h2 class="title">OUR LAUNDRY PRICES</h2>
                  <p class="description body-text">
                    At UClean, we believe in delivering high quality cleaning services at very affordable prices. With stores across multiple Tier 1, Tier 2 and Tier 3 cities, Quality, Speed and Economical Prices have been our biggest value drivers.
                  </p>
                  <a href='laundry-drycleaning-price-list' class="view-pricing-btn">View Pricing</a>
                </div>
              </div>

            <div class="promise-container">
                <h2 class="promise-title">OUR PROMISE TO YOU</h2>
                <app-our-promise></app-our-promise>
              </div>