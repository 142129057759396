<app-navbar></app-navbar>
<div class="modal fade" id="currentLocationModal" tabindex="-1" role="dialog"
     aria-labelledby="currentLocationModalLabel"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="full" id="currentLocation" style="height: 500px;">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" data-dismiss="modal" class="btn btn-danger" style="float: left;">Cancel
        </button>
        <button type="button" class="btn btn-success" style="float: right; width: 27%;" data-dismiss="modal" (click)="positionService.setLocation()">Ok</button>
      </div>
    </div>
  </div>
</div>
<div class="main-content">
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
