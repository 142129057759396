<!-- order-history.component.html -->
<div class="max-w-4xl mx-auto p-4">
    <h1 class="text-2xl font-bold mb-6">Order History</h1>
  
    <div class="order-card mb-6" *ngFor="let order of userOrdHistory">
      <div class="card">
        <div class="card-content p-6">
          <!-- Status Banner -->
          <div [class]="'status-banner ' + getStatusLabel(order.status).color">
            <div class="flex items-center gap-2">
              <i class="fas fa-box"></i>
              <span class="font-semibold">{{getStatusLabel(order.status).label}}</span>
            </div>
            <span class="text-sm">Order #{{order.booking_code}}</span>
          </div>
  
          <!-- Store Information -->
          <div class="store-info">
            <i class="fas fa-store"></i>
            <span class="font-semibold">{{getStoreCode(order.booking_code)}}</span>
          </div>
  
          <!-- Services -->
          <div class="services-section">
            <h3 class="font-semibold mb-2">Services Requested</h3>
            <div class="service-tags">
              <span class="service-tag" *ngFor="let service of getServices(order.booking_type)">
                {{service}}
              </span>
            </div>
          </div>
  
          <!-- Pickup Details -->
          <div class="pickup-details">
            <div class="detail-item">
              <span>Pickup Date: {{order.pickup_date}}</span>
            </div>
            <div class="detail-item">
              <i class="fas fa-clock"></i>
              <span>Time: {{order.pickup_time}}</span>
            </div>
          </div>
  
          <!-- Address -->
          <div class="address-section">
            <div class="address-content">
              <i class="fas fa-map-marker-alt"></i>
              <div>
                <h3 class="font-semibold mb-1">Pickup Address</h3>
                <p class="text-gray-600">
                  {{order.flat_info}}, {{order.street_info}}
                  <ng-container *ngIf="order.landmark">, Near {{order.landmark}}</ng-container>
                  <br>
                  Pincode: {{order.pincode}}
                </p>
              </div>
            </div>
          </div>
  
          <!-- Contact Information -->
          <div class="contact-info">
            <div class="contact-item">
              <span>{{order.contact_person}}</span>
            </div>
            <div class="contact-item">
              <span>{{order.contact_number}}</span>
            </div>
          </div>
  
        </div>
      </div>
    </div>
  </div>