import { Component, OnInit, ViewChild } from '@angular/core';
import { Address } from 'src/app/models/addresses';
import { PagesService } from '../pages.service';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, NgForm } from '@angular/forms'
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import { SlideInOutAnimation } from './animations';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  animations: [SlideInOutAnimation]
})
export class UserProfileComponent implements OnInit {

  animationState: any = {
    myProfile: 'in',
    orderHistory: 'out',
    addressBook: 'out',
    notificationSetting: 'out'
  };
  // @ViewChild('editAddForm') editAddFrom:NgForm | any

  constructor(private pagesService: PagesService, private fb: FormBuilder, public authService: AuthService, public router: Router) { }

  userAddresses: any = {};

  addressess: Address[] = [];
  addForm: boolean = false;
  newAdd: any = {};

  editProfile = true;
  userData: any = {};

  citiesList:any = [];

  type: string = "Home";
  flat_info: string = "";
  city_id: string = "";
  pincode: any;
  latitude: any;
  longitude: any;
  landmark: string = "";
  dAddress:any = {};
  currentUserId: string = '';
  showLoader: boolean = true;

  ngOnInit(): void {
    this.pagesService.currentUser().subscribe(
      (response: any) => {
        this.currentUserId = response.id;
      }
    );
    this.addressess = this.getAddresses();
    this.cities();
  }

  cities(){
    this.pagesService.cityList().subscribe((data : any)=>{
      this.citiesList = data.cities;
    });
  }

  getAddresses(): Address[] {
    return this.pagesService.getAddFromData();
  }

  cancelNewUser() {
    this.newAdd = {};
    this.addForm = false;
  }

  logOutUser() {
    this.pagesService.logOut().subscribe((data: any) => {
      localStorage.removeItem('userToken');
      localStorage.removeItem('user_data');
      localStorage.clear();
      this.authService.checkAuthAndGetUser();
      this.router.navigate(['/']);
    });
  }

  accordionStateChange(divName: string) {
    if(['myProfile', 'orderHistory', 'addressBook', 'notificationSetting'].includes(divName)) {
      this.animationState[divName] = this.animationState[divName] === 'out' ? 'in' : 'out';
    }
  }
}
