import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Address} from 'src/app/models/addresses';
import {PagesService} from '../../pages/pages.service';
import {OrderService} from '../order.service';
import {AddressService} from '../../../services/address.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '../../../models/location';
import {PositionService} from '../../../services/position.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LeafletMapComponent } from '../../components/leaflet-map/leaflet-map.component';
import { CustomLocationInfoModel, LocationModel } from 'src/app/models/leaflet';
import * as mapConfig from '../../components/leaflet-map/map-config';
import { Meta } from '@angular/platform-browser';
import { OrderStateService } from '../order-state.service';

@Component({
  selector: 'app-choose-address',
  templateUrl: './choose-address.component.html',
  styleUrls: ['./choose-address.component.scss']
})

export class ChooseAddressComponent implements OnInit {
  // Query params
  location: any;
  selectedStoreId: any;
  selectedServiceIds: any;
  selectedSlotId: any;
  selectedAddressId: any;

  // view states
  showLoader = true;
  showAddressList = false;
  showAddressForm = false;
  noAddressSelectedError = false;
  disableInputType = true;
  validation: any = {};
  isServiceProviderErrorModal = false;
  isApiCalling = false;
  storeAddressList: any[] = [];
  storeIdAfterAddressChange: any

  // all the addresses got from server
  addresses: any;
  citiesList: any;

  isNewAddr: boolean = false;
  address: any = {};

  lat: any;
  lng: any;
  mannualCity: any;
  cityCtrl = new FormControl();
  filteredCity!: Observable<any[]>;
  searchingLocation: boolean = false;
  @ViewChild('mapCompRef', {static: true}) mapCompRef!: LeafletMapComponent; 
  isMapInitialised = false;
  mapRef: any;

  constructor(private cdRef: ChangeDetectorRef,
    private metaTagService: Meta,
    private pagesService: PagesService, private route: ActivatedRoute,
    private router: Router, private orderService: OrderService,
    private addressService: AddressService, private positionService: PositionService, private orderStateService: OrderStateService) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {

      // console.log(params);
      this.location = {} as Location;
      this.location.lat = params['lat'];
      this.location.lng = params['lng'];
      this.location.name = params['locationName'];
      this.location.pincode = params['pincode'];
      this.location.city = params['city'];
      this.selectedServiceIds = params['service_ids'];
      this.selectedStoreId = params['store_id'];
      this.selectedSlotId = params['slot_id'];
      this.selectedAddressId = params['address_id'];
      this.selectedIndex=params["selected_index"];
      this.moveMapToParticularLocation({lat: this.location.lat, lng: this.location.lng});
      this.checkForServiceProvider({ latitude: params['lat'], longitude: params['lng']});
     
      // convert to array if single service selected
      if(!Array.isArray(this.selectedServiceIds)){
        let service = this.selectedServiceIds;
        this.selectedServiceIds = [];
        this.selectedServiceIds.push(service);
      }
      // console.log('Selected index',this.selectedIndex)
      this.pagesService.cityList().subscribe((data : any)=>{
        this.citiesList = data.cities;
        this.setCityChangeSubscription();
        for(let i = 0; i < this.citiesList.length; i++){
          if(this.citiesList[i].name.toLowerCase() == this.location.city.toLowerCase() || (this.citiesList[i].alias != undefined && this.citiesList[i].alias.indexOf(this.location.city) > 0)){
            this.address.city_id = this.citiesList[i].id;
            this.location.city_id = this.citiesList[i].id;
          }
        }
      });

      // get addresses
      this.addressService.getAddresses(this.selectedStoreId).subscribe((data: any) => {
        this.addresses = data.addresses;
        this.showLoader = false;
        // if empty addresses then show form directly
        if(this.addresses == undefined || this.addresses.length <= 0){
          
          this.addNewAddress();
          this.showAddressList = false;
        }else{
          this.showAddressList = true;
          //  console.log(this.addresses)
          if(this.selectedIndex>-1){
                  this.addresses[this.selectedIndex].selected=true;
          }
           
        }
      });


    });
  }

  onMapInit(event: any) {
    this.isMapInitialised = true;
    this.mapRef = event.mapRef;
    if (this.location.lat && this.location.lng) {
      this.moveMapToParticularLocation({lat: this.location.lat, lng: this.location.lng});
    }
  }

  moveMapToParticularLocation(newPosition: LocationModel) {
    const newLatLng = [+Number(newPosition.lat).toFixed(5), +Number(newPosition.lng).toFixed(5)];
    this.isMapInitialised && this.mapRef.setView(newLatLng, mapConfig.MAP_SETTINGS.defaultZoomView, mapConfig.MAP_SETTINGS.getMapMoveConfig());
  }

  onLocationConfirm(completeLocationInfo: CustomLocationInfoModel) {
    this.address['location'] = completeLocationInfo?.name;
    this.onAddressSelected(completeLocationInfo);
  }

  setCityChangeSubscription() {
    this.filteredCity = this.cityCtrl.valueChanges.pipe(
      startWith(''),
      map((city) =>
        city ? this.filterCity(city) : this.citiesList.slice()
      )
    )
    this.cityCtrl.valueChanges.subscribe((cityName) => {
      if(cityName) {
        console.log('new city', cityName);
        const city = this.citiesList.find((elm: any) => elm?.name.startsWith(cityName));
        this.address['city_id'] = city?.id;
        console.log(city);
      }
    })
  }
  filterCity(name: string) {
    let arr = this.citiesList.filter(
      (city: any) => city?.name.toLowerCase()?.indexOf(name?.toLowerCase()) === 0
    );

    return arr.length ? arr : [{ name: 'Please select the city as the location which you are trying to select in not in our serviceable area, kindly change the location.', id: '' }];
  }

  disableInputForType(){
    this.disableInputType = false;
  }

  showEditUserForm(address: Address) {
    this.showAddressForm = true;
    this.address = address;
    this.moveMapToParticularLocation({lat: this.location.lat, lng: this.location.lng});
    this.isNewAddr = false;
    this.populateCityName(this.address?.city_id);
    this.scrollToForm();
  }

  scrollToForm() {
    setTimeout(() => {
      const elmntToView = document.getElementById("addressForm") as HTMLElement;
      elmntToView && elmntToView.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"}); 
    }, 500)
  }

  populateCityName(id: any) {
    const cityObj = this.citiesList.find((elm: any) => elm?.id == id);
    const cityName = cityObj ? cityObj?.name: '';
    this.cityCtrl.setValue(cityName);
    cityName? this.cityCtrl.disable(): this.cityCtrl.enable();
    console.log(cityName? 'cityname found': 'not found');
  }

  addNewAddress() {
    this.address = {"latitude": this.location.lat, "longitude": this.location.lng, "location": this.location.name, "pincode": this.location.pincode, "city_id": this.location.city_id};
    this.populateCityName(this.address?.city_id);
    this.showAddressForm = true;
    this.isNewAddr = true;
    this.scrollToForm();
  }

  hideAddressForm() {
    this.showAddressForm = false;
  }

  validate() {
    let ok = true;
    if(this.address.type == undefined || this.address.type.trim() == ""){
      this.validation.type = "has-error";
      ok = false
    }else{
      this.validation.type = "";
    }

    if(this.address.flat_info == undefined || this.address.flat_info.trim() == ""){
      this.validation.flat_info = "has-error";
      ok = false
    }else{
      this.validation.flat_info = "";
    }

    if(this.address.street_info == undefined || this.address.street_info.trim() == ""){
      this.validation.street_info = "has-error";
      ok = false
    }else{
      this.validation.street_info = "";
    }

    if(this.address.city_id == undefined){
      this.validation.city_id = "has-error";
      ok = false
    }else{
      this.validation.city_id = "";
    }

    if(this.address.pincode == undefined || this.address.pincode.length < 6){
      this.validation.pincode = "has-error";
      ok = false
    }else{
      this.validation.pincode = "";
    }

    return ok;
  }

  saveAdd() {
    if(this.validate()){
      // Clean up the address object before saving
      const addressToSave = { ...this.address };
      
      // Convert undefined landmark to empty string
      if (addressToSave.landmark === undefined) {
        addressToSave.landmark = '';
      }

      if(addressToSave.id != undefined){
        this.addressService.updateAddress(addressToSave).subscribe((data: any) => {
          if(data['status'] == "SUCCESS"){
            // Select it
            this.selectedAddressId = data['address']['id'];

            // Close the form and empty the address
            this.address = {};
            this.hideAddressForm();
          }
        });
      } else {
        this.addressService.createNewAddress(addressToSave).subscribe((data: any) => {
          if(data['status'] == "SUCCESS"){
            // Add to the list and select it
            this.addresses.push(data['address']);
            this.selectedAddressId = data['address']['id'];

            // Close the form and empty the address
            this.address = {};
            this.hideAddressForm();
            this.showLoader = true;
            setTimeout(() => {
              this.addressService.getAddresses(this.selectedStoreId).subscribe((data: any) => {
                this.addresses = data.addresses;
                // if empty addresses then show form directly
                if(this.addresses == undefined || this.addresses.length <= 0){
                  this.addNewAddress();
                  this.showAddressList = false;
                  if (this.location.pincode != undefined){
                    this.address.pincode = this.location.pincode;
                  }
                  this.address.city_id = localStorage.getItem('cityIdForAddress');
                  this.populateCityName(this.address?.city_id);
                } else {
                  this.showAddressList = true;
                }
                this.showLoader = false;
              });
            }, 500);
          }
        });
      }
    }
  }

  removeAdd(address: any) {
    console.log('Removing address:', address);
    
    // ask for confirmation then only delete the address
    if (confirm("Are you sure you want to delete this address!") == true) {
      console.log('Confirmed delete for address ID:', address.id);
      
      // make the call to backend
      this.addressService.deleteAddress(address.id).subscribe(
        (data: any) => {
          console.log('Delete address response:', data);
          
          if(data['status'].toLowerCase() === "success"){
            console.log('Address deletion successful');
            
            // Call method to refresh addresses
            this.refreshAddressList();
          } else {
            console.error('Address deletion failed');
          }
        },
        (error) => {
          console.error('Error deleting address:', error);
        }
      );
    }
  }
  
  // New method to refresh address list
  refreshAddressList() {
    console.log('Refreshing address list');
    this.showLoader = true;
    
    this.addressService.getAddresses(this.selectedStoreId).subscribe(
      (addressData: any) => {
        console.log('Fetched addresses:', addressData);
        
        this.addresses = addressData.addresses;
        this.showLoader = false;
  
        // Reset selectedAddressId if needed
        if(this.selectedAddressId && !this.addresses.some((addr: { id: any; }) => addr.id === this.selectedAddressId)){
          this.selectedAddressId = undefined;
        }
  
        // If no addresses left, show the address form
        if(!this.addresses || this.addresses.length === 0){
          console.log('No addresses left, showing address form');
          this.addNewAddress();
          this.showAddressList = false;
        } else {
          this.showAddressList = true;
        }
      },
      (error) => {
        console.error('Error fetching addresses:', error);
        this.showLoader = false;
      }
    );
  }

  removeAddFromLocal(address_id: string){
    for (let i = 0; i < this.addresses.length; i++) {
      let obj = this.addresses[i];
      if (address_id == obj.id) {
        this.addresses.splice(i, 1);
        return;
      }
    }

  }

 

  selectAddress(addressId: string){
    this.selectedAddressId = addressId;
  }

  getClass(addressId: string) {
    if (this.selectedAddressId == addressId) {
      return 'active';
    }
    return '';
  }

  
  checkedChange(i:number,item:any){
      //   console.log(item,i)
  }
   selectedIndex=-1;
   addressChange(ev: any, i: number) {
    console.log('address selectinggg !!!!!!!')
    let checked = ev.target.checked;
    
    if (checked) {
      this.selectedIndex = i;
      
      for (let x = 0; x < this.addresses.length; x++) {
        if (x === i) {
          console.log('Full address object being sent:', this.addresses[x]);
          console.log('Address ID:', this.addresses[x].id);
          
          this.addresses[x].selected = true;
          this.orderStateService.updateSelectedPickupAddress(this.addresses[x]);
        } else {
          this.addresses[x].selected = false;
        }
      }
    }
  }

  // for navigation
  takeToBuildOrder() {
    this.router.navigate(['build-your-order'], {
      queryParams: {
        lat: this.location.lat,
        lng: this.location.lng,
        locationName: this.location.name,
        pincode: this.location.pincode,
        city: this.location.city,
        service_ids: this.selectedServiceIds,
        slot_id: this.selectedSlotId,
        address_id: this.selectedAddressId,
      }
    });
  }

  takeToSchedulePickup() {
    this.router.navigate(['/schedule-pick-up'],
      {
        queryParams: {
          lat: this.location.lat,
          lng: this.location.lng,
          locationName: this.location.name,
          pincode: this.location.pincode,
          city: this.location.city,
          store_id: this.selectedStoreId,
          service_ids: this.selectedServiceIds,
          slot_id: this.selectedSlotId,
          address_id: this.selectedAddressId,
          selected_index:this.selectedIndex
         // selectedIndex:this.addresses.filter((x:any)=>x.selected==true)[0]
        }
      });
  }

  takeToConfirmOrder() {
    if (this.selectedAddressId != undefined) {
      this.noAddressSelectedError = false;
      const selectedAddress = this.addresses.find((elm: any) => elm?.id === this.selectedAddressId);
      const selectedAddressIndex = this.addresses.findIndex((elm: any) => elm?.id === this.selectedAddressId);
      this.checkForServiceProvider({ latitude: selectedAddress?.latitude, longitude: selectedAddress?.longitude })
      this.router.navigate(['/confirm-order'],
        {
          queryParams: {
            lat: selectedAddress?.latitude,
            lng: selectedAddress?.longitude,
            locationName: selectedAddress?.location,
            pincode: selectedAddress?.pincode,
            city: selectedAddress?.city_name,
            store_id: this.storeIdAfterAddressChange ? this.storeIdAfterAddressChange: this.selectedStoreId,
            service_ids: this.selectedServiceIds,
            slot_id: this.selectedSlotId,
            address_id: selectedAddress.id,
            selectedIndex: selectedAddressIndex
          }
        });
    }else{
      this.noAddressSelectedError = true;
    }
  }

  onAddressSelected(event: any) {
    this.mannualCity = false;
    this.address['pincode'] = event?.pincode;
    const selectedCity = this.citiesList.find((elm:any) => event?.name?.toLowerCase().includes(elm?.name?.toLowerCase()) ||  elm?.name?.toLowerCase().includes(event?.name?.toLowerCase()));
    this.address['city_id'] = selectedCity ? selectedCity?.id: '';
    this.address = this.address;
    this.populateCityName(this.address?.city_id);
    this.checkForServiceProvider({ latitude: event?.lat, longitude: event?.lng});
    this.orderService.services(event).subscribe((data: any) => {
      this.storeIdAfterAddressChange = data?.store?.id;
    });
  }

  onLocationSearchStateChange(event: boolean) {
    this.searchingLocation = event;
    this.cdRef.detectChanges();
  }

  onMannualCityChange() {
    this.mannualCity = true;
  }

  checkForServiceProvider(address: any) {
    [this.isServiceProviderErrorModal, this.isApiCalling] = [true, true];
    this.addressService.checkServiceProviderAvailablity(address).subscribe((data: any) => {
      this.isApiCalling = false;
      if (!data?.stores?.length) {
        this.isServiceProviderErrorModal = true;
      } else {
        this.isServiceProviderErrorModal = false;
        this.storeAddressList = data?.stores;
        this.moveMapToParticularLocation({lat: address?.latitude, lng: address?.longitude});
      }
    }, (_err: any) => {
      [this.isServiceProviderErrorModal, this.isApiCalling] = [true, false, true];
    });
  }

  enableLocationEditing() {
    document.getElementById('location-input')?.focus();
  }
}
