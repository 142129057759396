import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { enterAnimation } from 'src/app/animations/enter-leave.animation';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  animations: [ enterAnimation ]
})
export class LoginPageComponent implements OnInit {

  public isLoading = false;
  loginForm!: FormGroup;
  public errorMsg: any;
  public showError = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.formValueChangeSubscription();
  }

  initializeForm() {
     this.loginForm = this.fb.group({
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]]
    })
  }

  formValueChangeSubscription() {
    this.loginForm.valueChanges.subscribe(() => {
      this.showError = false;
    })
  }

  goToSignupPage() {
    this.router.navigate(['signup-page'], {
      replaceUrl: true
    })
  }

  goToOtpVerification() {
    this.router.navigate(['otp-verification'], {
      queryParams: {
        fromUrl: 'login-page',
        enc: btoa(JSON.stringify({...this.loginForm.getRawValue(), apiCallingTime: new Date().getTime()}))
      }
    })
  }

  sendOtpForVerification() {
    this.isLoading = true;
    const {phone} = this.loginForm.getRawValue();
    const requestBody = {
      mobile: phone
    }
    this.authService.sendOtpLogin(requestBody).subscribe((res: any) => {
      this.isLoading = false;
      if(res?.status === 'SUCCESS') {
        this.goToOtpVerification();
      } else if(res?.status === 'ERROR') {
        this.errorMsg = res?.msg;
        this.showError = true;
      }
      console.log(res);
    }, (err) => {
      this.isLoading = false;
    });
  }
}
