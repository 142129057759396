import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PagesService } from '../../pages/pages.service';
import { SortPipe } from '../../pipes/sort.pipe';


@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {

  constructor(
    private metaTagService: Meta,
    private pagesService:PagesService, private route : Router, private sortPipe: SortPipe) {}

  citiesList:any;
  searchStoreAdd: any;

  ngOnInit(): void {
    this.pagesService.cityList().subscribe((data : any)=>{
      this.citiesList = data.cities;
    });

    const sortedArr = this.sortPipe.transform(this.citiesList, "desc", "name");
  }


  getCityStores(id: any, index: any){
    console.log(this.citiesList[index].id)
    let ids = this.citiesList[index].id;
    let name = this.citiesList[index].name;
    this.route.navigate([`stores-by-cities/${ids}/${name}`])
    this.pagesService.locatonId = this.citiesList[index].id;
  }

}
