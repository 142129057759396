<section class="hp_banner_section" [style.background-image]="'url(' + bannerImageUrl + ')'">
    <div class="container">
      <div class="row">
        <div class="hp_banner_block">
          <div class="hp_banner_tilte">
            <h3 class="baner-top">Shoe Cleaning</h3>
            <h3 class="banner-botomm">Eco-Friendly - Expert Stain Removal</h3>
          </div>
  
        </div>
      </div>
    </div>
  </section>
 
  <div class="service-body">
    <h2 class="process-title" style="margin-bottom: 30px;">
      A Glance at Our Shoe Cleaning Service 
    </h2>
    <div class="body body-text">
     

      Your shoes aren't just footwear—they're a key part of your personal style and daily routine, telling the story of the places you've been. At UClean, we understand that your favorite pairs deserve more than just a quick wipe. Our Shoe Cleaning Service offers a meticulous clean that goes beyond the ordinary, using specialized techniques to restore the original look and feel of your shoes. 
From the latest trends made of suede, nubuck, calfskin, napa, patent leather, canvas, and other premium materials, our experienced professionals apply the proper techniques and cleaning agents tailored to each unique fabric. Whether it's removing stubborn stains or reviving tired leather, trust us to elevate your shoes to their former glory, all while offering a cost-effective alternative to  traditional methods. UClean is one of the few brands to offer manual as well machine-based waterless cleaning to meet the specific cleaning needs of your favorite kicks!


    </div>
</div>

            <div class="process">
               
                    <h2 class="process-title">OUR SHOE CLEANING PROCESS</h2>
                    <p class="process-description">Every cloth goes through a comprehensive 6 stage process enabling us to deliver on the promise of sparkling & fresh clothes, every time.</p>
                    
                    <div class="process-timeline">
                      <div class="process-step" *ngFor="let step of processSteps; let last = last">

                        <div class="step-icon">
                            <img [src]="step.iconPath" [alt]="step.title" class="custom-icon">
                          </div>
                    
                        <div class="step-content">
                          <h3 class="step-title">{{ step.title }}</h3>
                          <p class="step-description">{{ step.description }}</p>
                        </div>
                        <div class="step-connector" *ngIf="!last"></div>
                      </div>
                    </div>
                 
            </div>
            <div class="book-now">
                <h1>Trust us for keeping your clothes ‘AS GOOD AS NEW’!</h1>
                <button class="btn primary">Book Now</button>
            </div>
           
            <div class="dry-cleaning-container">
                <div class="image-container">
                  <img [src]="shoeCleaningImage" alt="Shoe Cleaning Service">
                </div>
                <div class="content-container">
                  <h2 class="title">OUR SHOE CLEANING PRICES</h2>
                  <p class="description body-text">
                    At UClean, we believe in delivering high quality cleaning services at very affordable prices. With stores across multiple Tier 1, Tier 2 and Tier 3 cities, Quality, Speed and Economical Prices have been our biggest value drivers.
                  </p>
                  <a href='laundry-drycleaning-price-list' class="view-pricing-btn">View Pricing</a>
                </div>
              </div>

            <div class="promise-container">
                <h2 class="promise-title">OUR PROMISE TO YOU</h2>
                <app-our-promise></app-our-promise>
              </div>