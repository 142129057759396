import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { PixelService } from 'ngx-pixel';
import { metaTags } from 'src/assets/meta-contant';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private metaTagService: Meta,
    private pixel: PixelService) { }

  ngOnInit(): void {
    this.pixel.trackCustom('HomePage');
    this.metaTagService.updateTag({ property: 'title', content: metaTags?.home?.title });
    this.metaTagService.updateTag({ property: 'description', content: metaTags?.home?.description });
  }

}
