import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Subscription, timer } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { UtilityService } from 'src/app/services/utility.service';
import { PagesService } from '../pages.service';

@Component({
  selector: 'app-new-login',
  templateUrl: './new-login.component.html',
  styleUrls: ['./new-login.component.scss']
})
export class NewLoginComponent implements OnInit {

  count: number = 0;
  phone: string = "";
  otp: any;
  hiddenOtpBox = true;
  hiddenLoginButton = true;
  hiddenOtpButton = false;
  hiddenReOtpButton = true;
  rcvOtp: any;
  loginError: any = {};
  loginModalshow = true;
  countDown: any = Subscription;
  counter :number = 0;
  tick = 1000;
  displayTimer = true;
  logInErrorMassege = false;
  @ViewChild('otpField', {static: true}) otpElement!: ElementRef;

  constructor(private pageservice: PagesService, private authService: AuthService, public utililtyService: UtilityService, private metaTagService: Meta) { }

  ngOnInit(): void {
  }

  login() {
    if(this.phone.length!= 10 || this.otp.length != 6) {
      return
    }
    //80e44ac193b016c331833af56c5b6318218d3f9da8f925e3a17a3e4d6d4e8028
    // if (this.otp == this.rcvOtp.otp) {
      let body = { "mobile": this.phone, "otp": this.otp };
      this.pageservice.loginData(body).subscribe((data: any) => {
        if(data?.status != 'ERROR') {
          localStorage.setItem('userToken', data.api_token);
          localStorage.setItem('user_data', btoa(JSON.stringify(data)));
          this.authService.checkAuthAndGetUser();
          this.loginModalshow = false;
        } else {
          alert(data?.msg);
        }
      });
      tap((res: any) => {
        return this.pageservice.authUser(res.email, res.localId, res.idToken, +res.expiresIn, res.tokenExpDate)
      })
    // }
    // else {
    //   var msg = { "msg": this.loginError };
    //   this.pageservice.loginData(msg).subscribe((data: any) => {
    //     this.loginError = data;
    //   });
    // }
  }

  sendOTP() {
    if(!this.phone || this.phone.length != 10) {
      return
    }
    var body = { "mobile": this.phone };
    if (this.phone.length == 10) {
      this.pageservice.senOTP(body).subscribe((data: any) => {
        this.rcvOtp = data;
        this.displayTimer = true;
        this.otpCount();
        if(this.count == 1) {
          this.counter = 30;
        }
        if(this.count == 2) {
          this.counter = 60;
          setTimeout(() => {
            if(this.counter == 0) {
              this.hiddenReOtpButton = true;
              this.logInErrorMassege = true;
            }
          }, 1000*60);
        }
        console.log(this.count);
        this.countDown = timer(0, this.tick)
        .pipe(take(this.counter))
        .subscribe(() => {
          --this.counter;
          // console.log(this.counter);
          if (this.counter == 0) {
            this.displayTimer = false;
            this.countDown.unsubscribe();
          }
        });
      });
    }
    else{
      alert ("Please enter 10 digit mobile number")
    }
  }

  enableOtpBox() {
    if (this.phone.length == 10) {
      this.hiddenOtpBox = false;
      this.hiddenLoginButton = false;
      this.hiddenOtpButton = true;
      this.hiddenReOtpButton = false;
      setTimeout(() => {
        document.getElementById('inputOtp')?.focus();
      }, 1000)
    }
  }

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ('00' + minutes).slice(-2) +
      ':' +
      ('00' + Math.floor(value - minutes * 60)).slice(-2)
    );
  }
  

  otpCount(): void{
    this.count++
    }

}
