import { Injectable } from '@angular/core';
import {Location} from '../models/location';
import {Loader} from '@googlemaps/js-api-loader';
import {environment} from '../../environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  location: Location | undefined;
  googleMap: any;
  geoLocation = new Subject<Location>();
  constructor() {
    this.googleMap = new Loader({
      apiKey: environment.BANNER_GOOGLE_API_KEY,
      libraries: ['places']
    });
  }

  initGeoComplete(docId: string, caller: any, callback: any, source: string): void{
    let regionInput = document.getElementById(docId) as HTMLInputElement;
    if(source == undefined || source == null){
      console.error("Source undefined");
    }else{
      console.log(source);  
    }
    this.googleMap.load().then(() => {
      let sessionToken = new google.maps.places.AutocompleteSessionToken();
      let options = {
        fields: ["formatted_address", "geometry", "name"],
        strictBounds: false,
        componentRestrictions: { country: ["IN", "NP"] },
        sessiontoken: sessionToken,
      };
      let autocomplete = new google.maps.places.Autocomplete(regionInput, options);
      autocomplete.addListener("place_changed", () => {

        const place = autocomplete.getPlace();

        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }

        let location = {} as Location;
        location.lat = place.geometry.location.lat();
        location.lng = place.geometry.location.lng();
        location.name = place.formatted_address as string;


        let geocoder = new google.maps.Geocoder();
        let latlng = new google.maps.LatLng(location.lat, location.lng);
        geocoder.geocode({ 'location': latlng }, (results, status) => {
          if (results != null && results != undefined && status == "OK") {
            location.pincode = this.getPincodeFromAddress(results[0]);
            location.city = this.getCityFromAddress(results[0]);
            console.log(location);
            this.location = location;

            callback(caller, location);
            this.geoLocation.next(location);
          } else {
            console.log(status);
          }
        })
      });
    });
  }

  getPincodeFromAddress(address_result: any): any{
    let components = address_result.address_components;
    for (let i = 0; i < components.length; i++) {
      let types = components[i].types;
      if(types.length == 1 && types[0] == "postal_code"){
        return components[i].short_name;
      }
    }
  }

  getCityFromAddress(address_result: any): any{
    let components = address_result.address_components;
    for (let i = 0; i < components.length; i++) {
      let types = components[i].types;
      // console.log(types, components[i].short_name);
      if(types.length == 2 && types.includes("locality") && types.includes("political")){
        return components[i].short_name;
      }
    }
  }
}
