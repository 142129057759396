import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PixelService } from 'ngx-pixel';
import { metaTags } from 'src/assets/meta-contant';
import { LOW_INVESTMENT_LIST, SUPPORT_LIST } from './franchise-constant';

@Component({
  selector: 'app-franchise-v2',
  templateUrl: './franchise-v2.component.html',
  styleUrls: ['./franchise-v2.component.scss']
})
export class FranchiseV2Component implements OnInit {

  public safeVideoUrl: any
  public lowInvestmentList = LOW_INVESTMENT_LIST;
  public supportFeatureList = SUPPORT_LIST;

  constructor(
    private pixel: PixelService,
    private _santizer: DomSanitizer,
    private router: Router,
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    this.safeVideoUrl = this._santizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/UTU2ecg0dx8');
    this.pixel.trackCustom('ApplyForFranchisePage');
    this.metaTagService.updateTag({ property: 'title', content: metaTags?.['apply-for-franchise']?.title });
    this.metaTagService.updateTag({ property: 'description', content: metaTags?.['apply-for-franchise']?.description });
  }

  moveToContactForm() {
    // setTimeout(() => {
    // const elmntToView = document.getElementById("franchise-form-v1") as HTMLElement;
    // elmntToView && elmntToView.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"}); 
    // const elmntToView = document.getElementById("franchise-page") as HTMLElement;
    // elmntToView && elmntToView.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"}); 
    // }, 500)
    window.scrollTo(0, document.body.scrollHeight);
  }

  onFranchiseApplied(event: any) {
    if (event?.status?.toLowerCase() === 'success') {
      this.router.navigate(['apply-for-franchise/success'],
        {
          queryParams: event
        })
    }
  }
}
