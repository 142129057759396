import { Component, Input, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-auth-wrapper',
  templateUrl: './auth-wrapper.component.html',
  styleUrls: ['./auth-wrapper.component.scss']
})
export class AuthWrapperComponent implements OnInit {

  @Input() isLoading = false;
  @Input() mainBannerText = 'Let <b>UClean</b> take care of your first impressions';

  constructor(private metaTagService: Meta) { }

  ngOnInit(): void {
  }

}
