import { Injectable } from '@angular/core';
import { Location } from '../models/location';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from '../../environments/environment';
import { LocationModel } from '../models/leaflet';

@Injectable({
  providedIn: 'root'
})
export class PositionService {
  currentLocation = {} as Location;
  location = {} as Location;
  loader: any;
  findPincode: any = [];
  isLocationFetched = false;
  autoFetchedLocation: any = {};

  // geoLocation = new Subject<Location>();
  constructor() {
    this.loader = new Loader({
      apiKey: environment.BANNER_GOOGLE_API_KEY,
      libraries: ['places']
    });
  }

  findCurrentLocation(onLocationSuccess: any, onLocationError: any) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          this.isLocationFetched = true;

          this.autoFetchedLocation = {lat: position.coords.latitude, lng: position.coords.longitude, event: position};
          onLocationSuccess(this.autoFetchedLocation);
        }
      },
        (error) => {
          if (error.code == error.PERMISSION_DENIED) {
            onLocationError('Location permission denied. Kindly allow it from browser settings.');
          } else {
            onLocationError(error);
          }
        },
        {
          maximumAge: Infinity
      });
    } else {
      onLocationError('Your browser doesn\'t support geolocation.');
    }
  }

  initMap(caller: any, callback: any): void {
    this.loader.load().then(() => {
      let map = new google.maps.Map(document.getElementById('currentLocation') as HTMLElement, {
        center: { lat: 20.5937, lng: 78.9629 },
        zoom: 5,
      });
      let infoWindow = (new google.maps.InfoWindow()) as google.maps.InfoWindow;

      const locationButton = document.createElement('button');

      locationButton.innerHTML = '<i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp;Use current location';
      locationButton.classList.add('use-location-btn');
      locationButton.classList.add('btn');
      locationButton.classList.add('btn-success');

      map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(locationButton);

      locationButton.addEventListener('click', () => {
        // Try HTML5 geolocation.
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              this.currentLocation.lat = pos.lat;
              this.currentLocation.lng = pos.lng;
              console.log(position);

              infoWindow.setPosition(pos);
              infoWindow.setContent('Location found.');
              infoWindow.open(map);
              map.setCenter(pos);
              map.setZoom(16);
              let geocoder = new google.maps.Geocoder();
              let latlng = new google.maps.LatLng(this.currentLocation.lat, this.currentLocation.lng);
              geocoder.geocode({ 'location': latlng }, (results, status) => {
                console.log("test");
                console.log(results);
                if (results != null && results != undefined && status == "OK") {
                  this.currentLocation.name = results[0].formatted_address;
                  console.log(this.currentLocation);
                  callback(caller, this.currentLocation);
                } else {
                  console.log(status);
                }
              })

            },
            () => {
              this.handleLocationError(true, infoWindow, map.getCenter()!, map);
            }
          );
        } else {
          // Browser doesn't support Geolocation
          // this.handleLocationError(false, infoWindow, this.map.getCenter()!);
        }
      });
    });
  }

  handleLocationError(
    browserHasGeolocation: boolean,
    infoWindow: google.maps.InfoWindow,
    pos: google.maps.LatLng,
    map: google.maps.Map
  ): any {
    infoWindow.setPosition(pos);
    infoWindow.setContent(
      browserHasGeolocation
        ? 'Location permission denied.'
        : 'Your browser doesn\'t support geolocation.'
    );
    infoWindow.open(map);
    alert("Location permission denied. or 'Your browser doesn\'t support geolocation.\n \n Please enable your current location")
  }

  setLocation(): void {
    this.location = this.currentLocation;
  }

  currentLocations(caller: any, callback: any) {
    let map = new google.maps.Map(document.getElementById('currentLocation') as HTMLElement, {
      center: { lat: 20.5937, lng: 78.9629 },
      zoom: 5,
    });
    let infoWindow = (new google.maps.InfoWindow()) as google.maps.InfoWindow;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          console.log(position.coords);
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.currentLocation.lat = pos.lat;
          this.currentLocation.lng = pos.lng;

          infoWindow.setPosition(pos);
          infoWindow.setContent('Location found.');
          infoWindow.open(map);
          map.setCenter(pos);
          map.setZoom(16);
          let geocoder = new google.maps.Geocoder();
          let latlng = new google.maps.LatLng(this.currentLocation.lat, this.currentLocation.lng);
          geocoder.geocode({ 'location': latlng }, (results, status) => {
            if (results != null && results != undefined && status == "OK") {
              this.currentLocation.name = results[0].formatted_address;
              this.currentLocation.pincode = this.getPincodeFromAddress(results[0]);
              this.currentLocation.city = this.getCityFromAddress(results[0]);
              this.setLocation();
              callback(caller, this.currentLocation);
            } else {
              console.log(status);
            }
          })

        },
        () => {
          this.handleLocationError(true, infoWindow, map.getCenter()!, map);
        }, {enableHighAccuracy: true}
      );

    } else {
      // Browser doesn't support Geolocation
      // this.handleLocationError(false, infoWindow, this.map.getCenter()!);
    }
  }

  getPincodeFromAddress(address_result: any): any{
    let components = address_result.address_components;
    for (let i = 0; i < components.length; i++) {
      let types = components[i].types;
      if(types.length == 1 && types[0] == "postal_code"){
        return components[i].short_name;
      }
    }
  }

  getCityFromAddress(address_result: any): any{
    let components = address_result.address_components;
    for (let i = 0; i < components.length; i++) {
      let types = components[i].types;
      if(types.length == 2 && types.includes("locality") && types.includes("political")){
        return components[i].short_name;
      }
    }
  }

  getInfoFromLatLng(location: LocationModel , callback: any) {
    let geocoder = new google.maps.Geocoder();
    let latlng = new google.maps.LatLng(location.lat, location.lng);
    geocoder.geocode({ 'location': latlng }, (results, status) => {
      if (results != null && results != undefined && status == "OK") {
        const extractedDetails = {
          ...location,
          name: results[0].formatted_address,
          pincode: this.getPincodeFromAddress(results[0]),
          city: this.getCityFromAddress(results[0])
        }
        console.log(results[0]);
        callback(extractedDetails);
      } else {
        console.log('Information Not Found from Lat Lng by Google API ');
      }
    })
  }
}
