  
  <div *ngIf="showLoginModal" class="modal-overlay">
   
    <div class="modal-content">
      <h2>LOGIN !</h2>
      <p>please login to proceed to order</p>
      
      <div class="modal-actions">
        <button type="button" (click)="closeLoginModal()">Cancel</button>
        <button type="button" (click)="redirectToLogin()">Proceed to Login</button>
      </div>
    </div>
  </div>