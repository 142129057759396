import { Component, Input, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.scss']
})
export class BlogCardComponent implements OnInit {

  @Input() blogImageUrl: any;
  @Input() blogHeaderText: any;
  @Input() blogSubHeaderText: any;
  @Input() blogDescriptionText: any;
  @Input() learnMoreLink: any;

  constructor(
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
  }

}
