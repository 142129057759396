<div class="franchise-page-v2" id="franchise-page">
    <section class="inner_banner_section franchise_page_bannerv1">
        <div class="container">
            <div class="row">
                <div class="inner_banner_block">
                    <div class="inner_banner_tilte">
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section class="franchise-form-bannerv1" id="franchise-form-v1">
        <app-franchise-form (onFranchiseApplied)="onFranchiseApplied($event)"></app-franchise-form>
    </section>
    
    <section class="franchise-presence-section">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="presence-box">
                        <img src="//assets.ucleanlaundry.com/assets/images/franchise/presence-1.svg">
                        <!-- <img src="//assets.ucleanlaundry.com/assets/images/franchise/presence-sub-1.svg"> -->
                        <p class="svg"><span style="font-size: 23px;"><b>500</b>+</span> STORE's</p>

                    </div>
                </div>
                <div class="col-md-4">
                    <div class="presence-box">
                        <img src="//assets.ucleanlaundry.com/assets/images/franchise/presence-2.svg">
                        <!-- <img src="//assets.ucleanlaundry.com/assets/images/franchise/presence-sub-2.svg"> -->
                        <p class="svg"><span style="font-size: 23px;"><b>150</b>+</span> CITIES</p>

                    </div>
                </div>
                <div class="col-md-4">
                    <div class="presence-box no-border">
                        <img src="//assets.ucleanlaundry.com/assets/images/franchise/presence-3.svg">
                        <!-- <img src="//assets.ucleanlaundry.com/assets/images/franchise/presence-sub-3.svg"> -->
                        <p class="svg"><span style="font-size: 23px;"><b>2 M</b></span> UNIQUE<br style="margin-left: 2px;"> USERS</p>

                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section class="franchise-laundry-section section-end-border">
        <img class="section-vector-img1" src="//assets.ucleanlaundry.com/assets/images/franchise/laundry-business/Vector.svg" alt="vector-img">
        <img class="section-vector-img2" src="//assets.ucleanlaundry.com/assets/images/franchise/laundry-business/Vector1.svg" alt="vector-img">
        <div class="full-width-container">
            <div class="row">
                <div class="col-md-12">
                    <div class="main-section-header">
                        <p>LAUNDRY BUSINESS POTENTIAL</p>
                    </div>
                </div>
                <div class="col-md-12 laundry-col-box">
                    <div class="laundry-section-box">
                        <div class="laundry-description-section">
                            <p>India’s Laundry Market is estimated to be a staggering $35.83 Bn, with only 4.23% share of organized players</p>
                            <ul>
                                <li>Basic Human Need</li>
                                <li>Everyone is a <b>Potential Customer</b> - Even a day old baby</li>
                                <li><b>Very High</b> Purchase Frequency / Subscription Model</li>
                                <li>Outsourcing Laundry <b>Saves Money & Time</b></li>
                                <li><b>Garment industry is moving from fast fashion to slow fashion</b>. This means more investment in cleaning & upkeep of the garment.</li>
                            </ul>
                        </div>
                        <div class="laundry-image-section">
                            <img src="//assets.ucleanlaundry.com/assets/images/franchise/laundry-business/main-img.svg" alt="laundry-business">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section class="franchise-largest-industry-section section-end-border">
        <div class="full-width-container">
            <div class="row">
                <div class="col-md-12">
                    <div class="main-section-header">
                        <p>WHY INVEST WITH US</p>
                    </div>
                    <div class="section-header">
                        <span class="section-number">1</span>
                        <p>It Is INDIA'S 7th Largest Industry!</p>
                    </div>
                </div>
                <div class="col-md-12 industry-col-box">
                    <div class="industry-section-box">
                        <div class="industry-description-section">
                            <p>Not many people know that size of the Laundry industry is bigger than the size of popular industries like the beauty & wellness industry, the fast food industry (including major food chains like Dominos), and the diagnostic labs industry.</p>
                            <p>You will be surprised to know that <b>96% of the laundry industry is unorganised</b>.</p>
                            <p>But now with huge investments coming into the industry, it is now expected to explode just like food delivery (Swiggy, Zomato), UPI payments (PayTM, Phonepe), and online cabs (Ola, Uber).</p>
                        </div>
                        <div class="industry-image-section">
                            <img src="//assets.ucleanlaundry.com/assets/images/franchise/largest-industry/largest-industry.svg" alt="industry-table">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img class="section-vector-img" src="//assets.ucleanlaundry.com/assets/images/franchise/largest-industry/Vector2.svg" alt="vector-img">
    </section>
    
    <section class="franchise-business-section section-end-border">
        <div class="full-width-container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-header">
                        <span class="section-number">2</span>
                        <p>Laundry Is INDIA'S Most Profitable Franchise Business</p>
                    </div>
                </div>
                <div class="col-md-12 business-col-box">
                    <div class="business-section-box">
                        <div class="business-table-section">
                            <img src="//assets.ucleanlaundry.com/assets/images/franchise/business-section/business-img.svg" alt="business-table">
                        </div>
                        <div class="business-description-section">
                            <p>The Laundry & Dry cleaning franchise business offers a high scope of growth with <b>High ROI, Low Risk and Zero Hassles</b>. which makes Laundry Business the best investment</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section class="franchise-low-investment-section section-end-border">
        <div class="full-width-container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-header">
                        <span class="section-number">3</span>
                        <p>Low-Investment & Small Format Model</p>
                    </div>
                </div>
                <div class="col-md-12 low-investment-box" *ngFor="let lowInvestmentObj of lowInvestmentList;">
                    <div class="low-investment-box-content">
                        <div class="image-section">
                            <img [src]="lowInvestmentObj?.imagePath" alt="investment-img">
                        </div>
                        <div class="description-section">
                            <p class="headertext">{{ lowInvestmentObj?.headerText }}</p>
                            <p class="subheadertext" *ngIf="lowInvestmentObj?.subHeaderText">{{ lowInvestmentObj?.subHeaderText }}</p>
                            <p class="description">{{ lowInvestmentObj?.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section class="franchise-support-section section-end-border">
        <div class="full-width-container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-header">
                        <span class="section-number">4</span>
                        <p>Our 24x7 Support System</p>
                    </div>
                </div>
                <div class="col-md-12 support-box" *ngFor="let supportObj of supportFeatureList;">
                    <div class="support-box-content">
                        <p class="support-box-headertext">{{ supportObj?.headerText }}</p>
                        <p class="support-box-description" [innerHTML]="supportObj?.description"></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section class="franchise-video-section">
        <div class="full-width-container">
            <div class="row">
                <div class="col-md-12">
                    <div class="main-section-header center-text footer-header">
                        <p>Watch our videos to learn more</p>
                    </div>
                </div>
                <div class="col-md-12 video-section-box">
                    <div class="content-box">
                        <div class="description-section">
                            <p class="description-header">Become a UClean's Franchise Owner</p>
                            <p>
                                At UClean, we understand how difficult it is to build a business from ground up. This is why we have built Industry's best Franchise Support Program that takes care of everything you need to run a successful UClean store. We are committed to your growth and will leave no stone unturned to make this happen.
                            </p>
                        </div>
                        <div class="image-section">
                            <iframe [src]="safeVideoUrl" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <img class="franchise-contact-btn" src="//assets.ucleanlaundry.com/assets/images/franchise/contact-btn.svg" alt="contact-btn" (click)="moveToContactForm()">
</div>
