<section class="inner_banner_section">
    <div class="container">
        <div class="row">
            <div class="inner_banner_block">
                <div class="inner_banner_tilte">
                    <h1>Your order is confrimed</h1>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="media_page_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="confirm_pop_title_bar">
                        <img src="//assets.ucleanlaundry.com/assets/images/confirm_pop_icon.png">
                        <h1>Your Order is Confirmed</h1>
                        <p>Thank you for choosing UClean as your Laundry Partner. Your order is placed with
                            <span *ngIf="store.virtual_store == 0">{{store.owner_name}}</span>
                            <span *ngIf="store.virtual_store == 1">Uclean</span>
                            <br>
                            <b>The store team will contact you shortly! <br>Thank You</b>
                        </p>
                    </div>
                    <div class="text-center" style="width: auto; margin: auto;">
                        <button type="button" (click)="takeToBuildOrder()" class="btn btn-success"
                            style="margin-right: 10%;">Order Again</button>
                        <button type="button" routerLink="/" class="btn btn-success">Back to home</button>
                    </div>
                    <div class="pop_eft">
                        <ul>
                            <li><img src="//assets.ucleanlaundry.com/assets/images/icon_4.png" alt=" Laundry wash and iron"></li>
                            <li><img src="//assets.ucleanlaundry.com/assets/images/icon_3.png" alt=" laundry wash and fold"></li>
                            <li><img src="//assets.ucleanlaundry.com/assets/images/icon_1.png" alt="Laundry "></li>
                            <li><img src="//assets.ucleanlaundry.com/assets/images/icon_4.png" alt=" Laundry wash and iron"></li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    
</section>