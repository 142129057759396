import {Component, OnDestroy, OnInit} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import {AuthService} from './services/auth.service';
import { DeviceService } from './services/device.service';
import {PositionService} from './services/position.service';
import { RouterInterceptorService } from './services/router-interceptor.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'uclean-frontend';
  lat: any;
  lng: any;
  private ngSubscriptionList: any[] = [];

  constructor(
    public authService: AuthService,
    public router:Router,
    private deviceService: DeviceService,
    public positionService: PositionService,
    private _routerInterceptor: RouterInterceptorService){
    window.addEventListener('scroll', this.onScroll.bind(this), true);
  }

  onScroll = (event: any): void => {
    let doc = document.documentElement;
    let left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
    let top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    this.deviceService.scrollPositionChange.next({left: Math.ceil(left), top: Math.ceil(top)});    
  };

  ngOnInit(): void {
    // this.router.navigate(['']);   
    this.authService.checkAuthAndGetUser();
    this.setBrowserBackSubscription();
  }

  setBrowserBackSubscription() {
    this.ngSubscriptionList.push(this._routerInterceptor.routerHistorySubject.subscribe((url: any) => {
      if (url?.current === '/' && ![undefined, '', '/'].includes(url?.previous)) {
        window.location.reload();
      }
    }));
  }

  ngOnDestroy(): void {
    this.ngSubscriptionList.forEach((subscription: any) => {
      subscription && subscription.unsubscribe();
    })
  }
}

