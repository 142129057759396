<!-- inner_banner_section start-->

<section class="inner_banner_section blog_page_banner">
    <div class="container">
        <div class="row">
            <div class="inner_banner_block">
                <div class="inner_banner_tilte">
                    <h1>Blog</h1>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- inner_banner_section end-->


<!-- blog_page_section start-->

<section class="blog_page_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="col-lg-4 col-md-4 col-sm-5 col-xs-12 col-lg-pull-8 col-md-pull-8 col-sm-pull-7 left_sidebar_block mob-search">
                    <div class="row">
                        <div class="left_sidebar ">
                            <h4>Search</h4>
                            <div class="search_bar">
                                <form action="" class="search-form">
                                    <div class="form-group has-feedback">
                                        <input type="text" class="form-control" name="search" [(ngModel)]="searchBlogs"
                                            id="search" placeholder="search">
                                        <span class="glyphicon glyphicon-search form-control-feedback"></span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-7 col-xs-12 col-lg-push-4 col-md-push-4 col-sm-push-5">
                    <div class="row"
                        *ngFor="let blog of mainBlog | filter:searchBlogs | paginate: { itemsPerPage: 5, currentPage: p }">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="blog_post_block">
                                <div class="post_image">
                                    <img [routerLink]="['/blog', blog.slug]" src="{{blog.full_url}}">
                                </div>
                                <div class="post_content">
                                    <span>{{blog.created_at}}</span>
                                    <h4>{{blog.title}}</h4>
                                    <p>{{blog.description}}</p>
                                    <div class="post_meta">
                                        <a [routerLink]="['/blog', blog.slug]">Learn More</a>
                                        <span class="pull-right">
                                            <!-- <ul class="fa_link">
                                                <li><i class="fa fa-share-alt" aria-hidden="true"></i>Share</li>
                                                <li><a href="" target="_blank"><i class="fa fa-facebook"
                                                            aria-hidden="true"></i></a></li>
                                                <li><a href="" target="_blank"><i class="fa fa-instagram"
                                                            aria-hidden="true"></i></a></li>
                                            </ul> -->
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="navigation_blog">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <div id="pagination" (click)="scrollToTop()">
                                                <pagination-controls (pageChange)="p = $event" class="float-right">
                                                </pagination-controls>
                                            </div>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-5 col-xs-12 col-lg-pull-8 col-md-pull-8 col-sm-pull-7 left_sidebar_block">
                    <div class="row web-search">
                        <div class="left_sidebar">
                            <h4>Search</h4>
                            <div class="search_bar">
                                <form action="" class="search-form">
                                    <div class="form-group has-feedback">
                                        <input type="text" class="form-control" name="search" [(ngModel)]="searchBlogs"
                                            id="search" placeholder="search">
                                        <span class="glyphicon glyphicon-search form-control-feedback"></span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="left_sidebar recent_post_sidebar">
                            <h4>Recent Posts</h4>
                            <div class="cent_post" *ngFor="let blog of recentBlogs">
                                <li [routerLink]="['blog', blog.slug]">
                                    <div class="post_thumb">
                                        <img src="{{blog.full_url}}" alt="" title="">
                                    </div>
                                    <div class="post_details">
                                        <div class="post_description">
                                            <a>{{blog.description}}</a>
                                        </div>
                                        <div class="date_time">
                                            <p><i class="fa fa-calendar" aria-hidden="true"></i>{{blog.created_at}}</p>
                                        </div>
                                    </div>
                                </li>
                            </div>
                        </div>
                    </div>


                    <!-- <div class="row">
                   <div class="left_sidebar cate_sidebar">
                       <h4>categories</h4>
                       <div class="categories_bar">
                           <li><a href="">Lorem Ipsum is simplydummy text of the printing </a></li>
                           <li><a href="">Lorem Ipsum is simplydummy text of the printing </a></li>
                           <li><a href="">Lorem Ipsum is simplydummy text of the printing </a></li>
                           <li><a href="">Lorem Ipsum is simplydummy text of the printing </a></li>

                       </div>
                   </div>
               </div> -->

                    <!-- <div class="row">
                   <div class="left_sidebar tag_sidebar">
                       <h4>Tags</h4>
                       <div class="tags_bar">
                           <a href="">Home Cleaning</a>
                           <a href="">Fabric Care</a>
                           <a href="">Shoe Cleaning</a>
                           <a href="">Washroom Cleaning</a>
                           <a href="">Bag Cleaning</a>
                           <a href="">Car Cleaning</a>
                       </div>
                   </div>
               </div> -->

                    <!-- <div class="row">
                   <div class="left_sidebar arch_sidebar">
                       <h4>Archives</h4>
                       <div class="arch_bar">
                           <li><a href="">November 2020</a></li>
                           <li><a href="">October 2020 </a></li>
                           <li><a href="">September 2020 </a></li>
                           <li><a href="">August 2020</a></li>
                           <li><a href="">July 2020</a></li>
                       </div>
                   </div>
               </div> -->

                    <div class="row">
                        <div class="left_sidebar">
                            <h4>Follow us</h4>
                            <div class="follow_bar">
                                <ul>
                                <li><a href="https://www.facebook.com/ucleanindia/" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li><a href="https://twitter.com/ucleanindia" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/uclean/" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                                </li>
                                <li><a href="https://www.youtube.com/channel/UCCB8jfgaSt-UJwALz9QBHRg" target="_blank"><i class="fa fa-youtube-play" aria-hidden="true"></i></a>
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!--<div class="row">
                   <div class="news_letter news_sidebar">
                       <h4>Newsletter</h4>
                       <form action="" method="post">
                           <input type="text" id="" name="" placeholder="Enter Your Email..">
                           <br>
                           <br>
                           <input type="submit" value="Subscribe" class="btn btn-large">
                       </form>
                   </div>
               </div>-->

                </div>
            </div>
        </div>
    </div>
</section>

<!-- blog_page_section end-->
