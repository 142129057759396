<section class="screen login-page">
    <app-auth-wrapper [isLoading]="isLoading">
        <div class="form-wrapper">
            <div class="form-header-wrapper">
                <p>Feel free to login</p>
                <span class="line"></span>
            </div>
            <div class="form-sub-header">
                <span>Don't have a account?</span><a href="javascript:void(0)">&nbsp;<b (click)="goToSignupPage()">Sign
                        up</b></a>
            </div>
            <ng-container *ngIf="false">
                <div class="form-social-media-section">
                    <div class="social-btn" matRipple><img src="assets/images/login/google.svg">Login with Google</div>
                    <div class="social-btn" matRipple><img src="assets/images/login/fb.svg">Login with Facebook</div>
                </div>
                <div class="section-partition">
                    <span class="line"></span>
                    <span class="partition-text">OR</span>
                    <span class="line"></span>
                </div>
            </ng-container>
            <div class="input-form mt-15">
                <form [formGroup]="loginForm">
                    <input class="full-width-input"
                        [ngClass]="{'invalid': loginForm?.controls?.phone?.dirty && !loginForm?.controls?.phone?.valid}"
                        numberOnly maxlength="10" placeholder="Phone no" type="tel" formControlName="phone"
                        formControlName="phone">
                </form>
                <button class="primary-btn" matRipple [ngClass]="{'blur': loginForm.invalid}"
                    [disabled]="loginForm.invalid" (click)="sendOtpForVerification()">Continue</button>
            </div>
            <div class="form-sub-header">
                <span class="api-error" [@enterAnimation] *ngIf="showError">{{ errorMsg }}</span>
            </div>
        </div>
    </app-auth-wrapper>
</section>