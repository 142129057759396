import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PagesService } from '../pages.service';
import { Title, Meta } from '@angular/platform-browser';
import { PositionService } from 'src/app/services/position.service';
import { CustomLocationInfoModel } from 'src/app/models/leaflet';
import { Subscription, EMPTY, forkJoin } from 'rxjs';
import { tap, filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-store-detail',
  templateUrl: './store-detail.component.html',
  styleUrls: ['./store-detail.component.scss']
})
export class StoreDetailComponent implements OnInit, OnDestroy {
  private readonly STORAGE_KEY = 'store_metatags';

  storeCode: string | null = null;
  metatags: any = {};
  storeData: any = {}; // Will hold the store data from API
  private routeSubscription: Subscription | undefined;
  private metaTagsSubscription: Subscription | undefined;
  private storeDataSubscription: Subscription | undefined;
  footerHeading1: any;
  footerText1: any;
  footerHeading2: any;
  footerText2: any;

  constructor(
    private pagesService: PagesService,
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private positionService: PositionService
  ) { }

  storesDtl: any = {};
  store: any;
  detail: any;
  lat: any;
  long: any;
  timing: any = {};

  title: any;
  url: any;
  
  ngOnInit(): void {
    // Get the store code from route params
    this.routeSubscription = this.route.params
      .pipe(
        tap((params) => {
          this.storeCode = params['id'];
          console.log('Store ID:', this.storeCode);
        }),
        filter(() => !!this.storeCode)
      )
      .subscribe(() => {
        // Now that we have a valid store code, make both API calls independently
        
        // API call 1: Get store data
        this.pagesService.getStoreById(this.storeCode).subscribe(storeData => {
          if (storeData) {
            this.storeData = storeData;
            this.lat = this.storeData.latitude;
            this.long = this.storeData.longitude;
            // Any other store properties you need
          }
        });
        
        // API call 2: Get metatags
        this.pagesService.getMetatags(this.storeCode).subscribe(metatags => {
          if (metatags) {
            this.metatags = metatags;
            
            // Save the metatags to localStorage
            this.saveMetatags(this.metatags);
            
            // Update the title if available
            if (this.metatags.meta_title) {
              this.title = this.metatags.meta_title;
              this.titleService.setTitle(this.title);
              this.meta.updateTag({
                name: this.metatags.meta_title,
                keywords: this.metatags.keywords
              });
            }
            
            // Footer data
            this.footerHeading1 = this.metatags.footer_h_1;
            this.footerText1 = this.metatags.footer_text_1;
            this.footerHeading2 = this.metatags.footer_h_2;
            this.footerText2 = this.metatags.footer_text_2;
          }
        });
      });
  }

  private saveMetatags(metatags: any): void {
    try {
      localStorage.setItem(this.STORAGE_KEY, JSON.stringify(metatags));
    } catch (error) {
      console.error('Error saving metatags to localStorage:', error);
    }
  }

  private loadSavedMetatags(): void {
    try {
      const savedMetatags = localStorage.getItem(this.STORAGE_KEY);
      if (savedMetatags) {
        this.metatags = JSON.parse(savedMetatags);
        if (this.metatags.meta_title) {
          this.title = this.metatags.meta_title;
          this.titleService.setTitle(this.title);
          this.meta.updateTag({
            name: this.metatags.meta_title,
            keywords: this.metatags.keywords
          });
        }
        
        // Initialize footer data from cached metatags
        this.footerHeading1 = this.metatags.footer_h_1;
        this.footerText1 = this.metatags.footer_text_1;
        this.footerHeading2 = this.metatags.footer_h_2;
        this.footerText2 = this.metatags.footer_text_2;
      }
    } catch (error) {
      console.error('Error loading metatags from localStorage:', error);
    }
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) this.routeSubscription.unsubscribe();
    if (this.metaTagsSubscription) this.metaTagsSubscription.unsubscribe();
    if (this.storeDataSubscription) this.storeDataSubscription.unsubscribe();
  }

  public loadJsFile(url: string) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  onOrderNow(storesDtl: any) {
    const storePosition = { lat: this.storeData?.latitude, lng: this.storeData.longitude };
    this.positionService.getInfoFromLatLng(storePosition, (e: CustomLocationInfoModel) => {
      this.router.navigate(['build-your-order'], {
        queryParams: {
          lat: storePosition?.lat,
          lng: storePosition?.lng,
          locationName: e?.name,
          pincode: e?.pincode,
          city: e?.city
        }
      });
    });
  }
}