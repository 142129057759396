import { Component, OnInit } from '@angular/core';
import { PagesService } from '../pages.service';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss']
})
export class CitiesComponent implements OnInit {

  constructor(
    private metaTagService: Meta,
    private pagesService: PagesService, private snap: ActivatedRoute, private route: Router) {
      this.pagesService.currentStoreName$.subscribe(storeName => {
      });

      this.pagesService.currentMetatags$.subscribe(metatags => {
      });
    }
  storesList: { storecode: string; name: string; address: string; latitude: number; longitude: number; mobile: number; }[] | any;
  citiesList: any;
  searchStoreAdd: any;
  loader: any;
  map: any;
  marker: any;
  city_id: any;
  directonUrl = "https://www.google.com/maps/dir//";
  storeLat: number = 0;
  storeLng: number = 0;
  finalDirectionURL: any;

  metatagsUrl: any;

  ngOnInit(): void {
    // this.loader = new Loader({
    //   apiKey: environment.GOOGLE_API_KEY,
    //   libraries: ['places']
    // });

    this.snap.params.subscribe(data => {
      console.log("data mine", data)
      this.city_id = data.id;
    })
    this.pagesService.getStores().subscribe((data: any) => {
      this.storesList = data.stores.filter((ele: any) => ele.city_id === Number(this.city_id));
      this.loadMap();
    });

    this.pagesService.cityList().subscribe((data: any) => {
      this.citiesList = data.cities;
    });
  }

  loadMap(): void {
    let pos = { lat: parseFloat(this.storesList[0].latitude), lng: parseFloat(this.storesList[0].longitude) };
    let that = this;
    this.loader?.load().then(() => {
      that.map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
        center: pos,
        zoom: 12,
      })
      for (let store of that.storesList) {
        let lat = Number(store.latitude);
        let lng = Number(store.longitude);
        that.marker = new google.maps.Marker({
          position: { lat: lat, lng: lng },
          map: that.map,
        });
      }
    })
  }

  getStoresDtl(store: any) {
    // Set current store name in service
    this.pagesService.setCurrentStoreName(store.name);
    this.pagesService.storeDtlstoreCode = store.storecode;
    this.pagesService.storeDtlCityName = store.city_name;
    this.pagesService.storeDtlLocalityName = store.local_area_name_for_seo;
    this.pagesService.setCurrentStore(store);
    
   
    this.pagesService.storeDetail(store.storecode).subscribe((data: any) => {
      this.metatagsUrl = data.metatag;
      this.pagesService.setCurrentMetatags(data.metatag);
    });
    this.route.navigate([`storeaddress/${store.id}`]);
  }


  goToDirection(store: any) {
    this.storeLat = parseFloat(store.latitude);
    this.storeLng = parseFloat(store.longitude);
    this.finalDirectionURL = this.directonUrl + this.storeLat + ',' + this.storeLng + '/@' + this.storeLat + ',' + this.storeLng + 'z';
    return this.finalDirectionURL;
  }
}