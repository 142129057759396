import { Component, OnInit } from '@angular/core';
import { PagesService } from '../pages.service';
import { Loader } from '@googlemaps/js-api-loader';
import {environment} from '../../../../environments/environment';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { PixelService } from 'ngx-pixel';
import { metaTags } from 'src/assets/meta-contant';
@Component({
  selector: 'app-locate-us',
  templateUrl: './locate-us.component.html',
  styleUrls: ['./locate-us.component.scss']
})
export class LocateUsComponent implements OnInit {
  constructor(
    private router: Router,
    private pixel: PixelService,
    private pagesService:PagesService,private route: Router, private metaTagService: Meta) {}
  storesList: { storecode: string; name: string; address: string; latitude: number; longitude: number; mobile: number; }[] | any;
  citiesList:any;
  searchStoreAdd: any;
  selectedStore: { storecode: string; name: string; address: string; latitude: number; longitude: number; mobile: number; } | any;
  loader: any;
  map: any;
  marker: any;
  directonUrl = "https://www.google.com/maps/dir//";
  storeLat: number = 0;
  storeLng: number = 0;
  finalDirectionURL:any;
  metatagsUrl: any;

  ngOnInit(): void {
    if(this.router.url.includes('uclean-store-near-you')) {
      this.pixel.trackCustom('UcleanStoreNearYouPage');
      this.metaTagService.updateTag({ property: 'title', content: metaTags?.['uclean-store-near-you']?.title });
      this.metaTagService.updateTag({ property: 'description', content: metaTags?.['uclean-store-near-you']?.description });
    }
    this.pagesService.getStores().subscribe((data : any)=>{
      this.storesList = data.stores;
      this.selectedStore = this.storesList[0];
      this.loadMap();
    });

    this.pagesService.cityList().subscribe((data : any)=>{
      this.citiesList = data.cities;
    });

    this.loader = new Loader({
      apiKey: environment.GOOGLE_API_KEY,
      libraries: ['places']
    });
  }

  loadMap(): void{
    let pos = {lat: parseFloat(this.selectedStore.latitude), lng: parseFloat(this.selectedStore.longitude)};
    let that = this;

    this.loader.load().then(() => {
      that.map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
        center: pos,
        zoom: 16,
      })

      that.marker = new google.maps.Marker({
        position: pos,
        map: that.map,
      });

      that.initGeoComplete();
    })
  }


  initGeoComplete(): void{
    let regionInput = document.getElementById("searchRegion") as HTMLInputElement;

    let options = {
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
      types: ["(regions)"],
    };
    let autocomplete = new google.maps.places.Autocomplete(regionInput, options);

    autocomplete.addListener("place_changed", () => {

      const place = autocomplete.getPlace();

      if (!place.geometry || !place.geometry.location) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }
      console.log(place.geometry.location.lat());
      console.log(place.geometry.location.lng());

      // TODO add the code for searching stores based on the location selected by the user - @sachin
      // If the place has a geometry, then present it on a map.
    });
  }

  setLocation(): void{
    let pos = {lat: parseFloat(this.selectedStore.latitude), lng: parseFloat(this.selectedStore.longitude)};
    console.log(pos);
    this.map.setCenter(pos);
    this.marker.setPosition(pos);
  }

  chooseSelectedStore(storecode: any) {
    this.selectedStore = this.storesList.find( (element: any) => element.storecode == storecode );
    this.setLocation();
  }

  getStoresDtl(store: any) {
    this.pagesService.setCurrentStoreName(store.name);
    this.pagesService.storeDtlstoreCode = store.storecode;
    this.pagesService.storeDtlCityName = store.city_name;
    this.pagesService.storeDtlLocalityName = store.local_area_name_for_seo;
    this.pagesService.setCurrentStore(store);
    
   
    this.pagesService.storeDetail(store.storecode).subscribe((data: any) => {
      this.metatagsUrl = data.metatag;
      this.pagesService.setCurrentMetatags(data.metatag);
    });
    this.route.navigate([`storeaddress/${store.id}`]);
  }

  goToDirection(id: any, index: any) {
    this.storeLat = parseFloat(this.storesList[index].latitude);
    this.storeLng = parseFloat(this.storesList[index].longitude);
    this.finalDirectionURL = this.directonUrl+this.storeLat+','+this.storeLng+'/@'+this.storeLat+','+this.storeLng+'z';
    return this.finalDirectionURL;
  }

}
