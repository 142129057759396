<!-- inner_banner_section start-->

<section class="inner_banner_section build_order_banner">
  <div class="container">
    <div class="row">
      <div class="inner_banner_block">
        <div class="inner_banner_tilte">
          <h1>Build order</h1>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- inner_banner_section end-->

<!-- page_progress_section start-->

<section class="page_progress_section">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px;">
        <div class="prog_step">
          <ul>
            <li class="active"><i class="fa fa-circle" aria-hidden="true"></i>
              <p>Build your <br> Order</p>
            </li>
            <li><i class="fa fa-circle" aria-hidden="true"></i>
              <p>Schedule <br> Pick up</p>
            </li>
            <li><i class="fa fa-circle" aria-hidden="true"></i>
              <p>Choose <br> Address</p>
            </li>
            <li><i class="fa fa-circle" aria-hidden="true"></i>
              <p>Confirm </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- page_progress_section end-->

<!-- build_order_pg_section start-->

<section class="build_order_pg_section">
  <div class="container">
    <div class="row" *ngIf="showLoader">
      <div class="col-xs-12">
        <div id='loading_wrap'></div>
      </div>
    </div>

    <div class="row" *ngIf="!showLoader && showNoStoreFound">
      <div class="col-xs-12 text-center">
        <h2>There are no nearest stores near your location, Please try another location.</h2>
        <br/>
        <a routerLink="/" style="background-color:#5dab26; color:white;" class="page_back_btn">Back to Home</a>
      </div>
    </div>
    <div class="row" *ngIf="!showLoader && !showNoStoreFound">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="sec_title_bar">
          <h2 class="cart">Build your laundry cart</h2> 
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top">
        <div class="media_page build_order">

          <!-- <nav id="nav-tab">
              <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                  <a class="nav-item nav-link selected" id="nav-home-tab" data-toggle="tab" href="#nav-home"
                      role="tab" aria-controls="nav-home" aria-selected="true">Fabric Care</a>
                  <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile"
                      role="tab" aria-controls="nav-profile" aria-selected="false">Home Care</a>
              </div>
          </nav> -->

          <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
            <div class="tab-pane active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
              <div class="b_order_list" *ngFor="let service of services">
                <div [className]="'b_order_item image '+service.name" id="flex_img">
                </div>
                <div class="b_order_item_name" id="flex_title" >
                  <h4>{{service.name}}</h4>
                  <ul>
                    <li class="mobile-time-price">
                      <img src="//assets.ucleanlaundry.com/assets/images/clock_g.png">  {{service.turn_around_time}}&nbsp;&nbsp; <i class="fa fa-inr" aria-hidden="true"></i> {{service.base_price}}/{{service.unit}} onwards
                    </li>
                    <li *ngFor="let item of service.description"> 
                      {{item}}
                    </li>
                  </ul>
                </div>
                <div class="b_order_item web-time-price" id="flex_text">
                  <ul>
                    <li>
                      <p><img src="//assets.ucleanlaundry.com/assets/images/clock_g.png">{{service.turn_around_time}}
                      </p>
                    </li>
                    <li>
                      <p><i class="fa fa-inr" aria-hidden="true"></i>{{service.base_price}}/{{service.unit}} onwards</p>
                    </li>
                  </ul>
                </div>
                <div class=" b_order_item" id="flex_add" style="margin:0%">
                  <div class="quantity buttons_added" style="border: none;">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" (change)="toggleService(service.id, $event)"
                             [checked]="checkSelected(service.id)">
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="row b_order_list" style="width:100%" *ngFor="let service of services">
                <div [className]="'col-xs-2 b_order_item image '+service.name" id="flex_img">
                </div>
                <div class="col-xs-7 b_order_item_name" id="flex_title">
                  <h4>{{service.name}}</h4>
                  <ul>
                    <li class="mobile-time-price">
                      <img src="//assets.ucleanlaundry.com/assets/images/clock_g.png">  {{service.turn_around_time}}&nbsp;&nbsp; <i class="fa fa-inr" aria-hidden="true"></i> {{service.base_price}}/{{service.unit}} onwards
                    </li>
                    <li *ngFor="let item of service.description"> 
                      {{item}}
                    </li>
                  </ul>
                </div>
                <div class="b_order_item web-time-price" id="flex_text">
                  <ul>
                    <li>
                      <p><img src="//assets.ucleanlaundry.com/assets/images/clock_g.png">{{service.turn_around_time}}
                      </p>
                    </li>
                    <li>
                      <p><i class="fa fa-inr" aria-hidden="true"></i>{{service.base_price}}/{{service.unit}} onwards</p>
                    </li>
                  </ul>
                </div>
                <div class="col-xs-3 b_order_item" id="flex_add" style="margin:0%">
                  <div class="quantity buttons_added" style="border: none;">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" (change)="toggleService(service.id, $event)"
                             [checked]="checkSelected(service.id)">
                    </div>
                  </div>
                </div>
              </div> -->

              <!-- <div class="b_order_list">
                  <div class="b_order_item" id="flex_img">
                      <img src="//assets.ucleanlaundry.com/assets/images/Premium_Laundry_icon.png">
                  </div>
                  <div class="b_order_item" id="flex_title">
                      <h4>Premium Laundry</h4>
                  </div>
                  <div class="b_order_item" id="flex_text">
                      <ul>
                          <li>
                              <p><img src="//assets.ucleanlaundry.com/assets/images/clock_g.png">48hour
                              </p>
                          </li>
                          <li>
                              <p><i class="fa fa-inr" aria-hidden="true"></i> 59/pc onwards</p>
                          </li>
                      </ul>
                  </div>
                  <div class="b_order_item" id="flex_add">
                      <div class="quantity buttons_added">
                          <div class="plus-minus">
                              <input type="checkbox" name="b" id="b" class="css-checkbox">
                              <label for="b" class="css-label">
                                  <span class="fa fa-plus"></span>
                                  <span class="fa fa-check"></span>
                              </label>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="b_order_list">
                  <div class="b_order_item" id="flex_img">
                      <img src="//assets.ucleanlaundry.com/assets/images/Wash_and_Fold_Icon.png">
                  </div>
                  <div class="b_order_item" id="flex_title">
                      <h4>Laundry by Kg</h4>
                      <p>Wash & Iron</p>
                  </div>
                  <div class="b_order_item" id="flex_text">
                      <ul>
                          <li>
                              <p><img src="//assets.ucleanlaundry.com/assets/images/clock_g.png">48 hour
                              </p>
                          </li>
                          <li>
                              <p><i class="fa fa-inr" aria-hidden="true"></i>90/Kg onwards</p>
                          </li>
                      </ul>
                  </div>
                  <div class="b_order_item" id="flex_add">
                      <div class="quantity buttons_added">
                          <div class="plus-minus">
                              <input type="checkbox" name="c" id="c" class="css-checkbox">
                              <label for="c" class="css-label">
                                  <span class="fa fa-plus"></span>
                                  <span class="fa fa-check"></span>
                              </label>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="b_order_list">
                  <div class="b_order_item" id="flex_img">
                      <img src="//assets.ucleanlaundry.com/assets/images/Steam_Ironing_Icon.png">
                  </div>
                  <div class="b_order_item" id="flex_title">
                      <h4>Steam Ironing</h4>
                  </div>
                  <div class="b_order_item" id="flex_text">
                      <ul>
                          <li>
                              <p><img src="//assets.ucleanlaundry.com/assets/images/clock_g.png"> 24 hour
                              </p>
                          </li>
                          <li>
                              <p><i class="fa fa-inr" aria-hidden="true"></i> 10/per Kg onwards</p>
                          </li>
                      </ul>
                  </div>
                  <div class="b_order_item" id="flex_add">
                      <div class="quantity buttons_added">
                          <div class="plus-minus">
                              <input type="checkbox" name="d" id="d" class="css-checkbox">
                              <label for="d" class="css-label">
                                  <span class="fa fa-plus"></span>
                                  <span class="fa fa-check"></span>
                              </label>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="b_order_list">
                  <div class="b_order_item" id="flex_img">
                      <img src="//assets.ucleanlaundry.com/assets/images/Wash_and_Iron_icon.png">
                  </div>
                  <div class="b_order_item" id="flex_title">
                      <h4>Wash and Iron</h4>
                  </div>
                  <div class="b_order_item" id="flex_text">
                      <ul>
                          <li>
                              <p><img src="//assets.ucleanlaundry.com/assets/images/clock_g.png">3-5 Days
                              </p>
                          </li>
                          <li>
                              <p><i class="fa fa-inr" aria-hidden="true"></i> 299/per Kg onwards</p>
                          </li>
                      </ul>
                  </div>
                  <div class="b_order_item" id="flex_add">
                      <div class="quantity buttons_added">
                          <div class="plus-minus">
                              <input type="checkbox" name="e" id="e" class="css-checkbox">
                              <label for="e" class="css-label">
                                  <span class="fa fa-plus"></span>
                                  <span class="fa fa-check"></span>
                              </label>
                          </div>
                      </div>
                  </div>
              </div> -->

              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="alert alert-danger text-center" role="alert" [hidden]=!noServiceSelectedError>
                  <span class="error-block">Please select the service</span>
                </div>

                <!-- <hr class="hr" style="color: #74B944; font-weight: bold;">
                <p class="count"><b>Total count : 0</b></p>

                <div class="buttn">
                  <button>Skip Count</button> <button>Order</button>
                </div> -->
                

                <div class="page_bn_btn">
                  <!-- <a class="skip_btn" routerLink="schedule-pick-up">Skip Step</a> -->
                  <a class="page_next_btn" (click)="takeToSchedulePickup()">Next <img
                    src="//assets.ucleanlaundry.com/assets/images/right_icon.png"></a>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
              1
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- build_order_pg_section end-->



<!-- https://uclean.in/api/v1/cities -->