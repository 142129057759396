declare let L : any;

export const MAP_SETTINGS = {
    defaultZoomView: 15,
    getMapMoveConfig: () => {
        return {
            animate: true,
            pan: {
              animate: true,
              duration: 3
            },
            zoom: { animate: true }
        };
    }
}

export function getCustomMarker(iconId: any, keepIconSize?: any) {
  let icon = L.icon({
    iconUrl: getIconUrl(iconId),
    shadowUrl: '',
    iconSize: iconId != 5 ? (keepIconSize ? [38, 40] : []) : [8, 8],
    shadowSize: [0, 0], 
    // iconAnchor: [22, 94],
    // shadowAnchor: [4, 62],
    popupAnchor: [0, -18]
  });
  var shadowIcon = new L.Icon({
    iconUrl: getIconUrl(iconId),
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [38, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [71, 51],
    shadowAnchor: [10, 55]
});
  return shadowIcon;
}

function getIconUrl(iconId: any) {
  let iconUrl = '';
  const currentLocation = 'https://assets.ucleanlaundry.com/assets/images/location2.png';
  const location = 'https://apis.mapmyindia.com/map_v3/1.png';
  switch (iconId) {
    case 2:
      iconUrl = currentLocation;
      break;
    case 1:
      iconUrl = location;
      break;
    default:
      iconUrl = currentLocation;
  }
  return iconUrl;
}

export function getVisibleLayers() {
    return {
        'Default ': L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }),
        'Satellite': L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
          maxZoom: 20,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        })
      }
}