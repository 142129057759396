// order-history.component.ts
import { Component, OnInit } from '@angular/core';
import { PagesService } from '../pages.service';

interface Order {
  id: number;
  booking_code: string;
  status: number;
  booking_type: string;
  created_at: string;
  pickup_date: string;
  pickup_time: string;
  flat_info: string;
  street_info: string;
  landmark: string;
  pincode: number;
  contact_person: string;
  contact_number: string;
  store_contact_number: string;
}

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {
  userOrdHistory: Order[] = [];

  constructor(private pagesService: PagesService) {}

  ngOnInit(): void {
    this.orderHis();
  }

  orderHis() {
    this.pagesService.orderHistory().subscribe((data: any) => {
      this.userOrdHistory = data.orders;
    });
  }

  getStoreCode(bookingCode: string): string {
    return bookingCode.split('-')[0];
  }

  getStatusLabel(status: number): { label: string; color: string } {
    switch (status) {
      case 1:
        return { label: 'Order Placed', color: 'bg-blue-500' };
      case 2:
        return { label: 'Pickup Scheduled', color: 'bg-yellow-500' };
      case 3:
        return { label: 'In Process', color: 'bg-orange-500' };
      case 4:
        return { label: 'Delivered', color: 'bg-green-500' };
      default:
        return { label: 'Unknown', color: 'bg-gray-500' };
    }
  }

  getServices(bookingType: string | null | undefined): string[] {
    if (!bookingType) {
      return []; // Return an empty array if bookingType is null or undefined
    }
    return bookingType.split(', ');
  }
}
