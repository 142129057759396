import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { PagesService } from '../pages.service';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {

  constructor(private pagesService: PagesService, private metaTagService: Meta) { }

  userOrdHistory: any;
  downloadInvoice = false;
  invoiceNotGenerate = true;

  ngOnInit(): void {
    this.orderHis();
  }

  orderHis() {
    this.pagesService.orderHistory().subscribe((data: any) => {
      this.userOrdHistory = data.orders;
    });
  }

  invoice(){
    if(this.userOrdHistory.status == 4 && this.userOrdHistory.invoice_id != null) {
      this.downloadInvoice = true;
      this.invoiceNotGenerate = false;
    }
  }
}
