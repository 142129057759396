import {Component, OnInit} from '@angular/core';
import {PagesService} from '../../pages/pages.service';
import {OrderService} from '../order.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '../../../models/location';
import { PixelService } from 'ngx-pixel';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-build-your-order',
  templateUrl: './build-your-order.component.html',
  styleUrls: ['./build-your-order.component.scss']
})
export class BuildYourOrderComponent implements OnInit {
  // Query params
  location: any;
  selectedServiceIds: any;
  selectedSlotId: any;
  selectedAddressId: any;

  // view states
  showLoader = true;
  showNoStoreFound = false;
  noServiceSelectedError = false;

  // Data received from server
  services: any;
  store: any;
  cityIdForAddress:any;


  constructor(
    private pixel: PixelService,
    private metaTagService: Meta,
    private orderService: OrderService, private route: ActivatedRoute, public router: Router , private pagesService: PagesService) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.location = {} as Location;
      this.location.lat = params['lat'];
      this.location.lng = params['lng'];
      this.location.name = params['locationName'];
      this.location.pincode = params['pincode'];
      this.location.city = params['city'];
      this.selectedServiceIds = params['service_ids'];
      this.selectedSlotId = params['slot_id'];
      this.selectedAddressId = params['address_id'];
      this.pixel.trackCustom('AddToCart');
      if(this.selectedServiceIds == undefined){
        this.selectedServiceIds = [];
      }
      // convert to array if single service selected
      if(!Array.isArray(this.selectedServiceIds)){
        let service = this.selectedServiceIds;
        this.selectedServiceIds = [];
        this.selectedServiceIds.push(service);
      }
      this.orderService.services(this.location).subscribe((data: any) => {
        this.showLoader = false;
        if(data.store == undefined){
          this.showNoStoreFound = true;
        }
        this.services = data.services;
        this.store = data.store;
        localStorage.setItem('cityIdForAddress',data.store.city_id);

      }
      ,error=>{
        // console.log(error);
      });


    });
  }

  takeToSchedulePickup() {
    if (this.selectedServiceIds != undefined && this.selectedServiceIds.length > 0) {
      this.noServiceSelectedError = false;
      this.router.navigate(['/schedule-pick-up'],
        {
          queryParams: {
            lat: this.location.lat,
            lng: this.location.lng,
            locationName: this.location.name,
            pincode: this.location.pincode,
            city: this.location.city,
            store_id: this.store.id,
            service_ids: this.selectedServiceIds,
            slot_id: this.selectedSlotId,
            address_id: this.selectedAddressId
          }
        });
    } else {
      this.noServiceSelectedError = true;
    }
  }

  toggleService(id: string, event: any) {
    if (event.target.checked && this.selectedServiceIds.indexOf('' + id) < 0) {
      this.selectedServiceIds.push('' + id);
    } else if (!event.target.checked && this.selectedServiceIds.indexOf('' + id) > -1) {
      // remove from selected Services;
      this.removeElementFromServices('' + id);
    }
  }

  checkSelected(id: string): boolean {
    if (this.selectedServiceIds != undefined && this.selectedServiceIds.indexOf('' + id) > -1) {
      return true;
    }
    return false;
  }


  removeElementFromServices(id: string) {
    for (let i = 0; i < this.selectedServiceIds.length; i++) {
      if (this.selectedServiceIds[i] === id) {
        this.selectedServiceIds.splice(i, 1);
      }
    }
  }
}
