import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { RouteStateService } from 'src/app/services/route-state.service';

@Component({
  selector: 'app-otp-verification-success',
  templateUrl: './otp-verification-success.component.html',
  styleUrls: ['./otp-verification-success.component.scss']
})
export class OtpVerificationSuccessComponent implements OnInit {
  constructor(
    private router: Router, 
    private metaTagService: Meta,
    private routeStateService: RouteStateService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.routeStateService.isNavigatingFromDeleteAccount()) {
        this.router.navigate(['/profile']);
        this.routeStateService.resetState(); // Reset the state after navigation
      } else {
        this.router.navigate(['/']);
      }
    }, 5000);
  }
}

//if youre not able to delete the account through app, you can use the following page to delete the account.