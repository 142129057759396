<header class="header_section" [ngClass]="{'location-disabled': isLocationNotAllowed, 'mini-scroll': !isScrollToNav}" #stickyHeader>
	<div class="container">
		<div class="row">
			<div class="col-lg-2 col-md-2 col-sm-3 col-xs-5">
				<div class="logo_sec"><a  href="javascript:void(0)" (click)="home()">
					<!-- <div class="logo_sec"><a  routerLink="/"> -->
						<img src="//assets.ucleanlaundry.com/assets/images/logo.png" alt=" UClean Laundry and Dry Cleaning" >
					</a>
				</div>
			</div>
			<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12" >
				<!-- <ng-container *ngIf="isLocationFetched && isHome">
					<input type="text" name="location" class="location-form-control location-icon" placeholder="Select Location"
						[(ngModel)]="address.name" GooglePlace (setAddress)="onAddressSelected($event)" #inputVal
						(keyup)="getVal(inputVal.value)" (click)="currentLoc()">
					<div *ngIf="showCurrentLoc" class="current_loc">
						<button style="display: inline-block;" (click)="setLocation();"><i class="fa fa-crosshairs"
								aria-hidden="true"></i>Choose your current location</button>
					</div>
				</ng-container>
				<ng-container *ngIf="isLocationFetching">
					<span class="location-loader"><img class="location-icon-loader" src="//assets.ucleanlaundry.com/assets/images/vector/location.svg" alt="">&nbsp; Fetching..</span>
				</ng-container> -->
			</div>
			<div class="col-lg-7 col-md-7 col-sm-6 col-xs-6">
				<div class="menu_sec">
					<ul>
						<!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
							true}"><a routerLink="apply-for-franchise1">Franchise</a></li> -->
						<li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
							true}"><a routerLink="/"> Home</a></li>
						<li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
							true}"><a routerLink="about-us"> About us</a></li>
						<li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
							true}"><a routerLink="services">Services </a></li>
						<li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
							true}"><a routerLink="laundry-drycleaning-price-list">Pricing List</a></li>
						<!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
							true}" *ngIf="!authService.loggedIn" 
							style="cursor: pointer;"><a type="button" data-toggle="modal" data-target="#loginModal">Login/Signup</a></li> -->
						<li  *ngIf="!authService.loggedIn" 
							style="cursor: pointer;"><a routerLink="login-page">Login/Signup</a></li>
						<li class="profile-btn" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
							true}" *ngIf="authService.loggedIn"><a routerLink="profile">Profile</a></li>
						<li class="franchise-web-btn" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
							true}"><a routerLink="apply-for-franchise">Franchise</a></li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	<!-- <div class="location-disabled-strip" *ngIf="isLocationNotAllowed && !isManualRemoved">
		<div class="mesg-box"><p>Location permission denied. Please enable your current location from the browser settings.</p></div>
		<div class="action-box"><span class="close-mesg-icon" (click)="closeLocationDisabledBanner()">×</span></div>
	</div> -->
</header>

<!---mob_header start--->
<header class="hp_mob_header" [ngClass]="{'location-disabled': isLocationNotAllowed}">
	<div class="container">
		<div class="row">
			<div class="col-sm-4 col-xs-4 logo_sec"><a href="javascript:void(0)" (click)="home()">
				<!-- <div class="col-sm-4 col-xs-4 logo_sec"><a routerLink="/"> -->
					<img src="//assets.ucleanlaundry.com/assets/images/logo.png" alt=" UClean Laundry and Dry Cleaning" >
				</a>
			</div>
			<div class="col-sm-4 col-xs-2 mob_header">
				<div style="margin: 5px 16px -4px -12px;">
					<span><a *ngIf="!authService.loggedIn" type="button" data-toggle="modal" data-target="#loginModal"
							style="cursor: pointer;" style="font-size: 25px; color: #7bbb43;" type="button"
							data-toggle="modal" data-target="#loginModal"><i class="fa fa-user"
								aria-hidden="true"></i></a></span>
					<span><a *ngIf="authService.loggedIn" routerLink="profile"
							style="font-size: 25px; color: #7bbb43;cursor: pointer;" type="button"><i class="fa fa-user"
								aria-hidden="true"></i></a></span>
				</div>
				<div class="menu_btn_open">
					<span aria-hidden="true" class="menu_open"><i class="fa fa-bars" style="font-size:24px"></i></span>

				</div>
				<div class="topnav mob_menu">
					<ul>
						<li class="menu_close">
							<img src="//assets.ucleanlaundry.com/assets/images/logo.png" alt=" UClean Laundry and Dry Cleaning"  style="width: 157px; padding-left: 14px;">
							<span style="float:right; padding-right:20px">
								<svg width="40" height="40" viewBox="0 0 40 40" fill="none"
									xmlns="http://www.w3.org/2000/svg"><line x1="11" y1="27.3845" x2="29.3848" y2="8.99969" stroke="#003A25" stroke-width="4" stroke-linecap="round" /><line x1="11.8284" y1="9" x2="30.2132" y2="27.3848" stroke="#003A25" stroke-width="4" stroke-linecap="round" /></svg>
							</span>
						</li>
						<li class="menu_close"><a routerLink="/"><span><svg width="20" height="18" viewBox="0 0 20 18" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M18.6146 0H1.38535C0.621509 0 0 0.62151 0 1.38535V13.0744C0 13.8383 0.621509 14.4598 1.38535 14.4598H11.432L11.1287 16.3098C11.0716 16.6581 11.1868 16.9735 11.4368 17.1535C11.6868 17.3336 12.0223 17.3432 12.3346 17.1786L14.5022 16.0377C14.5627 16.0067 14.7293 16.0067 14.7898 16.0377L16.9574 17.1786C17.1056 17.2567 17.2592 17.2956 17.4064 17.2956C17.5691 17.2956 17.724 17.248 17.8552 17.1535C18.1052 16.9734 18.2204 16.6579 18.1634 16.3097L17.8601 14.4597H18.6147C19.3786 14.4597 20.0001 13.8382 20.0001 13.0743V1.38535C20 0.621431 19.3785 0 18.6146 0ZM14.646 12.0852C13.3148 12.0852 12.2316 11.002 12.2316 9.67072C12.2316 8.33944 13.3148 7.25625 14.646 7.25625C15.9773 7.25625 17.0605 8.33944 17.0605 9.67072C17.0605 11.002 15.9773 12.0852 14.646 12.0852ZM15.3429 14.987C14.9391 14.7744 14.3529 14.7744 13.9492 14.987L12.4167 15.7936L12.9039 12.8219C13.4206 13.1087 14.0145 13.2726 14.6461 13.2726C15.2778 13.2726 15.8716 13.1086 16.3883 12.8219L16.5782 13.9804C16.5786 13.9823 16.5788 13.9844 16.5792 13.9863L16.8755 15.7937L15.3429 14.987ZM18.8126 13.0744C18.8126 13.1817 18.7219 13.2723 18.6146 13.2723H17.6654L17.4486 11.9496C17.4477 11.9444 17.4463 11.9394 17.4452 11.9342C17.9467 11.3153 18.248 10.5276 18.248 9.67072C18.248 7.6846 16.6322 6.0688 14.6461 6.0688C12.66 6.0688 11.0442 7.6846 11.0442 9.67072C11.0442 10.5276 11.3455 11.3151 11.847 11.9342C11.846 11.9393 11.8445 11.9444 11.8437 11.9495L11.6267 13.2734C11.62 13.2731 11.6136 13.2723 11.607 13.2723H1.38535C1.27809 13.2723 1.18745 13.1817 1.18745 13.0744V1.38535C1.18745 1.27809 1.27809 1.18745 1.38535 1.18745H18.6146C18.7219 1.18745 18.8126 1.27809 18.8126 1.38535V13.0744Z"
											fill="#A5CF4D" />
										<path
											d="M16.5143 3.41699H3.05661C2.72864 3.41699 2.46289 3.68274 2.46289 4.01072C2.46289 4.33869 2.72864 4.60444 3.05661 4.60444H16.5143C16.8423 4.60444 17.1081 4.33869 17.1081 4.01072C17.1081 3.68274 16.8423 3.41699 16.5143 3.41699Z"
											fill="#A5CF4D" />
										<path
											d="M8.75636 6.63672H3.05661C2.72864 6.63672 2.46289 6.90247 2.46289 7.23044C2.46289 7.55841 2.72864 7.82416 3.05661 7.82416H8.75636C9.08433 7.82416 9.35008 7.55841 9.35008 7.23044C9.35008 6.90247 9.08433 6.63672 8.75636 6.63672Z"
											fill="#A5CF4D" />
									</svg>
						</span> Home</a></li>
						<li class="menu_close"><a routerLink="about-us"><span><svg width="20" height="20"
										viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M10 3.75C6.55375 3.75 3.75 6.55375 3.75 10C3.75 13.4462 6.55375 16.25 10 16.25C13.4462 16.25 16.25 13.4462 16.25 10C16.25 6.55375 13.4462 3.75 10 3.75ZM10 13.75C7.9325 13.75 6.25 12.0675 6.25 10C6.25 7.9325 7.9325 6.25 10 6.25C12.0675 6.25 13.75 7.9325 13.75 10C13.75 12.0675 12.0675 13.75 10 13.75Z"
											fill="#A5CF4D" />
										<path d="M11.25 0H8.75V2.5H11.25V0Z" fill="#A5CF4D" />
										<path d="M11.25 17.5H8.75V20H11.25V17.5Z" fill="#A5CF4D" />
										<path d="M20 8.75H17.5V11.25H20V8.75Z" fill="#A5CF4D" />
										<path d="M2.5 8.75H0V11.25H2.5V8.75Z" fill="#A5CF4D" />
										<path
											d="M16.1877 14.4198L14.4199 16.1875L16.1877 17.9552L17.9554 16.1875L16.1877 14.4198Z"
											fill="#A5CF4D" />
										<path
											d="M3.81267 2.04475L2.04492 3.8125L3.81267 5.58025L5.58042 3.8125L3.81267 2.04475Z"
											fill="#A5CF4D" />
										<path
											d="M3.81267 14.4197L2.04492 16.1875L3.81267 17.9552L5.58042 16.1875L3.81267 14.4197Z"
											fill="#A5CF4D" />
										<path
											d="M16.1877 2.04475L14.4199 3.8125L16.1877 5.58025L17.9554 3.8125L16.1877 2.04475Z"
											fill="#A5CF4D" />
									</svg>
								</span> About us</a></li>
								<li class="menu_close"><a routerLink="apply-for-franchise"><span><svg width="20" height="18"
									viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M18.6146 0H1.38535C0.621509 0 0 0.62151 0 1.38535V13.0744C0 13.8383 0.621509 14.4598 1.38535 14.4598H11.432L11.1287 16.3098C11.0716 16.6581 11.1868 16.9735 11.4368 17.1535C11.6868 17.3336 12.0223 17.3432 12.3346 17.1786L14.5022 16.0377C14.5627 16.0067 14.7293 16.0067 14.7898 16.0377L16.9574 17.1786C17.1056 17.2567 17.2592 17.2956 17.4064 17.2956C17.5691 17.2956 17.724 17.248 17.8552 17.1535C18.1052 16.9734 18.2204 16.6579 18.1634 16.3097L17.8601 14.4597H18.6147C19.3786 14.4597 20.0001 13.8382 20.0001 13.0743V1.38535C20 0.621431 19.3785 0 18.6146 0ZM14.646 12.0852C13.3148 12.0852 12.2316 11.002 12.2316 9.67072C12.2316 8.33944 13.3148 7.25625 14.646 7.25625C15.9773 7.25625 17.0605 8.33944 17.0605 9.67072C17.0605 11.002 15.9773 12.0852 14.646 12.0852ZM15.3429 14.987C14.9391 14.7744 14.3529 14.7744 13.9492 14.987L12.4167 15.7936L12.9039 12.8219C13.4206 13.1087 14.0145 13.2726 14.6461 13.2726C15.2778 13.2726 15.8716 13.1086 16.3883 12.8219L16.5782 13.9804C16.5786 13.9823 16.5788 13.9844 16.5792 13.9863L16.8755 15.7937L15.3429 14.987ZM18.8126 13.0744C18.8126 13.1817 18.7219 13.2723 18.6146 13.2723H17.6654L17.4486 11.9496C17.4477 11.9444 17.4463 11.9394 17.4452 11.9342C17.9467 11.3153 18.248 10.5276 18.248 9.67072C18.248 7.6846 16.6322 6.0688 14.6461 6.0688C12.66 6.0688 11.0442 7.6846 11.0442 9.67072C11.0442 10.5276 11.3455 11.3151 11.847 11.9342C11.846 11.9393 11.8445 11.9444 11.8437 11.9495L11.6267 13.2734C11.62 13.2731 11.6136 13.2723 11.607 13.2723H1.38535C1.27809 13.2723 1.18745 13.1817 1.18745 13.0744V1.38535C1.18745 1.27809 1.27809 1.18745 1.38535 1.18745H18.6146C18.7219 1.18745 18.8126 1.27809 18.8126 1.38535V13.0744Z"
										fill="#A5CF4D" />
									<path
										d="M16.5143 3.41699H3.05661C2.72864 3.41699 2.46289 3.68274 2.46289 4.01072C2.46289 4.33869 2.72864 4.60444 3.05661 4.60444H16.5143C16.8423 4.60444 17.1081 4.33869 17.1081 4.01072C17.1081 3.68274 16.8423 3.41699 16.5143 3.41699Z"
										fill="#A5CF4D" />
									<path
										d="M8.75636 6.63672H3.05661C2.72864 6.63672 2.46289 6.90247 2.46289 7.23044C2.46289 7.55841 2.72864 7.82416 3.05661 7.82416H8.75636C9.08433 7.82416 9.35008 7.55841 9.35008 7.23044C9.35008 6.90247 9.08433 6.63672 8.75636 6.63672Z"
										fill="#A5CF4D" />
								</svg>
							</span> Franchise</a></li>
							
						<li class="menu_close"><a routerLink="services"> <span><svg width="20" height="15"
										viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M11.2807 8.76504L11.3099 8.78523L11.3418 8.80107L19.0749 12.6511C19.4353 12.8394 19.609 13.2439 19.426 13.6697L19.4068 13.7145L19.3966 13.7621C19.2971 14.2254 18.9788 14.5 18.4987 14.5H1.419C0.996102 14.5 0.617188 14.2098 0.521044 13.7621C0.426308 13.3209 0.664237 12.8576 1.06348 12.6502C1.06393 12.65 1.06439 12.6498 1.06484 12.6495L9.67457 8.27044L10.0267 8.09135L9.93391 7.70735C9.8764 7.46931 9.87491 7.29675 9.87491 6.99396C9.87491 6.04125 10.3619 5.22586 11.0807 4.78607C11.851 4.37902 12.2383 3.56336 12.058 2.68124C11.9493 1.8721 11.2772 1.16801 10.4656 1.06064C10.1721 1.01761 9.88711 1.01277 9.60714 1.07861C9.32329 1.14537 9.07424 1.27776 8.84287 1.46809C8.41879 1.80118 8.14501 2.40462 8.14501 2.99094V3.74622C8.14501 3.86421 8.10874 3.92152 8.08474 3.94635C8.06223 3.96964 8.01521 4.00151 7.91511 4.00151C7.81501 4.00151 7.76799 3.96964 7.74547 3.94635C7.72148 3.92152 7.68521 3.86421 7.68521 3.74622V2.99094C7.68521 2.21662 7.99587 1.53688 8.52056 1.11459L8.53341 1.10425L8.54556 1.09308C9.07781 0.603507 9.79157 0.41204 10.5256 0.537154C11.4794 0.724536 12.2746 1.53753 12.459 2.54884C12.6436 3.63761 12.1447 4.70294 11.25 5.19668L11.2089 5.21936L11.1728 5.24929C10.7183 5.62549 10.3347 6.21083 10.3347 6.99396C10.3347 7.69639 10.7153 8.37504 11.2807 8.76504ZM1.26375 13.1201L1.49199 14.065H18.6446L18.8658 13.1165L11.1306 9.30145C11.0273 9.19995 10.9268 9.11326 10.8383 9.03701L10.8328 9.03222C10.7215 8.93629 10.6287 8.85589 10.5373 8.7613L10.2796 8.49458L9.94956 8.66389L1.26375 13.1201Z"
											fill="#A5CF4D" stroke="#A5CF4D" />
									</svg>
								</span> Services </a>
								<div style="margin-left:20px">
									<a routerLink="services/DryCleaning" style="font-weight: 300; font-family: 'All Round Gothic'; line-height: 20px; font-size:13px"><span><i class="fa fa-chevron-right" style="color:#A5CF4D"></i></span> Dry Cleaning</a>
									<a routerLink="services/LaundrybyKg" style="font-weight: 300; font-family: 'All Round Gothic'; line-height: 20px; font-size:13px"><span><i class="fa fa-chevron-right" style="color:#A5CF4D"></i></span> Laundry by Kg</a>
									<a routerLink="services/PremiumLaundry" style="font-weight: 300; font-family: 'All Round Gothic'; line-height: 20px; font-size:13px"><span><i class="fa fa-chevron-right" style="color:#A5CF4D"></i></span> Premium Laundry</a>
									<a routerLink="services/SteamIroning" style="font-weight: 300; font-family: 'All Round Gothic'; line-height: 20px; font-size:13px"><span><i class="fa fa-chevron-right" style="color:#A5CF4D"></i></span> Steam Ironing</a>
									<a routerLink="services/ShoeCleaning" style="font-weight: 300; font-family: 'All Round Gothic'; line-height: 20px; font-size:13px"><span><i class="fa fa-chevron-right" style="color:#A5CF4D"></i></span> Shoe Cleaning</a>
									<a routerLink="services/BagCleaning" style="font-weight: 300; font-family: 'All Round Gothic'; line-height: 20px; font-size:13px"><span><i class="fa fa-chevron-right" style="color:#A5CF4D"></i></span> Bag Cleaning</a>
									<a routerLink="services/CurtainCleaning" style="font-weight: 300; font-family: 'All Round Gothic'; line-height: 20px; font-size:13px"><span><i class="fa fa-chevron-right" style="color:#A5CF4D"></i></span> Curtain Cleaning</a>
									<a routerLink="services/SofaCleaning" style="font-weight: 300; font-family: 'All Round Gothic'; line-height: 20px; font-size:13px"><span><i class="fa fa-chevron-right" style="color:#A5CF4D"></i></span> Sofa Cleaning</a>
									<a routerLink="services/CarpetCleaning" style="font-weight: 300; font-family: 'All Round Gothic'; line-height: 20px; font-size:13px"><span><i class="fa fa-chevron-right" style="color:#A5CF4D"></i></span> Carpet Cleaning</a>
								</div>
								
						</li>
								
						<li class="menu_close"><a routerLink="laundry-drycleaning-price-list"><span><svg width="20" height="20"
										viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M14.5501 9.67892L10.3214 5.45024L10.923 4.84863L15.1517 9.07731L14.5501 9.67892ZM12.2835 11.9455L8.05488 7.71684L8.65649 7.11523L12.8852 11.3439L12.2835 11.9455ZM10.017 14.212L5.78833 9.98331L6.38998 9.38166L10.6187 13.6103L10.017 14.212ZM7.7502 16.4787L3.52148 12.25L4.12314 11.6483L8.35185 15.8771L7.7502 16.4787Z"
											fill="#A5CF4D" />
										<path
											d="M19.4895 0.514346C19.1588 0.182611 18.7188 0 18.2451 0L12.5205 0.0176508C12.0549 0.0190942 11.6173 0.201087 11.2887 0.530141L0.515473 11.3031C-0.457174 12.3598 0.184975 13.4471 0.515473 13.7774L6.22252 19.4845C7.28429 20.4385 8.36651 19.815 8.69681 19.4845L19.4698 8.71143C19.7989 8.38238 19.9809 7.94486 19.9823 7.47947L20 1.75481C20.0015 1.28657 19.8201 0.846123 19.4895 0.514346ZM19.1314 7.47703C19.1308 7.71594 19.0373 7.9407 18.8682 8.10982L8.09519 18.8828C7.75571 19.2223 7.16362 19.2223 6.82417 18.8828L1.11712 13.1758C0.947422 13.006 0.619275 12.4091 1.11712 11.9047L11.8906 1.13179C12.0593 0.962667 12.284 0.869176 12.5229 0.868599L18.2505 0.850948C18.4911 0.850948 18.7169 0.944852 18.8869 1.11513C19.0566 1.28549 19.1499 1.51178 19.149 1.75233L19.1314 7.47703Z"
											fill="#A5CF4D" />
										<path
											d="M16.1105 2.22754C15.193 2.22754 14.4492 2.9713 14.4492 3.88881C14.4492 4.80632 15.193 5.55009 16.1105 5.55009C17.028 5.55009 17.7718 4.80632 17.7718 3.88881C17.7718 2.9713 17.028 2.22754 16.1105 2.22754ZM16.1105 4.71535C15.654 4.71535 15.284 4.3453 15.284 3.88881C15.284 3.43233 15.654 3.06228 16.1105 3.06228C16.5669 3.06228 16.937 3.43233 16.937 3.88881C16.937 4.3453 16.5669 4.71535 16.1105 4.71535Z"
											fill="#A5CF4D" />
									</svg>
								</span> Pricing List</a></li>
						<li class="menu_close"><a routerLink="uclean-store-near-you"><span><svg width="15" height="18"
										viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M7.2 0C3.22105 0 0 3.18418 0 7.15505C0 12.0999 5.15368 16.6327 6.74526 17.8314C7.01053 18.0562 7.38947 18.0562 7.69263 17.8314C9.28421 16.5952 14.4 12.0999 14.4 7.15505C14.4 3.18418 11.1789 0 7.2 0ZM7.2 10.1145C5.53263 10.1145 4.16842 8.76587 4.16842 7.11759C4.16842 5.4693 5.53263 4.12071 7.2 4.12071C8.86737 4.12071 10.2316 5.4693 10.2316 7.11759C10.2316 8.76587 8.86737 10.1145 7.2 10.1145Z"
											fill="#A5CF4D" />
									</svg>
								</span> Locate Us</a></li>

						


						<!-- <li class="menu_close" style="display: inline-flex; margin-top:20px; padding:2px 0px 2px 20px"><a class="sub-links" routerLink="about-us">About us</a>&nbsp;//&nbsp;<a class="sub-links" routerLink="apply-for-franchise">Franchise</a>&nbsp;//&nbsp;<a class="sub-links" routerLink="services">Services</a></li>

						<li class="menu_close" style="display: inline-flex; padding:2px 0px 2px 20px"><a class="sub-links" routerLink="uclean-store-near-you">uCleane Store near you	</a>&nbsp;//&nbsp;<a class="sub-links" routerLink="laundry-drycleaning-price-list">Pricing</a></li> -->


						
					</ul>

					

				</div>
			</div>
			<div class="col-md-12 col-sx-12 mob_loc" *ngIf="isLocationFetched && isHome">
				<input type="text" name="location" class="location-form-control location-icon" placeholder="Select Location"
					[(ngModel)]="address.name" GooglePlace (setAddress)="onAddressSelected($event)" #inputVal
					(keyup)="getVal(inputVal.value)" (click)="currentLoc()">
				<div *ngIf="showCurrentLoc" class="current_loc">
					<button style="display: inline-block;" (click)="setLocation();"><i class="fa fa-crosshairs"
							aria-hidden="true"></i>Choose your current location</button>
				</div>
			</div>
		</div>
	</div>
	<!-- <div class="location-disabled-strip" *ngIf="isLocationNotAllowed && !isManualRemoved">
		<div class="mesg-box"><p>Location permission denied. Please enable your current location from the browser settings.</p></div>
		<div class="action-box"><span class="close-mesg-icon" (click)="closeLocationDisabledBanner()">×</span></div>
	</div> -->
</header>

<app-new-login></app-new-login>