import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { BlogService } from '../blog.service';

@Component({
  selector: 'app-main-blogs',
  templateUrl: './main-blogs.component.html',
  styleUrls: ['./main-blogs.component.scss']
})
export class MainBlogsComponent implements OnInit {

  constructor(private blogService:BlogService, private metaTagService: Meta) {}

  mainBlog:any;
  recentBlogs:any;
  p:any = [];

  searchBlogs: any;

  ngOnInit(): void {
    this.blogService.mainBlogs().subscribe((data:any)=>{
      this.mainBlog = data.blogs;
    });

    this.blogService.latestBlogs().subscribe((data:any)=>{
      this.recentBlogs = data.blogs;
    });
  };

  scrollToTop(){
    window.scroll(0,0);
  }
}
