import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OrderStateService } from '../order-state.service';
import { CalenderService } from 'src/app/services/calender.service';

@Component({
  selector: 'app-week-day-lister',
  templateUrl: './week-day-lister.component.html',
  styleUrls: ['./week-day-lister.component.scss']
})
export class WeekDayListerComponent implements OnInit {
  public weekDaysList: any[] = [];
  selectedDate: any;
  @Output() onDateSelect = new EventEmitter();

  constructor(
    private calenderService: CalenderService,
    private orderStateService: OrderStateService
  ) {}

  ngOnInit(): void {
    this.weekDaysList = this.calenderService.getWeekDaysList();
    
    // Restore saved date if available
    const savedSchedule = this.orderStateService.getCurrentSchedule();
    if (savedSchedule?.selectedDate) {
      const formattedDate = `${savedSchedule.selectedDate.year}-${String(savedSchedule.selectedDate.month).padStart(2, '0')}-${String(savedSchedule.selectedDate.day).padStart(2, '0')}`;
      this.selectedDate = formattedDate;
    } else if (this.weekDaysList.length > 0) {
      // Default to first date if no saved state
      this.selectedDate = this.weekDaysList[0].formatDate;
      this.onWeekDateSelect(this.weekDaysList[0]);
    }
  }

  onWeekDateSelect(item: any) {
    this.selectedDate = item.formatDate;
    const dateObj = {
      day: +item.dd,
      month: +item.mm,
      year: +item.yyyy
    };
    this.onDateSelect.emit(dateObj);
  }
}