<!-- inner_banner_section start-->

<section class="inner_banner_section covid_page_banner">
    <div class="container">
       <div class="row">
          <div class="inner_banner_block">
               <div class="inner_banner_tilte">
                   <h1>covid 19</h1>
               </div>
          </div>
       </div>
    </div>
</section>

<!-- inner_banner_section end-->

<!-- covid_page_video_section end-->

<section class="covid_page_video_section">
    <div class="container">
       <div class="row">
           <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
               <div class="main_title_bar">
                   <h2>Covid Protection Protocols</h2>
                   <p>UClean has always committed itself to highest standards of hygiene.However, in view of the Covid19 Pandemic, <br> we have implemented enhanced COVID protocols to keep you and your clothes <span>100% Safe!</span></p>
               </div>
           </div>
           <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
               <div class="covid_video_block">
                   <img src="//assets.ucleanlaundry.com/assets/images/vidoe_bg_01.jpg">
                   <span><img src="//assets.ucleanlaundry.com/assets/images/video_btn.png"></span>
               </div>
           </div>
       </div>
    </div>
</section>

<!-- covid_page_video_section end-->

<!-- covid_page_section start-->

<section class="covid_page_section">
    <div class="container">
       <div class="row">
           <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
               <div class="main_title_bar">
                   <h2>10 Step COVID Protection</h2>
               </div>
           </div>
           <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top">
               <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div class="covid_block">
                       <div class="covid_icon">
                          <img src="//assets.ucleanlaundry.com/assets/images/covid_icon_01.png">
                       </div>
                       <div class="covid_text">
                          <p><span>1.</span><strong>Who 7 CDC</strong> Guidelines <br> followed to give you highest  <br>  standards of cleaning</p>
                       </div>
                    </div>
               </div>
               <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div class="covid_block">
                       <div class="covid_icon">
                          <img src="//assets.ucleanlaundry.com/assets/images/covid_icon_02.png">
                       </div>
                       <div class="covid_text">

                          <p><span>2.</span>		<strong>No Mixing</strong> of clothes. Separate <br>  cycle for each customer.</p>
                       </div>
                    </div>
               </div>
               <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div class="covid_block">
                       <div class="covid_icon">
                          <img src="//assets.ucleanlaundry.com/assets/images/covid_icon_03.png">
                       </div>
                       <div class="covid_text">

                          <p><span>3.</span>	<strong>Free Antiseptic</strong> for every wash. <br>  Hospital-Grade Quality & FDA  <br> Approvedd formulation</p>
                       </div>
                    </div>
               </div>
           </div>

           <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top">
               <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div class="covid_block">
                       <div class="covid_icon">
                          <img src="//assets.ucleanlaundry.com/assets/images/covid_icon_04.png">
                       </div>
                       <div class="covid_text">

                          <p><span>4.</span>	<strong>Steam Sterlisation</strong> Done for all <br> Steam lroned clothes  & Immediately <br> packed to prevent comtamination.</p>
                       </div>
                    </div>
               </div>
               <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div class="covid_block">
                       <div class="covid_icon">
                          <img src="//assets.ucleanlaundry.com/assets/images/covid_icon_05.png">
                       </div>
                       <div class="covid_text">

                          <p><span>5.</span>	<strong>High Temperature</strong> Air drying of clothes  <br> in galavanised steel dryers that kills 99.9%  <br> bacteria & Viruses.it is WHO recommended  <br>  & safer than line deying in open.</p>
                       </div>
                    </div>
               </div>
               <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div class="covid_block">
                       <div class="covid_icon">
                          <img src="//assets.ucleanlaundry.com/assets/images/covid_icon_06.png">
                       </div>
                       <div class="covid_text">

                          <p><span>6.</span>	<strong>Zero-Contact Service</strong> Available at <br>  your doorstep with FREE Pick-up <br> & Drop. e-bills & e-payments <br>  also  available.</p>
                       </div>
                    </div>
               </div>
           </div>

           <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top">
               <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div class="covid_block">
                       <div class="covid_icon">
                          <img src="//assets.ucleanlaundry.com/assets/images/covid_icon_07.png">
                       </div>
                       <div class="covid_text">

                          <p><span>7.</span>	<strong>Daily Temperature Checks</strong> for all team <br>  members. Delivery boys are checked <br> before each pickup & drop.</p>
                       </div>
                    </div>
               </div>
               <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div class="covid_block">
                       <div class="covid_icon">
                          <img src="//assets.ucleanlaundry.com/assets/images/covid_icon_08.png">
                       </div>
                       <div class="covid_text">

                          <p><span>8.</span>	<strong>Disinfection of Packges </strong> before every  <br> delivery for a safe exchange.</p>
                       </div>
                    </div>
               </div>
               <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                   <div class="covid_block">
                       <div class="covid_icon">
                          <img src="//assets.ucleanlaundry.com/assets/images/covid_icon_09.png">
                       </div>
                       <div class="covid_text">

                          <p><span>9.</span><strong>Daily Sanitisation</strong> of stores, equipments, <br>  bins, bage & other high touch points  <br> for staff & customer safety.</p>
                       </div>
                   </div>
               </div>

           <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top">
               <div class="col-lg-3 col-md-4 col-sm-2 col-xs-12">

               </div>
               <div class="col-lg-6 col-md-4 col-sm-8 col-xs-12">
                    <div class="covid_block">
                       <div class="covid_icon">
                          <img src="//assets.ucleanlaundry.com/assets/images/covid_icon_10.png">
                       </div>
                       <div class="covid_text">

                          <p><span>10.</span>	<strong>Separate Areas,</strong> bins, bags 7 equipments <br> for clean  & dirty clothes.</p>
                       </div>
                    </div>
               </div>
               <div class="col-lg-3 col-md-4 col-sm-2 col-xs-12">

               </div>
           </div>

           </div>
       </div>
    </div>
</section>

<!-- covid_page_section end-->

<!-- covid_mid_section start-->

<section class="covid_mid_section">
    <div class="container">
       <div class="row">
           <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
               <div class="main_title_bar">
                   <h2>Uclean is the india’s largest laundry <br> chain with 200+ franchise</h2>
               </div>
           </div>

           <div class="eft1"><img src="//assets.ucleanlaundry.com/assets/images/icon_1.png" alt="Laundry "></div>
           <div class="eft2"><img src="//assets.ucleanlaundry.com/assets/images/icon_4.png"></div>
       </div>
    </div>
</section>

<!-- covid_mid_section end-->

<!-- covid_clean_section start-->

<section class="covid_clean_section">
    <div class="container">
       <div class="row">
           <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
               <div class="main_title_bar">
                   <h2>Cleaning and Disinfecting your Home</h2>
               </div>
           </div>

           <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top">
               <div class="covid_block_sec">
                   <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 covid_block_1">
                       <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                           <div class="covid_icon_sec">
                               <img src="//assets.ucleanlaundry.com/assets/images/covid_icon_5.png">
                           </div>
                       </div>

                       <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                           <span class="point_sec">1</span>
                           <div class="covid_text">
                               <h4>Clean Frequently Touched Surfaces</h4>
                               <p>Clean highly touched surfaces that include Tables, doorknobs, light switches, countertops, handles, desks, toilets, faucets, sinks, etc. using soap and water</p>
                           </div>
                       </div>
                   </div>

                   <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 covid_block_2">
                       <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-lg-push-6 col-md-push-6 col-sm-push-6">
                           <div class="covid_icon_sec">
                               <img src="//assets.ucleanlaundry.com/assets/images/covid_icon_6.png">
                           </div>
                       </div>

                       <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-lg-pull-6 col-md-pull-6 col-sm-pull-6">
                           <span class="point_sec">2</span>
                           <div class="covid_text">
                               <h4>Disinfect</h4>
                               <p>Clean the area or item with soap and water and then use a household disinfectant Diluted household bleach solutions may also be used or alcoholic solutions with at least 70% alcoholLeave solution on the surface for at least 1 minute</p>
                           </div>
                       </div>
                   </div>

                   <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 covid_block_1">
                       <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                           <div class="covid_icon_sec">
                               <img src="//assets.ucleanlaundry.com/assets/images/covid_icon_7.png">
                           </div>
                       </div>

                       <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                           <span class="point_sec">3</span>
                           <div class="covid_text">
                               <h4> Soft Surfaces</h4>
                               <p>For soft surfaces such as carpeted floor, rugs, and drapes Clean the surface using soap and water or disinfect the surface with disinfactants Launder items (if possible) according to the manufacturer's instructions Use the warmest appropriate water setting and dry items completely</p>
                           </div>
                       </div>
                   </div>

                   <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 covid_block_2">
                       <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-lg-push-6 col-md-push-6 col-sm-push-6">
                           <div class="covid_icon_sec">
                               <img src="//assets.ucleanlaundry.com/assets/images/covid_icon_8.png">
                           </div>
                       </div>

                       <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-lg-pull-6 col-md-pull-6 col-sm-pull-6">
                           <span class="point_sec">4</span>
                           <div class="covid_text">
                               <h4>Electronics</h4>
                               <p>For electronics, such as tables, touch screens keyboard, and remote controlConsider putting a wipeable cover on electronicsYou can use alcohol-based wipes or sprays containing at least 70% alcohol. Dry surface thoroughly</p>
                           </div>
                       </div>
                   </div>

                   <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 covid_block_1">
                       <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                           <div class="covid_icon_sec">
                               <img src="//assets.ucleanlaundry.com/assets/images/covid_icon_9.png">
                           </div>
                       </div>

                       <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                           <span class="point_sec">5</span>
                           <div class="covid_text">
                               <h4>Laundry</h4>
                               <p>Use the warmest appropriate water setting and dry items completelyWear disposable gloves when handling dirty laundry from a person who is sick. Remove gloves, and wash hands right away Dirty laundry from a person who is sick can be washed with other people's items</p>
                           </div>
                       </div>
                   </div>

               </div>
           </div>

       </div>
    </div>
</section>

<!-- covid_clean_section0 end-->
