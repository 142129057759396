import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from 'src/environments/environment';
import { LocationService } from '../services/location.service';
@Directive({
  selector: '[GooglePlace]',
  providers: [NgModel],
  host: {
    '(input)': 'onInputChange()'
  }
})
export class GooglePlaceDirective {

  modelValue: any;
  private _el!: any;
  autocomplete: any;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @Output() searchState: EventEmitter<any> = new EventEmitter();


  constructor(el: ElementRef, private model: NgModel) {
    this.loadGooglePackage(el, model);
  }

  loadGooglePackage(el: ElementRef, model: NgModel) {
    let that: any = this;
    new Loader({
      apiKey: environment.GOOGLE_API_KEY,
      libraries: ['places']
    }).load().then(() => {
    this._el = el.nativeElement;
    (this._el as HTMLElement).classList.add('google-place-input');
    (this._el as HTMLElement).addEventListener('focus', (e: any) => {
      e.target.classList.remove('google-place-input');
    });
    (this._el as HTMLElement).addEventListener('focusout', (e: any) => {
      e.target.classList.add('google-place-input');
    });
    this.modelValue = this.model;
    var input = this._el;
    this.autocomplete = new google.maps.places.Autocomplete(input, {});
    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      var place = this.autocomplete.getPlace();
      let location = {} as any;
      location.lat = place.geometry.location.lat();
      location.lng = place.geometry.location.lng();
      location.name = place.formatted_address as string;
      let geocoder = new google.maps.Geocoder();
      let latlng = new google.maps.LatLng(location.lat, location.lng);
      this.searchState.emit(true);
      geocoder.geocode({ 'location': latlng }, (results, status) => {
        if (results != null && results != undefined && status == "OK") {
          location.pincode = this.getPincodeFromAddress(results[0]);
          location.city = this.getCityFromAddress(results[0]);
          console.log(location);
          that.location = location;
          this.invokeEvent(location);
          this.searchState.emit(false);
        } else {
          console.log(status);
        }
      })
    })
    });
  }

  invokeEvent(place: any) {
    this.setAddress.emit(place);
  }

  onInputChange() {
    //console.log(this.model);
  }

  getPincodeFromAddress(address_result: any): any{
    let components = address_result.address_components;
    for (let i = 0; i < components.length; i++) {
      let types = components[i].types;
      if(types.length == 1 && types[0] == "postal_code"){
        return components[i].short_name;
      }
    }
  }

  getCityFromAddress(address_result: any): any{
    let components = address_result.address_components;
    for (let i = 0; i < components.length; i++) {
      let types = components[i].types;
      // console.log(types, components[i].short_name);
      if(types.length == 2 && types.includes("locality") && types.includes("political")){
        return components[i].short_name;
      }
    }
  }

}
