import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sofa-cleaning',
  templateUrl: './sofa-cleaning.component.html',
  styleUrls: ['./sofa-cleaning.component.scss']
})
export class SofaCleaningComponent implements OnInit {
  bannerImageUrl = `${environment.imageBaseUrl}Banner/SofaCleaning.jpg`;
  sofaCleaningImage: string = `${environment.imageBaseUrl}cta/SofaCleaning1.jpg`;
  processSteps = [
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step6.svg`,
      title: 'Garment Inspection',
      description: 'Inspect fabric, stains, and colors. Assess fiber type for cleaning needs. Check for potential color bleeding.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step5.svg`,
      title: 'Stain Removal',
      description: 'Remove stains with special solvents. Focus on tough spots for thorough cleaning. Pre-wash treatment for all visible stains.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step4.svg`,
      title: 'Customized Clean',
      description: 'Use garment-specific cleaning methods. Adjust cleaning techniques per fabric. Ensure thorough, gentle cleaning.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step3.svg`,
      title: 'Finishing',
      description: 'Steam iron and add finishing treatments. Apply starch for stiffness if needed. Polish zari and soften fabric. Add a water repellent coat.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step2.svg`,
      title: 'Quality Check',
      description: 'Multiple quality checks to ensure top quality and customer satisfaction.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step1.svg`,
      title: 'Packaging',
      description: 'Packaging options as per your needs. Combined, Individual or Hanger Packaging.'
    }
  ];
  
  constructor() { }

  ngOnInit(): void {
  }

}
