<!-- hp_location_section start-->	
	  
<section class="hp_location_section">
    <div class="container">
       <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
             <div class="main_title_bar">
                <h2 class="deli">Cities We Deliver to</h2>
             </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top">
             <div class="locat_sec" *ngFor="let city of citiesList | sort:'asc':'name'; index as i">
                <li  (click)="getCityStores(city.id,i)">{{city.name}}</li>					
             </div>
          </div>
       </div>
    </div>
 </section>
 
 <!-- hp_location_section end-->	