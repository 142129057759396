import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import * as $ from 'jquery'

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss']
})
export class OurServicesComponent implements OnInit {

  constructor(private metaTagService: Meta) { }

  ngOnInit(): void {
    // console.log("Here...")
  }
}
