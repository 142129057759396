import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { PixelService } from 'ngx-pixel';
import { metaTags } from 'src/assets/meta-contant';
import { PagesService } from '../pages.service';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {

  constructor(
    private pixel: PixelService,
    private pagesService: PagesService, private metaTagService: Meta) { }

  mediaData:any;

  ngOnInit(): void {
    this.pagesService.media().subscribe((data:any)=>{
      this.mediaData = data.medias;
    });
    this.pixel.trackCustom('MediaPage');
    this.metaTagService.updateTag({ property: 'title', content: metaTags?.media?.title });
    this.metaTagService.updateTag({ property: 'description', content: metaTags?.media?.description });
  }

  p : any= [];

  scrollToTop(){
    window.scroll(0,0);
  }

}
