<!-- inner_banner_section start-->

<section class="inner_banner_section_services">
   <div class="container">
      <div class="row">
         <div class="inner_banner_block">
            <div class="inner_banner_tilte">
               <!-- <h1>UCLEAN SERVICES</h1> -->
            </div>
         </div>
      </div>
   </div>
</section>

<!-- inner_banner_section end-->

<!-- services_page_section start-->


<section class="page_services_section">
   <div class="container">
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 " style="height: 20px;">
            <div class="sec_title_bar">
               <h2>Our Services</h2>
            </div>
         </div>
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 services">
            <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
               <div class="tab-pane active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  <div id="owl-demo" class="owl-carousel owl-theme">
                     <div class="item"  (click)="navigateToService(data.name)"  [class.phase-active]="activeButton === i" *ngFor="let data of services; index as i;">
                        <div class="serv_block_page_page">
                           <div class="service_img_sec">
                              <img src="{{data.img}}">
                           </div>
                           <div class="service_text_sec">
                              <p>{{data.name}}</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<section class="ab_about_section">
   <div class="container">
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
            <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
               <div class="ab_about_img">
                  <img src="//assets.ucleanlaundry.com/assets/images/service_main_image_11zon.webp">
               </div>
            </div>
            <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12">
               <div class="ab_service_title">
                  <h2>LAUNDRY</h2>
                  <span></span>
                  <p> The laundry process at UClean begins by sorting the clothes. After sorting, similar clothes are
                     washed together. The best detergent brands are used for washing clothes. The washing machine is
                     then loaded by adjusting the right setting for your clothes. The freshwater rinse during the
                     process helps in eliminating the dirt and stains from the clothes. We air-dry your clothes in
                     anti-bacterial stainless-steel air-dryers that eliminate Direct UV rays from the sun which react with the colour
                     pigments of your clothes and create bleaching effect. After the laundry cycle ends,High pressure
                     vacuum steam iron is done for that perfect finishing
                     . As per CDC and WHO, steam sterilization is best and safest way to sanitize your clothes.
                  </p>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<section class="ab_promise_section">
   <div class="container">
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 service">
               <div class="promise_block pro-ser">
                  <div class="serv_promise_icon">
                     <img src="//assets.ucleanlaundry.com/assets/images/affordable_rates_11zon.webp">
                  </div>
                  <div class="promise_text pro-ser-p">
                     <p>Affordable rates</p>
                  </div>
               </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 service">
               <div class="promise_block pro-ser">
                  <div class="serv_promise_icon">
                     <img src="//assets.ucleanlaundry.com/assets/images/skin_friendly_11zon.webp">
                  </div>
                  <div class="promise_text pro-ser-p">
                     <p>Skin friendly</p>
                  </div>
               </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 service">
               <div class="promise_block pro-ser">
                  <div class="serv_promise_icon" style="padding-top: 20px">
                     <img src="//assets.ucleanlaundry.com/assets/images/uv_safe_air_drying_11zon.webp">
                  </div>
                  <div class="promise_text pro-ser-p">
                     <p> UV Safe Air Drying</p>
                  </div>
               </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 service">
               <div class="promise_block pro-ser">
                  <div class="serv_promise_icon" style="padding-top: 12px">
                     <img src="//assets.ucleanlaundry.com/assets/images/uses_less_fresh_water_11zon.webp">
                  </div>
                  <div class="promise_text pro-ser-p">
                     <p>Uses less & Fresh Water</p>
                  </div>
               </div>
            </div>
         </div>
         <div class="text-center">
            <button type="button" routerLink="/" class="btn btn-primary place-order">Place Order</button>
         </div>
      </div>
   </div>
</section>
<section class="upis_section">
   <div class="container">
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="sec_title_bar">
               <h2>USPs</h2>
            </div>
         </div>
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <li class="col-lg-6 col-md-6 col-sm-6 col-xs-12 rr_block">
               <div class="reviews_sec">
                  <span>
                     <p>It is Cheaper, its Laundry by Kilo</p>
                  </span>
                  <p>YES! Outsourcing your laundry is cheaper than washing clothes at home. A family of four
                     spends approximately Rs. 2500 per month on...</p>
                  <span>
                     <p>Read more</p>
                  </span>
               </div>
            </li>
            <li class="col-lg-6 col-md-6 col-sm-6 col-xs-12 rr_block">
               <div class="reviews_sec">
                  <span>
                     <p>It Saves Time, Space & Water</p>
                  </span>
                  <p>It takes about 40-50 hours per month to do the laundry for a family of 4. Further, you need a
                     dedicated space for line drying your clothes, ...</p>
                  <span>
                     <p>Read more</p>
                  </span>
               </div>
            </li>
            <li class="col-lg-6 col-md-6 col-sm-6 col-xs-12 rr_block">
               <div class="reviews_sec">
                  <span>
                     <p>High-Quality Cleaning</p>
                  </span>
                  <p>Our team of dedicated experts are well-trained to take care of varied garments that you send
                     to us.
                     At UClean, we focus on providing adequate care...</p>
                  <span>
                     <p>Read more</p>
                  </span>
               </div>
            </li>
            <li class="col-lg-6 col-md-6 col-sm-6 col-xs-12 rr_block">
               <div class="reviews_sec">
                  <span>
                     <p>Skin-friendly</p>
                  </span>
                  <p>Unlike your homes, our stores carry a buffet of detergents, emulsifiers, stain treatment kits,
                     softeners, antiseptics, whiteners and finishing...</p>
                  <span>
                     <p>Read more</p>
                  </span>
               </div>
            </li>
            <li class="col-lg-6 col-md-6 col-sm-6 col-xs-12 rr_block">
               <div class="reviews_sec">
                  <span>
                     <p>Air Drying with Temperature Control</p>
                  </span>
                  <p>Line drying under the sun makes your garment dull and dry. Direct UV rays from the sun
                     react with the colour pigments of your clothes and create...</p>
                  <span>
                     <p>Read more</p>
                  </span>
               </div>
            </li>
            <li class="col-lg-6 col-md-6 col-sm-6 col-xs-12 rr_block">
               <div class="reviews_sec">
                  <span>
                     <p>High-pressure Steam iron instead of hot plate steam iron (Only done for Wash & Iron
                        Service)</p>
                  </span>
                  <p>Have you ever noticed shine-marks on your polyester trousers or those poly-georgette tops?
                     This happens because the home iron or...</p>
                  <span>
                     <p>Read more</p>
                  </span>
               </div>
            </li>
         </div>
      </div>
   </div>
</section>
<section class="laundry_process_section">
   <div class="container">
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
               <div class="sec_title_bar">
                  <h2>The Laundry Process</h2>
               </div>
            </div>
         </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top">
         <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 uper">
            <div class="promise_block">
               <div class="promise_text up">
                  <p>Clothes are carefully sorted based on colour, material, texture, soiling level etc.</p>
               </div>
            </div>
         </div>
         <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 uper">
            <div class="promise_block">
               <div class="promise_text up">
                  <p>Clothes with extra soiling & oil stains are treated to a special enzyme soak before washing</p>
               </div>
            </div>
         </div>
         <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 uper">
            <div class="promise_block">
               <div class="promise_text up">
                  <p>Softener is added as per finishing required</p>
               </div>
            </div>
         </div>
         <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 uper">
            <div class="promise_block">
               <div class="promise_text up">
                  <p>High pressure vacuum steam iron is done for that perfect finishing</p>
               </div>
            </div>
         </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
         <div class="process_structure">
            <div class="process_structure_img">
               <ul>
                  <li class="uper">
                     <div class="steps_name">
                        <p>Tagging</p>
                     </div>
                     <div class="img_section up">
                        <img src="//assets.ucleanlaundry.com/assets/images/Tagging_11zon.webp">
                     </div>
                  </li>
                  <li class="connect_imgs_1">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_2.png">
                  </li>
                  <li class="down">
                     <div class="img_section down">
                        <img src="//assets.ucleanlaundry.com/assets/images/sorting_11zon.webp">
                     </div>
                     <div class="steps_name">
                        <p>Sorting</p>
                     </div>
                  </li>
                  <li class="connect_imgs_2">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_1.png">
                  </li>
                  <li class="uper">
                     <div class="steps_name">
                        <p>Treatment</p>
                     </div>
                     <div class="img_section up">
                        <img src="//assets.ucleanlaundry.com/assets/images/cuff_collar_11zon.webp">
                     </div>
                  </li>
                  <li class="connect_imgs_1">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_2.png">
                  </li>
                  <li class="down">
                     <div class="img_section down">
                        <img src="//assets.ucleanlaundry.com/assets/images/pre_wash_11zon.webp">
                     </div>
                     <div class="steps_name">
                        <p>Pre-Wash</p>
                     </div>
                  </li>
                  <li class="connect_imgs_2">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_1.png">
                  </li>
                  <li class="uper">
                     <div class="steps_name">
                        <p>Washing</p>
                     </div>
                     <div class="img_section up">
                        <img src="//assets.ucleanlaundry.com/assets/images/washing_11zon.webp">
                     </div>
                  </li>
                  <li class="connect_imgs_1">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_2.png">
                  </li>
                  <li class="down">
                     <div class="img_section down">
                        <img src="//assets.ucleanlaundry.com/assets/images/softener_11zon.webp">
                     </div>
                     <div class="steps_name">
                        <p>Softener</p>
                     </div>
                  </li>
                  <li class="connect_imgs_2">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_1.png">
                  </li>
                  <li class="uper">
                     <div class="steps_name">
                        <p>Drying</p>
                     </div>
                     <div class="img_section up">
                        <img src="//assets.ucleanlaundry.com/assets/images/drying_11zon.webp">
                     </div>
                  </li>
                  <li class="connect_imgs_1">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_2.png">
                  </li>
                  <li class="down">
                     <div class="img_section down">
                        <img src="//assets.ucleanlaundry.com/assets/images/ironing.png">
                     </div>
                     <div class="steps_name">
                        <p>Ironing</p>
                     </div>
                  </li>
               </ul>
            </div>
         </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top">
         <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 dwon">
            <div class="promise_block">
               <div class="promise_text down">
                  <p>Clothes are tagged to avoid potential mix up</p>
               </div>
            </div>
         </div>
         <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 dwon">
            <div class="promise_block">
               <div class="promise_text down">
                  <p>Cuff & collar are treated using special detergents</p>
               </div>
            </div>
         </div>
         <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 dwon">
            <div class="promise_block">
               <div class="promise_text down">
                  <p>Clothes are washed as per the load type</p>
               </div>
            </div>
         </div>
         <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 dwon">
            <div class="promise_block">
               <div class="promise_text down">
                  <p>Clothes are Air-dried in anti-bacterial stainless-steel dryers</p>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<section class="laundry_process_section_mob">
   <div class="container">
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
               <div class="sec_title_bar">
                  <h2>The Laundry Process</h2>
               </div>
            </div>
         </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pro-pad">
         <div class="process_structure_mob">
            <div class="process_structure_img_mob tagging tag-padd">
               <ul >
                  <li class="uper_mob">
                     <div class="steps_name_mob">
                        <p>Tagging</p>
                     </div>
                     <div class="img_section up">
                        <img src="//assets.ucleanlaundry.com/assets/images/Tagging.png">
                     </div>
                  </li>
                  <li class="connect_imgs_1">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_2_mob.png">
                  </li>
                  <li class="down_mob">
                     <div class="img_section down">
                        <img src="//assets.ucleanlaundry.com/assets/images/sorting_11zon.webp">
                     </div>
                     <div class="steps_name_mob">
                        <p>Sorting</p>
                     </div>
                  </li>
                  <li class="connect_imgs_2" style="margin-top:-7%;">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_1_mob.png">
                  </li>
                  <li class="uper_mob">
                     <div class="steps_name_mob">
                        <p>Treatment</p>
                     </div>
                     <div class="img_section up">
                        <img src="//assets.ucleanlaundry.com/assets/images/cuff_collar_11zon.webp">
                     </div>
                  </li>
                  <li class="connect_imgs_1"  style="margin-top: -9%;">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_2_mob.png">
                  </li>
                  <li class="down_mob">
                     <div class="img_section down">
                        <img src="//assets.ucleanlaundry.com/assets/images/pre_wash_11zon.webp">
                     </div>
                     <div class="steps_name_mob">
                        <p>Pre-Wash</p>
                     </div>
                  </li>
                  <li class="connect_imgs_2" style="margin-top: -7%; margin-bottom: 2%;">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_1_mob.png">
                  </li>
                  <li class="uper_mob">
                     <div class="steps_name_mob">
                        <p>Washing</p>
                     </div>
                     <div class="img_section up">
                        <img src="//assets.ucleanlaundry.com/assets/images/washing_11zon.webp">
                     </div>
                  </li>
                  <li class="connect_imgs_1">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_2_mob.png">
                  </li>
                  <li class="down_mob">
                     <div class="img_section down">
                        <img src="//assets.ucleanlaundry.com/assets/images/softener_11zon.webp">
                     </div>
                     <div class="steps_name_mob">
                        <p>Softener</p>
                     </div>
                  </li>
                  <li class="connect_imgs_2" style="margin-top: -7%;">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_1_mob.png">
                  </li>
                  <li class="uper_mob">
                     <div class="steps_name_mob" style="margin-left: 10%;">
                        <p>Drying</p>
                     </div>
                     <div class="img_section up">
                        <img src="//assets.ucleanlaundry.com/assets/images/drying_11zon.webp">
                     </div>
                  </li>
                  <li class="connect_imgs_1" style="margin-top: -7%;">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_2_mob.png">
                  </li>
                  <li class="down_mob">
                     <div class="img_section down">
                        <img src="//assets.ucleanlaundry.com/assets/images/ironing.png">
                     </div>
                     <div class="steps_name_mob">
                        <p>Ironing</p>
                     </div>
                  </li>
               </ul>
            </div>
         </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 lp">
         <div class="process_text">
         <p>The laundry process at UClean begins by sorting the clothes. After sorting, similar clothes are washed together. The best detergent brands are used for washing clothes. The washing
            machine is then loaded by adjusting the right setting for your clothes. The freshwater rinse
            during the process helps in eliminating the dirt and stains from the clothes. After the laundry
            cycle ends, the washer is unloaded and the clothes are sent for ironing. Ironing takes place
            with great attention to the garments.</p>
         </div>
      </div>
   </div>
</section>
<section class="services_faq_section service">
   <div class="container">
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="sec_title_bar">
               <h2>Frequently Ask Questions</h2>
            </div>
         </div>
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="accordion_one">
               <div class="panel-group" id="accordion_oneLeft">
                  <div class="panel panel-default">
                     <div class="panel-heading service">
                        <h4 class="panel-title" id="question"> <a data-toggle="collapse" data-parent="#accordion_oneLeft"
                              href="#collapseFiveLeftone" aria-expanded="false" class="collapsed">What all services does UClean offer?</a> </h4>
                     </div>
                     <div id="collapseFiveLeftone" class="panel-collapse collapse" aria-expanded="false" role="tablist"
                        style="height: 0px;">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>Yes, UClean offers many other services like laundry, premium laundry,  dry cleaning, steam ironing, shoe cleaning, bag cleaning, and sofa cleaning. </p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLeftTwo" aria-expanded="false">What type of laundry services does UClean offer?</a> </h4>
                     </div>
                     <div id="collapseFiveLeftTwo" class="panel-collapse collapse" aria-expanded="false" role="tablist"
                        style="height: 0px;">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>UClean offers two types of laundry services as per your requirements. We offer laundry by kilogram for regular clothes in that you can choose to wash & fold or wash & iron. We also provide premium laundry for your formal wear, occasion wears without embellishments.</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLeftThree" aria-expanded="false">Does UClean offer corporate or bulk laundry services?</a> </h4>
                     </div>
                     <div id="collapseFiveLeftThree" class="panel-collapse collapse" aria-expanded="false"
                        role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>Yes, UClean offers corporate or bulk laundry services.</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLeftfour" aria-expanded="false">What is your pricing structure for different services?</a> </h4>
                     </div>
                     <div id="collapseFiveLeftfour" class="panel-collapse collapse" aria-expanded="false"
                        role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>Our pricing varies depending on the service and item. You can find a detailed price list on our website or app. We offer competitive rates for all our services.</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLeftfive" aria-expanded="false">Do you offer commercial laundry services for businesses?</a> </h4>
                     </div>
                     <div id="collapseFiveLeftfive" class="panel-collapse collapse" aria-expanded="false"
                        role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>Yes, we provide commercial laundry services for businesses. Please contact our customer service for more information about our business solutions.</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLeftsix" aria-expanded="false">How does UClean ensure the quality of its services?</a> </h4>
                     </div>
                     <div id="collapseFiveLeftsix" class="panel-collapse collapse" aria-expanded="false" role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>UClean ensures quality through trained professionals, modern equipment, and strict quality control. We use eco-friendly cleaning agents and adhere to industry best practices. Each item is carefully sorted and handled to deliver excellent results consistently. </p>
                           </div>
                        </div>
                     </div>
                  </div>


                  
                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLeftseven" aria-expanded="false">Does UClean offer garment repair or alteration services?</a> </h4>
                     </div>
                     <div id="collapseFiveLeftseven" class="panel-collapse collapse" aria-expanded="false"
                        role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>No, UClean does not offer any garment repair or alteration services.</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLefteight" aria-expanded="false">Does UClean offer stain removal guarantees?</a> </h4>
                     </div>
                     <div id="collapseFiveLefteight" class="panel-collapse collapse" aria-expanded="false"
                        role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>While we strive to achieve the best possible results in stain removal, we cannot guarantee that every stain will be completely eliminated. However, please rest assured that we will make every effort to treat and minimize the stain to the best of our ability.</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLeftnine" aria-expanded="false">Do you offer wash-and-fold services for regular laundry?</a> </h4>
                     </div>
                     <div id="collapseFiveLeftnine" class="panel-collapse collapse" aria-expanded="false"
                        role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>Yes, we offer wash-and-fold services for regular laundry. This is a convenient option for everyday items that don't require dry cleaning or special care.</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLeftten" aria-expanded="false">Can you clean large items like comforters, curtains, or rugs?</a> </h4>
                     </div>
                     <div id="collapseFiveLeftten" class="panel-collapse collapse" aria-expanded="false"
                        role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>Yes, we can clean large items such as comforters, curtains, and rugs. Please inform us when scheduling your service, as these items may require special handling.</p>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLefteleven" aria-expanded="false">Does UClean handle specialty items like wedding dresses or leather goods?</a> </h4>
                     </div>
                     <div id="collapseFiveLefteleven" class="panel-collapse collapse" aria-expanded="false"
                        role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>Yes, UClean handles specialty items like wedding dresses or leather goods. We are one of the first laundry chains to offer a complete leather cleaning service.</p>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLefttwelve" aria-expanded="false">Does UClean provide laundry services for specialty items like baby clothes or pet bedding?</a> </h4>
                     </div>
                     <div id="collapseFiveLefttwelve" class="panel-collapse collapse" aria-expanded="false"
                        role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>No, UClean does offer laundry services for pet clothing and bedding. For baby clothes, you may request a separate wash with an additional cycle of antiseptic wash.</p>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLeftthirteen" aria-expanded="false">Do you offer leather or suede cleaning services?</a> </h4>
                     </div>
                     <div id="collapseFiveLeftthirteen" class="panel-collapse collapse" aria-expanded="false"
                        role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>Yes, we offer cleaning services for leather and suede items. These require special care and cleaning methods, which our trained staffs are equipped to handle.</p>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLeftforteen" aria-expanded="false">Can you remove tough odors from clothes?</a> </h4>
                     </div>
                     <div id="collapseFiveLeftforteen" class="panel-collapse collapse" aria-expanded="false"
                        role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>Yes, we have specialized treatments for removing tough odors from clothes. Please inform us about any strong odors when placing your order.</p>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLeftfifteen" aria-expanded="false">Do you offer stain removal services?</a> </h4>
                     </div>
                     <div id="collapseFiveLeftfifteen" class="panel-collapse collapse" aria-expanded="false"
                        role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>Yes, we offer stain removal services. Our experts are trained to treat a wide variety of stains using appropriate techniques and products.</p>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLeftsixteen" aria-expanded="false">How do you handle delicate or specialty fabrics?</a> </h4>
                     </div>
                     <div id="collapseFiveLeftsixteen" class="panel-collapse collapse" aria-expanded="false"
                        role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>Our experienced staff is trained to handle a variety of fabrics. We use appropriate cleaning methods and products for delicate items like silk, wool, and other specialty fabrics.</p>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLeftseventeen" aria-expanded="false">What types of fabrics can you clean?</a> </h4>
                     </div>
                     <div id="collapseFiveLeftseventeen" class="panel-collapse collapse" aria-expanded="false"
                        role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>We can clean most fabric types, including cotton, wool, silk, polyester, leather, suede, and more. If you're unsure about a particular fabric, please ask our staff.</p>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLefteighteen" aria-expanded="false">What are the turnaround times for laundry services?</a> </h4>
                     </div>
                     <div id="collapseFiveLefteighteen" class="panel-collapse collapse" aria-expanded="false"
                        role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>For Laundry Wash & Fold, the turnaround time is 12-24 Hrs. For laundry (wash & iron) and premium laundry, it is 24-48 hours. For dry cleaning, shoe and bag Cleaning, it is 3-5 days. We also provide express services for same-day deliveries on a chargeable basis.</p>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLeftnineteen" aria-expanded="false">How long does dry cleaning typically take?</a> </h4>
                     </div>
                     <div id="collapseFiveLeftnineteen" class="panel-collapse collapse" aria-expanded="false"
                        role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>Standard dry cleaning usually takes 3-5 business days. We also offer express services for an additional fee</p>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLefttwenty" aria-expanded="false">Do you offer express or same-day services?</a> </h4>
                     </div>
                     <div id="collapseFiveLefttwenty" class="panel-collapse collapse" aria-expanded="false"
                        role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>Yes, we offer express and same-day services for most of our cleaning options at an additional cost. Availability may depend on the specific service and current workload.</p>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLefttwentyone" aria-expanded="false">Can I specify any special instructions for my laundry?</a> </h4>
                     </div>
                     <div id="collapseFiveLefttwentyone" class="panel-collapse collapse" aria-expanded="false"
                        role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>Yes, you can specify any special instructions while placing your order on the app. You may also call the store for the same once the order is placed.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            
            </div>
         </div>
      </div>
   </div>
</section>