<section class="screen otp-success">
    <app-auth-wrapper [mainBannerText]="mainBannerText" [isLoading]="showLoader">
        <div class="form-wrapper">
            <div class="backdrop">
                <img src="//assets.ucleanlaundry.com/assets/images/login/back-arrow.svg" (click)="goBackToPrevScreen()"> Back to previous screen
            </div>
            <div class="form-header-wrapper font-700">
                <p>Order confirmed</p>
                <span class="line"></span>
            </div>
            <div class="success-msg-container">
                <img src="//assets.ucleanlaundry.com/assets/images/login/success.svg" alt="success">
                <p><b>YOUR ORDER IS CONFIRMED</b></p>
            </div>
            <div class="msg-description">
                <div [innerHTML]="descriptionText"></div>
            </div>
            <div class="modal-footer-button">
                <div class="button-wrapper">
                    <div class="btn" matRipple (click)="goToHome()"><p>Back to Home</p></div>
                    <div class="btn primary-footer-button" matRipple (click)="takeToBuildOrder()"><p>Continue ordering</p></div>
                </div>
            </div>
        </div>
    </app-auth-wrapper>
</section>