import { Component, OnInit } from '@angular/core';
import {Address} from 'src/app/models/addresses';
import {PagesService} from '../../pages/pages.service';
import {AddressService} from '../../../services/address.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '../../../models/location';
import {PositionService} from '../../../services/position.service';
import { OrderService } from '../../build-order/order.service';
import { LocationService } from 'src/app/services/location.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-user-addresses',
  templateUrl: './user-addresses.component.html',
  styleUrls: ['./user-addresses.component.scss']
})
export class UserAddressesComponent implements OnInit {
  // Query params
  location: any;
  selectedStoreId: any;
  selectedServiceIds: any;
  selectedSlotId: any;
  selectedAddressId: any;

  // view states
  showLoader = true;
  showAddressList = false;
  showAddressForm = false;
  noAddressSelectedError = false;
  disableInputType = true;
  validation: any = {};
  autoComplete: boolean = false;

  constructor(
    private metaTagService: Meta,
    private pagesService: PagesService, private route: ActivatedRoute,private locationService: LocationService,
              private router: Router, private orderService: OrderService,
              private addressService: AddressService, private positionService: PositionService) {
  }

  // all the addresses got from server
  addresses: any;
  citiesList: any;

  isNewAddr: boolean = false;
  address: any = {};

  searchLocation: any;
  lat: any;
  lng: any;

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.location = {} as Location;
      this.location.lat = params['lat'];
      this.location.lng = params['lng'];
      this.location.name = params['locationName'];
      this.location.pincode = params['pincode'];
      this.location.city = params['city'];
      this.selectedAddressId = params['address_id'];

      // get addresses
      this.addressService.getAllAddresses().subscribe((data: any) => {
        this.addresses = data.addresses;
        this.showLoader = false;
        // if empty addresses then show form directly
        if(this.addresses == undefined || this.addresses.length <= 0){
          this.addNewAddress();
          this.showAddressList = false;
        }else{
          this.showAddressList = true;
        }
      });

      this.cities();
    });

  }


  getLocation(){
    this.positionService.currentLocations(this, this.positionCallback);
  }

  enableAutoComplete(){
    if(!this.autoComplete){
      this.locationService.initGeoComplete('searchRegion', this, this.locationCallback, "USER_ADDRESS");
      this.autoComplete = true;
    }

  }

  positionCallback(that: any, location: Location){
    that.searchLocation = location.name;
    that.setAddress(location);
  }

  locationCallback(that: any, location: Location) {
    that.positionService.location = location;
    that.setAddress(location);
  }

  setAddress(location: Location){
    for(let i = 0; i < this.citiesList.length; i++){
      if(this.citiesList[i].name.toLowerCase() == location.city.toLowerCase()){
        location.city_id = this.citiesList[i].id;
      }
    }
    this.address = {"latitude": location.lat, "longitude": location.lng,
      "location": location.name, "pincode": location.pincode, "city_id": location.city_id};

  }

  disableInputForType(){
    this.disableInputType = false;
  }

  showEditUserForm(address: Address) {
    this.showAddressForm = true;
    this.address = address;
    this.searchLocation = this.address.location;
    this.isNewAddr = false;
  }

  addNewAddress() {
    this.showAddressForm = true;
    this.isNewAddr = true;
  }

  hideAddressForm() {
    this.showAddressForm = false;
  }

  validate() {
    let ok = true;
    if(this.address.type == undefined || this.address.type.trim() == ""){
      this.validation.type = "has-error";
      ok = false
    }else{
      this.validation.type = "";
    }

    if(this.address.flat_info == undefined || this.address.flat_info.trim() == ""){
      this.validation.flat_info = "has-error";
      ok = false
    }else{
      this.validation.flat_info = "";
    }

    if(this.address.street_info == undefined || this.address.street_info.trim() == ""){
      this.validation.street_info = "has-error";
      ok = false
    }else{
      this.validation.street_info = "";
    }

    if(this.address.landmark == undefined || this.address.landmark.trim() == ""){
      this.validation.landmark = "has-error";
      ok = false
    }else{
      this.validation.landmark = "";
    }

    if(this.address.city_id == undefined){
      this.validation.city_id = "has-error";
      ok = false
    }else{
      this.validation.city_id = "";
    }

    if(this.address.pincode == undefined){
      this.validation.pincode = "has-error";
      ok = false
    }else{
      this.validation.pincode = "";
    }

    return ok;
  }

  saveAdd() {
    if(this.validate()){
      if(this.address.id != undefined){
        this.addressService.updateAddress(this.address).subscribe((data: any) => {
          if(data['status'] == "SUCCESS"){
            // Select it
            this.selectedAddressId = data['address']['id'];

            // Close the form and empty the address
            this.address = {};
            this.hideAddressForm();
          }
        });
      }else{
        this.addressService.createNewAddress(this.address).subscribe((data: any) => {
          if(data['status'] == "SUCCESS"){
            // Add to the list and select it
            this.addresses.push(data['address']);
            this.selectedAddressId = data['address']['id'];

            // Close the form and empty the address
            this.address = {};
            this.hideAddressForm();
          }
        });
      }
    }
  }

  removeAdd(address: any) {
    // ask for confirmation then only delete the address
    if (confirm("Are you sure you want to delete this address!") == true) {
      // make the call to backend
      this.addressService.deleteAddress(address.id).subscribe((data: any) => {
        if(data['status'] == "SUCCESS"){
          // if it was selected address remove it
          if(this.selectedAddressId == address.id){
            this.selectedAddressId = undefined;
          }

          // remove from the local array as well.
          this.removeAddFromLocal(address.id);
        }

      })
    }
  }

  removeAddFromLocal(address_id: string){
    for (let i = 0; i < this.addresses.length; i++) {
      let obj = this.addresses[i];
      if (address_id == obj.id) {
        this.addresses.splice(i, 1);
        return;
      }
    }

  }

  selectAddress(addressId: string){
    this.selectedAddressId = addressId;
  }

  getClass(addressId: string) {
    if(this.selectedAddressId == addressId){
      return 'active';
    }
    return '';
  }


  // get data from services
  cities(){
    this.pagesService.cityList().subscribe((data : any)=>{
      this.citiesList = data.cities;
    });
  }
}
