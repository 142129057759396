import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  addressesUrl = environment.baseUrl + 'webusers/getUserAddresses?franchise=' + environment.franchise; //DONE
  addressDeleteUrl = environment.baseUrl + 'webusers/address/:id?franchise=' + environment.franchise;   //DONE
  createNewAddUrl = environment.baseUrl + 'webusers/addAddress?franchise='+ environment.franchise;   //DONE
  updateAddressUrl = environment.baseUrl + 'webusers/updateAddress/:id?franchise=' + environment.franchise; //DONE
  checkServiceProviderUrl = environment.baseUrl + 'webusers/stores';  //DONE
  constructor(private httpclient: HttpClient) { }

  getAddresses(store_id: string) {
    return this.httpclient.get(this.addressesUrl + "&store_id=" + store_id);
  }
  
  createNewAddress(address: any) {
    return this.httpclient.post(this.createNewAddUrl, address);
  }
  
  updateAddress(address: any) {
    return this.httpclient.put(this.updateAddressUrl + address.id, address);
  }
  
  deleteAddress(address_id: string) {
    const base_url = this.addressDeleteUrl.replace(':id', address_id);
    return this.httpclient.delete(base_url);
  }
  
  getAllAddresses() {
    return this.httpclient.get(this.addressesUrl);
  }
  
  checkServiceProviderAvailablity(address: any) {
    return this.httpclient.get(this.checkServiceProviderUrl + "?franchise=UCLEAN&lat=" + address?.latitude + "&lng=" + address?.longitude + "&radius=10");
  }
}
