import { Component, OnInit, ViewChild } from '@angular/core';
import { BannerService } from './banner.service';
import { LocationService } from '../../../services/location.service';
import { Location } from '../../../models/location';
import { Router } from '@angular/router';
import { PositionService } from '../../../services/position.service';
import { Meta } from '@angular/platform-browser';
import { CustomLocationInfoModel, LocationModel } from 'src/app/models/leaflet';
import * as mapConfig from '../../components/leaflet-map/map-config';
import { LeafletMapComponent } from '../../components/leaflet-map/leaflet-map.component';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  constructor(
    private metaTagService: Meta,
    private bannerService: BannerService, private locationService: LocationService,
    public router: Router, private positionService: PositionService) {
  }

  location: Location | undefined;
  banner: any;
  service: any = {};
  showError = false;
  searchLocation: any;
  inputAddValue: any;
  showCurrentLoc = true;
  showLoader = false;
  locationModal = false;
  finalAddress: any;
  isMapInitialised = false;
  mapRef: any;
  mapLocation: any;
  latestPosition: any;
  @ViewChild('mapCompRef', {static: true}) mapCompRef!: LeafletMapComponent; 

  ngOnInit(): void {
    this.bannerService.homeBanner().subscribe((data: any) => {
      this.banner = data.banners;
    });

    this.locationService.initGeoComplete('searchRegion', this, this.locationCallback.bind(this), "BANNER");
    
    this.locationService.geoLocation.subscribe(location => {
      this.mapLocation = location;
      console.log("dropdown updated", this.mapLocation);
      // this.positionService.location = location
    });
    //this.positionService.initMap(this, this.positionCallback)
  }

  locationCallback(that: any, location: Location) {
    that.positionService.location = location;
    that.showError = false;
    this.showLoader = false;
    this.mapLocation = location;
  }

  positionCallback(that: any, location: Location) {
    that.showLoader = false;
    that.searchLocation = location.name;
    that.showCurrentLoc = true;
    that.latestPosition = location;
    that.finalAddress = true;
    that.onMapInit();
  }

  takeToBuildOrder() {
    if (this.positionService.location != undefined && Object.keys(this.positionService.location).length > 0) {
      this.router.navigate(['build-your-order'], {
        queryParams: {
          lat: this.positionService.location.lat,
          lng: this.positionService.location.lng,
          locationName: this.positionService.location.name,
          pincode: this.positionService.location.pincode,
          city: this.positionService.location.city
        }
      });
    } else {
      this.showError = true;
    }
  }

  getVal(value: any) {
    this.inputAddValue = value;
    if (this.inputAddValue.length == 0) {
      this.showCurrentLoc = true;
    }
    if (this.inputAddValue.length > 0) {
      this.showCurrentLoc = true;
    }
  }

  currentLoc() {
    this.showCurrentLoc = true;
  }

  hideCurrentLoc() {
    setTimeout(() => {
      this.showCurrentLoc = true;
    }, 500);
  }

  setLocation() {
    this.showLoader = true;
    setTimeout(() => {
      this.showLoader = false;
      this.mapCompRef?.map.invalidateSize();
    }, 2000)
    this.positionService.currentLocations(this, this.positionCallback);
  }

  openWhatsapp() {
    window.open('https://wa.me/919999759911?text=Hello%20U%20Clean', "_blank");
  }

  openApp() {
    window.open('https://onelink.to/pv4ck7/', "_blank");
  }
  onMapInit(event?:any) {
    this.isMapInitialised = true;
    if(event) {
      this.mapRef = event?.mapRef;
      console.log("this.mapRef", this.mapRef);
    }
    console.log("map inint with latest loaction", this.latestPosition);

    if (this.latestPosition?.lat && this.latestPosition?.lng) {
      this.moveMapToParticularLocation({lat: this.latestPosition?.lat, lng: this.latestPosition?.lng});
    }
  }
  
  moveMapToParticularLocation(newPosition: LocationModel) {
    const newLatLng = [+Number(newPosition.lat).toFixed(5), +Number(newPosition.lng).toFixed(5)];
    this.isMapInitialised && this.mapRef.setView(newLatLng, mapConfig.MAP_SETTINGS.defaultZoomView, mapConfig.MAP_SETTINGS.getMapMoveConfig());
  }

  onLocationConfirm(completeLocationInfo: CustomLocationInfoModel) {
    console.log("onLocationConfirm", completeLocationInfo);
    this.searchLocation = completeLocationInfo?.name
  }


}

