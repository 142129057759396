import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PagesService } from '../pages.service';
import { RouteStateService } from 'src/app/services/route-state.service';

@Component({
  selector: 'app-protectedpage',
  templateUrl: './protectedpage.component.html',
  styleUrls: ['./protectedpage.component.scss']
})
export class ProtectedpageComponent implements OnInit {
  deleteForm: FormGroup;
  showMessageBox = false;
  showLoginModal = false;

  // Add getter for easier template access
  get agreementValue(): boolean {
    return this.deleteForm.get('agreement')?.value || false;
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private pagesService: PagesService,
    private routeStateService: RouteStateService
  ) {
    // Initialize form in constructor
    this.deleteForm = this.fb.group({
      reason: ['', Validators.required],
      message: [''],
      agreement: [false, Validators.required]
    });

    // Listen to reason changes to show/hide message box
    this.deleteForm.get('reason')?.valueChanges.subscribe(value => {
      this.showMessageBox = value === 'Others';
    });
  }

  ngOnInit(): void {
    // Any initialization logic can go here
        // Set the flag when accessing this component
        this.routeStateService.setFromDeleteAccount(true);
  }
  start(): void {
    console.log("at least this is working");
  }

  closeLoginModal(event?: Event): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.showLoginModal = false;
  }

  redirectToLogin(event?: Event): void {
    if (event) {
      event.preventDefault();
    }
    this.showLoginModal = false; // Close the modal first
    this.router.navigate(['login-page'], {
      replaceUrl: true
    });
  }

  onSubmit(event?: Event): void {
    if (event) {
      event.preventDefault();
    }
    
    console.log('Form submission attempted');
    console.log('Form valid:', this.deleteForm.valid);
    console.log('Agreement checked:', this.agreementValue);
    
    if (this.deleteForm.valid && this.agreementValue) {
      console.log('Showing login modal');
      this.showLoginModal = true;
    } else {
      console.log('Form validation failed');
      // Mark all fields as touched to trigger validation messages
      Object.keys(this.deleteForm.controls).forEach(key => {
        const control = this.deleteForm.get(key);
        control?.markAsTouched();
      });
    }
  }
}