// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { Component } from '@angular/core';
import { Router } from '@angular/router';

const isProduction = true; // Set this to false for development

export const environment = {
  production: isProduction,
  baseUrl: isProduction ? "https://uclean.in/" : "http://localhost:4200/",
  imageBaseUrl: isProduction ? "//assets.ucleanlaundry.com/assets/" : "../../../../assets/",
  franchise: "UCLEAN",
  GOOGLE_API_KEY: 'AIzaSyB1D2T7hcgHM2UnBSnerOjI4xrFEEJTJ1Y',
  BANNER_GOOGLE_API_KEY: 'AIzaSyB1D2T7hcgHM2UnBSnerOjI4xrFEEJTJ1Y',
  // BANNER_GOOGLE_API_KEY: 'AIzaSyD2-4eOc0LTkT37lSKVx6SfQgyPLEd6bC4',
  FRANCHISE_GOOGLE_API_KEY: 'AIzaSyBeVuvxRysA0iDAIwp-mkMRXJRqtthrkgY',
  FRANCHISE_FORM_GOOGLE_API_KEY: 'AIzaSyBwkYG3rxMcfkqu6Y_OJRCnQ_wLlKE_k1w',
  USER_ADRESSES_GOOGLE_API_KEY: 'AIzaSyAMlhdi_bECm9rCHUFz589Wz0VpI1-YWRE',
};