import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor( private http:HttpClient ) { }
  mainBlogsUrl = environment.baseUrl + 'blogs?franchise=' + environment.franchise; //DONE
  latestBlogsUrl = environment.baseUrl + 'webusers/medias'; //DONE

  mainBlogs(){
    return this.http.get(this.mainBlogsUrl);
  }

  latestBlogs(){
    return this.http.get(this.latestBlogsUrl);
  }

  detailBlogs(slug : any){
    return this.http.get(this.mainBlogsUrl+"/"+slug);
  }
}
