<!-- inner_banner_section start-->
<section class="inner_banner_section date_time_page_banner">
  <div class="container">
    <div class="row">
      <!-- <div class="inner_banner_block">
        <div class="inner_banner_tilte">
          <h1>Select Your Pick up Date & time</h1>
        </div>
      </div> -->
    </div>
  </div>
</section>
<!-- inner_banner_section end-->

<!-- page_progress_section start-->

<section class="page_progress_section">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px">
        <div class="prog_step">
          <ul>
            <li class="active"><a (click)="takeToBuildOrder()"><i class="fa fa-circle" aria-hidden="true"></i>
                                                                  
              <p class="pp"> Build your <br> Order</p>
            </a></li>
            <li class="active"><i class="fa fa-circle" aria-hidden="true"></i>
              <p class="pp">Schedule <br> Pick up</p>
            </li>
            <li><i class="fa fa-circle" aria-hidden="true"></i>
              <p class="pp"> Choose <br> Address</p>
            </li>
            <li><i class="fa fa-circle" aria-hidden="true"></i>
              <p class="pp">Confirm </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- page_progress_section end-->
<div class="dat">

  <h1 class="datetime">SELECT PICK-UP DATE & TIME</h1>

</div>


<!-- address_pg_section start-->

<section class="date_time_pg_section">
  <div class="container">
    <div class="row" *ngIf="showLoader">
      <div class="col-xs-12">
        <div id='loading_wrap'></div>
      </div>
    </div>
    <div class="row" *ngIf="!showLoader && showNoSlotsFound">
      <div class="col-xs-12 text-center">
        <h2>There are no slots for this location, Please try another location.</h2>
        <br/>
        <a routerlink="/" class="page_back_btn">Back to Home</a>
      </div>
    </div>
    <div class="row calender" *ngIf="!showLoader && !showNoSlotsFound">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 select ">
        <div class="sec_title_bar">
          <!-- <h2 class="select">Select date & Time</h2> -->
        </div>
      </div>
      <app-week-day-lister (onDateSelect)="onDateSelect($event)"></app-week-day-lister>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 tim">
        <div class="date_time_block">
          <!-- <div>
            <ngb-datepicker class="cal" id='gfg' (dateSelect)="onDateSelect($event)" [minDate]="from_date"
                            [maxDate]="to_date" [(ngModel)] = "selected_date"></ngb-datepicker>
          </div> -->
            <div class="slot_block" *ngIf= "selected_date">
              <div class="slot_title">
                <h4 class="available"  >Available slots</h4>
              </div>
              
              <div class="slot_time">
                
                <ul>
                  <li *ngFor="let slot of timingSlots" (click)="selectSlot(slot.id)" [class.active]="selectedSlotId == slot.id">{{slot.combine_time}}</li>
                </ul>
              </div>
            </div>
        </div>
      </div>
         
      <div class="col-xs-12 btt">
        <br/>
        <div class="alert alert-danger text-center" role="alert" [hidden]=!noSlotSelectedError>
          <span class="error-block">Please select the slot</span>
        </div>

        <!-- <hr> -->
        <!-- <p class="count"><b>Total count : 0</b></p> -->
        <div class="page_bn_btn">
          <a class="page_back_btn" (click)="takeToBuildOrder()">Back</a>
          <!-- <a *ngIf="!authService.loggedIn" class="page_next_btn nextt" data-toggle="modal" data-target="#loginModal" >Next <img
            src="//assets.ucleanlaundry.com/assets/images/right_icon.png"></a> -->
          <a *ngIf="!authService.loggedIn" class="page_next_btn nextt" data-toggle="modal" data-target="#loginModal" >Next</a>
          <a *ngIf="authService.loggedIn" (click)="takeToChooseAddress()" class="page_next_btn">Next</a>
        </div>
      </div>


    </div>
  </div>
</section>


<!-- address_pg_section end-->

<app-login class="foot"></app-login>
