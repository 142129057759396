<section class="hp_banner_section" [style.background-image]="'url(' + bannerImageUrl + ')'">
    <div class="container">
      <div class="row">
        <div class="hp_banner_block">
          <div class="hp_banner_tilte">
            <h3 class="baner-top">Dry Cleaning</h3>
            <h3 class="banner-botomm">Eco-Friendly - Expert Stain Removal</h3>
          </div>
  
        </div>
      </div>
    </div>
  </section>
 
  <div class="service-body">
    <h2 class="process-title" style="margin-bottom: 30px;">
      Dry Cleaning- Eco-Friendly I Expert Stain Removal
    </h2>
    <div class="body body-text">
      All of us have sentimental value attached to some of those special clothes  in our wardrobe. Whether it’s your wedding lehenga, or that designer gown or a bespoke custom-fit suit, we trust our Dry Cleaners for professional cleaning of all such special garments that we hold dear to our heart.. However, most Dry Cleaners are not transparent about the process and chemicals, while being whimsical about pricing. At UClean, we have solved all these problems. Your precious garments are processed by the best in trade professionals, using skin friendly formulations. What’s more you ask? We are 100% transparent about our pricing and declare it upfront. So next time, trust UClean to look after your beloved garments with best-in-class dry cleaning. 


    </div>
    <h2 class="process-title" style="margin-bottom: 30px; margin-top: 50px;">
      A glance at our dry cleaning service:
    </h2>
    <div class="body-lower body-text">
      Our highly trained team tailors the cleaning process to the specific needs of each garment, whether it’s a heavily embellished designer outfit, delicate silks and chiffons, or even leather. From cozy blankets to intricate couture, every item is handled with the utmost care and expertise. We understand the complexities of different fabrics and the challenges they present, and we meet them with skill and precision. Plus, we value your time, always delivering on schedule—all from the comfort of your home with our free pickup and drop-off services.
      Trust us for keeping your clothes ‘AS GOOD AS NEW’!              
       
    </div>
    </div>
            <div class="process">
               
                    <h2 class="process-title">OUR DRY CLEANING PROCESS</h2>
                    <p class="process-description">Every cloth goes through a comprehensive 6 stage process enabling us to deliver on the promise of sparkling & fresh clothes, every time.</p>
                    
                    <div class="process-timeline">
                      <div class="process-step" *ngFor="let step of processSteps; let last = last">

                        <div class="step-icon">
                            <img [src]="step.iconPath" [alt]="step.title" class="custom-icon">
                          </div>
                    
                        <div class="step-content">
                          <h3 class="step-title">{{ step.title }}</h3>
                          <p class="step-description">{{ step.description }}</p>
                        </div>
                        <div class="step-connector" *ngIf="!last"></div>
                      </div>
                    </div>
                 
            </div>
            <div class="book-now">
                <h1>Trust us for keeping your clothes ‘AS GOOD AS NEW’!</h1>
                <button class="btn primary">Book Now</button>
            </div>
           
            <div class="dry-cleaning-container">
                <div class="image-container">
                  <img [src]="dryCleaningImage" alt="Dry Cleaning Service">
                </div>
                <div class="content-container">
                  <h2 class="title">OUR DRY CLEANING PRICES</h2>
                  <p class="description body-text">
                    At UClean, we believe in delivering high quality cleaning services at very affordable prices. With stores across multiple Tier 1, Tier 2 and Tier 3 cities, Quality, Speed and Economical Prices have been our biggest value drivers.
                  </p>
                  <a href='laundry-drycleaning-price-list' class="view-pricing-btn">View Pricing</a>
                </div>
              </div>

            <div class="promise-container">
                <h2 class="promise-title">OUR PROMISE TO YOU</h2>
                <app-our-promise></app-our-promise>
              </div>