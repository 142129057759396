import { Component, OnInit } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { PixelService } from "ngx-pixel";
import { metaTags } from "src/assets/meta-contant";
import { PagesService } from "../pages.service";

@Component({
  selector: "app-services",
  templateUrl: "./services.component.html",
  styleUrls: ["./services.component.scss"],
})
export class ServicesComponent implements OnInit {
  constructor(
    private router: Router,
    private pixel: PixelService,
    private metaTagService: Meta,
    private pagesService: PagesService
  ) {}

  citiesList: any;
  activeButton: number = 0;

  ngOnInit(): void {
    this.pagesService.cityList().subscribe((data: any) => {
      this.citiesList = data.cities;
    });

    $(document).ready(function () {
      (<any>$(".owl-carousel")).owlCarousel({
        autoPlay: 3000,
        //loop: true,
        margin: 10,
        nav: true,
        navText: [
          "<div class='nav-btn prev-slide'></div>",
          "<div class='nav-btn next-slide'></div>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 2,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    const url = this.router.url.slice(1);
    if (url === "services") {
      this.pixel.trackCustom("ServicesPage");
      this.metaTagService.updateTag({
        property: "title",
        content: metaTags?.services?.title,
      });
      this.metaTagService.updateTag({
        property: "description",
        content: metaTags?.services?.description,
      });
    } else if (url === "services/DryCleaning") {
      this.pixel.trackCustom("DryCleaningServicePage");
      this.metaTagService.updateTag({
        property: "title",
        content: metaTags?.["services/DryCleaning"]?.title,
      });
      this.metaTagService.updateTag({
        property: "description",
        content: metaTags?.["services/DryCleaning"]?.description,
      });
    } else if (url === "services/LaundrybyKg") {
      this.pixel.trackCustom("LaundrybyKgServicesPage");
      this.metaTagService.updateTag({
        property: "title",
        content: metaTags?.["services/LaundrybyKg"]?.title,
      });
      this.metaTagService.updateTag({
        property: "description",
        content: metaTags?.["services/LaundrybyKg"]?.description,
      });
    } else if (url === "services/PremiumLaundry") {
      this.pixel.trackCustom("PremiumLaundryServicesPage");
      this.metaTagService.updateTag({
        property: "title",
        content: metaTags?.["services/PremiumLaundry"]?.title,
      });
      this.metaTagService.updateTag({
        property: "description",
        content: metaTags?.["services/PremiumLaundry"]?.description,
      });
    } else if (url === "services/SteamIroning") {
      this.pixel.trackCustom("SteamIroningServicesPage");
      this.metaTagService.updateTag({
        property: "title",
        content: metaTags?.["services/SteamIroning"]?.title,
      });
      this.metaTagService.updateTag({
        property: "description",
        content: metaTags?.["services/SteamIroning"]?.description,
      });
    } else if (url === "services/ShoeCleaning") {
      this.pixel.trackCustom("ShoeCleaningServicesPage");
      this.metaTagService.updateTag({
        property: "title",
        content: metaTags?.["services/ShoeCleaning"]?.title,
      });
      this.metaTagService.updateTag({
        property: "description",
        content: metaTags?.["services/ShoeCleaning"]?.description,
      });
    } else if (url === "services/BagCleaning") {
      this.pixel.trackCustom("BagCleaningServicesPage");
      this.metaTagService.updateTag({
        property: "title",
        content: metaTags?.["services/BagCleaning"]?.title,
      });
      this.metaTagService.updateTag({
        property: "description",
        content: metaTags?.["services/BagCleaning"]?.description,
      });
    } else if (url === "services/CurtainCleaning") {
      this.pixel.trackCustom("CurtainCleaningServicesPage");
      this.metaTagService.updateTag({
        property: "title",
        content: metaTags?.["services/CurtainCleaning"]?.title,
      });
      this.metaTagService.updateTag({
        property: "description",
        content: metaTags?.["services/CurtainCleaning"]?.description,
      });
    } else if (url === "services/SofaCleaning") {
      this.pixel.trackCustom("SofaCleaningServicesPage");
      this.metaTagService.updateTag({
        property: "title",
        content: metaTags?.["services/SofaCleaning"]?.title,
      });
      this.metaTagService.updateTag({
        property: "description",
        content: metaTags?.["services/SofaCleaning"]?.description,
      });
    } else if (url === "services/CarpetCleaning") {
      this.pixel.trackCustom("CarpetCleaningServicesPage");
      this.metaTagService.updateTag({
        property: "title",
        content: metaTags?.["services/CarpetCleaning"]?.title,
      });
      this.metaTagService.updateTag({
        property: "description",
        content: metaTags?.["services/CarpetCleaning"]?.description,
      });
    } else if (url === "services/HomeDeepCleaning") {
      this.pixel.trackCustom("HomeDeepCleaningServicesPage");
      this.metaTagService.updateTag({
        property: "title",
        content: metaTags?.["services/HomeDeepCleaning"]?.title,
      });
      this.metaTagService.updateTag({
        property: "description",
        content: metaTags?.["services/HomeDeepCleaning"]?.description,
      });
    } else if (url === "services/CarCleaning") {
      this.pixel.trackCustom("CarCleaningServicesPage");
      this.metaTagService.updateTag({
        property: "title",
        content: metaTags?.["services/CarCleaning"]?.title,
      });
      this.metaTagService.updateTag({
        property: "description",
        content: metaTags?.["services/CarCleaning"]?.description,
      });
    }
  }

  services = [
    {
      id: 1,
      name: "Dry Cleaning",
      img: "//assets.ucleanlaundry.com/assets/images/dry_cleaning_11zon.webp",
    },
    {
      id: 2,
      name: "Premium Laundry",
      img: "//assets.ucleanlaundry.com/assets/images/premium_laundry_cleaning_11zon.webp",
    },
    {
      id: 3,
      name: "Laundry",
      img: "//assets.ucleanlaundry.com/assets/images/laundry_cleaning_11zon.webp",
    },
    {
      id: 4,
      name: "Steam Ironing",
      img: "//assets.ucleanlaundry.com/assets/images/steam_ironing_11zon.webp",
    },
    {
      id: 5,
      name: "Shoe Cleaning",
      img: "//assets.ucleanlaundry.com/assets/images/shoe_cleaning_11zon.webp",
    },
    {
      id: 6,
      name: "Bag Cleaning",
      img: "//assets.ucleanlaundry.com/assets/images/bag_cleaning_11zon.webp",
    },
    {
      id: 8,
      name: "Sofa Cleaning",
      img: "//assets.ucleanlaundry.com/assets/images/sofa_cleaning_11zon.webp",
    },
    {
      id: 9,
      name: "Carpet Cleaning",
      img: "//assets.ucleanlaundry.com/assets/images/carpet_cleaning_11zon.webp",
    },
  ];
  navigateToService(serviceName: string) {
    this.router.navigate([`/services/${serviceName.replace(/\s+/g, '')}`]);
  }
}
