<!-- inner_banner_section start-->
<section class="inner_banner_section order_summary_page_banner">
  <div class="container">
    <div class="row">
      <!-- <div class="inner_banner_block">
        <div class="inner_banner_tilte">
          <h1>Select Your Pick up Date & time</h1>
        </div>
      </div> -->
    </div>
  </div>
</section>
<!-- inner_banner_section end-->

<!-- page_progress_section start-->

<section class="page_progress_section">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px; margin-top:15px">
        <div class="prog_step">
          <ul>
            <li class="active"><a (click)="takeToBuildOrder()"><i class="fa fa-circle"
                                                                  aria-hidden="true"></i>
              <p> Build your <br> Order</p>
            </a></li>
            <li class="active"><a (click)="takeToSchedulePickup()"><i class="fa fa-circle"
                                                                      aria-hidden="true"></i>
              <p>Schedule <br> Pick up</p>
            </a></li>
            <li class="active"><a (click)="takeToChooseAddress()"><i class="fa fa-circle"
                                                                     aria-hidden="true"></i>
              <p>Choose <br> Address</p>
            </a></li>
            <li class="active"><i class="fa fa-circle" aria-hidden="true"></i>
              <p>Confirm </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- page_progress_section end-->

<!-- address_pg_section start-->

<section class="order_summary_pg_section">
  <div class="container">
    <div class="row" *ngIf="showLoader">
      <div class="col-xs-12">
        <div id='loading_wrap'></div>
      </div>
    </div>
    <div class="row" *ngIf="!showLoader">
      
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top">
        <div class="col-lg-7 col-md-8 col-sm-12 col-xs-12" style="padding:0px">
          <div class="order_summary_pg">

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 order">
              <div class="sec_title_bar">
                <h2>Order Summary</h2>
              </div>
            </div>
            <!-- <div class="order_id" *ngIf="store.virtual_store == 0">
              <h4>{{store.storecode}} - {{store.owner_name}}</h4>
              <p>Store Address <span class="mid-sign"><b>:</b></span> <span class="end">{{store.address}}</span> </p>
            </div> -->

            <div class="order_info">
              <ul class=" orr">
                <li><b class="start">Store Address</b></li>
                <li class="mid-sign">&nbsp;&nbsp;&nbsp;&nbsp;<b>:</b></li>
                <li class="end" style="color:  #65BA0D; width:46% "><b>{{store.address}}</b></li>
              </ul>
            </div>
            <div class="order_info">
              <ul class="orr">
                <li><b>Pick up Date</b></li>
                <li class="mid-sign">&nbsp;&nbsp;&nbsp;&nbsp;<b>:</b></li>
                <li class="end" style="color:  #65BA0D; "><b>{{pickup_date}}</b></li>
              </ul>
            </div>

            <div class="order_info">
              <ul class="orr">
                <li><b>Pick up Time</b></li>
                <li class="mid-sign">&nbsp;&nbsp;&nbsp;&nbsp;<b>:</b></li>
                <li class="end" style="color:  #65BA0D; "><b>{{slot.combine_time}}</b></li>
              </ul>
            </div>

            <div class="order_info">
              <ul class="orr">
                <li><b>Pick up Address</b></li>
                <li class="mid-sign">&nbsp;&nbsp;&nbsp;&nbsp;<b>:</b></li>
                <li class="end" style="width:50%; color:  #65BA0D;">
                 <p><b>{{address.flat_info}}, {{address.pocket_info}}, {{address.landmark}}, {{address.city_name}} - {{address.pincode}}</b> </p>
                </li>
              </ul>
            </div>

            <div class="order_info">
              <ul>
                <li><b>Apply Coupon </b></li>
                <li class="coupon_btn">
                  <input type="text" name="coupon" class="form-control coupon_text" placeholder="Coupon Code"
                         [(ngModel)]="selectedCouponCode">
                  <button class="apply_coupon_btn" (click)="applyCoupon()">Apply Coupon</button>
                  <br/>
                  <p class="text-danger" *ngIf="couponErrorMessage">{{couponErrorMessage}}</p>
                  <p class="text-success" *ngIf="couponSuccessMessage">{{couponSuccessMessage}}</p>
                </li>
              </ul>
            </div>

            <!-- <div class="order_info ">
                <ul class="order_no invoice_item_sec">
                    <li><b>Invoice</b></li>
                    <li><b>:</b></li>
                    <li><i class="fa fa-inr" aria-hidden="true"></i>200 <a href="">Download Invoice</a></li>
                </ul>
            </div>

            <div class="order_info">
                <ul class="order_no">
                    <li><b>Payment</b></li>
                    <li><b>:</b></li>
                    <li [hidden]="true"><a class="paynow_btn" href="">Pay on App</a></li>
                </ul>
            </div> -->

            <div class="order_info">
              <ul class="order_no">
                
                <label>Add Special instructions (optional)</label>
                <textarea id="textareareview" name="textareareview" rows="4" cols="50"
                          [(ngModel)]="suggestion" style="padding-left:10px" maxlength="100"></textarea>
                <p class="textarea">Max. Char - 100</p>
              </ul>
            </div>

          </div>
        </div> 
        <div class="col-lg-5 col-md-4 col-sm-12 col-xs-12" style="padding:0px">
          <div class="order_summary_pg">
            <div class="order_summary_detail">
              <h4>order details</h4>
            </div>

            <div class="order_sel_list">
              <ul class="order_sel" *ngFor="let service of orderSummary.services">
                <li>{{service.name}}</li>
                <li><span><i class="fa fa-check" aria-hidden="true"></i></span></li>
              </ul>
            </div>
            <div class="order_shp">
              <ul>
                <!--                                <li>Total <span>20 Items</span></li>-->
                <li *ngIf="couponSuccessMessage">Discount <span class="text-success"
                                                                style="width:70%;">{{couponSuccessMessage}}</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
        <div class="page_bn_btn">
          <a class="page_back_btn" (click)="takeToChooseAddress()">Back</a>
          <a class="page_next_btn" (click)="createOrder()">Confirm Order <img
            src="//assets.ucleanlaundry.com/assets/images/right_icon.png"></a>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Confirm pop up start-->

<!-- <div class="modal fade confirm_pop" id="confirm-order-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog">
    <div class="loginmodal-container">
      <div class="confirm_pop_title_bar">
        <img src="//assets.ucleanlaundry.com/assets/images/confirm_pop_icon.png">
        <h1>Your Order is Confirmed</h1>
        <p>Thank you for choosing UClean as your Laundry Partner. Your order is placed with
          <span *ngIf="store.virtual_store == 0">{{store.owner_name}}</span>
          <span *ngIf="store.virtual_store == 1">Uclean</span>
          <br>
          <b>The store team will contact you shortly!</b>
        </p>
      </div>
      <div class="text-center">
        <button type="button" (click)="takeToBuildOrder()" class="btn btn-success" style="float:left; margin-left: 10%;">Order Again</button>
        <button type="button" routerLink="/" class="btn btn-success" style="float: right; margin-right: 10%;">Back to home</button>
      </div>
      <div class="pop_eft">
        <ul>
          <li><img src="//assets.ucleanlaundry.com/assets/images/icon_4.png"></li>
          <li><img src="//assets.ucleanlaundry.com/assets/images/icon_3.png"></li>
          <li><img src="//assets.ucleanlaundry.com/assets/images/icon_1.png"></li>
          <li><img src="//assets.ucleanlaundry.com/assets/images/icon_4.png"></li>
        </ul>
      </div>

    </div>
  </div>
</div> -->

<!--  Confirm pop up end-->

<!-- address_pg_section end-->
