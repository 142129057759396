<section class="profile_right_block">
  <div class="user_add_book">
    <div class="row" *ngIf="showLoader">
      <div class="col-xs-12">
        <div id='loading_wrap'></div>
      </div>
    </div>
    <div class="row" *ngIf="!showLoader && showAddressList">
      <div class="col-xs-12">
        <div class="address_pg_block">
          <div class="profile_title">
            <h2>Address Book</h2>
          </div>
          <div class="address_list_sec">
            <ul>
              <li (click)="selectAddress(address.id)" *ngFor="let address of addresses"
                  [ngClass]="getClass(address.id)">
                <span>
                  <img src="//assets.ucleanlaundry.com/assets/images/hotal_icon.png" *ngIf="address.type == 'Hotel'">
                  <img src="//assets.ucleanlaundry.com/assets/images/home_icon.png" *ngIf="address.type == 'Home'">
                  <img src="//assets.ucleanlaundry.com/assets/images/work_icon.png" *ngIf="address.type == 'Office'">
                </span>
                <p>{{address.flat_info}}, {{address.street_info}}</p>
                <p>{{address.landmark}}</p>

                
                <p>{{address.city_name}} - {{address.pincode}}</p>
                <button name="button" (click)="removeAdd(address)" type="button">Delete</button>
              </li>
            </ul>
          </div>
          <div class="new_address_btn">
            <button (click)="addNewAddress()" id="add_btn_form" name="button" data-toggle="modal"
                    data-target="#editAddModel" type="button"><i class="fa fa-plus-circle"
                                                                 aria-hidden="true"></i>Add New Address
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!showLoader && showAddressForm" class="modal fade" id="editAddModel" tabindex="-1" role="dialog"
         aria-labelledby="editAddModelTitle"
         aria-hidden="true" style="z-index: 1;">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" style="text-align: center; font-size: 20px; color: #003a25;">Add New Address</h4>
            <button type="button" class="close" data-dismiss="modal" style="margin: -4% 3% 0% 0%;" aria-label="Close">
              <span aria-hidden="true" style="font-size: larger;">&times;</span>
            </button>
          </div>
          <div class="add_new_form" style="width: auto; margin: 0;">
            <div class="row">
              <div class="col-xs-12">
                <div class="add_new_form">
                  <input type="text" id="searchRegion" class="form-control"
                         placeholder="Select Location" [(ngModel)]="searchLocation" (keyup)="enableAutoComplete()">
                  <form (ngSubmit)="saveAdd();">
                    <div class="form-group">

                      <button type="button" class="btn btn-success" style="float: right;" (click)="getLocation()">Use
                        Current Location
                      </button>
                    </div>
                    <div class="form-group" [ngClass]="validation.type">
                      <label>
                        <input type="radio" name="address_type" value="Home" [(ngModel)]="address.type">
                        Home
                      </label> &nbsp;&nbsp;
                      <label>
                        <input type="radio" name="address_type" value="Office" [(ngModel)]="address.type">
                        Office
                      </label>&nbsp;&nbsp;
                      <label>
                        <input type="radio" name="address_type" value="Hotel" [(ngModel)]="address.type">
                        Hotel
                      </label>&nbsp;&nbsp;
                      <label>
                        <input type="radio" name="address_type" value="Other" (click)="disableInputForType()">
                        Other
                      </label>&nbsp;&nbsp;
                      <input [hidden]="disableInputType" type="text" [(ngModel)]="address.type" name="type"
                             placeholder="Address Type">
                    </div>

                    <div class="form-group" [ngClass]="validation.flat_info">
                      <input type="text" name="flat_info" class="form-control" placeholder="House-No. / Flat-No."
                             [(ngModel)]="address.flat_info">
                    </div>
                    <div class="form-group" [ngClass]="validation.street_info">
                      <input type="text" name="street_info" class="form-control" placeholder="Street / Road"
                             [(ngModel)]="address.street_info">
                    </div>
                    <div class="form-group" [ngClass]="validation.landmark">
                      <input type="text" name="landmark" class="form-control" placeholder="Landmark"
                             [(ngModel)]="address.landmark">
                    </div>
                    <div class="form-group" [ngClass]="validation.city_id">
                      <select name="city_id" [disabled]="true" class="form-control" [(ngModel)]="address.city_id">
                        <option>Select City</option>
                        <option *ngFor="let city of citiesList" [value]="city.id">
                          {{city.name}}</option>
                      </select>
                    </div>

                    <div class="form-group" [ngClass]="validation.pincode">
                      <input name="pincode" type="text" class="form-control" placeholder="Post Code"
                             [(ngModel)]="address.pincode" [disabled]="true">
                    </div>
                    <div class="row">
                      <div class="col-md-12" style="text-align: center;">
                        <div class="form-group">
                          <input type="submit" name="button" class="btn btn-success" value="Save">
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
