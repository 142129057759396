import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { PagesService } from '../../pages/pages.service';
@Component({
  selector: 'app-login-otp-verification',
  templateUrl: './login-otp-verification.component.html',
  styleUrls: ['./login-otp-verification.component.scss']
})
export class LoginOtpVerificationComponent implements OnInit {

  isLoading = false;
  fromUrl!: string;
  formValue: any;
  fromSignup: boolean = false;
  counter: number = 30;
  timerRunning = true;
  timerId: any;
  showError = false;
  errorMsg: any;
  otpControl = new FormControl('');

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private pageservice: PagesService,
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.fromUrl = params?.fromUrl;
      this.formValue = JSON.parse(atob(params?.enc));
      this.fromSignup = !!this.formValue?.name;
      this.checkRemainingTimeAndPlayCounter();
      console.log('queryparams subscription');
    });
  }

  checkRemainingTimeAndPlayCounter() {
    const apiCallingTime = this.formValue?.apiCallingTime;
    const currentTime = new Date().getTime();
    const totalDifferenceInSeconds = (currentTime - apiCallingTime) / 1000 % 60;
    if (totalDifferenceInSeconds > 30) {
      this.requestForOTP();
    } else {
      const counter = 30 - Math.ceil(totalDifferenceInSeconds);
      this.startOtpTimer(counter);
    }
  }

  startOtpTimer(counter?: number) {
    this.counter = counter ? counter : 30;
    if(this.timerId) {
      clearInterval(this.timerId);
    }
    this.timerId = setInterval(() => {
      if (this.counter > 0) {
        this.timerRunning = true;
        this.counter -= 1;
      } else {
        this.timerRunning = false;
        clearInterval(this.timerId);
      }
    }, 1000);
  }

  requestForOTP() {
    this.isLoading = true;
    const { phone } = this.formValue;
    this.authService.sendOtpLogin({ mobile: phone }).subscribe((res: any) => {
      this.isLoading = false;
      if (res?.status === 'SUCCESS') {
        this.startOtpTimer();
        this.updateApiCallTimeInUrl();
        this.showError = false;
      } else if (res?.status === 'ERROR') {
        this.errorMsg = res?.msg;
        this.showError = true;
      }
      console.log(res);
    }, (err) => {
      this.isLoading = false;
      this.showError = false;
    });
  }

  verifyOtp() {
    let body = { "mobile": this.formValue?.phone, "otp": this.otpControl.value };
      this.pageservice.loginData(body).subscribe((data: any) => {
        if(data?.status != 'ERROR') {
          localStorage.setItem('userToken', data.api_token);
          localStorage.setItem('user_data', btoa(JSON.stringify(data)));
          this.authService.checkAuthAndGetUser();
          this.goToOtpVerificationSuccess();
        } else {
          alert(data?.msg);
        }
      });
      tap((res: any) => {
        return this.pageservice.authUser(res.email, res.localId, res.idToken, +res.expiresIn, res.tokenExpDate)
      })
  }

  updateApiCallTimeInUrl() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { fromUrl: this.fromUrl, enc: btoa(JSON.stringify({ ...this.formValue, apiCallingTime: new Date().getTime() })) },
      replaceUrl: true,
    });
  }

  goToOtpVerificationSuccess() {
    this.router.navigate(['otp-verification-success'], {
      replaceUrl: true
    });
  }

  goToPreviousScreen() {
    this.router.navigate([this.fromUrl], {
      replaceUrl: true
    });
  }

  onOtpChange(e: any) {
    this.otpControl.setValue(e);
  }

  ngOnDestroy() {
    this.timerId && clearInterval(this.timerId);
  }
}
