import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shoe-cleaning',
  templateUrl: './shoe-cleaning.component.html',
  styleUrls: ['./shoe-cleaning.component.scss']
})
export class ShoeCleaningComponent implements OnInit {
  bannerImageUrl = `${environment.imageBaseUrl}Banner/ShoeCleaning.jpg`;
  shoeCleaningImage: string = `${environment.imageBaseUrl}cta/ShoeCleaning.jpg`;
  processSteps = [
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step6.svg`,
      title: 'Inspection',
      description: 'Inspect shoes for overall condition, material type, and level of dirt and wear. Remove laces for separate wash.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step5.svg`,
      title: 'Stain Treatment',
      description: 'Dry brushing to gently remove visible dirt and debris. Pre-wash treatment for all visible stains with specialized stain removers. Focus on tough spots for thorough cleaning.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step4.svg`,
      title: 'Surface Cleaning',
      description: 'Cleaning of outer surface of the shoe using material-specific foam or cream cleanser.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step3.svg`,
      title: 'Inner Sole And Shoes Cleaning',
      description: 'Comprehensive cleaning of inner soles and shoes for a fresh, odor-free, and spotless finish.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step2.svg`,
      title: 'Drying',
      description: 'Air-dry shoes in a well-ventilated area, avoiding direct sunlight to prevent warping and odors.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step1.svg`,
      title: 'Conditioning',
      description: 'Shoes surface is gently buffed with cream conditioner to restore suppleness and shine.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step3.svg`,
      title: 'Quality Check',
      description: 'Inspect shoes to ensure they are clean and in excellent condition. Clean laces are reinserted.'
    },
    {
      iconPath: `${environment.imageBaseUrl}icons/dry-clean-steps/step2.svg`,
      title: 'Packaging',
      description: 'Shoes are packaged in transparent shoe packaging with humidity catchers.'
    }
  ];
  
  
  constructor() { }

  ngOnInit(): void {
  }

}
