 <!-- login_form start-->
 <div class="modal fade in sign_up_form" id="login-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
    <div class="modal-dialog">
        <div class="loginmodal-container">

            <div class="form_title_bar">
                <img src="//assets.ucleanlaundry.com/assets/images/sign_up_icon.png">
                <h1>Sign up</h1>
            </div>

            <form class="login_form_info">
                <div class="form-group">
                    <input type="text" name="phone"  class="form-control" id="inputphonenumber" placeholder="Enter Your Phone Number">
                </div>

                <div class="form-group">
                    <input type="submit" name="login" class="login loginmodal-submit" value="Send OTP">
                </div>
            </form>

            <div class="social_buttons">
                <a href="#" class="btn-fb"><img src="//assets.ucleanlaundry.com/assets/images/fb-icon.png"> Facebook</a>
                <a href="#" class="btn-tw"><img src="//assets.ucleanlaundry.com/assets/images/gg-icon.png"> Google</a>
            </div>



            <div class="form_bottom">
                <p>By signing in you agree to the</p>
                <a href="">Terms of Service</a> & <a href="">Privacy Policy</a>
            </div>

        </div>
    </div>
</div>

 <!-- login_form end-->
