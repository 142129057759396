import { Injectable, NgModule } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Address } from 'src/app/models/addresses';
import { Subject, throwError, Observable, BehaviorSubject } from 'rxjs';
import { Location } from 'src/app/models/location';
import { User } from 'src/app/models/user.model';
import { LocationService } from 'src/app/services/location.service';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class PagesService {

  user = new Subject<User>();
  geoLocation = {} as Location;

  constructor(private httpclient: HttpClient,
    private locationService: LocationService) {
    this.locationService.geoLocation.subscribe(location => {
      console.log('Location received:', location);
      this.geoLocation = location;
      console.log('Stored geoLocation:', this.geoLocation);
    });
  }

  stroesListUrl = environment.baseUrl + 'webusers/stores?franchise=' + environment.franchise; //DONE
  cityListUrl = environment.baseUrl + 'cities'; //DONE
  sendOTP = environment.baseUrl + 'webusers/loginOTP';  //DONE
  loginUrl = environment.baseUrl + 'webusers/login?franchise=' + environment.franchise;   //DONE
  servicesUrl = environment.baseUrl + 'webusers/services?franchise=' + environment.franchise;
  // storeDetailUrl = `${environment.baseUrl}webusers/stores?franchise=UCLEAN&lat=${global.USER_Lat}&lng=${global.USER_Long}`;
  franchiseUrl = environment.baseUrl + 'applyForFranchise?franchise=' + environment.franchise;  //DONE
  currenUserUrl = environment.baseUrl + 'webusers/getProfile?franchise=' + environment.franchise;  //DONE
  addressesUrl = environment.baseUrl + 'webusers/getUserAddresses?franchise=' + environment.franchise;  //DONE
  searchStoreUrl = environment.baseUrl + 'api/v1/stores/_search?franchise=UCLEAN&lat=28.5293121&lng=77.1484442&radius=10&city_id=1'; //Not available
  logoutUrl = environment.baseUrl + 'webuser/logout?franchise=' + environment.franchise;  //DONE
  updateUserProfileUrl = environment.baseUrl + 'webusers/updateUser?franchise=' + environment.franchise;  //DONE
  orderHistoryUrl = environment.baseUrl + 'webuser/history?franchise=' + environment.franchise;  //DONE
  createNewAddUrl = environment.baseUrl + 'webusers/addAddress?franchise=' + environment.franchise;  //DONE
  storePricesUrl = environment.baseUrl + 'webusers/pricing?franchise=UCLEAN&';  // NOT DONE
  cityPricesUrl = environment.baseUrl + 'webusers/pricing?franchise=UCLEAN&';   // NOT DONE
  mediaUrl = environment.baseUrl + 'webusers/medias';  //DONE
  deleteAccountUrl = environment.baseUrl + 'delete-account';

  private currentStoreName = new Subject<string>();
  currentStoreName$ = this.currentStoreName.asObservable();

  private currentMetatags = new Subject<any>();
  currentMetatags$ = this.currentMetatags.asObservable();

  // Add a new Subject to hold the store object
  private currentStore = new BehaviorSubject<any>(null);
  currentStore$ = this.currentStore.asObservable();

  // Add these new methods
  setCurrentStoreName(storeName: string) {
    console.log('Setting store name in service:', storeName);
    this.currentStoreName.next(storeName);
  }

  setCurrentStore(store: any) {
    console.log('Setting current store in service:', store);
    this.currentStore.next(store);
  }

    // Add method to get current value
    getCurrentStore() {
      return this.currentStore.getValue();
    }


  setCurrentMetatags(metatags: any) {
    console.log('Setting metatags in service:', metatags);
    this.currentMetatags.next(metatags); // Emits the value for subscribers
  }


  // Update the existing getMetatags method to include logging
  getMetatags(storeCode: any): Observable<any> {
    console.log('Fetching metatags for store:', storeCode);
    
    // Update the URL to match the new endpoint pattern
    const metaTagsUrl = `${environment.baseUrl}metatags/footer?franchise=${environment.franchise}&store=${storeCode}`;
    
    return this.httpclient.get(metaTagsUrl)
      .pipe(
        tap((response: any) => console.log('Metatags API response:', response)),
        map((response: any) => response.metatag), // Extract the metatag object from the response
        catchError(this.handleError)
      );
  }


  getStoreById(storeCode: any): Observable<any> {
    console.log('Fetching store by id:', storeCode);
    // Replace :id with the actual storeCode in the URL
    const storeUrl = `${environment.baseUrl}storesadress/${storeCode}?franchise=${environment.franchise}`;
    
    return this.httpclient.get(storeUrl)
      .pipe(
        tap((response: any) => console.log('Store API response:', response)),
        map((response: any) => response.store), // Extract the store object from the response
        catchError(this.handleError)
      );
  }
  get storeDetailUrl() {
    console.log('Current geoLocation:', this.geoLocation);
    const lat = this.geoLocation?.lat || '';
    const lng = this.geoLocation?.lng || '';
    console.log('Extracted lat:', lat);
    console.log('Extracted lng:', lng);
    return `${environment.baseUrl}webusers/stores?franchise=UCLEAN&lat=${lat}&lng=${lng}`;
  }
  getStores() {
    return this.httpclient.get(this.stroesListUrl);
  }
  deleteAccount(formBody: { mobile: string, userId: string, reason: string, message?: string }) {
    return this.httpclient.post(this.deleteAccountUrl, formBody);
  }
  cityList() {
    return this.httpclient.get(this.cityListUrl);
  }


  private handleError(error: any): Observable<never> {
    console.error('API Error:', error);
    return throwError(() => new Error('Failed to fetch data. Please try again later.')); // Re-throw the error
  }

  addresses() {
    return this.httpclient.get(this.addressesUrl);
  }

  orderHistory() {
    return this.httpclient.get(this.orderHistoryUrl);
  }

  services() {
    return this.httpclient.get(this.servicesUrl + '&lat=' + this.geoLocation.lat + '&lng=' + this.geoLocation.lng);
  }

  storeDetail(store: string): Observable<any> {
    // Check if location is available
    if (!this.geoLocation?.lat || !this.geoLocation?.lng) {
      console.warn('User location not found');
      return throwError(() => new Error('Location not available'));
    }

    // Replace hyphens and ensure proper URL encoding
    const encodedStore = encodeURIComponent(store.replace(/-/g, "_"));
    const url = `${this.storeDetailUrl}&store=${encodedStore}`;

    return this.httpclient.get(url);
  }

  loginUser: any;

  loginData(formBody: any) {
    return this.httpclient.post(this.loginUrl, formBody);
  }

  senOTP(formBody: any) {
    return this.httpclient.post(this.sendOTP, formBody);
  }

  createNewAdd(formBody: any) {
    return this.httpclient.post(this.createNewAddUrl, formBody);
  }

  deleteAdd() {
    return this.httpclient.post(this.createNewAddUrl,
      { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('userToken') }) });
  }

  logOut() {
    return this.httpclient.delete(this.logoutUrl);
  }

  franchise(formBody: any) {
    return this.httpclient.post(this.franchiseUrl, formBody);
  }

  updateUserProfile(formBody: any) {
    return this.httpclient.post(this.updateUserProfileUrl, formBody);
  }

  currentUser() {
    return this.httpclient.get(this.currenUserUrl);
  }

  searchStore() {
    return this.httpclient.get(this.searchStoreUrl)
    // { headers: new HttpHeaders({'lat=':this.lat+{'&lng=': this.lng}})});
  }

  private upersons: Address[] = [
  ];

  getAddFromData(): Address[] {
    return this.upersons;
  }

  addAdd(address: Address) {
    address.id = this.upersons.length + 1;
    this.upersons.push(address);

  }
  updateAdd(address: Address) {
    const index = this.upersons.findIndex(u => address.id == u.id);
    this.upersons[index] = address;
  }
  deleteUser(address: Address) {
    this.upersons.splice(this.upersons.indexOf(address), 1);
  }

  media() {
    return this.httpclient.get(this.mediaUrl);
  }

  public authUser(email: string, userId: string, token: any, expiresIn: any, _tokenExpDate: any) {
    const expiresDate: any = new Date(new Date().getTime() + expiresIn * 1000);
    const user = new User(email, userId, token, expiresDate, _tokenExpDate);
    alert(user)
    this.user.next(user)
  }

  locs: any = {}

  delAdd: any

  set delAdds(value: any) {
    this.delAdd = value;
  }

  get servs() {
    return this.delAdd;
  }

  locatonId: any;
  set locId(value: any) {
    this.locatonId = value;
  }

  strDtls: any;
  set storeData(value: any) {
    this.strDtls = value;
  }

  storeServices() {
    return this.httpclient.get(this.servicesUrl + '&store_id=' + this.selstoreID);
  }

  cityServices() {
    return this.httpclient.get(this.servicesUrl + '&city_id=' + this.selCityID);
  }

  storePrice() {
    return this.httpclient.get(this.storePricesUrl + 'city_id=' + this.selCityID + '&service_ids=' + this.selServiceID + '&store_id=' + this.selstoreID);
  }

  cityPrice() {
    return this.httpclient.get(this.storePricesUrl + 'city_id=' + this.selCityID + '&service_ids=' + this.selServiceID);
  }
  selstoreID: any;
  set storeId(value: any) {
    this.selstoreID = value;
  }

  selCityID: any;
  set cityId(value: any) {
    this.selCityID = value;
  }

  selServiceID: any;
  set serviceId(value: any) {
    this.selServiceID = value;
  }

  selStoreCityId: any;
  set storeCityId(value: any) {
    this.selStoreCityId = value;
  }

  storeDtlCityName: any;
  set storeDtlCity(value: any) {
    this.storeDtlCityName = value;
  }

  storeDtlstoreCode: any;
  set storeDtlcode(value: any) {
    this.storeDtlstoreCode = value;
  }

  storeDtlLocalityName: any;
  set storeDtllocality(value: any) {
    this.storeDtlLocalityName = value;
  }
  // new function to set current Store

}