<!-- inner_banner_section start-->

<section class="inner_banner_section media_page_banner">
    <div class="container">
       <div class="row">
          <div class="inner_banner_block">
               <!-- <div class="inner_banner_tilte">
                   <h1>Media</h1>
               </div> -->
          </div>
       </div>
    </div>
</section>

<!-- inner_banner_section end-->


<!-- media_page_section start-->

<section class="media_page_section">
    <div class="container">
       <div class="row">
           <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
               <div class="media_page | paginate: { itemsPerPage: 5, currentPage: p }">
                   <nav>
                       <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                           <a class="nav-item nav-link selected" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">In the News</a>
                       </div>
                   </nav>
                   <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                       <div class="tab-pane active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                           <li *ngFor="let media of mediaData | paginate: { itemsPerPage: 5, currentPage: p }">
                               <div class="col-lg-9 col-md-9 col-sm-8 col-xs-12 med_text">
                                   <a href="{{media.link}}"><h4>{{media.title}}</h4></a>
                                   <p>{{media.description}} <a href="{{media.link}}">Read More <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a></p>
                               </div>
                               <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12 med_icon">
                                   <a><img src="{{media.full_link}}"></a>
                               </div>

                           </li>

                       </div>
                   </div>
               </div>

               <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
               <div class="navigation_blog">
                   <nav aria-label="Page navigation example">
                       <ul class="pagination" (click)="scrollToTop()">
                        <pagination-controls (pageChange)=" p = $event" class="float-right"></pagination-controls>
                       </ul>
                   </nav>
               </div>
           </div>

           </div>



       </div>
    </div>
</section>

<!-- media_page_section end-->
