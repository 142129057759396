import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent {
  deleteForm: FormGroup;
  showMessageBox = false;
  userMobile: string = '';
  userId: string = '';

  // Add getter for easier template access
  get agreementValue(): boolean {
    return this.deleteForm.get('agreement')?.value || false;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private pagesService: PagesService
  ) {
    this.deleteForm = this.fb.group({
      reason: ['', Validators.required],
      message: [''],
      agreement: [false, Validators.required]
    });

    // Listen to reason changes to show/hide message box
    this.deleteForm.get('reason')?.valueChanges.subscribe(value => {
      this.showMessageBox = value === 'Others';
    });
  }

  ngOnInit(): void {
    // Fetch user ID from route parameter
    this.userId = this.route.snapshot.paramMap.get('id') || '';
    
    // Fetch user profile data when component initializes
    this.fetchUserProfile();
  }

  fetchUserProfile(): void {
    this.pagesService.currentUser().subscribe(
      (response: any) => {
        if (response && response.mobile) {
          this.userMobile = response.mobile;
          
          // Validate that the user ID from route matches the current user
          if (response.id !== this.userId) {
            console.error('User ID mismatch');
          
          }
        }
      },
      (error) => {
        console.error('Error fetching user profile:', error);
      }
    );
  }
  redirectToLogin(event?: Event): void {
    this.router.navigate(['login-page'], {
      replaceUrl: true
    });
  }

  onSubmit(): void {
    if (this.deleteForm.valid && this.agreementValue && this.userMobile && this.userId) {
      const formBody = {
        mobile: this.userMobile,
        userId: this.userId,
        reason: this.deleteForm.get('reason')?.value,
        message: this.deleteForm.get('message')?.value
      };

      this.pagesService.deleteAccount(formBody).subscribe(
        (response) => {
          // Handle successful deletion
          console.log('Account deleted:', response);
          // Add any additional success handling (e.g., redirect, show success message)
        },
        (error) => {
          // Handle deletion error
          console.error('Error deleting account:', error);
          // Add error handling (e.g., show error message to user)
        }
      );
      this.redirectToLogin();
    } else {
      // Handle invalid form or missing mobile number
      console.error('Form is invalid or required data is missing');
    }
  }
}