<!-- inner_banner_section start-->

<section class="inner_banner_section franchise_page_banner">
    <div class="container">
        <div class="row">
            <div class="inner_banner_block">
                <div class="inner_banner_tilte">
                    <h1>Franchise</h1>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- inner_banner_section end-->

<!-- franchise_form_section start-->

<section class="franchise_form_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="main_title_bar">
                    <h2>apply for uclean franchise</h2>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="franchise_form">
                    <form form [formGroup]="registerForm" (ngSubmit)="onSubmit()" [hidden]="hideForm" id="contact-form"
                        method="post" action="#contact" role="form" novalidate="novalidate">
                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <div class="form-group">
                                    <input formControlName="name" [(ngModel)]="name" id="form_name" type="text"
                                        name="name" value="" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                                        placeholder="Enter your Name*">
                                </div>
                                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                    <div *ngIf="f.name.errors.required">Please enter name</div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <div class="form-group">
                                    <input formControlName="email"
                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" [(ngModel)]="email"
                                        id="form_email" type="email" name="email" value="" class="form-control"
                                        placeholder="Enter your Email*">
                                </div>
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">Please enter email</div>
                                    <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <div class="form-group">
                                    <input formControlName="mobile"
                                        [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" [(ngModel)]="phone"
                                        id="form_phone" type="tel" name="phone" value="" class="form-control"
                                        placeholder="Enter your Number*">
                                </div>
                                <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                    <div *ngIf="f.mobile.errors.required">Please enter mobile</div>
                                    <div *ngIf="f.mobile.errors.minlength">Mobile must be at least 10 numbers</div>
                                    <div *ngIf="f.mobile.errors.maxlength">Mobile not more then be 10 numbers</div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <div class="form-group">
                                    <!-- <input formControlName="city"
                                        [ngClass]="{ 'is-invalid': submitted && f.city.errors }" [(ngModel)]="city"
                                       type="text" name="City" value=""
                                        class="form-control" placeholder="Enter the City*"
                                        style="height: 51px; margin-bottom: 3%;"> -->
                                        <input formControlName="city"
                                        [ngClass]="{ 'is-invalid': submitted && f.city.errors }" [(ngModel)]="city"
                                        id="searchRegion" (keyup)="addSearches()" type="text" name="City" value=""
                                        class="form-control" placeholder="Enter the City*"
                                        style="height: 51px; margin-bottom: 3%;">
                                    <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                        <div *ngIf="f.city.errors.required">Please select your city</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <textarea formControlName="dtlMsg"
                                        [ngClass]="{ 'is-invalid': submitted && f.city.errors }" [(ngModel)]="msg"
                                        id="form_message" name="message" class="form-control"
                                        placeholder="Tell us the area where you want to open the store*"
                                        rows="7"></textarea>
                                    <div *ngIf="submitted && f.dtlMsg.errors" class="invalid-feedback">
                                        <div *ngIf="f.dtlMsg.errors.required">Field should not be empty</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <input (click)="applyFranchise();" type="submit" class="btn btn-success btn-send"
                                    value=" Submit Application ">
                            </div>
                        </div>
                    </form>
                    <div [hidden]="showSuccesMsg" id="successMsg">
                        <h2>{{franchiseData.status}}</h2>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- franchise_page_section end-->


<!-- franchise_page_section start-->

<section class="franchise_page_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="about_img_sec">
                        <img src="//assets.ucleanlaundry.com/assets/images/about_img_11zon.webp" style="border-radius: 30px;">
                    </div>
                    <!-- <div class="about_img_sec" style="margin-top: 10%;">
                        <img class="second" src="//assets.ucleanlaundry.com/assets/images/service_main_image.png">
                    </div> -->
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="ab_about_title">
                        <h2>why invest with us</h2>
                        <p>At UClean, we understand how difficult it is to
                            build a business from ground up. This is why we
                            have built Industry's best Franchise Support
                            Program that takes care of everything you need
                            to run a successful UClean store. We are
                            committed to your growth and will leave no
                            stone unturned to make this happen. </p>
                        <ul class="list_franch">
                            <li>

                                <p><span>Project Support</span> Right location at the right price is essential
                                    for success of any retail business. Our
                                    project and marketing team will help you.
                                    identify locations in high performing micro
                                    markets, within your budget.</p>
                            </li>
                            <li>
                                <p><span>Operations Support</span>UClean stores enjoy highest rating of 4.4/5
                                    on Google for our quality. Our operations
                                    training ensures that franchises become
                                    experts at high-quality cleaning techniques.</p>
                            </li>
                            <li>
                                <p><span>Hiring & Training Support</span>We understand how important a good team
                                    is for running a successful business. We help
                                    you hire & train the right team with all the
                                    required operational skills</p>
                            </li>
                            <li>
                                <p><span>Supply Chain Management</span>We have established well-oiled supply chain
                                    machinery to ensure that you don't have to
                                    run pillar to post to buy operational items.</p>
                            </li>
                            <li>
                                <p><span>Tech & IT Support</span>Data confidentiality is at the heart of our
                                    business, Unlike, any other Laundry brand,
                                    UClean has built in-house invoicing and lead
                                    generation software to provide you with
                                    data security and data confidentiality</p>
                            </li>
                            <li>
                                <p>Laundry is one of the oldest industries yet highly
                                    organized. Every household generates laundry.
                                    Thus, leading to a never -ending demand
                                    </p>
                            </li>
                            <!-- <li>
                                <p><span>Laundry business has a very-high Repeat
                                    Purchase Rate</span>Laundry has a larger TAM (Total Addressable
                                    Market); everyone is a potential customer
                                    Outsourcing laundry is convenient and saves
                                    time & money
                                    Laundry and Dry Cleaning businesses are
                                    generational and enjoy high customer loyalty
                                    with low investment in customer retention</p>
                            </li>
                            <li>
                                <p><span>Demand for "Slow Fashion" is increasing</span>This means more investment in professional
                                    cleaning and upkeep of garment</p>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- franchise_page_section end-->


<!-- franchise_page_section2 start-->

<section class="franchise_page_section2">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-lg-push-6 col-md-push-6 ">
                    <div class="about_img_sec">
                        <img src="//assets.ucleanlaundry.com/assets/images/uclean_img01.png">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-lg-pull-6 col-md-pull-6">
                    <div class="ab_about_title">
                        <h2>our brand story</h2>
                        <p>UClean is India's largest franchise-based Laundry
                            and Dry-Cleaning chain, with 500+Franchises
                            across 150+ cities in India. Founded by Arunabh
                            Sinha in 2016, with a vision to make professional
                            laundry accessible and affordable for every
                            household in India. UClean proudly claims the
                            largest market share of the organized laundry
                            market for its consistent and concerted efforts
                            of ushering a laundry revolution in India.
                            In fact, UClean is the first and only Indian brand
                            to be conferred with, what is aptly called the
                            Oscars of the Franchising Industry, The Global
                            Franchise Awards for "The Best Emerging
                            Brand" in the world</p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- franchise_page_section2 end-->