import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { CalenderService } from 'src/app/services/calender.service';

@Component({
  selector: 'app-week-day-lister',
  templateUrl: './week-day-lister.component.html',
  styleUrls: ['./week-day-lister.component.scss']
})
export class WeekDayListerComponent implements OnInit {

  public weekDaysList: any[] = [];
  selectedDate: any;
  @Output() onDateSelect = new EventEmitter();
  
  constructor(
    private metaTagService: Meta,
    private calenderService: CalenderService) {
  }
  
  ngOnInit(): void {
    this.weekDaysList = this.calenderService.getWeekDaysList();
  }

  onWeekDateSelect(event: any) {
    this.onDateSelect.emit({
      day: +event?.dd,
      month: +event?.mm,
      year: +event?.yyyy
    })
  }

}
