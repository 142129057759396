import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { PagesService } from '../pages.service';
import {AuthService} from '../../../services/auth.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  constructor(private pagesService: PagesService, public authService: AuthService, private metaTagService: Meta) { }

  model: NgbDateStruct | any;

  editProfile = true;
  userData: any = {};


  ngOnInit(): void {

  }

  editProfileEnable() {
    this.editProfile = false;
  }

  editProfileDisable() {
    this.editProfile = true;
  }


  updateProfile() {
    var body = {
      "name": this.authService.user.name,
      "dob":this.authService.user.dob.year + '-' +this.authService.user.dob.month + '-' + this.authService.user.dob.day,
      "sex": this.authService.user.sex,
    }
    this.pagesService.updateUserProfile(body).subscribe((data: any) => {
      this.editProfile = true;
      return data = this.userData
    });
  }

}
