<section class="hp_banner_section" [style.background-image]="'url(' + bannerImageUrl + ')'">
    <div class="container">
      <div class="row">
        <div class="hp_banner_block">
          <div class="hp_banner_tilte">
            <h3 class="baner-top">Premium Laundry</h3>
            <h3 class="banner-botomm">Eco-Friendly - Expert Stain Removal</h3>
          </div>
  
        </div>
      </div>
    </div>
  </section>
  <div class="service-body">
    <h2 class="process-title" style="margin-bottom: 30px;">
      A Glance at Our Premium Laundry Service 
    </h2>
    <div class="body body-text" style="margin-bottom: 50px !important;">
     
      When your garments require more than just a standard wash, but don’t necessarily need a solvent cleaning, our Premium Laundry Service is the perfect solution. Designed for those special items that deserve extra attention—whether it’s formal wear, fabric that needs stain removal service or requires individualized care—our service ensures each piece is treated with the utmost precision. We go beyond the ordinary, offering a tailored approach that preserves the quality, texture, and beauty of your cherished clothes. Plus, it’s a more affordable option than traditional dry cleaning or solvent-based methods. Trust us to deliver exceptional results every time.
As polyester, nylon, and cellulose-based garments become more popular, traditional solvent cleaning is no longer the ideal treatment. Our skilled and certified technicians, with years of industry experience, are expertly trained to deliver exceptional results for these advanced fabrics. We ensure that each garment receives the specialized care it needs to maintain its quality and appearance.

    </div>
     
    </div>
            <div class="process">
               
                    <h2 class="process-title">OUR PREMIUM LAUNDRY PROCESS</h2>
                    <p class="process-description">Every cloth goes through a comprehensive 6 stage process enabling us to deliver on the promise of sparkling & fresh clothes, every time.</p>
                    
                    <div class="process-timeline">
                      <div class="process-step" *ngFor="let step of processSteps; let last = last">

                        <div class="step-icon">
                            <img [src]="step.iconPath" [alt]="step.title" class="custom-icon">
                          </div>
                    
                        <div class="step-content">
                          <h3 class="step-title">{{ step.title }}</h3>
                          <p class="step-description">{{ step.description }}</p>
                        </div>
                        <div class="step-connector" *ngIf="!last"></div>
                      </div>
                    </div>
                 
            </div>
            <div class="book-now">
                <h1>Trust us for keeping your clothes ‘AS GOOD AS NEW’!</h1>
                <button class="btn primary">Book Now</button>
            </div>
           
            <div class="dry-cleaning-container">
                <div class="image-container">
                  <img [src]="premiumLaundryImage" alt="Premium Laundry Service">
                </div>
                <div class="content-container">
                  <h2 class="title">OUR PREMIUM LAUNDRY PRICES</h2>
                  <p class="description body-text">
                    At UClean, we believe in delivering high quality cleaning services at very affordable prices. With stores across multiple Tier 1, Tier 2 and Tier 3 cities, Quality, Speed and Economical Prices have been our biggest value drivers.
                  </p>
                  <a href='laundry-drycleaning-price-list' class="view-pricing-btn">View Pricing</a>
                </div>
              </div>

            <div class="promise-container">
                <h2 class="promise-title">OUR PROMISE TO YOU</h2>
                <app-our-promise></app-our-promise>
              </div>