<!-- hp_why_us_section start-->

<section class="hp_why_us_section">
   <div class="container">
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="main_title_bar">
               <h2 class="wu">Why Uclean?</h2>
            </div>
         </div>
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top chip-list">
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 left_side_block">
               <li class="why_list_sec">
                  <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12 col-lg-push-9 col-md-push-7 ">
                     <div class="list_icon">
                        <img src="//assets.ucleanlaundry.com/assets/images/QUICK_SERVICE_11zon.webp"
                           alt=" Sofa Dry Cleaning Services">
                     </div>
                  </div>
                  <div class="col-lg-9 col-md-8 col-sm-12 col-xs-12 col-lg-pull-3 col-md-pull-5">
                     <div class="list_text">
                        <p>Quick Service</p>
                     </div>
                  </div>
               </li>
               <li class="why_list_sec">
                  <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12 col-lg-push-9 col-md-push-7">
                     <div class="list_icon">
                        <img src="//assets.ucleanlaundry.com/assets/images/DOORSTEP_PICK_UP_AND_DROP_11zon.webp"
                           alt=" Online dry cleaning services">
                     </div>
                  </div>
                  <div class="col-lg-9 col-md-8 col-sm-12 col-xs-12 col-lg-pull-3 col-md-pull-5">
                     <div class="list_text">
                        <p>Doorstep Pick <br> up and Drop</p>
                     </div>
                  </div>
               </li>
               <li class="why_list_sec">
                  <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12 col-lg-push-9 col-md-push-7">
                     <div class="list_icon">
                        <img src="//assets.ucleanlaundry.com/assets/images/affordable_per_kilo_pricing_11zon.webp"
                           alt=" laundry pick and drop services">
                     </div>
                  </div>
                  <div class="col-lg-9 col-md-8 col-sm-12 col-xs-12 col-lg-pull-3  col-md-pull-5">
                     <div class="list_text">
                        <p>Affordable per <br> kilo pricing</p>
                     </div>
                  </div>
               </li>

               <li class="why_list_sec">
                  <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12 col-lg-push-9 col-md-push-7">
                     <div class="list_icon">
                        <img src="//assets.ucleanlaundry.com/assets/images/STATE_OF_THE_ART_TECHNOLOGY_11zon.webp"
                           alt=" Laundry shop near me">
                     </div>
                  </div>
                  <div class="col-lg-9 col-md-8 col-sm-12 col-xs-12 col-lg-pull-3  col-md-pull-5">
                     <div class="list_text">
                        <p>State-of-the-art <br> Technology</p>
                     </div>
                  </div>
               </li>

               <li class="why_list_sec">
                  <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12 col-lg-push-9 col-md-push-7">
                     <div class="list_icon">
                        <img src="//assets.ucleanlaundry.com/assets/images/WE_SAVE_WATER-WITH_EVERY_WASH_CYCLE_11zon.webp"
                           alt=" dry cleaning shop near me">
                     </div>
                  </div>
                  <div class="col-lg-9 col-md-8 col-sm-12 col-xs-12 col-lg-pull-3  col-md-pull-5">
                     <div class="list_text">
                        <p>We Save Water with <br> every wash</p>
                     </div>
                  </div>
               </li>



            </div>

            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 center_side_block">
               <div class="logo_center">
                  <img src="//assets.ucleanlaundry.com/assets/images/center_logo_11zon.webp" alt=" Dry cleaners near me">
               </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 right_side_block">
               <li class="why_list_sec">
                  <div class="why-doc">
                     <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                        <div class="list_icon">
                           <img src="//assets.ucleanlaundry.com/assets/images/PROFESSIONAL_CLEANING_11zon.webp">
                        </div>
                     </div>
                     <div class="col-lg-9 col-md-8 col-sm-12 col-xs-12">
                        <div class="list_text">
                           <p>Professional <br> cleaning</p>
                        </div>
                     </div>
                  </div>
               </li>
               <li class="why_list_sec">
                  <div class="why-doc">
                     <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                        <div class="list_icon">
                           <img src="//assets.ucleanlaundry.com/assets/images/GREEN_CERTIFIED_DETERGENTS_11zon.webp"
                              alt=" laundry near me">
                        </div>
                     </div>
                     <div class="col-lg-9 col-md-8 col-sm-12 col-xs-12">
                        <div class="list_text">
                           <p>Green-Certified <br> Detergents </p>
                        </div>
                     </div>
                  </div>
               </li>
               <li class="why_list_sec">
                  <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                     <div class="list_icon">
                        <img src="//assets.ucleanlaundry.com/assets/images/ONDEMAND_EXPRESS_SERVICE_AVAILABLE_.webp"
                           alt=" Laundry">
                     </div>
                  </div>
                  <div class="col-lg-9 col-md-8 col-sm-12 col-xs-12">
                     <div class="list_text">
                        <p>On-demand<br>service also available</p>
                     </div>
                  </div>
               </li>
               <li class="why_list_sec">
                  <div class="why-doc">
                     <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                        <div class="list_icon">
                           <img src="//assets.ucleanlaundry.com/assets/images/100_SANITIZED_and_MACHINE_DRY_11zon.webp"
                              alt=" Uclean laundry">
                        </div>
                     </div>
                     <div class="col-lg-9 col-md-8 col-sm-12 col-xs-12">
                        <div class="list_text">
                           <p>100% Sanitized <br> Clothes</p>
                        </div>
                     </div>
                  </div>
               </li>
               <li class="why_list_sec">
                  <div class="why-doc">
                     <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                        <div class="list_icon">
                           <img src="//assets.ucleanlaundry.com/assets/images/CUSTOM_PACKAGING_FOLD_OR_HANGI.png"
                              alt=" uclean dry cleaner">
                        </div>
                     </div>
                     <div class="col-lg-9 col-md-8 col-sm-12 col-xs-12">
                        <div class="list_text">
                           <p>Custom packaging <br> Fold or Hang!</p>
                        </div>
                     </div>
                  </div>
               </li>
            </div>
         </div>
      </div>
      <div class="row mob-chip-list">
         <div class="col-sm-6 col-xs-6 chip" *ngFor="let chip of serviceList; let i = index;">
            <img class="list_icon_mob" [src]="chip?.image">
            <p class="list_text_mob">{{ chip?.name }}</p>
         </div>
      </div>
   </div>
</section>

<!-- hp_why_us_section end-->


<section class="Download-UClean-App">
   <div class="Download-outer">
      <div class="container ">
         <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
               <div class="Download-UClean-App-Inner">
                  <div class="row rel-p">
                     <div class="col-lg-5 col-md-12">
                        <div class="mobile-view">
                           <h2>Download UClean App</h2>
                        </div>
                        <div class="mobile-photo">
                           <img class="mobile1" src="//assets.ucleanlaundry.com/assets/img/mobile1_11zon.png"
                              alt=" app home page ">
                           <img class="mobile2" src="//assets.ucleanlaundry.com/assets/img/mobile2_11zon.png"
                              alt=" process of how to place order on app ">
                        </div>
                     </div>
                     <div class="col-lg-7 col-md-12">
                        <div class="d-app">
                           <h2>Download UClean App</h2>
                           <p>
                              Upgrade your laundry experience with our <span class="highlight-color">new app!</span> Get
                              the convenience of easy scheduling, real-time updates,
                              and <span class="highlight-color">free pick-up and drop-off</span> at your doorstep. No
                              more
                              hassle of traditional laundry services - with just a few taps,
                              you can have your clothes cleaned and returned to you in
                              no time. Download now and enjoy a stress-free laundry routine.
                           </p> <br />
                           <div class="app-icon">
                              <img class="playstore" src="//assets.ucleanlaundry.com/assets/img/playstore.png"
                                 alt=" best dry cleaners near me" (click)="openApp()">
                              <span class="or">OR</span>
                              <img class="app-store" src="//assets.ucleanlaundry.com/assets/img/app-store.png"
                                 alt=" best laundry near me" (click)="openApp()">
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="cover-skew"></div>
</section>
