<!-- inner_banner_section start-->

<section class="inner_banner_section store_page_banner">
    <div class="container">
        <div class="row">
            <div class="inner_banner_block">
                <div class="inner_banner_tilte">
                    <h1>{{storeData.name}}</h1>
                </div>
            </div>
            <div class="bn_btn">
                <a href="javascript:void(0)" (click)="onOrderNow(storesDtl)">Order Now</a>
            </div>
        </div>
    </div>
</section>

<section class="store_page_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 map">
                    <div class="store_map_sec">
                        <agm-map [latitude]='lat' [longitude]='long'>
                            <agm-marker [latitude]="lat" [longitude]="long">
                            </agm-marker>
                        </agm-map>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="store_detail_sec">
                        <h2 class="store-name">{{storesDtl.name}}</h2>
                        <ul class="store_detail_info">
                            <li>
                                <h5>Address</h5>
                                <p>{{ storeData.address }}</p>
                            </li>
                            <li>
                                <h5>Phone Number</h5><a>{{ storeData.mobile }}</a>
                            </li>
                            <li>
                                <h5>Store Timings</h5>
                                <p>Coming Soon</p>
                            </li>
                        </ul>

                      
                        <div class="store_btn">
                            <a class="store_price_list" routerLink="/laundry-drycleaning-price-list">Price List</a>
                            <!-- build-your-order?lat={{storesDtl.latitude}}&lng={{storesDtl.longitude}} -->
                            <a class="store_order_now" href="javascript:void(0)" (click)="onOrderNow(storesDtl)">Order Now</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<!-- store_page_section end-->

<!-- store_services_section start-->

<section class="store_services_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="main_title_bar">
                    <h2>Services available</h2>
                </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top">
                <div class="slider3">
                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <div class="store_serv">
                            <img src="//assets.ucleanlaundry.com/assets/images/ss_icon_1.png">
                            <h4>LAUNDRY BY KG</h4>
                            <p>The more, the better! Get laundry <br> done by kg, avail the best offers <br> at the best
                                prices.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <div class="store_serv">
                            <img src="//assets.ucleanlaundry.com/assets/images/ss_icon_2.png">
                            <h4>DRY CLEANING</h4>
                            <p>Professional dry cleaning services using high-quality technology and expert care at every
                                step. Advanced dry cleaning methods are used for your clothes.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <div class="store_serv">
                            <img src="//assets.ucleanlaundry.com/assets/images/ss_icon_3.png">
                            <h4>STEAM IRONING</h4>
                            <p>Removing wrinkles and providing the best-ironed clothes to you.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <div class="store_serv">
                            <img src="//assets.ucleanlaundry.com/assets/images/ss_icon_4.png">
                            <h4>SOFA CLEANING</h4>
                            <p>Trust no one for sofa cleaning than us! Get your Sofa neat and clean and dirt- free with
                                UClean!</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <div class="store_serv">
                            <img src="//assets.ucleanlaundry.com/assets/images/ss_icon_1.png">
                            <h4>LAUNDRY BY KG</h4>
                            <p>The more, the better! Get laundry <br> done by kg, avail the best offers <br> at the best
                                prices.</p>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</section>

<!-- store_services_section end-->

<!-- store_pg_block_section start-->

<section class="store_pg_block_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                    <div class="store_pg_img">
                        <img src="//assets.ucleanlaundry.com/assets/images/store_pg_img.jpg">
                    </div>
                </div>
                <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                   <!-- First section -->
                   <div class="store_pg_text">
                    <h2>{{footerHeading1 }}</h2>
                    <p>{{footerText1 }}</p>
                </div>

                </div>
            </div>
        </div>
    </div>
</section>

<!-- store_pg_block_section end-->

<!-- store_pg_bottom_section start-->

<section class="store_pg_bottom_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 col-lg-push-7">
                    <div class="store_pg_img2">
                        <img src="//assets.ucleanlaundry.com/assets/images/store_pg_img2.png">
                    </div>
                </div>
                <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 col-lg-pull-5">
                    
                    
<!-- Second section -->
<div class="store_pg_text">
    <h2>{{footerHeading2}}</h2>
    <p>{{footerText2 }}</p>
</div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- store_pg_bottom_section end-->
