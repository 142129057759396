import { Component, OnInit, ViewChild } from '@angular/core';
import { PagesService } from '../pages/pages.service';
import {AuthService} from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PositionService } from 'src/app/services/position.service';
import { OrderService } from '../build-order/order.service';
import { DeviceService } from 'src/app/services/device.service';
import { debounce, debounceTime, distinctUntilChanged, last, throttleTime } from 'rxjs/operators';
import { combineLatest, interval } from 'rxjs';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  phone: string = "";
  otp: any;
  loginBtn = true;
  profileBtn = false;
  rcvOtp :any;
  loginModalshow= true;
  address: any = {};
  isLocationFetching: boolean = false;
  isLocationFetched: boolean = false;
  showCurrentLoc = false;
  inputAddValue = '';
  locationDisallowed = true;
  @ViewChild('stickyHeader', {static:true}) stickyHeader:any;
  public scrollPosition: {left: number, top: number} = {left: 0, top: 0};
  public isScrollToNav = true;
  public isManualRemoved = false;

  constructor(
    public router:Router,
    public route: ActivatedRoute,
    public authService: AuthService,
    private orderService: OrderService,
    private positionService: PositionService,
    private deviceService: DeviceService,
    private metaTagService: Meta) { }

  ngOnInit(): void {
    // this.isLocationFetching = true;
    // setTimeout(() => {
    //   this.positionService.findCurrentLocation(this.onLocationFetched.bind(this), this.onLocationFailed.bind(this));
    // }, 3000);
    this.setScrollChangeSubscription();
  }

  setScrollChangeSubscription() {
    this.deviceService.scrollPositionChange.subscribe((scrollPosition: any) => {
      this.isScrollToNav = (this.scrollPosition?.top >= scrollPosition?.top) ? true: false;
      this.scrollPosition = scrollPosition;
    })
  }

  get isHome() {
    return this.router.url === '/';
  }

  get isLocationNotAllowed() {
    return !this.isLocationFetching && this.locationDisallowed && !this.isLocationFetched;
  }

  onLocationFetched(event: any) {
    this.isLocationFetching = false;
    this.locationDisallowed = false;
    this.isLocationFetched = true;
    this.positionService.currentLocations(this, this.positionCallback.bind(this));
  }

  onLocationFailed(event: any) {
    this.isLocationFetching = false;
    this.locationDisallowed = true;
    this.isLocationFetched = false;
  }

  positionCallback(that: any, location: any) {
    this.address = location;
    this.showCurrentLoc = false;
  }
  
  setLocation() {
    this.positionService.currentLocations(this, this.positionCallback.bind(this));
  }

  getVal(value: any) {
    this.inputAddValue = value;
    if (this.inputAddValue.length == 0) {
      this.showCurrentLoc = true;
    }
    if (this.inputAddValue.length > 0) {
      this.showCurrentLoc = false;
    }
  }

  currentLoc() {
    this.showCurrentLoc = true;
  }

  home(){
    window.location.href = '/';
  }

  onAddressSelected(event: any) {
    this.address = event;
  }

  closeLocationDisabledBanner() {
    this.isManualRemoved = true;
  }
}

