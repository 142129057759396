<!-- inner_banner_section start-->

<section class="inner_banner_section our_value_banner">
    <div class="container">
        <div class="row">
            <div class="inner_banner_block">
                <div class="inner_banner_tilte">
                    <!-- <h1>Career</h1> -->
                </div>
            </div>
        </div>
    </div>
</section>

<!-- inner_banner_section end-->


<!-- career_main_section start-->

<section class="career_main_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="main_title_bar">
                    <p>UClean is proudly built by the hard work and passion of its UChamps. We are always looking for
                        people who arefearless and <br> believe that no obstacle is too big to overcome. If you are
                        looking for a place that fosters creative freedom and enables its <br> people to be the best
                        version of themselves, you have what it takes to be a UChamp!</p>
                </div>
            </div>

        </div>
    </div>
</section>

<!-- career_main_section end-->


<!-- our_value_section start-->

<!-- <section class="our_value_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="main_title_bar">
                    <h2>Current Openings</h2>
                </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top">
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <div class="job_block">
                        <h4>Senior Software Engineer</h4>
                        <ul>
                            <li><i class="fa fa-map-marker" aria-hidden="true"></i>Delhi
                            <li>
                            <li type="button" data-toggle="modal" data-target="#jobDescriptionModal">Job Description
                            </li>
                        </ul>
                        <a href="#">Apply Now</a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <div class="job_block">
                        <h4>Product Designer</h4>
                        <ul>
                            <li><i class="fa fa-map-marker" aria-hidden="true"></i>Gurugram
                            <li>
                            <li type="button" data-toggle="modal" data-target="#jobDescriptionModal">Job Description
                            </li>
                        </ul>
                        <a href="#">Apply Now</a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <div class="job_block">
                        <h4>Technical Support</h4>
                        <ul>
                            <li><i class="fa fa-map-marker" aria-hidden="true"></i>Hyderabad
                            <li>
                            <li type="button" data-toggle="modal" data-target="#jobDescriptionModal">Job Description
                            </li>
                        </ul>
                        <a href="#">Apply Now</a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <div class="job_block">
                        <h4>Wordpress developers</h4>
                        <ul>
                            <li><i class="fa fa-map-marker" aria-hidden="true"></i>Kolkata
                            <li>
                            <li type="button" data-toggle="modal" data-target="#jobDescriptionModal">Job Description
                            </li>
                        </ul>
                        <a href="#">Apply Now</a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <div class="job_block">
                        <h4>Junior Graphic Designer</h4>
                        <ul>
                            <li><i class="fa fa-map-marker" aria-hidden="true"></i>Ahmedabad
                            <li>
                            <li type="button" data-toggle="modal" data-target="#jobDescriptionModal">Job Description
                            </li>
                        </ul>
                        <a href="#">Apply Now</a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <div class="job_block">
                        <h4>Digital Marketer</h4>
                        <ul>
                            <li><i class="fa fa-map-marker" aria-hidden="true"></i>Faridabad
                            <li>
                            <li type="button" data-toggle="modal" data-target="#jobDescriptionModal">Job Description
                            </li>
                        </ul>
                        <a href="#">Apply Now</a>
                    </div>
                </div>

            </div>



        </div>
    </div>
</section> -->

<!-- our_value_section end-->

<!-- career_benefit_section start-->

<section class="career_benefit_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="main_title_bar">
                    <h2>Why Should you join us?</h2>
                </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top">
                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div class="join_block">
                        <div class="career_icon_sec">
                            <img src="//assets.ucleanlaundry.com/assets/images/career_icon_1.png">
                        </div>
                        <div class="career_icon_text">
                            <h4>Equal Opportunity <br> Employer</h4>
                            <p>We don’t discriminate based on gender, age, religion, caste etc. If you are qualified &
                                capable for the role, we would love to have you.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div class="join_block">
                        <div class="career_icon_sec">
                            <img src="//assets.ucleanlaundry.com/assets/images/career_icon_2.png">
                        </div>
                        <div class="career_icon_text">
                            <h4>Flexible Working Programs</h4>
                            <p>We believe that talent is not restricted by time of day. We offer flexibility to choose
                                your work timings & workdays, based on the role.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div class="join_block">
                        <div class="career_icon_sec">
                            <img src="//assets.ucleanlaundry.com/assets/images/career_icon_3.png">
                        </div>
                        <div class="career_icon_text">
                            <h4>Performance-first <br> Culture</h4>
                            <p>We offer lucrative incentive programs for all roles. <span>If you grow, we grow! </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div class="join_block">
                        <div class="career_icon_sec">
                            <img src="//assets.ucleanlaundry.com/assets/images/career_icon_4.png">
                        </div>
                        <div class="career_icon_text">
                            <h4>Lean Team-Horizontal <br> Organization</h4>
                            <p>We believe in breaking barriers & not creating them. Everyone in the team always has
                                access to founders & top management. </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- career_benefit_section end-->


<!-- career_team_section start-->

<section class="career_team_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="main_title_bar">
                    <h2>Life at UClean!</h2>
                </div>
            </div>
        </div>
    </div>

    <div class="uc_img_item" style="width: 100%; margin:0; padding-bottom: 5%;">
        <ng-image-slider #nav [images]="imageObject" [infinite]="true" [autoSlide]="1"
            [imageSize]="{width: '33%', height: 450}" slideImage="1"></ng-image-slider>
    </div>

</section>

<!-- career_team_section end-->

<!-- career_join_section start-->

<section class="hp_main_section career_join_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                <div class="main_title_bar">
                    <h2>Follow us on</h2>
                </div> 

                <div class="follow_block">
                    <ul>
                        <li><a href="https://www.facebook.com/ucleanindia/" target="_blank"><i class="fa fa-facebook"
                                    aria-hidden="true"></i></a></li>
                        <li><a href="https://twitter.com/ucleanindia" target="_blank"><i class="fa fa-twitter"
                                    aria-hidden="true"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/uclean/" target="_blank"><i class="fa fa-linkedin"
                                    aria-hidden="true"></i></a></li>
                        <li><a href="https://instagram.com/_uclean_?utm_medium=copy_link" target="_blank"><i class="fa fa-instagram"
                                    aria-hidden="true"></i></a></li>
                    </ul>
                </div>

            </div>
        </div>
    </div>\
    <ul>
    <li><img src="//assets.ucleanlaundry.com/assets/images/icon_1.png"></li>
    <li><img src="//assets.ucleanlaundry.com/assets/images/icon_2.png" alt=" Dry cleaning services"></li>
    <li><img src="//assets.ucleanlaundry.com/assets/images/icon_3.png" alt=" laundry wash and fold"></li>
    <li><img src="//assets.ucleanlaundry.com/assets/images/icon_4.png"></li>
    </ul>
</section>

<!-- career_join_section end-->

<!-- job Description popup -->

<div class="modal fade" id="jobDescriptionModal" tabindex="-1" role="dialog" aria-labelledby="jobDescriptionModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="jobDescriptionModalLabel">Senior Manager - Supply Chain - Fulfiment </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>City- Faridabad <br>
                    Mode- Office-based</p>
                <br>
                <h4>Job Description:</h4>
                <br>
                <p>To ensure great customer experience through managing an earl. morning suppl. chain with a
                    multi hierarch. team of 5PPP+ members which can successfull. execute Tens of Thousands of
                    shipments every. morning without failures. The Operations function at Supr Dail. is responsible for
                    order fulfilment, logistics and customer experience. This role will have to look into the end-toend
                    responsibilit. for delivering on all these aspects as per the internal norms of the compan.
                </p>
                <br>
                <ul>
                    <li> Ensure compliance in hub, docks, operations and logistics in all areas</li>
                    <li> Execute plans for ensuring the availabilit. of adequate fleet and/or building the fleet and
                        infrastructure to meet the Suppl. demand </li>
                    <li>Strategize and Execute central initiatives as well as contribute with ideas for effectivel.
                        scaling up the operations in .our cit. </li>
                    <li>Explore opportunities for reducing the overall Cost Per Deliver.</li>
                    <li>Special Projects - Manage/ Contribute to Special projects such as launching new cities, building
                        new suppl. chain etc.</li>
                </ul>
                <br>

                <h4>Desired Candidature:</h4>
                <br>
                <p>Graduate OR Post Graduate with 7-1P .ears' work exp</p>
                <br>
                <ul>
                    <li>Prior experience in Handling end to end Supply Chain and operations implementation
                        (preferabl. in Logistics / Supply chain management)</li>
                    <li>Prior leadership experience. (experience in managing blue collared employees is a bonus)</li>
                    <li>Strong time management skills and the ability to priorities to meet daily, weekly, and long-term
                        requirements and goals</li>
                    <li>Data anal.tics skills / should be comfortable working with MS excel. Should have the intuition
                        to use data effectively for devising operations strategy</li>
                    <li>Structured problem solving, detail oriented and customer first attitude</li>
                    <li>The candidate should have an entrepreneurial attitude</li>
                    <li>Must have attention to detail and criticall. think through and resolve problems</li>
                    <li>Must have customer service skills and be able to maintain a consistent, elevated level of
                        service</li>
                    <li>Handled a team size of 200 + members. (Mix of on role & off role)</li>
                    <li>Good communication/stakeholder management skills</li>
                </ul>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" style="float: left;">Apply Now</button>
            </div>
        </div>
    </div>
</div>

<!-- job Description popup -->