<div *ngIf="loginModalshow" class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="loginModalLabel"
	aria-hidden="true">
	<div class="modal-dialog newLogin" role="document">
		<div class="modal-content">
            <div class="modal-body">
                <div class="modal-body-container">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="image-section">
                    </div>
                    <div class="form-section">
                        <ng-container *ngTemplateOutlet="loginForm"></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loginForm>
    <h1 class="form-header">Login/Signup</h1>
    <p class="form-subheader">Hi there, nice to see you</p>
    <form>
        <div class="login_form_info">
            <div class="form-group">
                <input maxlength="10" (keydown.enter)="sendOTP();enableOtpBox();otpField.focus();" type="text" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" [(ngModel)]="phone" class="form-control" id="inputphonenumber"
                    placeholder="Enter Phone No." autocomplete="off">
            </div>
            <div class="form-group" [hidden]="hiddenOtpBox">
                <input #otpField type="text" (keydown.enter)="login();" maxlength="6" (keypress)="utililtyService.numberOnly($event)" [(ngModel)]="otp" class="form-control" id="inputOtp" placeholder="OTP"
                autocomplete="off">

            </div>
            <p style="color: red; float: left;">{{loginError?.msg}}</p>

            <div class="form-group" [hidden]="hiddenReOtpButton">
                <button  class="resend-otp-button" [disabled]="displayTimer" (click)="sendOTP()">Resend
                    OTP<span *ngIf="displayTimer"> in {{transform(counter)}}</span></button>
            </div>

            <div class="form-group" *ngIf="logInErrorMassege">
                    <button style="float: left; color: #75b945; margin-bottom: 10px; cursor: auto; background: transparent; border: none;">Seems like we are facing some technical issue. Request you to please reach out to our customer care team at <a href="tel:+91 9999759911" style="color: #75b945;"> 9999759911</a></button>
            </div>

            <div class="form-group" [hidden]="hiddenOtpButton" (click)="enableOtpBox()">
                <input [disabled]="!phone || phone.length != 10" type="button" name="login" class="login loginmodal-submit" value="Send OTP"
                    (click)="sendOTP()">
            </div>
            <div class="form-group" (click)="login()" [hidden]="hiddenLoginButton">
                <input [disabled]="!otp || otp.length != 6" type="button" name="login" class="login loginmodal-submit" value="Login"
                    >
            </div>
        </div>
        <div class="form_bottom">
            <!-- routerLink="ui/SignUp" -->
            <!-- (click)="loginModalshow = false" -->
            <a href="javascript:void(0)">Don't have an account yet? <b>Create one</b></a>
        </div>
        <div class="form_bottom">
            <p>by logging in you agree to the terms & conditions</p>
            <a routerLink="terms-and-conditions">Terms of Service</a> & <a routerLink="privacy-policy">Privacy
                Policy</a>
        </div>
    </form>
</ng-template>