import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-our-promise',
  templateUrl: './our-promise.component.html',
  styleUrls: ['./our-promise.component.scss']
})
export class OurPromiseComponent implements OnInit {
  affordableRatesIconUrl: string = `${environment.imageBaseUrl}icons/affordableRates.svg`;
  skinFriendlyIconUrl: string = `${environment.imageBaseUrl}icons/skinFriendly.svg`;
  uvSafeIconUrl: string = `${environment.imageBaseUrl}icons/uvSafe.svg`;
  freshWaterIconUrl: string = `${environment.imageBaseUrl}icons/freshWater.svg`;


  constructor() { }

  ngOnInit(): void {
  }

}
