import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';
import { LocationService } from 'src/app/services/location.service';
import { PagesService } from '../pages.service';

@Component({
  selector: 'app-franchise-form',
  templateUrl: './franchise-form.component.html',
  styleUrls: ['./franchise-form.component.scss']
})
export class FranchiseFormComponent implements OnInit {
  registerForm: FormGroup | any;
  submitted = false;
  hideForm = false;
  name: string = "";
  email: string = "";
  phone: string = "";
  city: string = "";
  msg: string = "";
  franchiseData: any = {};
  showSuccesMsg = true;

  @Output() onFranchiseApplied = new EventEmitter();

  constructor(
    private pageservice: PagesService,
    private formBuilder: FormBuilder,
    private locationService: LocationService,
    private authService: AuthService,
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    const user = localStorage.getItem('user_data') ? JSON.parse(atob(localStorage.getItem('user_data') as any)) : {};
    
    this.registerForm = this.formBuilder.group({
      name: [(user?.name || ''), Validators.required],
      city: ['', Validators.required],
      dtlMsg: ['', Validators.required],
      email: [(user?.email || ''), [Validators.required, Validators.email]],
      mobile: [(user?.mobile || ''), [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      privacyPolicy: [false, Validators.requiredTrue]
    });

    this.registerForm.patchValue({
      name: user?.name,
      city: '',
      email: (user?.email || ''),
      mobile: (user?.mobile || '')
    });

    this.name = user?.name || '';
    this.city = '';
    this.email = user?.email || '';
    this.phone = user?.mobile || '';
  }

  get f() { return this.registerForm.controls; }

  locationCallback(that: any, location: Location) {
    that.positionService.location = location;
    that.showError = false;
  }

  applyFranchise() {
    if (this.registerForm.get('privacyPolicy').value) {
      var body = {
        "fname": this.name,
        "email": this.email,
        "mobile": this.phone,
        "city": this.city,
        "message": this.msg
      };
      
      this.pageservice.franchise(body).subscribe((data: any) => {
        this.franchiseData = data;
        this.hideForm = true;
        this.showSuccesMsg = false;
        this.onFranchiseApplied.emit({
          name: this.name,
          phone: this.phone,
          email: this.email,
          ...data
        });
      });
    }
  }

  addSearches() {
    this.locationService.initGeoComplete('searchRegion', this, this.locationCallback, "FRANCHISE_FORM");
  }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
  }
}