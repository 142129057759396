import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
}) 
export class BannerService {

  constructor( private http:HttpClient ) { }
  homeBannerUrl = environment.baseUrl + 'api/v1/banners';

  homeBanner(){
    return this.http.get(this.homeBannerUrl);
  }

}
