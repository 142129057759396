
<section class="user_profile_section">
  <div class="container">
    <div class="row" *ngIf="!authService.loggedIn">
      <div class="col-xs-12">
        <div id='loading_wrap'></div>
      </div>
    </div>

    <div class="row" *ngIf="authService.loggedIn">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="col-lg-4 col-md-4 col-sm-5">
          <div class="profile_menu_bar" id="sidebar-wrapper">
            <div class="user_pro">
              <!-- <img src="//assets.ucleanlaundry.com/assets/images/user_pro_img.jpg"> -->
              <img src="https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png">
            </div>
            <div class="user_name">
              <h4>{{authService.user.name}}</h4>
            </div>

            <nav class="spy spy-web">
              <ul class="sidebar-nav nav nav-tabs">
                <h4>Profile</h4>
                <li class="sidebar-brand active"><a href="#my_profile" data-toggle="tab"><i
                  class="fa fa-user" aria-hidden="true"></i>My Profile</a></li>
                <li><a href="#order_history" data-toggle="tab"><i
                  class="fa fa-file-text-o" aria-hidden="true"></i>Order History</a></li>
                <li><a href="#addresses_book" data-toggle="tab"><i
                  class="fa fa-map-marker" aria-hidden="true"></i>Address Book</a></li>
                <li><a href="#profile_settings" data-toggle="tab"><i class="fa fa-cog"
                                                                     aria-hidden="true"></i>Settings</a></li>
                                                                     <li>
                                                                      <a [routerLink]="['/account/delete', currentUserId]" routerLinkActive="active">
                                                                        <i class="fa fa-trash-o fa-lg"></i>
                                                                        Delete Account
                                                                      </a>
                                                                    </li>
                <li data-toggle="modal" data-target="#login-modal"><a><i class="fa fa-power-off"
                                                                         aria-hidden="true"></i>Logout</a></li>

              </ul>
            </nav>

            <nav class="spy spy-mobile">
              <ul class="sidebar-nav nav nav-tabs">
                <h4>Profile</h4>
                <!-- href="#my_profile" -->
                <li class="sidebar-brand active" (click)="accordionStateChange('myProfile')"><a  data-toggle="tab"><i
                  class="fa fa-user" aria-hidden="true"></i>My Profile</a></li>
                  <div class="custom-accordion-body" [ngClass]="{'visible': animationState?.myProfile === 'in'}" [@slideInOut]="animationState?.myProfile">
                    <app-my-profile></app-my-profile>
                  </div>
                  <!-- routerLink="/profile/order-history" -->
                <li (click)="accordionStateChange('orderHistory')"><a  data-toggle="tab"><i
                  class="fa fa-file-text-o" aria-hidden="true"></i>Order History</a></li>
                  <div class="custom-accordion-body" [ngClass]="{'visible': animationState?.orderHistory === 'in'}" [@slideInOut]="animationState?.orderHistory">
                    <app-order-history></app-order-history>
                  </div>
                  <!-- routerLink="/profile/address-book" -->
                <li (click)="accordionStateChange('addressBook')"><a  data-toggle="tab"><i
                  class="fa fa-map-marker" aria-hidden="true"></i>Address Book</a></li>
                  <div class="custom-accordion-body" [ngClass]="{'visible': animationState?.addressBook === 'in'}" [@slideInOut]="animationState?.addressBook">
                    <app-user-addresses></app-user-addresses>
                  </div>
                  <!-- routerLink="/profile/notification-setting" -->
                <li (click)="accordionStateChange('notificationSetting')"><a  data-toggle="tab"><i class="fa fa-cog"
                                                                                       aria-hidden="true"></i>Settings</a>
                </li>
                <li>
                  <a [routerLink]="['/account/delete', currentUserId]" routerLinkActive="active">
                    <i class="fa fa-trash-o fa-lg"></i>
                    Delete Account
                  </a>
                </li>
                <div class="custom-accordion-body" [ngClass]="{'visible': animationState?.notificationSetting === 'in'}" [@slideInOut]="animationState?.notificationSetting">
                  <app-profile-settings></app-profile-settings>
                </div>
                <li data-toggle="modal" data-target="#login-modal"><a><i class="fa fa-power-off"
                                                                         aria-hidden="true"></i>Logout</a></li>


              </ul>
            </nav>
          </div>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-7 tab-content web-outlet" id="myTabContent">

          <!--my_profile-->
          <div class="tab-pane fade in active" id="my_profile">
            <app-my-profile></app-my-profile>
          </div>
          <!--my_profile-->

          <!--order_history-->
          <div class="tab-pane fade" id="order_history">
            <app-order-history></app-order-history>
          </div>
          <!--order_history-->

          <!--addresses_book-->

          <div class="tab-pane fade" id="addresses_book">
            <app-user-addresses></app-user-addresses>
          </div>

          <!--addresses_book-->

          <!--profile_settings-->

          <div class="tab-pane fade" id="profile_settings">
            <app-profile-settings></app-profile-settings>

          </div>

          <!--profile_settings-->


        </div>

      </div>
    </div>
  </div>
</section>

<div class="modal fade confirm_pop" id="login-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog">
    <div class="loginmodal-container">

      <div class="confirm_pop_title_bar">
        <img src="//assets.ucleanlaundry.com/assets/images/confirm_pop_icon.png">
        <h1>Do you want to confirm logout</h1>
      </div>
      <div class="text-center" style="padding: 0 34px;">
        <button (click)="logOutUser()" type="button" style="float: left;"
                class="btn btn-success">Confirm
        </button>
        <button data-dismiss="modal" style="float: right;" type="button"
                class="btn btn-success">Cancel
        </button>
      </div>
      <div class="pop_eft">
        <ul>
          <li><img src="//assets.ucleanlaundry.com/assets/images/icon_4.png" alt=" Laundry wash and iron"></li>
          <li><img src="//assets.ucleanlaundry.com/assets/images/icon_3.png" alt=" laundry wash and fold"></li>
          <li><img src="//assets.ucleanlaundry.com/assets/images/icon_1.png" alt="Laundry "></li>
          <li><img src="//assets.ucleanlaundry.com/assets/images/icon_4.png" alt=" Laundry wash and iron"></li>
        </ul>
      </div>

    </div>
  </div>
</div>
