import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { PixelService } from 'ngx-pixel';
import { metaTags } from 'src/assets/meta-contant';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(
    private pixel: PixelService,
    private metaTagService: Meta) { }

  ngOnInit(): void {
    this.pixel.trackCustom('PrivacyPolicyPage');
    this.metaTagService.updateTag({ property: 'title', content: metaTags?.['privacy-policy']?.title });
    this.metaTagService.updateTag({ property: 'description', content: metaTags?.['privacy-policy']?.description });
  }

}
