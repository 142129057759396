import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: any;
  loggedIn = false;
  currentUserUrl = environment.baseUrl + 'api/v1/current_user';
  private sendOtpRegisterUrl = environment.baseUrl + '/api/v1/send_OTPR';
  private sendOtpLoginUrl = environment.baseUrl + '/api/v1/send_OTPL';

  constructor(private httpclient: HttpClient) { }

  getCurrentUser(){
    return this.httpclient.get(this.currentUserUrl);
  }

  get userDetails() {
    return JSON.parse(atob(localStorage.getItem('user_data') as any));
  }

  checkAuthAndGetUser(){
      this.getCurrentUser().subscribe((data : any)=>{
        this.user = data;
        if(this.user != undefined && Object.keys(this.user).length > 0){
          if(data.dob!=undefined){

            data.dob= this.GetDate(data.dob);

          }else{

            data.dob= "2022-09-15";

          }
          this.loggedIn = true;
          console.log("Logged In", this.user.name);
        }else{
          this.loggedIn = false;
          console.log("Not logged In");
        }
      });
  }

  GetDate(date: string)
  {
    return { year: parseInt(date.split('-')[0]), month: parseInt(date.split('-')[1]), day: parseInt(date.split('-')[2]) };
  }

  sendOtpRegister(body: any) {
    return this.httpclient.post(this.sendOtpRegisterUrl, body);
  }

  sendOtpLogin(body: any) {
    return this.httpclient.post(this.sendOtpLoginUrl, body);
  }

}
