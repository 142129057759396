import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { CustomLocationInfoModel, LocationModel } from 'src/app/models/leaflet';
import { PositionService } from 'src/app/services/position.service';
import * as mapConfig from './map-config';
declare let L: any;

@Component({
  selector: 'app-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.scss']
})
export class LeafletMapComponent implements OnInit {

  @Input() isMapVisible = true;
  @Input() isLoadCurrentLocationOnInit!: boolean;
  @Input() defaultMapLocation!: LocationModel;
  @Output() mapInit = new EventEmitter<any>();
  @Output() mapLocationDragged = new EventEmitter<CustomLocationInfoModel>();
  @Output() mapCurrentLocationRequest = new EventEmitter<CustomLocationInfoModel>();
  @Output() mapLocationUpdated = new EventEmitter<LocationModel>();

  public map: any;
  private centerMarkerRef: any;
  public isSystemLocationFetched!: boolean;
  public isLocationConfirmBtnVisible!: boolean;
  private currentLocationPosition!: LocationModel;
  private currentMapPosition!: LocationModel;

  constructor(
    private metaTagService: Meta,
    private positionService: PositionService
  ) { }

  ngOnInit(): void {
    this.positionService.findCurrentLocation(this.onLocationFetched.bind(this), this.onLocationFailed.bind(this));
  }

  ngOnChanges(): void {
    console.log("defaultMapLocation from map compo", this.defaultMapLocation);
    if(this.map) {  
      this.goToParticularLocation(this.defaultMapLocation);
    }
  }

  get currentLatLng() {
    return [this.currentLocationPosition?.lat, this.currentLocationPosition?.lng];
  }

  get defaultLatLng() {
    const delhiLatLng = [28.7041, 77.1025];
    return this.isLoadCurrentLocationOnInit && this.isSystemLocationFetched ? this.currentLatLng : (this.defaultMapLocation || delhiLatLng);
  }

  onLocationFetched(event: any) {
    this.isSystemLocationFetched = true;
    this.currentLocationPosition = event;
    this.initMap();
  }

  onLocationFailed(event: any) {
    this.isSystemLocationFetched = false;
    this.initMap();
  }

  initMap() {
    var baseLayers = mapConfig.getVisibleLayers();
    const container = document.getElementById('map');
    if (container) {
      const mapSettings = {
        zoomControl: false
      }
      this.map = L.map('map', mapSettings).setView(this.defaultLatLng, mapConfig.MAP_SETTINGS.defaultZoomView, mapConfig.MAP_SETTINGS.getMapMoveConfig());
      L.control.layers(baseLayers).addTo(this.map);
      L.control.zoom({ position: 'bottomright' }).addTo(this.map);
      this.map.scrollWheelZoom.disable();
      baseLayers['Default '].addTo(this.map);
      this.setMapInitialiseSubscription();
      this.setCurrentLocationMarker();
    }
  }

  setMapInitialiseSubscription() {
    this.map.whenReady((e: any) => {
      this.mapInit.emit({ mapRef: e.target });
    })
  }

  setCurrentLocationMarker() {
    this.centerMarkerRef = new L.marker(this.defaultLatLng, { icon: mapConfig.getCustomMarker(2, true) }).bindPopup('Your cloths Will be collected from here<br> Move map to pin your exact location').openPopup()
      .addTo(this.map)
      .on('mouseover', (e: any) => {
        e.target.openPopup();
      })
    this.setMapMoveListener();
  }

  setMapMoveListener() {
    this.map
      .on('move', (e: any) => {
        this.isLocationConfirmBtnVisible = false;
        this.centerMarkerRef.setLatLng(e.target.getCenter());
      })
      .on('dragend', (e: any) => {
        this.isLocationConfirmBtnVisible = true;
        let newPosition = e.target.getCenter();
        this.onMapLocationChangeAfterInit(newPosition);
      })
      .on('moveend', (e: any) => {
        let newPosition = e.target.getCenter();
        this.onMapLocationChangeAfterInit(newPosition);
      });
  }

  onMapLocationChangeAfterInit(e: LocationModel) {
    this.currentMapPosition = {
      lat: +Number(e.lat).toFixed(5),
      lng: +Number(e.lng).toFixed(5)
    }
    this.mapLocationUpdated.emit(this.currentMapPosition);
  }

  goToSystemLocation() {
    this.currentMapPosition = {
      lat: this.currentLatLng[0],
      lng: this.currentLatLng[1]
    }
    this.map.setView(this.currentLatLng, mapConfig.MAP_SETTINGS.defaultZoomView, mapConfig.MAP_SETTINGS.getMapMoveConfig());
    this.positionService.getInfoFromLatLng(this.currentMapPosition, (e: CustomLocationInfoModel) => {
      this.mapCurrentLocationRequest.emit(e);
    })
    
  }

  goToParticularLocation(newPosition: LocationModel) {
    const newLatLng = [+Number(newPosition?.lat).toFixed(5), +Number(newPosition?.lng).toFixed(5)];
    this.map.setView(newLatLng, mapConfig.MAP_SETTINGS.defaultZoomView, mapConfig.MAP_SETTINGS.getMapMoveConfig());
  }

  onConfirmLocationAfterDrag() {
    this.isLocationConfirmBtnVisible = false;
    this.positionService.getInfoFromLatLng(this.currentMapPosition, (e: CustomLocationInfoModel) => {
      console.log('location Information from google', e);
      this.mapLocationDragged.emit(e);
    })
  }
}
