export class User {
    constructor(
        public name:string,
        public email:string,
        public id:number,
        private _token:string,
        private _tokenExpDate:Date
    ){}

    get token(){
        if(!this._tokenExpDate || new Date() > this._tokenExpDate){
            return null
        }
        return this._token;
    }
}