import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PagesService } from '../pages.service';
import { Title, Meta } from '@angular/platform-browser';
import { PositionService } from 'src/app/services/position.service';
import { CustomLocationInfoModel } from 'src/app/models/leaflet';

@Component({
  selector: 'app-store-detail',
  templateUrl: './store-detail.component.html',
  styleUrls: ['./store-detail.component.scss']
})
export class StoreDetailComponent implements OnInit {

  title: any;
  url: any;

  constructor(
    private metaTagService: Meta,
    private pagesService: PagesService,
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private positionService: PositionService) { }

  storesDtl: any = {};
  store: any;
  detail: any;
  lat: any;
  long: any;
  metatags: any = {};
  timing: any = {};

  ngOnInit(): void {
    this.route.params.subscribe(data => {
      this.store = data.url;
      this.pagesService.storeDetail(this.store).subscribe((data: any) => {
        this.storesDtl = data.store;
        this.lat = parseFloat(data.store.latitude);
        this.long = parseFloat(data.store.longitude);
        if (data.metatag != null) {
          this.metatags = data.metatag;
          this.title = this.metatags.meta_title;
          this.timing = data.timings.general;
          this.titleService.setTitle(this.title)
          this.meta.updateTag({
            name: this.metatags.meta_title, keywords: this.metatags.keywords
          });
        }
      });
    });
  }

  public loadJsFile(url: string) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  onOrderNow(storesDtl: any) {
    const storePosition = { lat: storesDtl?.latitude, lng: storesDtl.longitude };
    this.positionService.getInfoFromLatLng(storePosition, (e: CustomLocationInfoModel) => {
      this.router.navigate(['build-your-order'], {
        queryParams: {
          lat: storePosition?.lat,
          lng: storePosition?.lng,
          locationName: e?.name,
          pincode: e?.pincode,
          city: e?.city
        }
      });
    })
  }

}
