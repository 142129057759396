<section class="screen signup">
    <app-auth-wrapper [isLoading]="isLoading">
        <div class="form-wrapper">
            <div class="form-header-wrapper">
                <p>Register yourself here</p>
                <span class="line"></span>
            </div>
            <div class="form-sub-header">
                <span>Already having an account?</span><a href="javascript:void(0)">&nbsp;<b
                        (click)="goToLoginPage()">Login</b></a>
            </div>
            <ng-container *ngIf="false">
                <div class="form-social-media-section">
                    <div class="social-btn" matRipple><img src="assets/images/login/google.svg">Sign Up with Google</div>
                    <div class="social-btn" matRipple><img src="assets/images/login/fb.svg">Sign Up with Facebook </div>
                </div>
                <div class="section-partition">
                    <span class="line"></span>
                    <span class="partition-text">OR</span>
                    <span class="line"></span>
                </div>
            </ng-container>
            <div class="input-form mt-15">
                <form [formGroup]="signupForm">
                    <div class="two-input-box">
                        <div><input class="full-width-input" characterSpaceOnly placeholder="Name" type="text" formControlName="name"
                            [ngClass]="{'invalid': signupForm?.controls?.name?.dirty && !signupForm?.controls?.name?.valid}">
                        </div>
                        <div>
                            <input class="full-width-input" numberOnly maxlength="10" placeholder="Phone no" type="tel" formControlName="phone"
                            [ngClass]="{'invalid': signupForm?.controls?.phone?.dirty && !signupForm?.controls?.phone?.valid}">
                        </div>
                    </div>
                    <input class="full-width-input mt-15" placeholder="Email" type="text" formControlName="email"
                    [ngClass]="{'invalid': signupForm?.controls?.email?.dirty && !signupForm?.controls?.email?.valid}">
                </form>
                <button matRipple class="primary-btn" [ngClass]="{'blur': signupForm.invalid}" [disabled]="signupForm.invalid" (click)="sendOtpForVerification()">Continue</button>
            </div>
            <div class="form-sub-header">
                <span class="api-error" [@enterAnimation] *ngIf="showError">{{ errorMsg }}</span>
            </div>
        </div>
    </app-auth-wrapper>
</section>